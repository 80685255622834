import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';



@Injectable()
export class ClimatizationEndpoint extends EndpointFactory {

  private readonly _climatizationUrl: string = "/api/climatization";

  get climatizationUrl() { return this.configurations.baseUrl + this._climatizationUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  changeTargetTemperatureEndpoint<T>(roomId: number, targetTemperature: number): Observable<T> {

    let endpointUrl = `${this.climatizationUrl}/targetTemperature`;

    return this.http.put<T>(endpointUrl, JSON.stringify({ "roomId": roomId, "targetTemperature": targetTemperature }), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.changeTargetTemperatureEndpoint(roomId, targetTemperature));
      }));
  }

  changeModeEndpoint<T>(roomId: number, mode: number): Observable<T> {

    let endpointUrl = `${this.climatizationUrl}/mode`;

    return this.http.put<T>(endpointUrl, JSON.stringify({ "roomId": roomId, "mode": mode }), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.changeModeEndpoint(roomId, mode));
      }));
  }
}
