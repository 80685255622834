import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class FirmwareVersionEndpoint extends EndpointFactory {

  private readonly _firmwareVersionUrl: string = "/api/firmwareversion";

  get firmwareVersionUrl() { return this.configurations.baseUrl + this._firmwareVersionUrl; }


  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getFirmwareVersionEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.firmwareVersionUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFirmwareVersionEndpoint(id));
      }));
  }
  
  getFirmwareVersionsEndpoint<T>(): Observable<T> {

    return this.http.get<T>(this._firmwareVersionUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFirmwareVersionsEndpoint());
      }));
  }

  getUpdateFirmwareVersionsEndpoint<T>(ids: number[]): Observable<T> {

    return this.http.post<T>(this._firmwareVersionUrl, JSON.stringify(ids), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateFirmwareVersionsEndpoint(ids));
      }));
  }
}
