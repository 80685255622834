import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Alarm } from '../../models/alarm/alarm.model';
import { PagedData } from '../../models/PagedData';
import { AlarmEndpoint } from './alarm-endpoint.service';
import { stagger } from '@angular/animations';

@Injectable()
export class AlarmService {

  constructor(private router: Router, private http: HttpClient,
    private alarmEndpoint: AlarmEndpoint) {

  }
  
  getAlarm(id?: number) {
    if (id) {
      return this.alarmEndpoint.getAlarmEndpoint<Alarm>(id);
    }
  }
  
  getAlarms(page: number = 0, pageSize: number = 9999) {

    return this.alarmEndpoint.getAlarmsEndpoint<PagedData<Alarm>>(page, pageSize);
  }

  updateAlarm(alarm: Alarm) {
    return this.alarmEndpoint.getUpdateAlarmEndpoint<Alarm>(alarm, alarm.id);
  }

  newAlarm(alarm: Alarm) {
    return this.alarmEndpoint.getNewAlarmEndpoint<Alarm>(alarm);
  }

  deleteAlarm(id) {

    return this.alarmEndpoint.getDeleteAlarmEndpoint(id);
  }

  changeProfile(id: number, profileId: number) {
    return this.alarmEndpoint.changeProfile(id, profileId).subscribe();
  }

  changeState(id: number, state: number) {
    return this.alarmEndpoint.changeState(id, state).subscribe();
  }
}
