import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { TranslateService } from "@ngx-translate/core";
import { Alarm } from '../../../models/alarm/alarm.model';
import { AlarmService } from '../../../services/alarm/alarm.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { AppTranslationService } from "../../../services/app-translation.service";
import { Utilities } from '../../../services/utilities';
import { Router } from '@angular/router';
import { AdminAlarmEditorComponent } from './admin-alarm-editor.component';

@Component({
  selector: "admin-alarm-list",
  templateUrl: './admin-alarm-list.component.html',
  styleUrls: ['./admin-alarm-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminAlarmListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'description',  'actions'];
  datasource: null;
  dataSource: MatTableDataSource<Alarm>;
  sourceAlarm: Alarm;
  editingAlarmName: { name: string };
  loadingIndicator: boolean;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private alarmService: AlarmService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    translate: TranslateService,
    private router: Router,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateAlarms(alarm: Alarm) {
    if (this.sourceAlarm) {
      Object.assign(this.sourceAlarm, alarm);
      this.sourceAlarm = null;
    }
    else {
      this.dataSource.data.unshift(alarm);
    }

    this.refresh();
  }

  private loadData() {
    var self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.alarmService.getAlarms(this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.dataSource.data = results.items;
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  public editAlarm(alarm?: Alarm) {
    this.sourceAlarm = alarm;

    if (!alarm) {
      alarm = new Alarm();
    }

    let dialogRef = this.dialog.open(AdminAlarmEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { alarm: alarm}
      });
    dialogRef.afterClosed().subscribe(alarm=> {
      if (alarm) {
        this.updateAlarms(alarm);
      }
    });
  }

  private confirmDelete(alarm: Alarm) {
    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.alarmService.deleteAlarm(alarm.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== alarm)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }


  public editSettings(alarm?: Alarm) {
    this.sourceAlarm = alarm;

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.router.navigate([`/admin/alarm/${alarm.id}/settings`]);
  }
}
