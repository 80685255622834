import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from "./components/login/login.component";
import { DeviceListComponent } from "./components/devices/device-list.component";
import { RoomListComponent } from "./components/rooms/room-list.component";
import { AdminAutomationRuleListComponent } from "./components/automationRules/admin/admin-automation-rule-list.component";
import { SettingsComponent } from "./settings/settings.component";
import { AboutComponent } from "./components/about/about.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AdminFloorListComponent } from "./components/floors/admin/admin-floor-list.component";
import { AdminRoomListComponent } from "./components/rooms/admin/admin-room-list.component";
import { AdminAlarmListComponent } from "./components/alarm/admin/admin-alarm-list.component";
import { AdminRoomActionListComponent } from "./components/rooms/admin/admin-room-action-list.component";
import { AdminModuleListComponent } from "./components/modules/admin/admin-module-list.component";
import { AdminModulePortMapListComponent } from "./components/modules/admin/admin-module-port-map-list.component";
import { AdminCabinetListComponent } from "./components/cabinets/admin/admin-cabinet-list.component";
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { AdminDeviceListComponent } from './components/devices/admin/admin-device-list.component';
import { ModuleResolve } from './services/module/module-resolve';
import { ModulePortMapResolve } from './services/module/module-port-map-resolve';
import { AdminModulePortMapInnerListingComponent } from './components/modules/admin/admin-module-port-map-inner-listing.component';
import { AdminInputMapListComponent } from "./components/inputMapping/admin/admin-input-map-list.component";
import { AdminDevicePortMapListComponent } from './components/devices/admin/admin-device-port-map-listing.component';
import { DeviceResolve } from './services/device/device-resolve';
import { MasterDeviceEditorComponent } from './components/masterdevice/master-device-editor.component';
import { MasterDeviceResolve } from './services/masterDevice/master-device-resolve';
import { AlarmComponent } from './components/alarm/alarm.component';
import { RoomResolve } from './services/room/room-resolve';
import { AlarmZonesResolve } from './services/alarm/alarm-zones-resolve';
import { DevicesResolve } from './services/device/devices-resolve';
import { RoomsResolve } from './services/room/rooms-resolve';
import { AlarmListComponent } from './components/alarm/alarm-list.component';
import { AdminAutomationRuleSettingsComponent } from './components/automationRules/admin/admin-automation-rule-settings.component';
import { AutomationRuleResolve } from './services/automationRule/automation-rule-resolve';
import { AutomationRuleStartActionsResolve } from './services/automationRule/automation-rule-start-action-resolve';
import { AutomationRuleStopActionsResolve } from './services/automationRule/automation-rule-stop-action-resolve';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EventNotificationListComponent } from './components/eventNotifications/event-notification-list.component';
import { RoomComponent } from './components/rooms/room.component';
import { AlarmProfilesResolve } from './services/alarm/alarm-profiles-resolve';
import { AdminAlarmProfileSettingsComponent } from './components/alarm/admin/admin-alarm-profile-settings.component';
import { AlarmProfileResolve } from './services/alarm/alarm-profile-resolve';
import { AutomationListComponent } from './components/automationRules/automation-list.component';
import { AdminSettingsComponent } from './components/settings/admin/admin-settings.component';
import { AdminEnergyZoneListComponent } from './components/energy/admin/admin-energy-zone-list.component';
import { AdminEnergyZoneDeviceListComponent } from './components/energy/admin/admin-energy-zone-device-list.component';
import { EnergyZoneDevicesResolve } from './services/energy/energy-zone-devices.resolve';
import { EnergyZoneResolve } from './services/energy/energy-zone.resolve';
import { EnergyReadingDevicesResolve } from './services/device/energy-reading-devices-resolve';
import { EnergyReadingComponent } from './components/energy/energy-reading.component';
import { EnergyZonesResolve } from './services/energy/energy-zones.resolve';
import { AutomationRulesResolve } from './services/automationRule/automation-rules-resolve';
import { FirmwareListComponent } from './components/firmwareVersion/firmware-list.component';
import { AdminAlarmSettingsComponent } from './components/alarm/admin/admin-alarm-settings.component';
import { AdminAccessKeyListComponent } from './components/accessKeys/admin/admin-access-key-list.component';
import { AccessKeysResolve } from './services/accessKey/access-keys-resolve';
import { AlarmAccessKeysResolve } from './services/alarm/alarm-access-key-resolve';
import { AlarmResolve } from './services/alarm/alarm-resolve';
import { AdminDeviceScheduleListComponent } from './components/devices/admin/admin-device-schedule-list.component';
import { RestoringComponent } from './components/restoring/restoring.component';
import {AdminAccessZoneListComponent} from "./components/accessZones/admin/admin-access-zone-list.component";
import {AccessZonesResolve} from "./services/accessZone/access-zones-resolve";
import {AdminAccessZoneSettingsComponent} from "./components/accessZones/admin/admin-access-zone-settings.component";
import {AccessZoneResolve} from "./services/accessZone/access-zone-resolve";
import {AdminRoomActionSettingsComponent} from "./components/rooms/admin/admin-room-action-settings.component";
import {RoomActionResolve} from "./services/room/room-action-resolve";
import {RoomActionMapsResolve} from "./services/room/room-action-maps-resolve";
import {AdminDeviceGroupListComponent} from "./components/deviceGroups/admin/admin-device-group-list.component";
import {AdminDeviceGroupSettingsComponent} from "./components/deviceGroups/admin/admin-device-group-settings.component";
import {DeviceGroupResolve} from "./services/deviceGroup/device-group-resolve";
import {DeviceFreePortsResolve} from "./services/devicePortMap/device-free-ports-resolve";


const routes: Routes = [
    { path: "", component: DashboardComponent, canActivate: [AuthGuard], data: { title: "Dashboard" }, resolve: { rooms: RoomsResolve } },
    { path: "login", component: LoginComponent, data: { title: "Login" } },
    { path: "settings", component: SettingsComponent, canActivate: [AuthGuard], data: { title: "Settings" } },
    { path: "about", component: AboutComponent, data: { title: "About Us" } },
    { path: "rooms", component: RoomListComponent, canActivate: [AuthGuard], data: { title: "Rooms" }, resolve: { rooms: RoomsResolve } },
    { path: "room/:id", component: RoomComponent, canActivate: [AuthGuard], data: { title: "Room" }, resolve: { room: RoomResolve } },
    { path: "devices", component: DeviceListComponent, canActivate: [AuthGuard], data: { title: "Devices" } },
    { path: "automation", component: AutomationListComponent, canActivate: [AuthGuard], data: { title: "Automation" } },
    { path: "alarms", component: AlarmListComponent, canActivate: [AuthGuard], data: { title: "Alarms" } },
    { path: "energyreadings", component: EnergyReadingComponent, data: { title: "Energy Overview" }, resolve: { zones: EnergyZonesResolve } },
    { path: "alarm/:id", component: AlarmComponent, canActivate: [AuthGuard], data: { title: "Alarms" }, resolve: { alarm: DeviceResolve, alarmZones: AlarmZonesResolve } },
    { path: "eventlogs", component: EventNotificationListComponent, data: { title: "Event Logs" } },
    { path: "admin/automationrules", component: AdminAutomationRuleListComponent, canActivate: [AuthGuard], data: { title: "Automation Rules" }, resolve: { automations: AutomationRulesResolve } },
    { path: "admin/automationrulesettings/:id", component: AdminAutomationRuleSettingsComponent, canActivate: [AuthGuard], data: { title: "Automation Rules" }, resolve: { automationRule: AutomationRuleResolve, automationRuleStartActions: AutomationRuleStartActionsResolve, automationRuleStopActions: AutomationRuleStopActionsResolve, devices: DevicesResolve, rooms: RoomsResolve, zones: EnergyZonesResolve } },
    { path: "admin/floors", component: AdminFloorListComponent, data: { title: "Floors" } },
    { path: "admin/rooms", component: AdminRoomListComponent, data: { title: "Rooms" } },
    { path: "admin/cabinets", component: AdminCabinetListComponent, data: { title: "Cabinets" } },
    { path: "admin/modules", component: AdminModuleListComponent, data: { title: "Modules" } },
    { path: "admin/moduleportmap", component: AdminModulePortMapListComponent, data: { title: "Module Port Map" } },
    { path: "admin/modulemap/:id", component: AdminModulePortMapInnerListingComponent, resolve: { module: ModuleResolve, modulePortMaps: ModulePortMapResolve, freePorts: DeviceFreePortsResolve } },
  { path: "admin/devices", component: AdminDeviceListComponent, data: { title: "Devices" } },
  { path: "admin/devicegroups", component: AdminDeviceGroupListComponent, canActivate: [AuthGuard], data: { title: "DeviceGroups" } },
  { path: "admin/devicegroups/:id/settings", component: AdminDeviceGroupSettingsComponent, canActivate: [AuthGuard], data: { title: "Group Settings" }, resolve: { deviceGroup: DeviceGroupResolve, devices: DevicesResolve, rooms: RoomsResolve}  },
  { path: "admin/deviceschedules", component: AdminDeviceScheduleListComponent, data: { title: "Device Schedules" } },
    { path: "admin/inputmapping", component: AdminInputMapListComponent, data: { title: "Inputs" } },
    { path: "admin/deviceportmap", component: AdminDevicePortMapListComponent, data: { title: "Device Mapping" } },
    { path: "admin/roomactions", component: AdminRoomActionListComponent, data: { title: "Device Mapping" } },
    { path: "admin/roomactions/:id/settings", component: AdminRoomActionSettingsComponent, canActivate: [AuthGuard], data: { title: "Action Settings" }, resolve: { roomAction: RoomActionResolve, devices: DevicesResolve, rooms: RoomsResolve} },
    { path: "admin/energyzones", component: AdminEnergyZoneListComponent, data: { title: "Energy Zones" } },
    { path: "admin/energyzonedevices/:id", component: AdminEnergyZoneDeviceListComponent, data: { title: "Energy Zone Devices" }, resolve: { energyZone: EnergyZoneResolve, energyZoneDevices: EnergyZoneDevicesResolve, devices: EnergyReadingDevicesResolve } },
    { path: "admin/masterdevice", component: MasterDeviceEditorComponent, data: { title: "Master Device Settings" }, resolve: { masterDevice: MasterDeviceResolve } },
    { path: "admin/firmwares", component: FirmwareListComponent, data: { title: "Firmwares" } },
    { path: "admin/alarms", component: AdminAlarmListComponent, canActivate: [AuthGuard], data: { title: "Alarms" } },
    { path: "admin/settings", component: AdminSettingsComponent, canActivate: [AuthGuard], data: { title: "Settings" } },
  { path: "admin/accesskeys", component: AdminAccessKeyListComponent, canActivate: [AuthGuard], data: { title: "Access Keys" } },
  { path: "admin/accesszones", component: AdminAccessZoneListComponent, canActivate: [AuthGuard], data: { title: "Access Zones" }, resolve: { accessZones: AccessZonesResolve} },
  { path: "admin/accesszones/:id/settings", component: AdminAccessZoneSettingsComponent, canActivate: [AuthGuard], data: { title: "Access Zone" }, resolve: { accessZone: AccessZoneResolve, devices: DevicesResolve, rooms: RoomsResolve } },
  { path: "admin/alarm/:id/settings", component: AdminAlarmSettingsComponent, canActivate: [AuthGuard], data: { title: "Alarms" }, resolve: { alarm: AlarmResolve, alarmZones: AlarmZonesResolve, alarmProfiles: AlarmProfilesResolve, alarmAccessKeys: AlarmAccessKeysResolve, allAccessKeys: AccessKeysResolve } },
    { path: "admin/alarm/:id/alarmprofile/:profileId", component: AdminAlarmProfileSettingsComponent, canActivate: [AuthGuard], data: { title: "Alarm Zones" }, resolve: { alarmZones: AlarmZonesResolve, alarmProfile: AlarmProfileResolve, devices: DevicesResolve, rooms: RoomsResolve } },
    { path: "home", redirectTo: "/", pathMatch: "full" },
  { path: "admin/restoring", component: RestoringComponent, data: { title: "Restoring database" } },
    { path: "**", component: NotFoundComponent, data: { title: "Page Not Found" } }
];


@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthService, AuthGuard, ModuleResolve, DeviceResolve, ModulePortMapResolve, MasterDeviceResolve]
})
export class AppRoutingModule { }
