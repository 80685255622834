// =============================
// Email: info@.com
// www..com/templates
// =============================

import { Component, OnInit, OnDestroy, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';

import { AlertService, DialogType, MessageSeverity } from '../../services/alert.service';
import { AppTranslationService } from "../../services/app-translation.service";
import { NotificationService } from "../../services/notification.service";
import { AccountService } from "../../services/account.service";
import { Permission } from '../../models/permission.model';
import { Utilities } from "../../services/utilities";
import { Notification } from '../../models/notification.model';

@Component({
    selector: 'notifications-viewer',
    templateUrl: './notifications-viewer.component.html',
    styleUrls: ['./notifications-viewer.component.scss']
})
export class NotificationsViewerComponent implements OnInit, OnDestroy
{
    displayedColumns = ['date', 'header', 'actions'];
    dataSource: MatTableDataSource<Notification>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

    loadingIndicator: boolean;

    dataLoadingConsecutiveFailures = 0;
    dataLoadingSubscription: any;

    constructor(
        private alertService: AlertService,
        private translationService: AppTranslationService,
        private accountService: AccountService,
        private notificationService: NotificationService,
        private snackBar: MatSnackBar
    )
    {
        this.dataSource = new MatTableDataSource<Notification>();
    }

    ngOnInit()
    {
        this.initDataLoading();
    }

    ngAfterViewInit()
    {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    ngOnDestroy()
    {
        if (this.dataLoadingSubscription)
        {
            this.dataLoadingSubscription.unsubscribe();
        }
    }

    initDataLoading()
    {
        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;

      let dataLoadTask = this.notificationService.getNewNotificationsPeriodically();

      let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
      let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

        this.dataLoadingSubscription = dataLoadTask
            .subscribe(notifications =>
            {
                this.alertService.stopLoadingMessage();
                this.loadingIndicator = false;
                this.dataLoadingConsecutiveFailures = 0;

                this.dataSource.data = notifications;
            },
            error =>
            {
                this.alertService.stopLoadingMessage();
                this.loadingIndicator = false;

              this.alertService.showMessage(errorLabel, errorDetail, MessageSeverity.warn);
                this.alertService.logError(error);

                if (this.dataLoadingConsecutiveFailures++ < 5)
                {
                    setTimeout(() => this.initDataLoading(), 5000);
                }
                else
                {
                  this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error);
                }
            });
    }

    getPrintedDate(value: Date)
    {
        if (value)
        {
            return Utilities.printTimeOnly(value) + " on " + Utilities.printDateOnly(value);
        }
    }

    confirmDelete(notification: Notification)
    {
      let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
      let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
      let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
      let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
      let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

      this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
            .onAction().subscribe(() =>
            {
              this.alertService.startLoadingMessage(deletingLabel);
                this.loadingIndicator = true;

                this.notificationService.deleteNotification(notification)
                    .subscribe(results =>
                    {
                        this.alertService.stopLoadingMessage();
                        this.loadingIndicator = false;

                        this.dataSource.data = this.dataSource.data.filter(item => item.id != notification.id)
                    },
                    error =>
                    {
                        this.alertService.stopLoadingMessage();
                        this.loadingIndicator = false;

                      this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorLabel,
                            MessageSeverity.error, error);
                    });
            });
    }

    togglePin(row: Notification)
    {

      let saveErrorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
      let saveErrorLabel = this.translationService.getTranslation(`shared.SaveError`);
      let savingLabel = this.translationService.getTranslation(`shared.SavingChanges`);
      

        let pin = !row.isPinned;
      let opText = savingLabel;

        this.alertService.startLoadingMessage(opText + "...");
       this.loadingIndicator = true;

        this.notificationService.pinUnpinNotification(row, pin)
            .subscribe(results =>
            {
                this.alertService.stopLoadingMessage();
                this.loadingIndicator = false;

                row.isPinned = pin;
            },
            error =>
            {
                this.alertService.stopLoadingMessage();
                this.loadingIndicator = false;

              this.alertService.showStickyMessage(saveErrorLabel, saveErrorDetail,
                    MessageSeverity.error, error);
            });
    }

    get canManageNotifications()
    {
        return this.accountService.userHasPermission(Permission.manageRolesPermission);
    }
}
