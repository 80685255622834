import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Room } from '../../models/room/room.model';
import { RoomService } from './room.service';
import {RoomAction} from "../../models/room/room-action.model";
import {RoomActionService} from "./room-action.service";

@Injectable()
export class RoomActionResolve implements Resolve<Observable<RoomAction>> {
  constructor(private roomActionService: RoomActionService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<RoomAction> | Promise<RoomAction> | any {
    let id = +route.params['id'];
    return this.roomActionService.getRoomAction(id).catch (error => {
      return Observable.of(null);
    });
  }
}
