import { User } from "../user.model";
import {AccessZoneAction} from "./access-zone-action.model";
import {AccessZoneUser} from "./access-zone-user.model";

export class AccessZone {

  constructor(id: number = 0, name?: string, description?: string, iconPath?: string, authorizedUsers?: AccessZoneUser[], zoneActions?: AccessZoneAction[]) {

    this.id = id;
    this.name = name;
    this.description = description;
    this.iconPath = iconPath;
    this.authorizedUsers = authorizedUsers;
    this.zoneActions = zoneActions;
  }

  public id: number;
  public name: string;
  public description: string;
  public iconPath: string;
  public authorizedUsers: AccessZoneUser[];
  public zoneActions: AccessZoneAction[];
}
