import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable, of } from 'rxjs';
import { Settings } from '../../models/settings/settings.model';
import { SettingsEndpoint } from './settings-endpoint.service';
import { LocalStoreManager } from '../local-store-manager.service';
import { map } from 'rxjs/operators';


@Injectable()
export class SettingsService {

  public DbSettingsKey = "settingslocal";

  constructor(private router: Router, private http: HttpClient,
    private settingsEndpoint: SettingsEndpoint) {

  }

  getSettings(): Observable<Settings> {
    return this.settingsEndpoint.getSettingsEndpoint<Settings>();
  }
  
  updateSettings(settings: Settings): Observable<Settings> {

    return this.settingsEndpoint.getUpdateSettingsEndpoint(settings, settings.id);
  }
}
