import { User } from "../user.model";

export class SelectedUser {

  constructor(user: User, selected: boolean) {

    this.user = user;
    this.selected = selected;
  }

  public user: User;
  public selected: boolean;
}
