import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { DevicePort } from '../../../models/device/device-port.model';
import { Device } from '../../../models/device/device.model';
import { Room } from '../../../models/room/room.model';
import { ModulePortMap } from '../../../models/module/module-port-map.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { ModulePortMapService } from '../../../services/module/module-port-map.service';
import {AppTranslationService} from "../../../services/app-translation.service";
import { IoType } from '../../../models/io-type.model';
import {DevicePortMapping} from "../../../models/device/device-port-mapping.model";
import {parseSelectorToR3Selector} from "@angular/compiler/src/core";



@Component({
  selector: "admin-module-port-map-editor",
  templateUrl: './admin-module-port-map-editor.component.html',
  styleUrls: ['./admin-module-port-map-editor.component.scss'],
  animations: [fadeInOut]
})
export class AdminModulePortMapEditorComponent implements OnInit {

  @ViewChild('form', { static: true })
  private form: NgForm;

  private isSaving: boolean;
  private onModuleSaved = new Subject<ModulePortMap>();

  private moduleId: number;
  private currentPortMap: ModulePortMap = new ModulePortMap();
  public allRooms: Room[];
  public allDevices: Device[];
  public filteredDevices: Device[];
  public allDevicePorts: DevicePort[];
  public isLoadingPorts: boolean = false;
  private unMappedDevicePorts: DevicePortMapping[]

  mappingForm: FormGroup;
  moduleSaved$ = this.onModuleSaved.asObservable();

  get code() {
    return this.mappingForm.get('code');
  }

  get description() {
    return this.mappingForm.get('description');
  }

  get device() {
    return this.mappingForm.get('device');
  }

  get room() {
    return this.mappingForm.get('room');
  }

  get devicePort() {
    return this.mappingForm.get('devicePort');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminModulePortMapEditorComponent>,
    private alertService: AlertService,
    private modulePortMapService: ModulePortMapService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { moduleId: number, modulePortMap: ModulePortMap, devices: Device[], freePorts: DevicePortMapping[], rooms: Room[] },
  ) {
    this.moduleId = data.moduleId;
    this.currentPortMap = data.modulePortMap;

    if (data.devices) {
      this.allDevices = data.devices;
    } else {
      this.allDevices = [];
    }

    if (data.rooms) {
      this.allRooms = data.rooms;
    } else {
      this.allRooms = [];
    }

    if (data.freePorts) {
      this.unMappedDevicePorts = data.freePorts;
    } else {
      this.unMappedDevicePorts = [];
    }

    if (this.currentPortMap.id > 0 && this.currentPortMap.devicePort && this.currentPortMap.devicePort.id > 0) {
      this.unMappedDevicePorts.push(new DevicePortMapping(this.currentPortMap.devicePort.id, this.currentPortMap.device.id, this.currentPortMap.devicePort.port, this.currentPortMap.devicePort.type));
    }

    this.filterDevices((this.currentPortMap.device && this.currentPortMap.device.room) ? this.currentPortMap.device.room.id: 0 );

    this.allDevicePorts = this.currentPortMap && this.currentPortMap.device ? this.currentPortMap.device.devicePorts : [];

    this.buildForm();
    this.resetForm();
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnChanges() {
    this.resetForm();
  }

  private loadData() {  }


  private filterDevices(roomId: number) {
    const ioType = this.currentPortMap.type;
    const isInputOrBoth = ioType == IoType.Input || ioType == IoType.Both;
    const isOutputOrBoth = ioType == IoType.Output || ioType == IoType.Both;

    let deviceIds = this.unMappedDevicePorts.filter(port =>
      isInputOrBoth ? (port.ioType == IoType.Input || port.ioType == IoType.Both) :
        isOutputOrBoth ? (port.ioType == IoType.Output || port.ioType == IoType.Both) :
          port.ioType === ioType
    ).map(x=>x.deviceId);

    var foundDevices = this.allDevices.filter(device =>
      deviceIds.some(x => x == device.id) && (device.roomId == roomId || roomId == 0));
    if (foundDevices.length > 0) {
      var foundDevice = foundDevices[0];
      console.log("RoomId: " + foundDevice.roomId + " deviceId: " + foundDevice.id);
    } else {
      console.log("no device found");
    }

    if (this.currentPortMap.device && this.currentPortMap.device.room) {
      this.filteredDevices = this.allDevices.filter(device =>
        device.room && device.roomId == roomId &&
        deviceIds.some(x => x == device.id)
      );
    } else {
      this.filteredDevices = this.allDevices.filter(device =>
        (roomId === 0 || (device.roomId == roomId)) &&
        deviceIds.some(x => x == device.id)
      );
    }
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.mappingForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    const editedMap = this.getEditeMap();

    this.modulePortMapService.updateModulePortMap(this.moduleId, editedMap.id, editedMap).subscribe(
      response => this.saveSuccessHelper(editedMap),
      error => this.saveFailedHelper(error));
  }

  private getEditeMap(): ModulePortMap {
    const formModel = this.mappingForm.value;

    return {
      id: this.currentPortMap.id,
      code: formModel.code,
      description: formModel.description,
      device: this.allDevices.find(x => x.id == formModel.device),
      devicePort: this.allDevicePorts.find(x => x.id == formModel.devicePort),
      room: this.allRooms.find(x => x.id == formModel.room),
      module: this.currentPortMap.module,
      order: this.currentPortMap.order,
      type: this.currentPortMap.type,
      wire: formModel.wire
    };
  }

  private saveSuccessHelper(modulePortMap?: ModulePortMap) {
    this.alertService.stopLoadingMessage();

    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);

    this.onModuleSaved.next(modulePortMap);

    this.dialogRef.close(modulePortMap);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.mappingForm = this.formBuilder.group({
      code: ['', Validators.required],
      description: '',
      room: [''],
      device: [''],
      devicePort: [''], //in case its asked again, this field cannot be required, unless custom validator is implemented
      wire: ''
    });
  }

  public onRoomChange(id: any) {
    var roomId: number = id.value;

    this.device.setValue(0);
    this.devicePort.setValue(0);

    this.filterDevices(roomId);
  }

  public onDeviceChange(deviceId: any) {
    var id: number = deviceId.value;
    this.devicePort.setValue(0);

    let currentDevice = this.allDevices.find(x => x.id == id);
    if (currentDevice) {

      this.isLoadingPorts = true;
      this.device.setValue(currentDevice.id);

      this.modulePortMapService.getDeviceMappedPorts(currentDevice.id)
        .subscribe(
          response => {
            this.isLoadingPorts = false;

            this.allDevicePorts = currentDevice.devicePorts;

            if (response && response.length > 0) {

              var connectedIds = response.filter(x => x.devicePort).map(x => x.devicePort.id);

              if (this.currentPortMap.type == IoType.Input || this.currentPortMap.type == IoType.Output || this.currentPortMap.type == IoType.Both) {

                if (this.currentPortMap.type == IoType.Input || this.currentPortMap.type == IoType.Both) {
                  this.allDevicePorts = currentDevice
                    .devicePorts
                    .filter(port => !connectedIds.some(x => x === port.id))
                    .filter(port => this.unMappedDevicePorts.some(x=> x.id === port.id))
                    .filter(port => port.type == IoType.Input || port.type == IoType.Both);
                } else {
                  this.allDevicePorts = currentDevice
                    .devicePorts
                    .filter(port => !connectedIds.some(x => x === port.id))
                    .filter(port => this.unMappedDevicePorts.some(x=> x.id === port.id))
                    .filter(port => port.type == IoType.Output || port.type == IoType.Both);
                }
              } else {
                this.allDevicePorts = currentDevice
                  .devicePorts
                  .filter(port => !connectedIds.some(x => x === port.id))
                  .filter(port => port.type == this.currentPortMap.type);
              }

              if (this.allDevicePorts.length == 1) {
                this.devicePort.setValue(this.allDevicePorts[0].id);
              }

            } else {
              this.allDevicePorts = currentDevice.devicePorts;
            }
          });
    }
    else {
      this.allDevicePorts = [];
      this.device.setValue(0);
    }
  }

  public clear() {
    this.mappingForm.reset({
      code: this.currentPortMap.code || '',
      description: this.currentPortMap.description || '',
      room: '',
      device: '',
      devicePort: '',
      wire: '',
    });
  }

  private resetForm(replace = false) {
    this.mappingForm.reset({
      code: this.currentPortMap.code || '',
      description: this.currentPortMap.description || '',
      room: this.currentPortMap.device && this.currentPortMap.device.room ? this.currentPortMap.device.room.id : '',
      device: this.currentPortMap.device ? this.currentPortMap.device.id : '',
      devicePort: this.currentPortMap.devicePort ? this.currentPortMap.devicePort.id : '',
      wire: this.currentPortMap.wire || '',
    });
  }
}
