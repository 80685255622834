import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { ModulePortMapUpdate } from '../../models/module/module-port-map-update.model';
import { ModulePortMap } from '../../models/module/module-port-map.model';
import { PagedData } from '../../models/PagedData';
import { ModulePortMapEndpoint } from './module-port-map-endpoint.service';
import {DevicePortMapping} from "../../models/device/device-port-mapping.model";


@Injectable()
export class ModulePortMapService {

  constructor(private router: Router, private http: HttpClient,
    private modulePortMapEndpoint: ModulePortMapEndpoint) {

  }

  getModulePortMap(moduleId?: number, id?: number) {
    if (id) {
      return this.modulePortMapEndpoint.getModulePortMapEndpoint<ModulePortMap>(moduleId, id);
    }

  }

  getModulePortMaps(moduleId: number, page: number = 0, pageSize: number = 9999) {

    return this.modulePortMapEndpoint.getModulePortMapsEndpoint<PagedData<ModulePortMap>>(moduleId, page, pageSize);
  }

  getDeviceMappedPorts(deviceId: number) {

    return this.modulePortMapEndpoint.getDeviceMappedPorts<ModulePortMap[]>(deviceId);
  }

  getFreePorts() {
    return this.modulePortMapEndpoint.getFreePorts<DevicePortMapping[]>();
  }

  updateModulePortMap(moduleId: number, id: number, modulePortMap: ModulePortMap) {
    return this.modulePortMapEndpoint.getUpdateModulePortMapEndpoint(moduleId, id, modulePortMap);
  }
}
