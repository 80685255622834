import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccessKey } from '../../models/accessKey/access-key.model';
import { PagedData } from '../../models/PagedData';
import { AccessKeyService } from '../accessKey/access-key.service';

@Injectable()
export class AccessKeysResolve implements Resolve<Observable<PagedData<AccessKey>>> {
  constructor(private accessKeyService: AccessKeyService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<PagedData<AccessKey>> | Promise<PagedData<AccessKey>> | any {
    return this.accessKeyService.getAccessKeys(0, 999).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
