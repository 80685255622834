import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { fadeInOut } from '../../services/animations';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import { EnergyZoneDeviceService } from '../../services/energy/energy-zone-device.service';
import { ActivatedRoute } from '@angular/router';
import { EnergyZone } from '../../models/energy/energy-zone.model';
import { EnergyReadingService } from '../../services/energy/energy-reading.service';
import { EnergyReadingViewType } from '../../models/energy/energy-reading-view-type.model';
import * as moment from 'moment';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'energy-reading-pi-chart',
  templateUrl: './energy-reading-pi-chart.component.html',
  styleUrls: ['./energy-reading-pi-chart.component.scss'],
  animations: [fadeInOut]
})
export class EnergyReadingPieChartComponent implements OnInit, OnChanges {
  @Input() zone: EnergyZone;
  @Input() date: Date;
  @Input() viewType: EnergyReadingViewType;

  public consumptionIcon = require("../../assets/images/energy.svg");
  public productionIcon = require("../../assets/images/energy.svg");

  public allZones: EnergyZone[];

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];

  constructor(
    private energyReadingService: EnergyReadingService,
    private route: ActivatedRoute) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    this.refreshData();
  }

  public onViewTypeChange(viewType: EnergyReadingViewType) {
    this.viewType = viewType;

    this.refreshData();

  }

  public refreshData() {
    this.pieChartData =[];
    this.pieChartLabels = []

    this.energyReadingService.getSum(this.viewType, this.date)
      .subscribe(results => {

          if (results) {

            for (var s = 0; s < results.length; s++) {
              this.pieChartLabels.push(results[s].zoneName);

              var kwh = results[s].value == 0 ? 0 : results[s].value / 1000;
              this.pieChartData.push(kwh);
            }
          }
      },
        error => {
        });
  }
}
