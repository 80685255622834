import { Component, Input, ViewChild, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AlarmService } from "../../../services/alarm/alarm.service";
import { Alarm } from '../../../models/alarm/alarm.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlarmType } from '../../../models/alarm/alarm-type.model';
import { DeviceTypeState } from '../../../models/device/device-type-state.model';
import { AlarmZoneState } from '../../../models/alarm/alarm-zone-state.model';
import {AppTranslationService} from "../../../services/app-translation.service";

@Component({
  selector: 'admin-alarm-editor',
  templateUrl: './admin-alarm-editor.component.html',
  styleUrls: ['./admin-alarm-editor.component.scss']
})
export class AdminAlarmEditorComponent {
  @ViewChild('form', { static: true })
  private form: NgForm;

  public isNewAlarm = false;
  private isSaving: boolean;
  private onAlarmSaved = new Subject<Alarm>();

  private alarm: Alarm = new Alarm();

  public allAlarmTypes: string[];

  alarmForm: FormGroup;
  alarmSaved$ = this.onAlarmSaved.asObservable();

  get name() {
    return this.alarmForm.get('name');
  }

  get description() {
    return this.alarmForm.get('name');
  }

  get alarmType() {
    return this.alarmForm.get('alarmType');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminAlarmEditorComponent>,
    private alertService: AlertService,
    private alarmService: AlarmService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { alarm: Alarm },
  )
  {
    this.alarm = data.alarm;
    this.isNewAlarm = data.alarm.id === 0;
    this.buildForm();
    this.resetForm();

    this.allAlarmTypes = Object.keys(AlarmType).filter((item) => {
      return isNaN(Number(item));
    });
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.alarmForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    let savingLabel = this.translationService.getTranslation(`shared.SavingChanges`);

    this.disableSaveButton();
    this.alertService.startLoadingMessage(savingLabel);

    const editedAlarm = this.getEditedAlarm();

    if (this.alarm.id == 0) {
      this.isNewAlarm = true;
      this.alarmService.newAlarm(editedAlarm).subscribe(
        alarm => this.saveSuccessHelper(alarm),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewAlarm = false;
      this.alarmService.updateAlarm(editedAlarm).subscribe(
        response => this.saveSuccessHelper(response),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedAlarm(): Alarm {
    const formModel = this.alarmForm.value;

    const alarmType: string = formModel.alarmType;

    return new Alarm(
      this.alarm.id,
      formModel.name,
      formModel.description,
      AlarmType[alarmType],
      [],
      AlarmZoneState.Off,
      "",
    );
  }

  private saveSuccessHelper(alarm?: Alarm) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewAlarm) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onAlarmSaved.next(alarm);

    this.dialogRef.close(alarm);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.alarmForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
      alarmType: ['', Validators.required],
    });
  }

  private resetForm(replace = false) {
    this.alarmForm.reset({
      name: this.alarm.name || '',
      description: this.alarm.description || '',
      alarmType: AlarmType[this.alarm.alarmType] || '',
    });
  }
}
