import { Component, Inject, ViewChild, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { RoomAction } from '../../../models/room/room-action.model';
import { Room } from '../../../models/room/room.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { RoomActionService } from "../../../services/room/room-action.service";
import { Device } from '../../../models/device/device.model';
import { PickerIconImage } from '../../../models/picker-icon-image.model';
import {AppTranslationService} from "../../../services/app-translation.service";
import { IoType } from '../../../models/io-type.model';
import {MatDialog} from "@angular/material/dialog";
import {DeviceTypeFunction} from "../../../models/device/device-type-function.model";


@Component({
  selector: 'admin-room-action-editor',
  templateUrl: './admin-room-action-editor.component.html',
  styleUrls: ['./admin-room-action-editor.component.scss']
})
export class AdminRoomActionEditorComponent {
  @ViewChild('form', { static: true })
  private form: NgForm;
  fallbackIcon = 'fa fa-book';
  icon: string;

  private isNewRoomAction = false;
  private isSaving: boolean;
  private onRoomActionSaved = new Subject<RoomAction>();

  private roomAction: RoomAction = new RoomAction();
  public allRooms: Room[];

  public allIconImages: PickerIconImage[] = [
    new PickerIconImage(
      "lightOn",
      "./assets/icons/lighton01.svg",
      ["lightOn"]
    ),
    new PickerIconImage(
      "lightOff",
      "./assets/icons/lightoff01.svg",
      ["lightOff"]
    ),
    new PickerIconImage(
      "openBlind",
      "./assets/icons/shutter70.svg",
      ["openBlind"]
    ),
    new PickerIconImage(
      "closeBlind",
      "./assets/icons/shutter0.svg",
      ["closeBlind"]
    ),
    new PickerIconImage(
      "sound",
      "./assets/icons/speaker.svg",
      ["sound"]
    ),
    new PickerIconImage(
      "wind",
      "./assets/icons/wind.svg",
      ["wind"]
    ),
    new PickerIconImage(
      "OpenDoor",
      "./assets/icons/OpenDoor.svg",
      ["OpenDoor"]
    ),
    new PickerIconImage(
      "ClosedDoor",
      "./assets/icons/ClosedDoor.svg",
      ["ClosedDoor"]
    ),
    new PickerIconImage(
      "Kitchen",
      "./assets/icons/kitchen.svg",
      ["kitchen"]
    ),
    new PickerIconImage(
      "support",
      "./assets/icons/apoio.svg",
      ["support"]
    ),
    new PickerIconImage(
      "office",
      "./assets/icons/office.svg",
      ["office"]
    ),
    new PickerIconImage(
      "house",
      "./assets/icons/house.svg",
      ["house"]
    ),
    new PickerIconImage(
      "living_room",
      "./assets/icons/living_room.svg",
      ["living_room"]
    ),
    new PickerIconImage(
      "gate",
      "./assets/icons/gate01.svg",
      ["gate"]
    ),
    new PickerIconImage(
      "sprinklers",
      "./assets/icons/sprinklers.svg",
      ["sprinklers"]
    ),
    new PickerIconImage(
      "ac",
      "./assets/icons/ac.svg",
      ["ac"]
    ),
    new PickerIconImage(
      "energy",
      "./assets/icons/energy.svg",
      ["energy"]
    ),
    new PickerIconImage(
      "gas",
      "./assets/icons/gas.svg",
      ["gas"]
    ),
    new PickerIconImage(
      "gas",
      "./assets/icons/fan.svg",
      ["fan"]
    ),
    new PickerIconImage(
      "water",
      "./assets/icons/valve_water.svg",
      ["water"]
    ),
    new PickerIconImage(
      "exclamation_iconl",
      "./assets/icons/exclamation_iconl.svg",
      ["exclamation_iconl"]
    ),
    new PickerIconImage(
      "fridge",
      "./assets/icons/fridge.svg",
      ["fridge"]
    ),
    new PickerIconImage(
      "remote",
      "./assets/icons/remote.svg",
      ["remote"]
    ),
    new PickerIconImage(
      "drop",
      "./assets/icons/drop.svg",
      ["drop"]
    ),
    new PickerIconImage(
      "clock",
      "./assets/icons/clock.svg",
      ["clock"]
    ),
  ];

  roomActionForm: FormGroup;
  roomActionSaved$ = this.onRoomActionSaved.asObservable();

  get name() {
    return this.roomActionForm.get('name');
  }

  get description() {
    return this.roomActionForm.get('description');
  }

  get room() {
    return this.roomActionForm.get('room');
  }

  get iconImage() {
    return this.roomActionForm.get('iconImage');
  }

  public allDevices: Device[];
  public allDeviceTypeFunctions: DeviceTypeFunction[];

  constructor(
    public dialogRef: MatDialogRef<AdminRoomActionEditorComponent>,
    private alertService: AlertService,
    private roomActionService: RoomActionService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { roomAction: RoomAction, rooms : Room[], devices: Device[], deviceTypeFunctions: DeviceTypeFunction[]},
  )
  {
    let self = this;
    this.roomAction = data.roomAction;
    this.allRooms = data.rooms;
    this.allDevices = data.devices.filter(x => x.ioType == IoType.Both || x.ioType == IoType.Output);
    this.allDeviceTypeFunctions = data.deviceTypeFunctions;


    this.buildForm();
    this.resetForm();
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.roomActionForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    if (this.isSaving) {
      return;
    }

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    const editedRoomAction = this.getEditedRoomAction();

    if (this.roomAction.id == 0) {
      this.isNewRoomAction = true;
      this.roomActionService.newRoomAction(editedRoomAction).subscribe(
        roomAction => this.saveSuccessHelper(roomAction),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewRoomAction = false;
      this.roomActionService.updateRoomAction(editedRoomAction).subscribe(
        response => this.saveSuccessHelper(editedRoomAction),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedRoomAction(): RoomAction {
    const formModel = this.roomActionForm.value;

    return {
      id: this.roomAction.id,
      name: formModel.name,
      description: formModel.description,
      room: this.allRooms.find(x => x.id == formModel.room),
      showOnDash: formModel.showOnDash,
      iconImage: formModel.iconImage != './assets/icons/Asset05.svg' ? formModel.iconImage : '',
    };
  }

  public onDeviceChange(deviceId: any, row: any) {

    let device = this.allDevices.find(x => x.id == deviceId.value);
    if (device) {
      row.currentData.outputDevice = device;
    }
  }

  public onFunctionChange(functionId: any, row: any) {

    let filteredFunction = row.currentData.outputDevice.functions.find(x => x.id == functionId.value);
    if (filteredFunction) {
      row.currentData.outputFunction = filteredFunction;
    }
  }

  public onRoomChange(roomId: any, row: any) {

    let filteredDevices = this.allDevices.filter(x => x.roomId == roomId.value);
    row.currentData.filteredDevices = filteredDevices;
  }

  private saveSuccessHelper(roomAction?: RoomAction) {

    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewRoomAction) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onRoomActionSaved.next(roomAction);

    this.dialogRef.close(roomAction);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    this.enableSaveButton();
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.roomActionForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
      room: ['', Validators.required],
      showOnDash: false,
      icon: '',
      iconImage: './assets/icons/Asset05.svg'
    });
  }

  private resetForm(replace = false) {
    this.enableSaveButton();
    this.roomActionForm.reset({
      name: this.roomAction.name || '',
      description: this.roomAction.description || '',
      room: this.roomAction.room && this.roomAction.room.id ? this.roomAction.room.id : '',
      showOnDash: this.roomAction.showOnDash || false,
      iconImage: this.roomAction.iconImage || './assets/icons/Asset05.svg'
    });
  }

  public selectIconImage(icon: PickerIconImage) {
    this.roomAction.iconImage = icon.path;
    this.roomActionForm.controls['iconImage'].setValue(icon.path);
  }
}
