import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { ZoneAction } from '../../models/alarm/zone-action.model';
import { PagedData } from '../../models/PagedData';
import { ZoneActionEndpoint } from './zone-action-endpoint.service';
import { ZoneActionType } from '../../models/alarm/zone-action-type.model';


@Injectable()
export class ZoneActionService {

  constructor(private router: Router, private http: HttpClient,
    private zoneActionMapEndpoint: ZoneActionEndpoint) {

  }
  
  getZoneAction(id?: number) {
    if (id) {
      return this.zoneActionMapEndpoint.getZoneActionEndpoint<ZoneAction>(id);
    }
  }
  
  getZoneActions(alarmZoneId: number, alarmProfileId: number, zoneActionType: ZoneActionType,  page: number = 0, pageSize: number = 9999) {

    return this.zoneActionMapEndpoint.getZoneActionsEndpoint<PagedData<ZoneAction>>(alarmZoneId, alarmProfileId, zoneActionType, page, pageSize);
  }

  updateZoneAction(zoneActionMap: ZoneAction) {
    return this.zoneActionMapEndpoint.getUpdateZoneActionEndpoint(zoneActionMap, zoneActionMap.id);
  }

  newZoneAction(zoneActionMap: ZoneAction) {
    return this.zoneActionMapEndpoint.getNewZoneActionEndpoint<ZoneAction>(zoneActionMap);
  }

  deleteZoneAction(id): Observable<ZoneAction> {

    return this.zoneActionMapEndpoint.getDeleteZoneActionEndpoint(id);
  }
}
