export class Floor {

  constructor(id: number = 0, name?: string, description?: string) {

    this.id = id;
    this.name = name;
    this.description = description;
  }

  public id: number;
  public name: string;
  public description: string;
}
