import {Component, ElementRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {Subject} from 'rxjs';

import {AlertService, MessageSeverity} from '../../../services/alert.service';
import {ModuleService} from "../../../services/module/module.service";
import {Module} from '../../../models/module/module.model';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Cabinet} from '../../../models/cabinet/cabinet.model';
import {ModuleType} from '../../../models/moduleType/module-type.model';
import {AnnouncementService} from '../../../services/announcement/announcement.service';
import {Announcement} from '../../../models/announcement/announcement.model';
import {HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from "@microsoft/signalr";
import {AuthService} from '../../../services/auth.service';
import {AppTranslationService} from "../../../services/app-translation.service";
import {AnnouncementType} from "../../../models/announcement/announcement-type.model";

@Component({
  selector: 'admin-module-editor',
  templateUrl: './admin-module-editor.component.html',
  styleUrls: ['./admin-module-editor.component.scss']
})
export class AdminModuleEditorComponent implements OnDestroy {
  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewModule = false;
  private isSaving: boolean;
  private onModuleSaved = new Subject<Module>();

  public module: Module = new Module();
  public allCabinets: Cabinet[];
  public allModuleTypes: ModuleType[];

  public allAnnouncements: Announcement[] = [];
  public searchingAnnouncements: boolean;
  public showNoSearchResults: boolean = false;

  private announcementsHubConnection: HubConnection;

  moduleForm: FormGroup;
  moduleSaved$ = this.onModuleSaved.asObservable();

  iconModule = require("../../../assets/images/ioc.svg");

  get name() {
    return this.moduleForm.get('name');
  }

  get description() {
    return this.moduleForm.get('description');
  }

  get serialNumber() {
    return this.moduleForm.get('serialNumber');
  }

  get ipAddress() {
    return this.moduleForm.get('ipAddress');
  }

  get macAddress() {
  return this.moduleForm.get('macAddress');
  }

  get cabinet() {
    return this.moduleForm.get('cabinet');
  }

  get moduleType() {
    return this.moduleForm.get('moduleType');
  }


  constructor(
    public dialogRef: MatDialogRef<AdminModuleEditorComponent>,
    private alertService: AlertService,
    private moduleService: ModuleService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private announcementService: AnnouncementService,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { module: Module, cabinets: Cabinet[], moduleTypes: ModuleType[] },
  ) {
    this.module = data.module;
    this.allCabinets = data.cabinets;
    this.allModuleTypes = data.moduleTypes;

    this.buildForm();
    this.resetForm();

    let builder = new HubConnectionBuilder();

    this.announcementsHubConnection = builder
      .withUrl(location.origin + '/hubs/announcement',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    this.announcementsHubConnection.on("announcement/module", (message) => {
      this.updateAnnouncements(message);
    });
    this.announcementsHubConnection.start().catch(function (err) {
    });
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.moduleForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    if (this.isSaving) {
      return;
    }

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    const editedModule = this.getEditedModule();

    if (this.module.id == 0) {
      this.isNewModule = true;
      this.moduleService.newModule(editedModule).subscribe(
        module => this.saveSuccessHelper(module),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewModule = false;
      this.moduleService.updateModule(editedModule).subscribe(
        response => this.saveSuccessHelper(editedModule),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedModule(): Module {
    const formModel = this.moduleForm.value;

    return {
      id: this.module.id,
      name: formModel.name,
      description: formModel.description,
      serialNumber: formModel.serialNumber,
      ipAddress: formModel.ipAddress,
      macAddress: formModel.macAddress,
      cabinet: this.allCabinets.find(x => x.id == formModel.cabinet),
      moduleType: this.allModuleTypes.find(x => x.id == formModel.moduleType),
      status: ""
    };
  }

  private saveSuccessHelper(module?: Module) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewModule) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onModuleSaved.next(module);

    this.dialogRef.close(module);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    this.enableSaveButton();
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.moduleForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
      serialNumber: '',
      ipAddress: '',
      macAddress: '',
      cabinet: ['', Validators.required],
      moduleType: ['', Validators.required]
    });
  }

  private resetForm(replace = false) {
    this.enableSaveButton();
    this.moduleForm.reset({
      name: this.module.name || '',
      description: this.module.description || '',
      serialNumber: this.module.serialNumber || '',
      ipAddress: this.module.ipAddress || '',
      macAddress: this.module.macAddress || '',
      cabinet: this.module.cabinet && this.module.cabinet.id ? this.module.cabinet.id : '',
      moduleType: this.module.moduleType && this.module.moduleType.id ? this.module.moduleType.id : ''
    });
  }

  public getAnnouncements() {
    let self = this;

    this.searchingAnnouncements = true;
    this.showNoSearchResults = false;

    setTimeout(() => {
      self.searchingAnnouncements = false;
    }, 10000);

    this.announcementService.getAnnouncements(AnnouncementType.Module)
      .subscribe(results => {

        if (results) {
          this.allAnnouncements = [];

          for (const entry of results) {
            const module = this.allModuleTypes.find(x => x.code === entry.type);
            if (module) {
              entry.name = module.name;
              this.allAnnouncements.push(entry);
            }
          }
        }

        if (this.allAnnouncements.length == 0) {
          this.showNoSearchResults = true;
        }
      },
        error => {
        });
  }

  private updateAnnouncements(message: any) {
    if (message && this.searchingAnnouncements) {
      this.allAnnouncements = [];

      for (const entry of message) {
        const module = this.allModuleTypes.find(x => x.code === entry.type);
        if (module) {
          entry.name = module.name;
          this.allAnnouncements.push(entry);
        }
      }
    }
  }

  selectModule(mac: string, type: string, ip: string) {
    const module = this.allModuleTypes.find(x => x.code === type);
    if (module) {
      this.moduleType.setValue(module.id);

      this.name.setValue(module.name);
      this.description.setValue(mac);
    }

    this.macAddress.setValue(mac);
  }

  public getIcon(): string {
    return this.iconModule;
  }

  public triggerIocAnnounce() {
    if (this.announcementsHubConnection.state === HubConnectionState.Disconnected) {

      this.announcementsHubConnection.start().then(() => {

        this.announcementsHubConnection.invoke("emitModuleAnnounce").catch();
      }).catch(function (err) {
      });

    } else if (this.announcementsHubConnection.state === HubConnectionState.Connected) {
      this.announcementsHubConnection.invoke("emitModuleAnnounce").catch();
    }

    this.getAnnouncements();
  }

  ngOnDestroy(): void {
    this.announcementsHubConnection.stop();
  }
}
