import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PagedData } from '../../models/PagedData';
import {AccessZone} from "../../models/accessZone/access-zone.model";
import {AccessZoneService} from "./access-zone.service";

@Injectable()
export class AccessZonesResolve implements Resolve<Observable<PagedData<AccessZone>>> {
  constructor(private accessZoneService: AccessZoneService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<PagedData<AccessZone>> | Promise<PagedData<AccessZone>> | any {
    return this.accessZoneService.getAccessZones(0, 999).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
