import { Device } from "../device/device.model";
import { DeviceFunction } from "../device/device-function.model";
import { ZoneActionType } from "./zone-action-type.model";
import { ZoneActionParameter } from "./zone-action-parameter.model";

export class ZoneAction {

  constructor(id: number = 0, alarmZoneId?: number, alarmProfileId?: number, waitTime?: number, device?: Device, deviceFunction?: DeviceFunction, type?: ZoneActionType, parameters?: ZoneActionParameter[]) {

    this.id = id;
    this.alarmZoneId = alarmZoneId;
    this.alarmProfileId = alarmProfileId;
    this.device = device;
    this.deviceFunction = deviceFunction;
    this.waitTime = waitTime;
    this.type = type;
    this.parameters = parameters;
  }

  public id: number;
  public waitTime: number;
  public alarmZoneId: number;
  public alarmProfileId: number;
  public device: Device;
  public type: ZoneActionType;
  public deviceFunction: DeviceFunction;
  public parameters: ZoneActionParameter[];
}
