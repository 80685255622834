import { Device } from "../device/device.model";

export class EnergyZoneDevice {

  constructor(id: number = 0, energyZoneId?: number, deviceId?: number, device?: Device) {

    this.id = id;
    this.energyZoneId = energyZoneId;
    this.deviceId = deviceId;
    this.device = device;
  }

  public id: number;
  public energyZoneId: number;
  public deviceId: number;
  public device: Device
}
