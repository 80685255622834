import { Component, Input, OnDestroy } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'climatization-card',
  templateUrl: './climatization-card.component.html',
  styleUrls: ['./climatization-card.component.scss']
})

export class ClimatizationCardComponent implements OnDestroy {
  
  @Input() roomId: number;
  temperature: number = 0;

  private hubConnection: HubConnection;

  iconFire = require("../../../assets/images/escudo.svg");
  
  constructor(
    private authService: AuthService,
  ) {
    this.buildHubConnection();
  }
    ngOnDestroy(): void {
      this.hubConnection.stop();
    }
  
  ngOnInit() {

    this.buildHubConnection();
  }

  private connect() {
    const self = this;
    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      this.hubConnection.start().then(() => {
        this.hubConnection.invoke("emitHumidity", self.roomId).catch(function (err) {
        });
      }).catch(err => console.error(err.toString()));
    } else if (this.hubConnection.state === HubConnectionState.Connected) {
      this.hubConnection.invoke("emitHumidity", self.roomId).catch(function (err) {
      });
    }
  }

  private buildHubConnection() {
    let builder = new HubConnectionBuilder();

    this.hubConnection = builder
      .withUrl(location.origin + '/hubs/currentHumidity',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.on("currentHumidity", (message) => {
      //TODO
    });

    

  }
}
