import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class ModulePortMapEndpoint extends EndpointFactory {

  private readonly _modulePortMapUrl: string = "/api/module/{moduleId}/portmaps";

  get modulePortMapUrl() { return this.configurations.baseUrl + this._modulePortMapUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  getModulePortMapEndpoint<T>(moduleId:number, id: number): Observable<T> {
    let endpointUrl = `${this.modulePortUrlWithId(moduleId)}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getModulePortMapEndpoint(moduleId, id));
      }));
  }

  getModulePortMapsEndpoint<T>(moduleId: number, page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this.modulePortUrlWithId(moduleId)}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getModulePortMapsEndpoint(page, pageSize));
      }));
  }

  getUpdateModulePortMapEndpoint<T>(moduleId: number, id: number, modulePortMapObject: any): Observable<T> {
    let endpointUrl = `${this.modulePortUrlWithId(moduleId)}/${id}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(modulePortMapObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateModulePortMapEndpoint(moduleId, id, modulePortMapObject));
      }));
  }

  getDeviceMappedPorts<T>(deviceId: number): Observable<T> {

    let endpointUrl = `${this.configurations.baseUrl}/api/device/${deviceId}/ports/mapped`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeviceMappedPorts(deviceId));
      }));
  }

  getFreePorts<T>(): Observable<T> {

    let endpointUrl = `${this.configurations.baseUrl}/api/device/ports/unmapped`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFreePorts());
      }));
  }

  private modulePortUrlWithId(moduleId: number): string {
    var urlWithId = this._modulePortMapUrl.replace("{moduleId}", moduleId.toString());

    return this.configurations.baseUrl + urlWithId;
  }

}
