import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ValidatorService } from 'angular4-material-table';

@Injectable()
export class ParameterValidatorService implements ValidatorService {
  getRowValidator(): FormGroup {
    return new FormGroup({
      'id': new FormControl(),
      'code': new FormControl(),
      'description': new FormControl(),
      'valueType': new FormControl(),
      'value': new FormControl(),
      'source': new FormControl(),
      'deviceId': new FormControl(),
      'deviceTypeValueId': new FormControl()
    });
  }
}
