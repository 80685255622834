import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Room } from 'src/app/models/room/room.model';
import { AlertService } from 'src/app/services/alert.service';
import { fadeInOut } from '../../services/animations';
import { SettingsService } from '../../services/settings/settings.service';
import { Settings } from '../../models/settings/settings.model';
import { HttpTransportType,  HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [fadeInOut]
})

export class DashboardComponent implements OnInit, OnDestroy {

  loadingIndicator: boolean;
  allRooms: Room[] = [];
  settings: Settings = new Settings();

  private hubConnection: HubConnection;

  constructor(
    private alertService: AlertService,
    private route: ActivatedRoute,
    private settingService: SettingsService,
    private authService: AuthService) {
  }
  
  ngOnInit(): void {
    this.buildHubConnection();

    this.settingService.getSettings().subscribe(result => {
      this.settings = result;
    },
      err => this.settings = new Settings());
  }

  private buildHubConnection() {
    let self = this;

    let builder = new HubConnectionBuilder();

    this.hubConnection = builder
      .withUrl(location.origin + "/hubs/weatherStation",
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.on("forecast", (message) => {

    });

    
  }

  private connect() {
    let self = this;

    if (this.hubConnection.state === HubConnectionState.Disconnected) {

      this.hubConnection.start().then(() => {
        this.hubConnection.invoke("emitForecast").catch(function (err) {
        });
      }).catch(function (err) {
      });

    } else if (this.hubConnection.state === HubConnectionState.Connected) {
      this.hubConnection.invoke("emitForecast").catch(function (err) {
      });
    }
  }

  ngOnDestroy(): void {
    this.hubConnection.stop();
  }
}
