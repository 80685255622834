import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EnergyZone } from '../../models/energy/energy-zone.model';
import { PagedData } from '../../models/PagedData';
import { EnergyZoneService } from './energy-zone.service';

@Injectable()
export class EnergyZonesResolve implements Resolve<Observable<PagedData<EnergyZone>>> {
  constructor(private energyZoneService: EnergyZoneService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<PagedData<EnergyZone>> | Promise<PagedData<EnergyZone>> | any  {
    return this.energyZoneService.getEnergyZones().catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
