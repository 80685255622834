import {Component, OnInit, AfterViewInit, ViewChild, Inject} from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';
import {fadeInOut} from "../../../services/animations";
import {AlertService, MessageSeverity} from "../../../services/alert.service";
import {AppTranslationService} from "../../../services/app-translation.service";
import {AccountService} from "../../../services/account.service";
import {User} from "../../../models/user.model";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {SelectedUser} from "../../../models/accessZone/selected-user.model";
import {AccessZoneActionService} from "../../../services/accessZone/access-action.service";
import {AccessZoneAction} from "../../../models/accessZone/access-zone-action.model";
import {AccessZone} from "../../../models/accessZone/access-zone.model";
import {AccessZoneService} from "../../../services/accessZone/access-zone.service";
import {AccessZoneUser} from "../../../models/accessZone/access-zone-user.model";

@Component({
  selector: 'admin-user-user-list',
  templateUrl: './admin-user-list.component.html',
  styleUrls: ['./admin-user-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminUserListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['fullName', 'actions'];
  dataSource: MatTableDataSource<SelectedUser>;
  sourceUser: User;
  loadingIndicator: boolean;

  private authorizedUsers: AccessZoneUser[];
  private accessZone: AccessZone;
  private isSaving: boolean;

  constructor(
    public dialogRef: MatDialogRef<AdminUserListComponent>,
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private accountService: AccountService,
    private accessZoneService: AccessZoneService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { accessZone: AccessZone},) {

    this.authorizedUsers = data.accessZone.authorizedUsers;
    this.accessZone = data.accessZone;

    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.accountService.getUsers().subscribe(
      users => this.onDataLoadSuccessful(users),
      error => this.onDataLoadFailed(error)
    );
  }

  public selectAll() {
    for (let user of this.dataSource.data) {
      user.selected = true;
    }
  }

  public toggle(user: SelectedUser) {
    //user.selected = !user.selected;
  }

  private disableSaveButton() {
      this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
    if (this.isSaving) {
      return;
    }

    this.disableSaveButton();

    for (var selUser of this.dataSource.data) {
      if (selUser.selected) {

        var authUser = new AccessZoneUser(0, selUser.user.fullName, selUser.user.id, this.accessZone.id);

        this.accessZone.authorizedUsers.push(authUser);
      }
    }

    this.accessZoneService.updateAccessZone(this.accessZone).subscribe(
      zone => this.saveSuccessHelper(zone),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(accessZone?: AccessZone) {
    this.alertService.stopLoadingMessage();

    let updated = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    this.alertService.showMessage(successLabel, updated, MessageSeverity.success);

    this.dialogRef.close(accessZone);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {
    this.enableSaveButton();

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  private onDataLoadSuccessful(users: User[]) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    let listOfUsers =[];
    for (let user of users) {
     let isAuthorized = this.authorizedUsers.find(x=> x.userId == user.id);
     if (!isAuthorized) {
       listOfUsers.push(new SelectedUser(user, false));
     }
    }
    this.dataSource.data = listOfUsers;
  }

  private onDataLoadFailed(error: any) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  public cancel() {
    this.dialogRef.close(null);
    this.enableSaveButton();
  }

}
