import {Component, OnInit} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {fadeInOut} from '../../services/animations';
import {FormControl} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material';
import {ActivatedRoute} from '@angular/router';
import {EnergyZone} from '../../models/energy/energy-zone.model';
import {EnergyReadingService} from '../../services/energy/energy-reading.service';
import {EnergyReadingViewType} from '../../models/energy/energy-reading-view-type.model';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'energy-reading',
  templateUrl: './energy-reading.component.html',
  styleUrls: ['./energy-reading.component.scss'],
  animations: [fadeInOut]
})
export class EnergyReadingComponent implements OnInit {


  public consumptionIcon = require("../../assets/images/energy.svg");
  public productionIcon = require("../../assets/images/energy.svg");

  public selectedDate: Date = new Date();
  public viewType: EnergyReadingViewType = EnergyReadingViewType.Year;
  public selectedViewType: string = "Hour";
  public allZones: EnergyZone[];

  public date = new FormControl(new Date());

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Series A' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Series B' }
  ];

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };
  public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartData: number[] = [300, 500, 100];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];

  constructor(
    private energyReadingService: EnergyReadingService,
    private route: ActivatedRoute) { }

  ngOnInit() {

    let self = this;
    let pagedData = this.route.snapshot.data['zones'];
    if (pagedData) {
      this.allZones = pagedData.items
        .sort(function (a,b) {

          if (a.mainZone) {
            return -1;
          }

          if (a.id> b.id) {
            return 0
          }

          return 1;
      });
    }
    this.date.setValue(new Date());
  }



  public onViewTypeChange(type: string) {
    this.viewType = EnergyReadingViewType[type];
    this.selectedViewType = type;

    this.refreshData();
  }

  public refreshData() {

  }

  public onDateChange(date: MatDatepickerInputEvent<Date>) {
    this.selectedDate = date.value;
    this.date.setValue(date.value);
    //this.refreshData();
  }

  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  changeLegendPosition(): void {
    this.pieChartOptions.legend.position = this.pieChartOptions.legend.position === 'left' ? 'top' : 'left';
  }
}
