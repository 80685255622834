import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { AlarmAccessKey } from '../../../models/alarm/alarm-access-key.model';
import { Device } from '../../../models/device/device.model';
import { AlarmAccessKeyService } from "../../../services/alarm/alarm-access-key.service";
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AccessKey } from '../../../models/accessKey/access-key.model';
import { AlarmProfile } from '../../../models/alarm/alarm-profile.model';
import { Alarm } from '../../../models/alarm/alarm.model';
import { PagedData } from '../../../models/PagedData';
import {AppTranslationService} from "../../../services/app-translation.service";



@Component({
  selector: 'admin-alarm-access-key-editor',
  templateUrl: './admin-alarm-access-key-editor.component.html',
  styleUrls: ['./admin-alarm-access-key-editor.component.scss']
})
export class AdminAlarmAccessKeyEditorComponent implements OnInit {
  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewAlarmAccessKey = false;
  private isSaving = false;
  private onAlarmAccessKeySaved = new Subject<AlarmAccessKey>();

  public isModuleVisible = false;
  public alarmAccessKey: AlarmAccessKey = new AlarmAccessKey();
  public alarm: Alarm = new Alarm();

  alarmAccessKeyForm: FormGroup;
  onAlarmAccessKeySaved$ = this.onAlarmAccessKeySaved.asObservable();

  allAccessKeys: AccessKey[];
  allProfiles: AlarmProfile[];

  get accessKey() {
    return this.alarmAccessKeyForm.get('accessKey');
  }

  get armedProfile() {
    return this.alarmAccessKeyForm.get('armedProfile');
  }

  get disarmedProfile() {
    return this.alarmAccessKeyForm.get('disarmedProfile');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminAlarmAccessKeyEditorComponent>,
    private alertService: AlertService,
    private alarmAccessKeyService: AlarmAccessKeyService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { alarmAccessKey: AlarmAccessKey; alarm: Alarm; allAccessKeys: AccessKey[]; allProfiles: AlarmProfile[] },
  ) {
    this.alarmAccessKey = data.alarmAccessKey;
    this.alarm = data.alarm;
    this.allAccessKeys = data.allAccessKeys;
    this.allProfiles = data.allProfiles;

    this.buildForm();
    this.resetForm();
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.alarmAccessKeyForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.disableSaveButton();
    this.alertService.startLoadingMessage("Saving changes...");

    const editedAlarmAccessKey = this.getEditedAlarmAccessKey();

    if (this.alarmAccessKey.id == 0) {
      this.isNewAlarmAccessKey = true;
      this.alarmAccessKeyService.newAlarmAccessKey(this.alarm.id, editedAlarmAccessKey).subscribe(
        alarmAccessKey => this.saveSuccessHelper(alarmAccessKey),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewAlarmAccessKey = false;
      this.alarmAccessKeyService.updateAlarmAccessKey(this.alarm.id, editedAlarmAccessKey).subscribe(
        response => this.saveSuccessHelper(response),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedAlarmAccessKey(): AlarmAccessKey {
    const formModel = this.alarmAccessKeyForm.value;

    return {
      id: this.alarmAccessKey.id,
      alarmId: this.alarm.id,
      armedProfile: null,
      armedProfileId: formModel.armedProfile,
      accessKey: null,
      disarmedProfile: null,
      disarmedProfileId: formModel.disarmedProfile,
      accessKeyId: formModel.accessKey
    };
  }

  private saveSuccessHelper(alarmAccessKey?: AlarmAccessKey) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewAlarmAccessKey) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onAlarmAccessKeySaved.next(alarmAccessKey);

    this.dialogRef.close(alarmAccessKey);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {
    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.alarmAccessKeyForm = this.formBuilder.group({
      armedProfile: ['', Validators.required],
      disarmedProfile: ['', Validators.required],
      accessKey: ['', Validators.required],
    });
  }

  private resetForm(replace = false) {
    this.alarmAccessKeyForm.reset({
      alarmId: this.alarmAccessKey.alarmId || '',
      armedProfile: this.alarmAccessKey.armedProfileId || '',
      disarmedProfile: this.alarmAccessKey.disarmedProfileId || '',
      accessKey: this.alarmAccessKey.accessKeyId
    });
  }
}
