import { Component, Input, ViewChild, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AccessKeyService } from "../../../services/accessKey/access-key.service";
import { AccessKey } from '../../../models/accessKey/access-key.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User } from '../../../models/user.model';
import { AccessKeyType } from '../../../models/accessKey/access-key-type.model';
import {AppTranslationService} from "../../../services/app-translation.service";
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'admin-accessKey-editor',
  templateUrl: './admin-access-key-editor.component.html',
  styleUrls: ['./admin-access-key-editor.component.scss']
})
export class AdminAccessKeyEditorComponent {
  @ViewChild('form', { static: true })
  private form: NgForm;

  public isNewAccessKey = false;
  private onAccessKeySaved = new Subject<AccessKey>();
  public isCodeMasked = true;
  public showCardLabel = false;

  private accessKey: AccessKey = new AccessKey();
  private hubConnection: HubConnection;
  private isSaving = false;

  allUsers: User[];
  allKeyTypes: string[];

  accessKeyForm: FormGroup;
  accessKeySaved$ = this.onAccessKeySaved.asObservable();

  get name() {
    return this.accessKeyForm.get('name');
  }

  get code() {
    return this.accessKeyForm.get('code');
  }

  get keyType() {
    return this.accessKeyForm.get('keyType');
  }

  get user() {
    return this.accessKeyForm.get('user');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminAccessKeyEditorComponent>,
    private alertService: AlertService,
    private accessKeyService: AccessKeyService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { accessKey: AccessKey, allUsers: User[], allKeyTypes: string[] },
  )
  {
    this.accessKey = data.accessKey;
    this.allUsers = data.allUsers;
    this.allKeyTypes = data.allKeyTypes;
    this.buildForm();
    this.resetForm();


    this.isCodeMasked = this.accessKey.keyType === AccessKeyType.Code? true : false;

    if (this.accessKey.id === 0) {
      this.isNewAccessKey = true
    }

    let builder = new HubConnectionBuilder();

    this.hubConnection = builder
      .withUrl(location.origin + '/hubs/newcard',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();
    this.hubConnection.on("announce", (message) => {
      this.setCode(message);
    });

    this.connect();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  private connect() {
    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      this.hubConnection.start().catch(function (err) {
      });
    }
  }

  private setCode(message: any) {
    if (message && message.ck && this.showCardLabel && this.code.value === '') {
      this.code.setValue(message.cd);
    }
  }

  ngOnChanges() {
    this.resetForm();
  }

  public save() {
     
    if (!this.accessKeyForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    if (this.isSaving) {
      return;
    }

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.SavingChanges`));

    const editedAccessKey = this.getEditedAccessKey();

    if (this.accessKey.id == 0) {
      this.isNewAccessKey = true;
      this.accessKeyService.newAccessKey(editedAccessKey).subscribe(
        accessKey => this.saveSuccessHelper(accessKey),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewAccessKey = false;
      this.accessKeyService.updateAccessKey(editedAccessKey).subscribe(
        response => this.saveSuccessHelper(editedAccessKey),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedAccessKey(): AccessKey {
    const formModel = this.accessKeyForm.value;

    var keyType: string = formModel.keyType;

    return {
      id: this.accessKey.id,
      name: formModel.name,
      description: formModel.description,
      code: formModel.code,
      keyType: AccessKeyType[keyType],
      userId: formModel.user,
      user: new User()
    };
  }

  private saveSuccessHelper(accessKey?: AccessKey) {
    this.alertService.stopLoadingMessage();

    let created = this.translationService.getTranslation(`shared.DataCreated`);
    let updated = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewAccessKey) {
      this.alertService.showMessage(successLabel, created, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updated, MessageSeverity.success);
    }

    this.onAccessKeySaved.next(accessKey);

    this.dialogRef.close(accessKey);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    this.enableSaveButton();
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  public onTypeChange(event) {
    this.accessKeyForm.patchValue({ code: '' });

    if(AccessKeyType[event.value] == AccessKeyType.Card.toString())
    {
       this.showCardLabel = true;
    }
    else
    {
      this.showCardLabel = false;
    }

    this.isCodeMasked = AccessKeyType[event.value] == AccessKeyType.Code.toString() ? true : false;
  }

  private buildForm() {
    this.accessKeyForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
      code: '',
      keyType: ['', Validators.required],
      user: 0,

    });
  }

  private resetForm(replace = false) {
    this.enableSaveButton();

    this.accessKeyForm.reset({
      name: this.accessKey.name || '',
      description: this.accessKey.description || '',
      code: this.accessKey.code || '',
      keyType: AccessKeyType[this.accessKey.keyType] || '',
      user: this.accessKey.userId ? this.accessKey.userId : 0,
    });
  }
}
