import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { EventNotification } from 'src/app/models/eventNotification/event-notification.model';
import { AlertService } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { EventNotificationService } from 'src/app/services/eventNotification/event-notification.service';

@Component({
  selector: 'alerts-card',
  templateUrl: './alerts-card.component.html',
  styleUrls: ['./alerts-card.component.scss']
})

export class AlertsCardComponent implements OnInit, AfterViewInit {

  displayedColumns = ['title', 'date'];
  datasource: null;
  dataSource: MatTableDataSource<EventNotification>;
  sourceEventNotification: EventNotification;
  editingEventNotificationName: { name: string };

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private eventNotificationService: EventNotificationService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateEventNotifications(eventNotification: EventNotification) {
    if (this.sourceEventNotification) {
      Object.assign(this.sourceEventNotification, eventNotification);
      this.sourceEventNotification = null;
    }
    else {
      this.dataSource.data.unshift(eventNotification);
    }

    this.refresh();
  }

  private loadData() {
    var self = this;

    this.eventNotificationService.getAlarms(0, 10)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();

        this.dataSource.data = results.items;
      },
        error => {
          this.alertService.stopLoadingMessage();
        });
  }
}
