import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog, PageEvent, Sort } from '@angular/material';

import { fadeInOut } from '../../../services/animations';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from "../../../services/app-translation.service";
import { RoomService } from '../../../services/room/room.service';
import { Room } from '../../../models/room/room.model';
import { AdminRoomEditorComponent } from "./admin-room-editor.component";
import { Floor } from '../../../models/floor/floor.model';
import { FloorService } from '../../../services/floor/floor.service';

@Component({
  selector: "admin-room-list",
  templateUrl: './admin-room-list.component.html',
  styleUrls: ['./admin-room-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminRoomListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'description', 'floor', 'tabOrder', 'actions'];
  datasource: null;
  dataSource: MatTableDataSource<Room>;
  sourceRoom: Room;
  editingRoomName: { name: string };
  loadingIndicator: boolean;

  allFloors: Floor[] = [];

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private roomService: RoomService,
    private floorService: FloorService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateRooms(room: Room) {
    if (this.sourceRoom) {
      Object.assign(this.sourceRoom, room);
      this.sourceRoom = null;
    }
    else {
      this.dataSource.data.unshift(room);
    }

    this.loadData();

    this.refresh();
  }

  private loadData() {
    var self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.floorService.getFloors()
      .subscribe(results => {
        this.allFloors = results.items;
      },
        error => {

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });

    this.roomService.getRooms(this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.dataSource.data = results.items;
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  public editRoom(room?: Room) {
    this.sourceRoom = room;

    if (!room) {
      room = new Room();

      if(this.dataSource.data.length > 0) {
        var sortedRooms = this.dataSource.data.sort((a, b) => {
          return this.compare(a.tabOrder, b.tabOrder, true);
        });

        var last = sortedRooms[sortedRooms.length-1];
        room.tabOrder = last.tabOrder + 1;

      } else {
        room.tabOrder = 1;
      }
    }

    let dialogRef = this.dialog.open(AdminRoomEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { room: room, floors: [...this.allFloors] }
      });
    dialogRef.afterClosed().subscribe(room => {
      if (room) {
        this.updateRooms(room);
      }
    });
  }

  private confirmDelete(room: Room) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.roomService.deleteRoom(room.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== room)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data;
    let sortedData = this.dataSource.data
    if (!sort.active || sort.direction === '') {
      sortedData = data;
      return;
    }

    sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'description': return this.compare(a.description, b.description, isAsc);
        case 'floor': return this.compare(a.floor ? a.floor.name : '', b.floor ? b.floor.name : '', isAsc);
        default: return 0;
      }
    });

    this.dataSource.data = sortedData;
  }

  public compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
