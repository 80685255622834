import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { AlarmProfile } from '../../../models/alarm/alarm-profile.model';
import { AlarmProfileService } from "../../../services/alarm/alarm-profile.service";
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { Device } from '../../../models/device/device.model';
import { PickerIconImage } from '../../../models/picker-icon-image.model';
import {AppTranslationService} from "../../../services/app-translation.service";



@Component({
  selector: 'admin-alarm-profile-editor',
  templateUrl: './admin-alarm-profile-editor.component.html',
  styleUrls: ['./admin-alarm-profile-editor.component.scss']
})
export class AdminAlarmProfileEditorComponent implements OnInit {
  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewAlarmProfile = false;
  private isSaving = false;
  private onAlarmProfileSaved = new Subject<AlarmProfile>();

  public isModuleVisible = false;
  public alarmProfile: AlarmProfile = new AlarmProfile();
  public alarm: Device = new Device();

  alarmProfileForm: FormGroup;
  onAlarmProfileSaved$ = this.onAlarmProfileSaved.asObservable();

  public allIconImages: PickerIconImage[] = [
    new PickerIconImage(
      "lightOn",
      "./assets/icons/lighton01.svg",
      ["lightOn"]
    ),
    new PickerIconImage(
      "lightOff",
      "./assets/icons/lightoff01.svg",
      ["lightOff"]
    ),
    new PickerIconImage(
      "openBlind",
      "./assets/icons/shutter70.svg",
      ["openBlind"]
    ),
    new PickerIconImage(
      "closeBlind",
      "./assets/icons/shutter0.svg",
      ["closeBlind"]
    ),
    new PickerIconImage(
      "sound",
      "./assets/icons/speaker.svg",
      ["sound"]
    ),
    new PickerIconImage(
      "wind",
      "./assets/icons/wind.svg",
      ["wind"]
    ),
    new PickerIconImage(
      "OpenDoor",
      "./assets/icons/OpenDoor.svg",
      ["OpenDoor"]
    ),
    new PickerIconImage(
      "ClosedDoor",
      "./assets/icons/ClosedDoor.svg",
      ["ClosedDoor"]
    ),
    new PickerIconImage(
      "Kitchen",
      "./assets/icons/kitchen.svg",
      ["kitchen"]
    ),
    new PickerIconImage(
      "support",
      "./assets/icons/apoio.svg",
      ["support"]
    ),
    new PickerIconImage(
      "office",
      "./assets/icons/office.svg",
      ["office"]
    ),
    new PickerIconImage(
      "house",
      "./assets/icons/house.svg",
      ["house"]
    ),
    new PickerIconImage(
      "living_room",
      "./assets/icons/living_room.svg",
      ["living_room"]
    ),
    new PickerIconImage(
      "gate",
      "./assets/icons/gate01.svg",
      ["gate"]
    ),
    new PickerIconImage(
      "sprinklers",
      "./assets/icons/sprinklers.svg",
      ["sprinklers"]
    ),
    new PickerIconImage(
      "ac",
      "./assets/icons/ac.svg",
      ["ac"]
    ),
    new PickerIconImage(
      "energy",
      "./assets/icons/energy.svg",
      ["energy"]
    ),
    new PickerIconImage(
      "gas",
      "./assets/icons/gas.svg",
      ["gas"]
    ),
    new PickerIconImage(
      "gas",
      "./assets/icons/fan.svg",
      ["fan"]
    ),
    new PickerIconImage(
      "water",
      "./assets/icons/valve_water.svg",
      ["water"]
    ),
    new PickerIconImage(
      "exclamation_iconl",
      "./assets/icons/exclamation_iconl.svg",
      ["exclamation_iconl"]
    ),
    new PickerIconImage(
      "fridge",
      "./assets/icons/fridge.svg",
      ["fridge"]
    ),
    new PickerIconImage(
      "remote",
      "./assets/icons/remote.svg",
      ["remote"]
    ),
    new PickerIconImage(
      "drop",
      "./assets/icons/drop.svg",
      ["drop"]
    ),
    new PickerIconImage(
      "clock",
      "./assets/icons/clock.svg",
      ["clock"]
    ),
  ];


  get name() {
    return this.alarmProfileForm.get('name');
  }

  get description() {
    return this.alarmProfileForm.get('description');
  }

  get iconPath() {
    return this.alarmProfileForm.get('iconPath');
  }

  get waitTime() {
    return this.alarmProfileForm.get('waitTime');
  }


  constructor(
    public dialogRef: MatDialogRef<AdminAlarmProfileEditorComponent>,
    private alertService: AlertService,
    private alarmProfileService: AlarmProfileService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { alarmProfile: AlarmProfile, alarm: Device },
  ) {
    this.alarmProfile = data.alarmProfile;
    this.alarm = data.alarm;


    this.buildForm();
    this.resetForm();
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.alarmProfileForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    const editedAlarmProfile = this.getEditedAlarmProfile();

    if (this.alarmProfile.id == 0) {
      this.isNewAlarmProfile = true;
      this.alarmProfileService.newAlarmProfile(editedAlarmProfile).subscribe(
        alarmProfile => this.saveSuccessHelper(alarmProfile),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewAlarmProfile = false;
      this.alarmProfileService.updateAlarmProfile(editedAlarmProfile).subscribe(
        response => this.saveSuccessHelper(editedAlarmProfile),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedAlarmProfile(): AlarmProfile {
    const formModel = this.alarmProfileForm.value;

    return {
      id: this.alarmProfile.id,
      alarmId: this.alarm.id,
      name: formModel.name,
      code: formModel.code,
      description: formModel.description,
      iconPath: './assets/icons/Asset05.svg',
      waitTime: formModel.waitTime
    };
  }

  private saveSuccessHelper(alarmProfile?: AlarmProfile) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewAlarmProfile) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onAlarmProfileSaved.next(alarmProfile);

    this.dialogRef.close(alarmProfile);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {
    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.alarmProfileForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
      code: '',
      iconPath: './assets/icons/Asset05.svg',
      waitTime: [0, Validators.required]
    });
  }

  private resetForm(replace = false) {
    this.alarmProfileForm.reset({
      name: this.alarmProfile.name || '',
      code: this.alarmProfile.code || '',
      description: this.alarmProfile.description || '',
      alarmId: this.alarm.id,
      iconPath: this.alarmProfile.iconPath || './assets/icons/Asset05.svg',
      waitTime: this.alarmProfile.waitTime || 0
    });
  }

  public selectIconImage(icon: PickerIconImage) {
    this.alarmProfile.iconPath = icon.path;
    this.alarmProfileForm.controls['iconPath'].setValue(icon.path);
  }
}
