import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Field, FieldMap, Rule } from "angular2-query-builder";
import { fadeInOut } from "../../../../services/animations";
import { AppTranslationService } from "../../../../services/app-translation.service";

@Component({
    selector: "zone-input",
    templateUrl: './zone-input.component.html',
    styleUrls: ['./zone-input.component.scss'],
  animations: [fadeInOut],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZoneInputComponent),
      multi: true
    }
  ]
})
export class ZoneInputComponent implements OnInit, ControlValueAccessor, OnChanges  {

  @Input() model: any;
  @Input() field: any;
  @Input() fields: any;

  public zone: any;
  public options: any[] = [];

  public dayLabel: string;
  public hourLabel: string;

    constructor( private translationService: AppTranslationService,
    ) {
      this.dayLabel = this.translationService.getTranslation('shared.Day');
      this.hourLabel = this.translationService.getTranslation('shared.Hour');
  }

  ngOnInit(): void {
    if (this.model !== undefined) {
      this.zoneOption = this.model.zoneOption;
      this.options = this.field.options;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  onChange: any = () => {
  }
  onTouch: any = () => { }

  get zoneOption(): any {
    if (this.model !== undefined) {
      return this.model.zoneOption;
    }

    return '';
  }

  set zoneOption(val: any) {
    if (val !== undefined && this.model !== undefined && this.model.zoneOptionId !== val) {
      this.model.zoneOption = val;
      this.onChange(this.model);
      this.onTouch(this.model);
    }
  }

  // get zoneTime(): any {
  //   if (this.model !== undefined) {
  //     return this.model.zoneTime;
  //   }
  //
  //   return '';
  // }
  //
  // set zoneTime(val: any) {
  //   if (val !== undefined && this.model !== undefined && this.model.zoneTime !== val) {
  //     this.model.zoneTime = val;
  //     this.onChange(this.model);
  //     this.onTouch(this.model);
  //   }
  // }

  writeValue(value: any) {
   this.model.value = value
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn
  }
}
