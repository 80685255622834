import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { fadeInOut } from '../../services/animations';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material';
import { EnergyZoneDeviceService } from '../../services/energy/energy-zone-device.service';
import { ActivatedRoute } from '@angular/router';
import { EnergyZone } from '../../models/energy/energy-zone.model';
import { EnergyReadingService } from '../../services/energy/energy-reading.service';
import { EnergyReadingViewType } from '../../models/energy/energy-reading-view-type.model';
import * as moment from 'moment';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {DetailedEnergyReading} from "../../models/energy/detailed-energy-reading";
import {HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from "@microsoft/signalr";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'energy-reading-detail-card',
  templateUrl: './energy-reading-detail-card.component.html',
  styleUrls: ['./energy-reading-detail-card.component.scss'],
  animations: [fadeInOut]
})
export class EnergyReadingDetailCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() zone: EnergyZone;
  @Input() date: Date;
  @Input() viewType: EnergyReadingViewType;

  private readingsConnection: HubConnection;

  public consumptionIcon = require("../../assets/images/energy.svg");
  public productionIcon = require("../../assets/images/energy.svg");

  public detail: DetailedEnergyReading = new DetailedEnergyReading();

  realtimeConsumptionLabel : string = "0 W";
  realtimeProductionLabel : string = "0 W";
  dailyConsumptionLabel : string = "";
  dailyProductionLabel : string = "";
  monthlyConsumptionLabel : string = "";
  monthlyProductionLabel : string = "";

  curentP1WattValue: number = 0;
  curentP2WattValue: number = 0;
  curentP3WattValue: number = 0;
  curentP1ReverseWattValue: number = 0;
  curentP2ReverseWattValue: number = 0;
  curentP3ReverseWattValue: number = 0;

  constructor(
    private energyReadingService: EnergyReadingService,
    private authService: AuthService,
    private route: ActivatedRoute) {
    this.createHubConnection();
  }

  ngOnInit() {
    this.connect();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refreshData();
  }

  public refreshData() {

    this.energyReadingService.getDetail(this.zone.id)
      .subscribe(result => {

        if (result) {
          this.detail = result;
          this.setLabels();
        }
      },
        error => {
        });
  }

  private createHubConnection() {
    let self = this;

    let builder = new HubConnectionBuilder();

    this.readingsConnection = builder
      .withUrl(location.origin + '/hubs/energyReadings',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken,
        })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.None)
      .build();

    this.readingsConnection.on("zoneReading", (message) => {

      console.log(message);

      self.updateDetailstatus(message);
    });
  }

  private connect() {
    let self = this;

    if (this.readingsConnection.state === HubConnectionState.Disconnected) {

      this.readingsConnection.start()
        .catch(function (err) {
        });

    }
  }

  private updateDetailstatus(message: any) {
    if (message && message.zoneId && message.zoneId == this.zone.id) {
      this.curentP1WattValue = message.realtimePhase1W ? message.realtimePhase1W : 0;
      this.curentP2WattValue = message.realtimePhase2W ? message.realtimePhase2W : 0;
      this.curentP3WattValue = message.realtimePhase3W ? message.realtimePhase3W : 0;
      this.curentP1ReverseWattValue = message.realtimePhase1ReverseW ? message.realtimePhase1ReverseW : 0;
      this.curentP2ReverseWattValue = message.realtimePhase2ReverseRW ? message.realtimePhase2ReverseRW : 0;
      this.curentP3ReverseWattValue = message.realtimePhase3ReverseRW ? message.realtimePhase3ReverseRW : 0;

      this.setRealtimeLabels();
    }
  }

  private setRealtimeLabels() {
    if (this.zone.mainZone) {

      this.realtimeConsumptionLabel = `${this.curentP1WattValue} W | ${this.curentP2WattValue} W | ${this.curentP3WattValue} W`;
      this.realtimeProductionLabel = `${this.curentP1ReverseWattValue} W | ${this.curentP2ReverseWattValue} W | ${this.curentP3ReverseWattValue} W`;
    } else {
      this.realtimeConsumptionLabel = `${this.curentP1WattValue} W`;
      this.realtimeProductionLabel = `${this.curentP1ReverseWattValue} W`;
    }
  }

  private setLabels(){
    if (this.detail) {

      var dailyPh1 = this.detail.dayPhase1Wh == 0 ? "0 kWh" : `${this.detail.dayPhase1Wh/1000} kWh`;
      var dailyPh2 = this.detail.dayPhase2Wh == 0 ? "0 kWh" : `${this.detail.dayPhase2Wh/1000} kWh`;
      var dailyPh3 = this.detail.dayPhase3Wh == 0 ? "0 kWh" : `${this.detail.dayPhase3Wh/1000} kWh`;
      var dailyPh1Reverse = this.detail.dayPhase1ReverseWh == 0 ? "0 kWh" : `${this.detail.dayPhase1ReverseWh/1000} kWh`;
      var dailyPh2Reverse = this.detail.dayPhase2ReverseWh == 0 ? "0 kWh" : `${this.detail.dayPhase2ReverseWh/1000} kWh`;
      var dailyPh3Reverse = this.detail.dayPhase3ReverseWh == 0 ? "0 kWh" : `${this.detail.dayPhase3ReverseWh/1000} kWh`;

      var monthPh1 = this.detail.monthPhase1Wh == 0 ? "0 kWh" : `${this.detail.monthPhase1Wh/1000} kWh`;
      var monthPh2 = this.detail.monthPhase2Wh == 0 ? "0 kWh" : `${this.detail.monthPhase2Wh/1000} kWh`;
      var monthPh3 = this.detail.monthPhase3Wh == 0 ? "0 kWh" : `${this.detail.monthPhase3Wh/1000} kWh`;
      var monthPh1Reverse = this.detail.monthPhase1ReverseWh == 0 ? "0 kWh" : `${this.detail.monthPhase1ReverseWh/1000} kWh`;
      var monthPh2Reverse = this.detail.monthPhase2ReverseWh == 0 ? "0 kWh" : `${this.detail.monthPhase2ReverseWh/1000} kWh`;
      var monthPh3Reverse = this.detail.monthPhase3ReverseWh == 0 ? "0 kWh" : `${this.detail.monthPhase3ReverseWh/1000} kWh`;

      if (this.zone.mainZone) {

        this.dailyConsumptionLabel = `${dailyPh1} | ${dailyPh2} | ${dailyPh3}`;
        this.dailyProductionLabel = `${dailyPh1Reverse} | ${dailyPh2Reverse} | ${dailyPh3Reverse}`;
        this.monthlyConsumptionLabel = `${monthPh1} | ${monthPh2} | ${monthPh3}`;
        this.monthlyProductionLabel = `${monthPh1Reverse} | ${monthPh2Reverse} | ${monthPh3Reverse}`;
      } else {
        this.dailyConsumptionLabel = `${dailyPh1}`;
        this.dailyProductionLabel = `${dailyPh1Reverse}`;
        this.monthlyConsumptionLabel = `${monthPh1}`;
        this.monthlyProductionLabel = `${monthPh1Reverse}`;
      }
    }
  }

  ngOnDestroy(): void {
    this.readingsConnection.stop();
  }
}
