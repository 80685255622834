import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource, MatSnackBar } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { DevicePortMap } from '../../../models/device/device-port-map.model';
import { Device } from '../../../models/device/device.model';
import { Room } from '../../../models/room/room.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { DeviceService } from '../../../services/device/device.service';
import { DevicePortMapService } from '../../../services/devicePortMap/device-port-map.service';
import { RoomService } from '../../../services/room/room.service';
import { Utilities } from '../../../services/utilities';
import { AdminDevicePortMapEditorComponent } from './admin-device-port-map-editor.component';
import { InputMappingService } from '../../../services/inputMapping/input-mapping.service';
import {AppTranslationService} from "../../../services/app-translation.service";
import {ModulePortMapService} from "../../../services/module/module-port-map.service";
import {ModulePortMap} from "../../../models/module/module-port-map.model";


@Component({
  selector: "admin-device-port-map-listing.component",
  templateUrl: './admin-device-port-map-listing.component.html',
  styleUrls: ['./admin-device-port-map-listing.component.scss'],
  animations: [fadeInOut]
})
export class AdminDevicePortMapListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['room', 'inputDevice', 'inputPort', 'inputFunction', 'outputDevice', 'outputPort', 'outputFunction', 'forceRemoteExecution', 'localExecution',  'actions'];
  dataSource: MatTableDataSource<DevicePortMap>;
  sourcePortMap: DevicePortMap;

  name: string;
  id: number;
  loadingIndicator: boolean;


  devicePortMaps: DevicePortMap[] = [];
  allDevices: Device[];
  allRooms: Room[];
  allInputs: Device[];
  allModulePortMaps: ModulePortMap[];

  constructor(
    private alertService: AlertService,
    private devicePortMapService: DevicePortMapService,
    private modulePortMapService: ModulePortMapService,
    private roomService: RoomService,
    private deviceService: DeviceService,
    private inputsService: InputMappingService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private translationService: AppTranslationService,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {

    this.dataSource.data = this.devicePortMaps;

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data, filter) => this.filterData(data, filter);
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {

    this.loadingIndicator = true;
    this.alertService.startLoadingMessage();

    let roomObs = this.roomService.getRooms();
    let deviceObs = this.deviceService.getDevices();
    let portMapsObs = this.devicePortMapService.getDevicePortMaps();
    let inputsObs = this.inputsService.getInputMappings(0, 999);
    let modulePortMapsObs = this.modulePortMapService.getModulePortMaps(0);

    forkJoin([roomObs, deviceObs, portMapsObs, inputsObs, modulePortMapsObs]).subscribe(results => {
      this.loadingIndicator = false;
      this.alertService.stopLoadingMessage();

      this.allRooms = results[0].items;
      this.allDevices = results[1].items;
      this.devicePortMaps = results[2].items;
      this.dataSource.data = results[2].items;
      this.allInputs = results[3].items;
      this.allModulePortMaps = results[4].items;

    },
      error => {
        this.loadingIndicator = false;

        let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
        let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

        this.alertService.showStickyMessage(errorLabel, errorDetail,
          MessageSeverity.error, error);
      }
    );
  }

  public editPortMap(portMap: DevicePortMap) {
    this.sourcePortMap = portMap;

    if (!portMap) {
      portMap = new DevicePortMap();
    }

    let dialogRef = this.dialog.open(AdminDevicePortMapEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { devicePortMap: portMap, devices: this.allDevices, rooms: this.allRooms, inputs: this.allInputs, modulePortMaps: this.allModulePortMaps}
      });
    dialogRef.afterClosed().subscribe(device => {
      if (device) {
        this.updateMaps(device);
      }
    });
  }

  private updateMaps(devicePortMap: DevicePortMap) {
    this.loadData();
  }

  public cancel() {

    this.alertService.resetStickyMessage();

    this.router.navigate(['/admin/deviceportmap']);
  }

  private filterData(input: DevicePortMap, filter: string): boolean {
    return Utilities.searchArray(filter, false,
      input.inputDevice != null ? input.inputDevice.name : null,
      input.outputDevice != null ? input.outputDevice.name : null,
      input.inputFunction != null ? input.inputFunction.name : null,
      input.outputFunction != null ? input.outputFunction.name : null,
      input.inputPort != null ? input.inputPort.description : null,
      input.outputPort != null ? input.outputPort.description : null,
      input.inputDevice != null && input.inputDevice.room ? input.inputDevice.room.name : null);
  }

  private confirmDelete(portMap: DevicePortMap) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.devicePortMapService.deleteDevicePortMap(portMap.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== portMap)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }
}
