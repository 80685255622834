import { FirmwareStatus } from "./firmware-status.model";
import { FirmwareType } from "./firmware-type.model";

export class FirmwareVersion {
  
  constructor(id: number = 0, name?: string, moduleId?: number, date?: Date, version?: string, status?: FirmwareStatus, firmwareType?: FirmwareType, canUpdate?: boolean, percent?: string) {

    this.id = id;
    this.name = name;
    this.moduleId = moduleId;
    this.version = version;
    this.date = date;
    this.status = status;
    this.firmwareType = firmwareType;
    this.canUpdate = canUpdate;
    this.percent = percent;
  }
  
  public id: number;
  public name: string;
  public moduleId: number;
  public date: Date;
  public version: string;
  public status: FirmwareStatus;
  public firmwareType: FirmwareType;
  public canUpdate: boolean;
  public percent: string;
}
