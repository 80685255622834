import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { CabinetEndpoint } from './cabinet-endpoint.service';
import { Cabinet } from '../../models/cabinet/cabinet.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class CabinetService {

  constructor(private router: Router, private http: HttpClient,
    private cabinetEndpoint: CabinetEndpoint) {

  }
  
  getCabinet(id?: number) {
    if (id) {
      return this.cabinetEndpoint.getCabinetEndpoint<Cabinet>(id);
    }

    
  }

  getCabinets(page: number = 0, pageSize: number = 9999) {

    return this.cabinetEndpoint.getCabinetsEndpoint<PagedData<Cabinet>>(page, pageSize);
  }
  
  updateCabinet(cabinet: Cabinet) {
    return this.cabinetEndpoint.getUpdateCabinetEndpoint(cabinet, cabinet.id);
  }
  
  newCabinet(cabinet: Cabinet) {
    return this.cabinetEndpoint.getNewCabinetEndpoint<Cabinet>(cabinet);
  }
  
  deleteCabinet(id): Observable<Cabinet> {

    return this.cabinetEndpoint.getDeleteCabinetEndpoint(id);
  }
}
