import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { DeviceFunction } from '../../../models/device/device-function.model';
import { Device } from '../../../models/device/device.model';
import { Room } from '../../../models/room/room.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { AutomationRule } from 'src/app/models/automationRule/automation-rule.model';
import {AppTranslationService} from "../../../services/app-translation.service";
import {RoomActionMap} from "../../../models/room/room-action-map.model";
import {RoomActionService} from "../../../services/room/room-action.service";
import {RoomAction} from "../../../models/room/room-action.model";


@Component({
  selector: "admin-action-map-editor.component",
  templateUrl: './admin-action-map-editor.component.html',
  styleUrls: ['./admin-action-map-editor.component.scss'],
  animations: [fadeInOut]
})
export class AdminRoomActionMapEditorComponent implements OnInit {

  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewAction = false;
  private isSaving: boolean;
  private onRoomActionMapSaved = new Subject<RoomActionMap>();

  public currentRoomActionMap: RoomActionMap;
  public roomAction: RoomAction;
  public allRooms: Room[];
  public allDevices: Device[];
  public filteredDevices: Device[];
  public deviceFunctions: DeviceFunction[];
  public allTypes: string[];

  formGroup: FormGroup;
  RoomActionMapSaved$ = this.onRoomActionMapSaved.asObservable();

  get room() {
    return this.formGroup.get('room');
  }

  get device() {
    return this.formGroup.get('device');
  }

  get waitTime() {
    return this.formGroup.get('waitTime');
  }

  get deviceFunction() {
    return this.formGroup.get('deviceFunction');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminRoomActionMapEditorComponent>,
    private alertService: AlertService,
    private roomActionService: RoomActionService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { roomAction: RoomAction, roomActionMap: RoomActionMap, rooms: Room[], devices: Device[] },
  ) {
      this.currentRoomActionMap = data.roomActionMap;
      this.roomAction = data.roomAction;
      this.allRooms = data.rooms;
      this.allDevices = data.devices;

      if (this.currentRoomActionMap && this.currentRoomActionMap.outputDevice && this.currentRoomActionMap.outputDevice) {
        this.deviceFunctions = this.currentRoomActionMap.outputDevice.functions;
      } else {
        this.deviceFunctions = [];
      }

      if (this.currentRoomActionMap && this.currentRoomActionMap.outputDevice && this.currentRoomActionMap.outputDevice.roomId && this.currentRoomActionMap.outputDevice.roomId != 0) {
        this.filteredDevices = this.allDevices.filter(x => x.room && x.room.id == this.currentRoomActionMap.outputDevice.roomId);
      } else {
        this.filteredDevices = this.allDevices;
      }

    this.buildForm();
    this.resetForm();
  }

  ngOnInit(): void {
    this.enableSaveButton();
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.formGroup.valid) {
      this.alertService.showValidationError();
      return;
    }

    if (this.isSaving) {
      return;
    }

    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    const editedRoomActionMap = this.getEditedAction();

    if (this.currentRoomActionMap.id == 0) {
      this.isNewAction = true;
      this.roomActionService.createRoomActionMap(this.roomAction.id, editedRoomActionMap).subscribe(
        device => this.saveSuccessHelper(device),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewAction = false;
      this.roomActionService.updateRoomActionMap(this.roomAction.id, editedRoomActionMap).subscribe(
        response => this.saveSuccessHelper(editedRoomActionMap),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedAction(): RoomActionMap {
    const formModel = this.formGroup.value;

    return {
      id: this.currentRoomActionMap.id,
      waitTime: formModel.waitTime,
      roomAction: this.roomAction,
      roomActionId: this.roomAction.id,
      outputDeviceId: formModel.device,
      outputDevice: this.allDevices.find(x => x.id == formModel.device),
      outputFunction: this.deviceFunctions.find(x => x.id == formModel.deviceFunction),
      outputFunctionId: formModel.deviceFunction,
      parameters: this.currentRoomActionMap.parameters
    };
  }

  private saveSuccessHelper(RoomActionMap?: RoomActionMap) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewAction) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onRoomActionMapSaved.next(RoomActionMap);

    this.dialogRef.close(RoomActionMap);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    this.enableSaveButton();
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
    this.enableSaveButton();
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      waitTime: [0, Validators.required],
      room: ['', Validators.required],
      device: ['', Validators.required],
      deviceFunction: ['', Validators.required]
    });
  }

  public onRoomChange(id: any) {

    this.device.setValue('');
    this.deviceFunction.setValue('');

    this.filteredDevices = this.allDevices.filter(x => x.room && x.room.id == id.value);
  }

  public onInputDeviceChange(id: any) {

    this.deviceFunction.setValue('');

    if (this.allDevices && this.allDevices.length > 0) {
      let currentDevice = this.allDevices.find(x => x.id == id.value);
      this.deviceFunctions = currentDevice.functions;
    }
  }

  private resetForm(replace = false) {
    this.formGroup.reset({
      waitTime: this.currentRoomActionMap.waitTime  || 0,
      room: this.currentRoomActionMap.outputDevice && this.currentRoomActionMap.outputDevice.room ? this.currentRoomActionMap.outputDevice.room.id : '',
      device: this.currentRoomActionMap.outputDevice ? this.currentRoomActionMap.outputDevice.id : '',
      deviceFunction: this.currentRoomActionMap.outputFunction ? this.currentRoomActionMap.outputFunction.id : ''
    });
  }
}

