// =============================
// Email: info@.com
// www..com/templates
// =============================

import { Component } from "@angular/core";

@Component({
    selector: "microluminapp-pro-admin",
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.scss']
})
export class AdminComponent
{

}
