import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class FloorEndpoint extends EndpointFactory {

  private readonly _floorUrl: string = "/api/floor";

  get floorUrl() { return this.configurations.baseUrl + this._floorUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getFloorEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.floorUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFloorEndpoint(id));
      }));
  }
  
  getFloorsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._floorUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getFloorsEndpoint(page, pageSize));
      }));
  }


  getNewFloorEndpoint<T>(floorObject: any): Observable<T> {

    return this.http.post<T>(this.floorUrl, JSON.stringify(floorObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewFloorEndpoint(floorObject));
      }));
  }

  getUpdateFloorEndpoint<T>(floorObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.floorUrl}/${id}` : `${this.floorUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(floorObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateFloorEndpoint(floorObject, id));
      }));
  }

  getDeleteFloorEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.floorUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteFloorEndpoint(id));
      }));
  }
  
}
