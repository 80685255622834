
import { Component, NgModule, Input, RenderComponentType, OnInit } from '@angular/core';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit
{
 
  appLogo = require("../assets/images/topo_appserver.png");
  iconHome = require("../assets/images/casa.svg");
  iconDiv = require("../assets/images/divisoes.svg");
  iconAuto = require("../assets/images/automacao02.svg");
  iconAlarm = require("../assets/images/escudo.svg");
  iconCam = require("../assets/images/camara.svg");
  iconEread = require("../assets/images/energy.svg");
  iconID = require("../assets/images/users_1.svg");
  iconFunc = require("../assets/images/id.svg");
  iconZones = require("../assets/images/zones.svg");
  iconDivision = require("../assets/images/divisoes.svg");
  iconSboard = require("../assets/images/puzzle.svg");
  iconMod = require("../assets/images/ioc.svg");
  iconDevice = require("../assets/images/sensor.svg");
  iconIQ = require("../assets/images/master01.svg");
  iconMap = require("../assets/images/iocmap.svg");
  iconMapio = require("../assets/images/maps.svg");
  iconAction = require("../assets/images/action.svg");
  iconScenes = require("../assets/images/servidores.svg");
  iconEnergy = require("../assets/images/energy.svg");
  iconAkeys = require("../assets/images/password.svg");
  iconRule = require("../assets/images/id.svg");
  iconFirm = require("../assets/images/Asset 119.svg");
  iconConf = require("../assets/images/engrenagem.svg");
  iconLog = require("../assets/images/log.svg");
  iconUlumin = require("../assets/images/ulumin.svg");
  iconClock = require("../assets/images/relogio.svg");


    @Input()
    title: string;

    @Input()
    icon: string;

  public headerIcon: string;

  constructor() {
    
  }
    ngOnInit(): void {
      this.headerIcon = this.getIcon();
    }

  getIcon() {

    switch (this.icon) {

      case 'users':
        return this.iconID;
      case 'alarms':
        return this.iconAlarm;
      case 'divisions':
        return this.iconDiv;
      case 'automations':
        return this.iconAuto;
      case 'alarm':
        return this.iconAlarm;
      case 'videocam':
        return this.iconCam;
      case 'energy':
        return this.iconEnergy;
      case 'func':
        return this.iconFunc;
      case 'layers':
        return this.iconZones;
      case 'sboard':
        return this.iconSboard;
      case 'modules':
        return this.iconMod;
      case 'devices':
        return this.iconDevice;
      case 'mopmodules':
        return this.iconMap;
      case 'mopio':
        return this.iconMapio;
      case 'action':
        return this.iconAction;
      case 'keys':
        return this.iconAkeys;
      case 'master':
        return this.iconIQ;
      case 'settings':
        return this.iconConf;
      case 'log':
        return this.iconLog;
      case 'clock':
        return this.iconClock;
      case 'about':
        return this.iconUlumin;

     default:
        return this.iconAlarm;
    }

  }
}
