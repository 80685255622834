import { Component, OnInit } from '@angular/core';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import {AppTranslationService} from "../../../services/app-translation.service";
import { Device } from '../../../models/device/device.model';
import {Room} from "../../../models/room/room.model";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute} from "@angular/router";
import {fadeInOut} from "../../../services/animations";
import {DeviceGroup} from "../../../models/deviceGroup/device-group.model";
import {MatTableDataSource} from "@angular/material/table";
import {DeviceGroupService} from "../../../services/deviceGroup/device-group.service";
import {AdminDeviceGroupDeviceEditorComponent} from "./admin-group-device-editor.component";



@Component({
  selector: 'admin-device-group-settings.component',
  templateUrl: './admin-device-group-settings.component.html',
  styleUrls: ['./admin-device-group-settings.component.scss'],
  animations: [fadeInOut]
})
export class AdminDeviceGroupSettingsComponent implements OnInit {

  public deviceGroup: DeviceGroup;
  private sourceDeviceGroup: Device;
  private allDevices: Device[];
  private allRooms: Room[];
  public AllDeviceInGroup: Device[];
  deviceGroupMapDisplayedColumns = [ 'device', "room", 'actions'];
  deviceGroupsDataSource: MatTableDataSource<Device>;
  loadingIndicator: boolean;

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private deviceGroupService: DeviceGroupService,
    private translationService: AppTranslationService,
    private route: ActivatedRoute,
  ) {
    this.deviceGroup = this.route.snapshot.data['deviceGroup'];
    this.allDevices = this.route.snapshot.data['devices'].items;
    this.allRooms = this.route.snapshot.data['rooms'].items;
    this.deviceGroupsDataSource = new MatTableDataSource<Device>();
    this.refreshData();
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  public addDeviceToGroup() {
    let dialogRef = this.dialog.open(AdminDeviceGroupDeviceEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { deviceGroup: this.deviceGroup, devices: this.allDevices, rooms: this.allRooms }
      });
    dialogRef.afterClosed().subscribe(refresh => {
      if (refresh) {
        this.refreshData();
      }
    });
  }

  private confirmDelete(device: Device) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
      this.alertService.startLoadingMessage(deletingLabel);

      this.deviceGroupService.deleteDevice(this.deviceGroup.id, device.id)
        .subscribe(results => {
            this.alertService.stopLoadingMessage();

            this.refreshData();
          },
          error => {
            this.alertService.stopLoadingMessage();

            this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
              MessageSeverity.error, error);
          });
    });
  }

  public applyFilter(filterValue: string) {
    this.deviceGroupsDataSource.filter = filterValue;
  }

  private refreshData() {
    var self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.deviceGroupService.getDeviceGroup(this.deviceGroup.id)
      .subscribe(result => {
          self.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          self.AllDeviceInGroup = result.devices;
          self.deviceGroupsDataSource.data = result.devices;
        },
        error => {
          self.alertService.stopLoadingMessage();

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          self.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }
}
