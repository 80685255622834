import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class ModuleTypeEndpoint extends EndpointFactory {

  private readonly _moduleTypeUrl: string = "/api/moduleType";

  get moduleTypeUrl() { return this.configurations.baseUrl + this._moduleTypeUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getModuleTypeEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.moduleTypeUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getModuleTypeEndpoint(id));
      }));
  }
  
  getModuleTypesEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._moduleTypeUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getModuleTypesEndpoint(page, pageSize));
      }));
  }


  getNewModuleTypeEndpoint<T>(moduleTypeObject: any): Observable<T> {

    return this.http.post<T>(this.moduleTypeUrl, JSON.stringify(moduleTypeObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewModuleTypeEndpoint(moduleTypeObject));
      }));
  }

  getUpdateModuleTypeEndpoint<T>(moduleTypeObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.moduleTypeUrl}/${id}` : `${this.moduleTypeUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(moduleTypeObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateModuleTypeEndpoint(moduleTypeObject, id));
      }));
  }

  getDeleteModuleTypeEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.moduleTypeUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteModuleTypeEndpoint(id));
      }));
  }
  
}
