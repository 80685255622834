import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog, PageEvent, Sort } from '@angular/material';
import { fadeInOut } from '../../../services/animations';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from "../../../services/app-translation.service";
import { DeviceService } from '../../../services/device/device.service';
import { Utilities } from '../../../services/utilities';
import { Device } from '../../../models/device/device.model';
import { AdminDeviceEditorComponent } from "./admin-device-editor.component";
import { DeviceTypeService } from '../../../services/deviceType/device-type.service';
import { DeviceType } from '../../../models/device/device-type.model';
import { DeviceCategory } from '../../../models/device/device-category.model';
import { RoomService } from '../../../services/room/room.service';
import { Room } from '../../../models/room/room.model';
import { TranslateService } from "@ngx-translate/core";
import { AdminDeviceSettingsEditorComponent } from './admin-device-settings-editor.component';
import { Module } from '../../../models/module/module.model';
import { ModuleService } from '../../../services/module/module.service';
import { DeviceConnectionType } from 'src/app/models/device/device-connection-type.model';

@Component({
  selector: "admin-device-list",
  templateUrl: './admin-device-list.component.html',
  styleUrls: ['./admin-device-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminDeviceListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name','ref', 'category', 'room', 'connectionType', 'actions'];
  datasource: null;
  dataSource: MatTableDataSource<Device>;
  sourceDevice: Device;
  editingDeviceName: { name: string };
  loadingIndicator: boolean;


  allDeviceTypes: DeviceType[] = [];
  allRooms: Room[] = [];
  allCategories: string[] = [];
  allConnectionTypes: string[] = [];
  allModules: Module[] = [];

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private deviceService: DeviceService,
    private roomService: RoomService,
    private deviceTypeService: DeviceTypeService,
    private moduleService: ModuleService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    translate: TranslateService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data, filter) => this.filterData(data, filter);
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateDevices(device: Device) {
    if (this.sourceDevice) {
      Object.assign(this.sourceDevice, device);
      this.sourceDevice = null;
    }
    else {
      this.dataSource.data.unshift(device);
    }

    this.refresh();
  }

  private loadData() {
    let self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

    this.allCategories = Object.keys(DeviceCategory).filter((item) => {
        return isNaN(Number(item));
    });

    this.allConnectionTypes = Object.keys(DeviceConnectionType).filter((item) => {
      return isNaN(Number(item));
    });

    this.deviceTypeService.getDeviceTypes()
      .subscribe(results => {
        this.allDeviceTypes = results.items;
      },
        error => {
          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
      });

    this.roomService.getRooms()
      .subscribe(results => {
        this.allRooms = results.items;
      },
        error => {
          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
      });

    this.moduleService.getModules()
      .subscribe(results => {
        this.allModules = results.items;
      },
        error => {
          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });

    this.deviceService.getDevices(this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.dataSource.data = results.items;
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  public translateCategory(category: DeviceCategory): string {
    try {
      return this.translationService.getTranslation(`lookups.deviceCategory.${DeviceCategory[category]}`);
    } catch (e) {
      return category.toString();
    }
  }

  public translateConnectionType(connectionType: DeviceConnectionType): string {
    try {
      return this.translationService.getTranslation(`lookups.DeviceConnectionType.${DeviceConnectionType[connectionType]}`);
    } catch (e) {
      return connectionType.toString();
    }
  }

  public editDevice(device?: Device) {
    this.sourceDevice = device;

    if (!device) {
      device = new Device();
    }

    let dialogRef = this.dialog.open(AdminDeviceEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { device: device, deviceTypes: [...this.allDeviceTypes], rooms: [...this.allRooms], categories: [...this.allCategories], connectionTypes: [...this.allConnectionTypes], modules: [...this.allModules],   }
      });
    dialogRef.afterClosed().subscribe(editedDevice => {
      if (editedDevice) {
        this.updateDevices(editedDevice);
      }
    });
  }

  public editSettings(device?: Device) {
    this.sourceDevice = device;

    let dialogRef = this.dialog.open(AdminDeviceSettingsEditorComponent,
      {
        panelClass: 'mat-dialog-lg',
        data: { device: device }
      });
    dialogRef.afterClosed().subscribe(device => {
      if (device) {
        this.updateDevices(device);
      }
    });
  }

  private confirmDelete(device: Device) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.deviceService.deleteDevice(device.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== device)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }

  sortData(sort: Sort) {
      const data = this.dataSource.data;
      let sortedData = this.dataSource.data
      if (!sort.active || sort.direction === '') {
        sortedData = data;
        return;
      }

      sortedData = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'name': return this.compare(a.name, b.name, isAsc);
          case 'description': return this.compare(a.description, b.description, isAsc);
          case 'category': return this.compare(a.category, a.category, isAsc);
          case 'room': return this.compare(a.room ? a.room.name : '', b.room ? b.room.name : '', isAsc);
          default: return 0;
        }
      });

      this.dataSource.data = sortedData;
    }

  public compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private filterData(input: Device, filter: string): boolean {
    return Utilities.searchArray(filter, false,
      input.name,
      input.description,
      input.room != null ? input.room.name : null);
  }
}
