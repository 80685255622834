import { Device } from "./device.model";
import { Room } from "../room/room.model";
import { DeviceFunction } from "./device-function.model";

export class DeviceSchedule {
  
  constructor(id: number = 0, deviceId?: number, hour?: number, minute?: number, monday?: boolean, tuesday?: boolean, wednesday?: boolean, thursday?: boolean, friday?: boolean, saturday?: boolean, sunday?: boolean, functionId?: number, functionCode?: number, functionValue?: string, isEnabled?: boolean, device?: Device, room?: Room, deviceFunction?: DeviceFunction) {

    this.id = id;
    this.deviceId = deviceId;
    this.hour = hour;
    this.minute = minute;
    this.monday = monday;
    this.tuesday = tuesday;
    this.wednesday = wednesday;
    this.thursday = thursday;
    this.friday = friday;
    this.saturday = saturday;
    this.sunday = sunday;
    this.functionCode = functionCode;
    this.functionValue = functionValue;
    this.isEnabled = isEnabled;
    this.device = device;
    this.room = room;
    this.functionId = functionId;
    this.deviceFunction = deviceFunction;
  }
  
  public id: number;
  public deviceId: number;
  public hour: number;
  public minute: number;
  public monday: boolean;
  public tuesday: boolean;
  public wednesday: boolean;
  public thursday: boolean;
  public friday: boolean;
  public saturday: boolean;
  public sunday: boolean;
  public functionId: number;
  public functionCode: number;
  public functionValue: string;
  public isEnabled: boolean;
  public device: Device;
  public room: Room;
  public deviceFunction: DeviceFunction;  
}
