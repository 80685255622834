import { DeviceType } from "./device-type.model";
import { IoType } from "../io-type.model";
import { Device } from "./device.model";

export class DevicePortMapping {

  constructor(id: number = 0, deviceId: number = 0, port?: string, ioType?: IoType) {

    this.id = id;
    this.port = port;
    this.ioType = ioType;
    this.deviceId = deviceId;
  }

  public id: number;
  public deviceId: number;
  public port: string;
  public ioType: IoType;
}
