// =============================
// Email: info@.com
// www..com/templates
// =============================

import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog } from '@angular/material';

import { fadeInOut } from '../services/animations';
import { AlertService, DialogType, MessageSeverity } from '../services/alert.service';
import { AppTranslationService } from "../services/app-translation.service";
import { AccountService } from '../services/account.service';
import { Utilities } from '../services/utilities';
import { Role } from '../models/role.model';
import { Permission } from '../models/permission.model';
import { EditRoleDialogComponent } from "./edit-role-dialog.component";

@Component({
    selector: "app-role-list",
    templateUrl: './role-list.component.html',
    styleUrls: ['./role-list.component.scss'],
    animations: [fadeInOut]
})
export class RoleListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

    displayedColumns = ['roleName', 'description', 'users', 'actions'];
    dataSource: MatTableDataSource<Role>;
    allPermissions: Permission[] = [];
    sourceRole: Role;
    editingRoleName: { name: string };
    loadingIndicator: boolean;

    constructor(
        private alertService: AlertService,
        private translationService: AppTranslationService,
        private accountService: AccountService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog
    ) {
        this.dataSource = new MatTableDataSource();
    }

    get canManageRoles() {
        return this.accountService.userHasPermission(Permission.manageRolesPermission)
    }

    ngOnInit() {
        this.loadData();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    public applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue;
    }

    private refresh() {
        // Causes the filter to refresh there by updating with recently added data.
        this.applyFilter(this.dataSource.filter);
    }

    private updateRoles(role: Role) {
        if (this.sourceRole) {
            Object.assign(this.sourceRole, role);
            this.sourceRole = null;
        }
        else {
            this.dataSource.data.push(role);
        }

        this.refresh();
    }

    private loadData() {
        this.alertService.startLoadingMessage();
      this.loadingIndicator = true;

      let loadErrorLabel = this.translationService.getTranslation(`shared.LoadError`);
      let loadErrorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);

        this.accountService.getRolesAndPermissions()
            .subscribe(results => {
                this.alertService.stopLoadingMessage();
                this.loadingIndicator = false;

                this.dataSource.data = results[0];
                this.allPermissions = results[1];
            },
            error => {
                this.alertService.stopLoadingMessage();
                this.loadingIndicator = false;

              this.alertService.showStickyMessage(loadErrorLabel, loadErrorDetail,
                    MessageSeverity.error, error);
            });
    }

    private editRole(role?: Role) {
        this.sourceRole = role;

        let dialogRef = this.dialog.open(EditRoleDialogComponent,
            {
                panelClass: 'mat-dialog-md',
                data: { role: role, allPermissions: this.allPermissions }
            });
        dialogRef.afterClosed().subscribe(role => {
            if (role && this.canManageRoles) {
                this.updateRoles(role);
            }
        });
    }

  private confirmDelete(role: Role) {
      let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
      let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
      let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
      let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
      let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);


    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
            .onAction().subscribe(() => {
              this.alertService.startLoadingMessage(deletingLabel);
                this.loadingIndicator = true;

                this.accountService.deleteRole(role)
                    .subscribe(results => {
                        this.alertService.stopLoadingMessage();
                        this.loadingIndicator = false;
                        this.dataSource.data = this.dataSource.data.filter(item => item !== role)
                    },
                    error => {
                        this.alertService.stopLoadingMessage();
                        this.loadingIndicator = false;

                      this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                            MessageSeverity.error, error);
                    });
            });
    }
}
