import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class DeviceTypeEndpoint extends EndpointFactory {

  private readonly _deviceTypeUrl: string = "/api/deviceType";

  get deviceTypeUrl() { return this.configurations.baseUrl + this._deviceTypeUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getDeviceTypeEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.deviceTypeUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeviceTypeEndpoint(id));
      }));
  }
  
  getDeviceTypesEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._deviceTypeUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeviceTypesEndpoint(page, pageSize));
      }));
  }


  getNewDeviceTypeEndpoint<T>(deviceTypeObject: any): Observable<T> {

    return this.http.post<T>(this.deviceTypeUrl, JSON.stringify(deviceTypeObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewDeviceTypeEndpoint(deviceTypeObject));
      }));
  }

  getUpdateDeviceTypeEndpoint<T>(deviceTypeObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.deviceTypeUrl}/${id}` : `${this.deviceTypeUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(deviceTypeObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateDeviceTypeEndpoint(deviceTypeObject, id));
      }));
  }

  getDeleteDeviceTypeEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.deviceTypeUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteDeviceTypeEndpoint(id));
      }));
  }
  
}
