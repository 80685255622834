import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { DeviceScheduleEndpoint } from './device-schedule-endpoint.service';
import { DeviceSchedule } from '../../models/device/device-schedule.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class DeviceScheduleService {

  constructor(private router: Router, private http: HttpClient,
    private deviceScheduleEndpoint: DeviceScheduleEndpoint) {
  }
  
  getDeviceSchedule(id?: number) {
    if (id) {
      return this.deviceScheduleEndpoint.getDeviceScheduleEndpoint<DeviceSchedule>(id);
    }
  }

  getDeviceSchedules(page: number = 0, pageSize: number = 9999) {

    return this.deviceScheduleEndpoint.getDeviceSchedulesEndpoint<DeviceSchedule[]>(page, pageSize);
  }
  
  updateDeviceSchedule(deviceSchedule: DeviceSchedule) {
      return this.deviceScheduleEndpoint.getUpdateDeviceScheduleEndpoint<DeviceSchedule>(deviceSchedule, deviceSchedule.id);
  }
  
  newDeviceSchedule(deviceSchedule: DeviceSchedule) {
    return this.deviceScheduleEndpoint.getNewDeviceScheduleEndpoint<DeviceSchedule>(deviceSchedule);
  }
  
  deleteDeviceSchedule(id): Observable<DeviceSchedule> {

    return this.deviceScheduleEndpoint.getDeleteDeviceScheduleEndpoint(id);
  }
}
