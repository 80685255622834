import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class AlarmZoneEndpoint extends EndpointFactory {

  private readonly _alarmZoneUrl: string = "/api/alarmzone";

  get alarmzoneUrl() { return this.configurations.baseUrl + this._alarmZoneUrl; }


  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getAlarmZoneEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.alarmzoneUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAlarmZoneEndpoint(id));
      }));
  }
  
  getAlarmZonesEndpoint<T>(alarmId?:number, page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._alarmZoneUrl}?alarmId=${alarmId}&page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAlarmZonesEndpoint(page, pageSize));
      }));
  }

  getNewAlarmZoneEndpoint<T>(alarmZoneObject: any): Observable<T> {

    return this.http.post<T>(this.alarmzoneUrl, JSON.stringify(alarmZoneObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewAlarmZoneEndpoint(alarmZoneObject));
      }));
  }

  getUpdateAlarmZoneEndpoint<T>(alarmZoneObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.alarmzoneUrl}/${id}` : `${this.alarmzoneUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(alarmZoneObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateAlarmZoneEndpoint(alarmZoneObject, id));
      }));
  }

  getDeleteAlarmZoneEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.alarmzoneUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteAlarmZoneEndpoint(id));
      }));
  }

  changeState<T>(id: number, state: number): Observable<T> {
    let endpointUrl = `${this.alarmzoneUrl}/${id}/state/${state}`;

    return this.http.post(endpointUrl, [], this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.changeState(id, state));
      }));
  }
}
