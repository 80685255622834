import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class SettingsEndpoint extends EndpointFactory {

  private readonly _settingsUrl: string = "/api/settings";

  get settingsUrl() { return this.configurations.baseUrl + this._settingsUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getSettingsEndpoint<T>(): Observable<T> {
    
    return this.http.get<T>(this.settingsUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSettingsEndpoint());
      }));
  }
  
  getUpdateSettingsEndpoint<T>(settingsObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.settingsUrl}/${id}` : `${this.settingsUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(settingsObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateSettingsEndpoint(settingsObject, id));
      }));
  }

}
