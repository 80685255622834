import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class EnergyZoneEndpoint extends EndpointFactory {

  private readonly _energyZoneUrl: string = "/api/energyzone";

  get energyZoneUrl() { return this.configurations.baseUrl + this._energyZoneUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getEnergyZoneEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.energyZoneUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getEnergyZoneEndpoint(id));
      }));
  }
  
  getEnergyZonesEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._energyZoneUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getEnergyZonesEndpoint(page, pageSize));
      }));
  }


  getNewEnergyZoneEndpoint<T>(energyZoneObject: any): Observable<T> {

    return this.http.post<T>(this.energyZoneUrl, JSON.stringify(energyZoneObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewEnergyZoneEndpoint(energyZoneObject));
      }));
  }

  getUpdateEnergyZoneEndpoint<T>(energyZoneObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.energyZoneUrl}/${id}` : `${this.energyZoneUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(energyZoneObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateEnergyZoneEndpoint(energyZoneObject, id));
      }));
  }

  getDeleteEnergyZoneEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.energyZoneUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteEnergyZoneEndpoint(id));
      }));
  }
  
}
