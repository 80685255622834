import {Component, Inject, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Subject} from 'rxjs';
import {Room} from '../../../models/room/room.model';
import {AlertService, MessageSeverity} from '../../../services/alert.service';
import {Device} from '../../../models/device/device.model';
import {AppTranslationService} from "../../../services/app-translation.service";
import {IoType} from '../../../models/io-type.model';
import {DeviceTypeFunction} from "../../../models/device/device-type-function.model";
import {DeviceGroup} from "../../../models/deviceGroup/device-group.model";
import {DeviceGroupService} from "../../../services/deviceGroup/device-group.service";
import {DeviceCategory} from "../../../models/device/device-category.model";
import {KeyValue} from "@angular/common";


@Component({
  selector: 'admin-device-group-editor.component',
  templateUrl: './admin-device-group-editor.component.html',
  styleUrls: ['./admin-device-group-editor.component.scss']
})
export class AdminDeviceGroupEditorComponent {
  @ViewChild('form', { static: true })
  private form: NgForm;
  fallbackIcon = 'fa fa-book';
  icon: string;

  private isNewDeviceGroup = false;
  private isSaving: boolean;
  private onDeviceGroupSaved = new Subject<DeviceGroup>();
  public allCategories: string[];
  public translatedCategories: KeyValue<string, string>[] = [];

  private deviceGroup: DeviceGroup = new DeviceGroup();
  public allRooms: Room[];

  deviceGroupForm: FormGroup;

  get name() {
    return this.deviceGroupForm.get('name');
  }

  get category() {
    return this.deviceGroupForm.get('category');
  }

  get room() {
    return this.deviceGroupForm.get('room');
  }


  public allDevices: Device[];
  public allDeviceTypeFunctions: DeviceTypeFunction[];

  constructor(
    public dialogRef: MatDialogRef<AdminDeviceGroupEditorComponent>,
    private alertService: AlertService,
    private deviceGroupService: DeviceGroupService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { deviceGroup: DeviceGroup, rooms : Room[], devices: Device[], deviceTypeFunctions: DeviceTypeFunction[]},
  )
  {
    let self = this;
    this.deviceGroup = data.deviceGroup;
    this.allRooms = data.rooms;
    this.allDevices = data.devices.filter(x => x.ioType == IoType.Both || x.ioType == IoType.Output);
    this.allDeviceTypeFunctions = data.deviceTypeFunctions;
    this.allCategories = Object.keys(DeviceCategory).filter((item) => {
      return isNaN(Number(item));
    });

    this.translateCategories();
    this.buildForm();
    this.resetForm();
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.deviceGroupForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    if (this.isSaving) {
      return;
    }

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    const editedDeviceGroup = this.getEditedDeviceGroup();

    if (this.deviceGroup.id == 0) {
      this.isNewDeviceGroup = true;
      this.deviceGroupService.newDeviceGroup(editedDeviceGroup).subscribe(
        deviceGroup => this.saveSuccessHelper(deviceGroup),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewDeviceGroup = false;
      this.deviceGroupService.updateDeviceGroup(editedDeviceGroup).subscribe(
        response => this.saveSuccessHelper(editedDeviceGroup),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedDeviceGroup(): DeviceGroup {
    const formModel = this.deviceGroupForm.value;

    let category: DeviceCategory;

    if (this.deviceGroupForm.get('category').enabled) {
      category = parseInt(DeviceCategory[formModel.category]);
    } else {
      category = parseInt(this.deviceGroup.category.toString()) as DeviceCategory;
    }

    return {
      id: this.deviceGroup.id,
      name: formModel.name,
      category: category,
      room: this.allRooms.find(x => x.id == formModel.room),
      roomId: 0,
      devices: []
    };
  }

  public onRoomChange(roomId: any, row: any) {

    let filteredDevices = this.allDevices.filter(x => x.roomId == roomId.value);
    row.currentData.filteredDevices = filteredDevices;
  }

  private saveSuccessHelper(deviceGroup?: DeviceGroup) {

    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewDeviceGroup) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onDeviceGroupSaved.next(deviceGroup);

    this.dialogRef.close(deviceGroup);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    this.enableSaveButton();
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.deviceGroupForm = this.formBuilder.group({
      name: ['', Validators.required],
      room: ['', Validators.required],
      category: [{ value: '', disabled : this.deviceGroup.id != 0}, Validators.required],
    });
  }

  private resetForm(replace = false) {
    this.enableSaveButton();
    this.deviceGroupForm.reset({
      name: this.deviceGroup.name || '',
      room: this.deviceGroup.room && this.deviceGroup.room.id ? this.deviceGroup.room.id : '',
      category: DeviceCategory[this.deviceGroup.category] || '',

    });
  }

  private translateCategories() {

    let self = this;

    if (this.allCategories) {

      this.allCategories.forEach(function (item) {

        if ((<any>DeviceCategory)[item] != DeviceCategory.None) {
          var translation = self.translationService.getTranslation('lookups.deviceCategory.' + item);
          self.translatedCategories.push({ key: item, value: translation });
        }
      });

      var sorted = self.translatedCategories.sort((a, b) => {

        var isAsc = true;

        return (a.value < b.value ? -1 : 1) * (isAsc ? 1 : -1);

      });

      self.translatedCategories = sorted;

    }

  }
}
