import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { PagedData } from '../../models/PagedData';
import {AccessZoneActionEndpoint} from "./access-action-endpoint.service";
import {AccessZoneAction} from "../../models/accessZone/access-zone-action.model";


@Injectable()
export class AccessZoneActionService {

  constructor(private router: Router, private http: HttpClient,
    private accessZoneActionMapEndpoint: AccessZoneActionEndpoint) {

  }

  getAccessZoneAction(zoneId:number, id: number) {
    return this.accessZoneActionMapEndpoint.getAccessZoneActionEndpoint<AccessZoneAction>(zoneId, id);
  }

  getAccessZoneActions(zoneId: number, page: number = 0, pageSize: number = 9999) {

    return this.accessZoneActionMapEndpoint.getAccessZoneActionsEndpoint<PagedData<AccessZoneAction>>(zoneId, page, pageSize);
  }

  updateAccessZoneAction(zoneId:number, accessZoneActionMap: AccessZoneAction) {
    return this.accessZoneActionMapEndpoint.getUpdateAccessZoneActionEndpoint(zoneId, accessZoneActionMap, accessZoneActionMap.id);
  }

  newAccessZoneAction(zoneId:number, accessZoneActionMap: AccessZoneAction) {
    return this.accessZoneActionMapEndpoint.getNewAccessZoneActionEndpoint<AccessZoneAction>(zoneId, accessZoneActionMap);
  }

  deleteAccessZoneAction(zoneId:number, id): Observable<AccessZoneAction> {

    return this.accessZoneActionMapEndpoint.getDeleteAccessZoneActionEndpoint(zoneId, id);
  }
}
