import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ZoneActionService } from 'src/app/services/alarm/zone-action.service';
import { AlarmZone } from '../../../models/alarm/alarm-zone.model';
import { ZoneActionType } from '../../../models/alarm/zone-action-type.model';
import { ZoneAction } from '../../../models/alarm/zone-action.model';
import { ZoneDevice } from '../../../models/alarm/zone-device.model';
import { DeviceCategory } from '../../../models/device/device-category.model';
import { Device } from '../../../models/device/device.model';
import { Room } from '../../../models/room/room.model';
import { IoType } from '../../../models/io-type.model';
import { ZoneDeviceService } from '../../../services/alarm/zone-device.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { Utilities } from '../../../services/utilities';
import { AdminAlarmZoneActionEditorComponent } from './admin-alarm-zone-action-editor.component';
import { AdminZoneActionSettingsEditorComponent } from './admin-alarm-zone-action-settings.component';
import { AdminAlarmZoneSensorEditorComponent } from './admin-alarm-zone-sensor-editor.component';
import { AlarmProfile } from 'src/app/models/alarm/alarm-profile.model';



@Component({
  selector: 'admin-alarm-zone-settings',
  templateUrl: './admin-alarm-zone-settings.component.html',
  styleUrls: ['./admin-alarm-zone-settings.component.scss']
})
export class AdminAlarmZoneSettingsComponent implements OnInit {

  public allZoneDevices: ZoneDevice[];

  zoneDevicesDisplayedColumns = ['room', 'device', "devicePort", "deviceFunction", 'actions'];
  zoneDevicesDataSource: MatTableDataSource<ZoneDevice>;
  sourceZoneDevice: ZoneDevice;
  sourceZoneAction: ZoneAction;

  public allStartZoneActions: ZoneAction[];
  public allStopZoneActions: ZoneAction[];
  zoneActionMapsDisplayedColumns = ['waitTime', 'device', "deviceFunction", 'actions'];
  zoneStartActionMapsDataSource: MatTableDataSource<ZoneAction>;
  zoneStopActionMapsDataSource: MatTableDataSource<ZoneAction>;

  @Input() allRooms: Room[];

  @Input() allDevices: Device[];
  @Input() inputDevices: Device[];
  @Input() outputDevices: Device[];
  @Input() alarmZone: AlarmZone;
  @Input() alarmProfile: AlarmProfile;

  constructor(
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private zoneDeviceService: ZoneDeviceService,
    private zoneActionService: ZoneActionService,
    private translationService: AppTranslationService,
  ) {
    this.zoneDevicesDataSource = new MatTableDataSource();
    this.zoneStartActionMapsDataSource = new MatTableDataSource();
    this.zoneStopActionMapsDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.alertService.stopLoadingMessage();

    this.zoneDeviceService.getZoneDevices(this.alarmZone.id, this.alarmProfile.id, 0, 999).subscribe(results => {
      this.allZoneDevices = results.items;
      this.zoneDevicesDataSource.data = this.allZoneDevices;
    });

    this.zoneActionService.getZoneActions(this.alarmZone.id, this.alarmProfile.id, ZoneActionType.Start, 0, 999).subscribe(results => {
      this.allStartZoneActions = results.items;
      this.zoneStartActionMapsDataSource.data = this.allStartZoneActions;
    });

    this.zoneActionService.getZoneActions(this.alarmZone.id, this.alarmProfile.id, ZoneActionType.Stop, 0, 999).subscribe(results => {
      this.allStopZoneActions = results.items;
      this.zoneStopActionMapsDataSource.data = this.allStopZoneActions;
    });
  }

  public editZoneSensor(zoneSensor: ZoneDevice) {
    this.sourceZoneDevice = zoneSensor;

    if (!zoneSensor) {
      zoneSensor = new ZoneDevice();
    }

    let dialogRef = this.dialog.open(AdminAlarmZoneSensorEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { alarmZone: this.alarmZone, alarmProfile: this.alarmProfile, zoneDevice: zoneSensor, inputs: this.inputDevices, rooms: this.allRooms}
      });
    dialogRef.afterClosed().subscribe(device => {
      if (device) {
        this.updateZoneSensors(device);
      }
    });
  }


  public createZoneAction(actionType: ZoneActionType) {
    let zoneAction = new ZoneAction();
    this.sourceZoneAction = zoneAction;

    let dialogRef = this.dialog.open(AdminAlarmZoneActionEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { alarmZone: this.alarmZone, alarmProfile: this.alarmProfile, zoneAction: zoneAction, devices: this.allDevices, rooms: this.allRooms, actionType: actionType }
      });
    dialogRef.afterClosed().subscribe(action => {
      if (action) {
        this.updateZoneActions(action);
      }
    });
  }

  public editZoneAction(zoneAction: ZoneAction, actionType: ZoneActionType) {
    this.sourceZoneAction = zoneAction;

    if (!zoneAction) {
      zoneAction = new ZoneAction();
    }

    let dialogRef = this.dialog.open(AdminAlarmZoneActionEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { alarmZone: this.alarmZone, alarmProfile: this.alarmProfile, zoneAction: zoneAction, devices: this.allDevices, rooms: this.allRooms, actionType: actionType }
      });
    dialogRef.afterClosed().subscribe(action => {
      if (action) {
        this.updateZoneActions(action);
      }
    });
  }

  public editZoneActionSettings(zoneAction?: ZoneAction) {
    this.sourceZoneAction = zoneAction;

    let dialogRef = this.dialog.open(AdminZoneActionSettingsEditorComponent,
      {
        panelClass: 'mat-dialog-lg',
        data: { zoneAction: zoneAction }
      });
    dialogRef.afterClosed().subscribe(zoneAction => {
      if (zoneAction) {
        this.updateZoneActions(zoneAction);
      }
    });
  }

  private confirmZoneSensorDelete(sensor: ZoneDevice) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);

        this.zoneDeviceService.deleteZoneDevice(sensor.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.zoneDevicesDataSource.data = this.zoneDevicesDataSource.data.filter(item => item !== sensor)
          },
            error => {
              this.alertService.stopLoadingMessage();

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }

  private confirmZoneActionDelete(zoneAction: ZoneAction) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);

        this.zoneActionService.deleteZoneAction(zoneAction.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();

            if (zoneAction.type == ZoneActionType.Start) {
              this.zoneStartActionMapsDataSource.data = this.zoneStartActionMapsDataSource.data.filter(item => item !== zoneAction)
            }
            else {
              this.zoneStopActionMapsDataSource.data = this.zoneStopActionMapsDataSource.data.filter(item => item !== zoneAction)
            }
          },
            error => {
              this.alertService.stopLoadingMessage();

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }

  private filterSensorDevices(devices: Device[]): Device[] {
    return devices.filter(x => x.category === DeviceCategory.MovementSensor || x.category == DeviceCategory.SmokeSensor);
  }

  private filterOutputs(devices: Device[]): Device[] {
    return devices.filter(x => x.ioType === IoType.Output || x.ioType === IoType.Both);
  }

  private updateZoneActions(zoneAction: ZoneAction) {
    if (this.sourceZoneAction) {
      Object.assign(this.sourceZoneAction, zoneAction);
      this.sourceZoneAction = null;

      if (zoneAction.type === ZoneActionType.Start) {
        this.loadStartActions();
      }
      else {
        this.loadStopActions();
      }

    }

    this.refreshZoneActions();
  }

  private updateZoneSensors(zoneDevice: ZoneDevice) {
    if (this.sourceZoneDevice) {
      Object.assign(this.sourceZoneDevice, zoneDevice);
      this.sourceZoneDevice = null;
    }
    else {
      this.zoneDevicesDataSource.data.unshift(zoneDevice);
    }

    this.refreshZoneDevices();
  }

  public applyZoneDeviceFilter(filterValue: string) {
    this.zoneDevicesDataSource.filter = filterValue;
  }

  private refreshZoneDevices() {
    this.applyZoneDeviceFilter(this.zoneDevicesDataSource.filter);
  }

  public applyStartZoneActionFilter(filterValue: string) {
    this.zoneStartActionMapsDataSource.filter = filterValue;
  }

  public applyStopZoneActionFilter(filterValue: string) {
    this.zoneStopActionMapsDataSource.filter = filterValue;
  }

  private refreshZoneActions() {
    this.applyStartZoneActionFilter(this.zoneStartActionMapsDataSource.filter);
    this.applyStopZoneActionFilter(this.zoneStopActionMapsDataSource.filter);
  }

  private loadStartActions() {

    this.zoneActionService.getZoneActions(this.alarmZone.id, this.alarmProfile.id, ZoneActionType.Start, 0, 999).subscribe(results => {
      this.allStartZoneActions = results.items;
      this.zoneStartActionMapsDataSource.data = this.allStartZoneActions;
    });
  }

  private loadStopActions() {

    this.zoneActionService.getZoneActions(this.alarmZone.id, this.alarmProfile.id, ZoneActionType.Stop, 0, 999).subscribe(results => {
      this.allStopZoneActions = results.items;
      this.zoneStopActionMapsDataSource.data = this.allStopZoneActions;
    });
  }

  public translateType(type: ZoneActionType): string {
    try {
      return this.translationService.getTranslation(`lookups.deviceCategory.${ZoneActionType[type]}`);
    } catch (e) {
      return type.toString();
    }
  }

  public cancel() {

    this.alertService.resetStickyMessage();

    this.router.navigate([`admin/alarm/${this.alarmZone.alarmId}/settings`, ]);
  }
}
