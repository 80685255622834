import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { EnergyReadingEnpoint } from './energy-reading-endpoint.service';
import {ProcessedEnergyReading} from "../../models/energy/processed-energy-reading.model";
import {EnergyReadingSum} from "../../models/energy/energy-reading-sum";
import {DetailedEnergyReading} from "../../models/energy/detailed-energy-reading";


@Injectable()
export class EnergyReadingService {

  constructor(private router: Router, private http: HttpClient,
    private energyReadingEndpoint: EnergyReadingEnpoint) {

  }

  getEnergyReadings(type: number, zoneId:number, date: Date): Observable<ProcessedEnergyReading[]>{

    return this.energyReadingEndpoint.getEnergyReadingsEndpoint(type, zoneId, date);
  }

  getSum(type: number, date: Date): Observable<EnergyReadingSum[]>{

    return this.energyReadingEndpoint.getSum(type, date);
  }

  getDetail(zoneId: number): Observable<DetailedEnergyReading>{

    return this.energyReadingEndpoint.getDetailed(zoneId);
  }
}
