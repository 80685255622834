import { Device } from "../device/device.model";
import { DeviceFunction } from "./device-function.model";
import { DevicePort } from "./device-port.model";

export class DevicePortMap {

  constructor(id: number = 0, inputDevice?: Device, inputFunction?: DeviceFunction, inputPort?: DevicePort, outputDevice?: Device, outputFunction?: DeviceFunction, outputPort?: DevicePort, forceRemoteExecution?: boolean, localExecution?: boolean ) {

    this.id = id;
    this.inputDevice = inputDevice;
    this.inputFunction = inputFunction;
    this.inputPort = inputPort;
    this.outputDevice = outputDevice;
    this.outputFunction = outputFunction;
    this.outputPort = outputPort;
    this.forceRemoteExecution = forceRemoteExecution;
    this.localExecution = localExecution;
  }

  public id: number;
  public inputDevice: Device;
  public inputFunction: DeviceFunction;
  public inputPort: DevicePort;
  public outputDevice: Device;
  public outputFunction: DeviceFunction;
  public outputPort: DevicePort;
  public forceRemoteExecution: boolean;
  public localExecution: boolean;
}
