import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { FloorEndpoint } from './floor-endpoint.service';
import { Floor } from '../../models/floor/floor.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class FloorService {

  constructor(private router: Router, private http: HttpClient,
    private floorEndpoint: FloorEndpoint) {

  }
  
  getFloor(id?: number): Observable<Floor> {
    if (id) {
      return this.floorEndpoint.getFloorEndpoint<Floor>(id);
    }

    
  }

  getFloors(page: number = 0, pageSize: number = 9999): Observable<PagedData<Floor>>{

    return this.floorEndpoint.getFloorsEndpoint<PagedData<Floor>>(page, pageSize);
  }
  
  updateFloor(floor: Floor): Observable<Floor> {
    return this.floorEndpoint.getUpdateFloorEndpoint(floor, floor.id);
  }
  
  newFloor(floor: Floor): Observable<Floor>{
    return this.floorEndpoint.getNewFloorEndpoint<Floor>(floor);
  }
  
  deleteFloor(id): Observable<Floor> {

    return this.floorEndpoint.getDeleteFloorEndpoint(id);
  }
}
