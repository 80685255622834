import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog, PageEvent } from '@angular/material';

import { fadeInOut } from '../../../services/animations';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from "../../../services/app-translation.service";
import { FloorService } from '../../../services/floor/floor.service';
import { Utilities } from '../../../services/utilities';
import { Floor } from '../../../models/floor/floor.model';
import { AdminFloorEditorComponent } from "./admin-floor-editor.component";

@Component({
  selector: "admin-floor-list",
  templateUrl: './admin-floor-list.component.html',
  styleUrls: ['./admin-floor-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminFloorListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'description', 'actions'];
  datasource: null;
  dataSource: MatTableDataSource<Floor>;
  sourceFloor: Floor;
  editingFloorName: { name: string };
  loadingIndicator: boolean;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private floorService: FloorService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateFloors(floor: Floor) {
    if (this.sourceFloor) {
      Object.assign(this.sourceFloor, floor);
      this.sourceFloor = null;
    }
    else {
      this.dataSource.data.unshift(floor);
    }

    this.refresh();
  }

  private loadData() {
    var self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.floorService.getFloors(this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.dataSource.data = results.items;
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  public editFloor(floor?: Floor) {
    this.sourceFloor = floor;

    if (!floor) {
      floor = new Floor();
    }

    let dialogRef = this.dialog.open(AdminFloorEditorComponent,
      {
        panelClass: 'mat-dialog-sm',
        data: { floor: floor }
      });
    dialogRef.afterClosed().subscribe(floor => {
      if (floor) {
        this.updateFloors(floor);
      }
    });
  }

  private confirmDelete(floor: Floor) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.floorService.deleteFloor(floor.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== floor)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }
}
