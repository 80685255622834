import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';



@Injectable()
export class AlarmAccessKeyEndpoint extends EndpointFactory {

  private readonly _alarmAccessKeyUrl: string = "/api/alarm/{alarmId}/alarmAccessKey";

  get alarmAccessKeyUrl() { return this.configurations.baseUrl + this._alarmAccessKeyUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getAlarmAccessKeyEndpoint<T>(alarmId: number, id: number): Observable<T> {
    let endpointUrl = `${this.alarmAccessKeyUrl}/${id}`;
    endpointUrl = endpointUrl.replace("{alarmId}", alarmId.toString());

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAlarmAccessKeyEndpoint(alarmId, id));
      }));
  }
  
  getAlarmAccessKeysEndpoint<T>(alarmId: number): Observable<T> {

    let endpointUrl = `${this.alarmAccessKeyUrl}`;
    endpointUrl = endpointUrl.replace("{alarmId}", alarmId.toString());

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAlarmAccessKeysEndpoint(alarmId));
      }));
  }


  getNewAlarmAccessKeyEndpoint<T>(alarmId: number, alarmAccessKeyObject: any): Observable<T> {

    let endpointUrl = this.alarmAccessKeyUrl.replace("{alarmId}", alarmId.toString());

    return this.http.post<T>(endpointUrl, JSON.stringify(alarmAccessKeyObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewAlarmAccessKeyEndpoint(alarmId, alarmAccessKeyObject));
      }));
  }

  getUpdateAlarmAccessKeyEndpoint<T>(alarmId: number, alarmAccessKeyObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.alarmAccessKeyUrl}/${id}` : `${this.alarmAccessKeyUrl}`;
    endpointUrl = endpointUrl.replace("{alarmId}", alarmId.toString());

    return this.http.put<T>(endpointUrl, JSON.stringify(alarmAccessKeyObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateAlarmAccessKeyEndpoint(alarmId, alarmAccessKeyObject, id));
      }));
  }

  getDeleteAlarmAccessKeyEndpoint<T>(alarmId: number, id: number): Observable<T> {
    let endpointUrl = `${this.alarmAccessKeyUrl}/${id}`;
    endpointUrl = endpointUrl.replace("{alarmId}", alarmId.toString());

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteAlarmAccessKeyEndpoint(alarmId, id));
      }));
  }
  
}
