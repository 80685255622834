import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { RoomEndpoint } from './room-endpoint.service';
import { Room } from '../../models/room/room.model';
import { PagedData } from '../../models/PagedData';
import { Device } from '../../models/device/device.model';
import { RoomAction } from '../../models/room/room-action.model';

@Injectable()
export class RoomService {

  constructor(private router: Router, private http: HttpClient,
    private roomEndpoint: RoomEndpoint) {

  }
  
  getRoom(id?: number): Observable<Room> {
    if (id) {
      return this.roomEndpoint.getRoomEndpoint<Room>(id);
    }

    
  }

  getRooms(page: number = 0, pageSize: number = 9999): Observable<PagedData<Room>> {

    return this.roomEndpoint.getRoomsEndpoint<PagedData<Room>>(page, pageSize);
  }

  getRoomDevices(roomId:number, page: number = 0, pageSize: number = 9999): Observable<PagedData<Device>> {

    return this.roomEndpoint.getRoomDevicesEndpoint<PagedData<Device>>(roomId, page, pageSize);
  }


  getRoomActions(roomId: number, page: number = 0, pageSize: number = 9999) {

    return this.roomEndpoint.getRoomActionsEndpoint<PagedData<RoomAction>>(roomId, page, pageSize);
  }

  updateRoom(room: Room): Observable<Room> {
    return this.roomEndpoint.getUpdateRoomEndpoint(room, room.id);
  }
  
  newRoom(room: Room): Observable<Room> {
    return this.roomEndpoint.getNewRoomEndpoint<Room>(room);
  }
  
  deleteRoom(id): Observable<Room> {

    return this.roomEndpoint.getDeleteRoomEndpoint(id);
  }
}
