import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog, PageEvent } from '@angular/material';

import { fadeInOut } from '../../../services/animations';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from "../../../services/app-translation.service";
import { ModuleService } from '../../../services/module/module.service';
import { Module } from '../../../models/module/module.model';
import { AdminModuleEditorComponent } from "./admin-module-editor.component";
import { CabinetService } from '../../../services/cabinet/cabinet.service';
import { Cabinet } from '../../../models/cabinet/cabinet.model';
import { ModuleTypeService } from '../../../services/moduleType/module-type.service';
import { ModuleType } from '../../../models/moduleType/module-type.model';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { ModuleStatus } from '../../../models/module/module-status.model';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: "admin-module-list",
  templateUrl: './admin-module-list.component.html',
  styleUrls: ['./admin-module-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminModuleListComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'description', 'moduleType', 'cabinet', 'status', 'actions'];
  datasource: null;
  dataSource: MatTableDataSource<Module>;
  sourceModule: Module;
  editingModuleName: { name: string };
  loadingIndicator: boolean;


  allCabinets: Cabinet[] = [];
  allModules: Module[] = [];
  allModuleTypes: ModuleType[] = [];
  private moduleStatusHubConnection: HubConnection;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private moduleService: ModuleService,
    private cabinetService: CabinetService,
    private moduleTypeService: ModuleTypeService,
    private snackBar: MatSnackBar,
    private authService: AuthService,
    private dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource();
    this.startModuleStatusHubConnection();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateModules(module: Module) {
    if (this.sourceModule) {
      Object.assign(this.sourceModule, module);
      this.sourceModule = null;
    }
    else {
      this.dataSource.data.unshift(module);
    }

    this.refresh();
  }

  private loadData() {
    var self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.moduleTypeService.getModuleTypes()
      .subscribe(results => {
        this.allModuleTypes = results.items;
      },
        error => {

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });

    this.cabinetService.getCabinets()
      .subscribe(results => {
        this.allCabinets = results.items;
      },
        error => {

        let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });

    this.moduleService.getModules(this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.allModules = results.items;

        this.dataSource.data = this.allModules;

        this.connect();
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  public editModule(module?: Module) {
    this.sourceModule = module;

    if (!module) {
      module = new Module();
    }

    let dialogRef = this.dialog.open(AdminModuleEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { module: module, cabinets: [...this.allCabinets], moduleTypes: [...this.allModuleTypes]   }
      });
    dialogRef.afterClosed().subscribe(module => {
      if (module) {
        this.updateModules(module);
      }
    });
  }

  private confirmDelete(module: Module) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.moduleService.deleteModule(module.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== module)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }

  private startModuleStatusHubConnection() {
    const self = this;

    let builder = new HubConnectionBuilder();

    this.moduleStatusHubConnection = builder
      .withUrl(location.origin + '/hubs/moduleStatus',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    this.moduleStatusHubConnection.on("moduleStatus", (message) => {
      self.updateModuleStatus(message);
    });


  }

  private updateModuleStatus(message: any) {
    if (message && message.mStatus) {
      for (let entry of message.mStatus) {
        const moduleId = entry.id;
        if (moduleId && this.allModules && this.allModules.length > 0) {
          var module = this.allModules.find(x => x.id == moduleId);
          if (module) {
            module.status = this.getStatusLabel(entry.status);
          }
        }
        else {
          console.log("module not available");
        }
      }
    }
  }

  private connect() {
    if (this.moduleStatusHubConnection.state === HubConnectionState.Disconnected) {
      this.moduleStatusHubConnection.start().then(() => {
        this.moduleStatusHubConnection.invoke("emitStatus").catch(function (err) {
        });
      }).catch(function (err) {
      });
    } else if (this.moduleStatusHubConnection.state === HubConnectionState.Connected) {
      this.moduleStatusHubConnection.invoke("emitStatus").catch(function (err) {
      });
    }
  }

  private getStatusLabel(status: number)
  {
    return this.translationService.getTranslation(`lookups.moduleStatus.${ModuleStatus[status]}`);
  }

  ngOnDestroy(): void {
    this.moduleStatusHubConnection.stop();
  }
}
