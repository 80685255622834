import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Device } from '../../../models/device/device.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { AppTranslationService } from "../../../services/app-translation.service";
import { InputMappingService } from '../../../services/inputMapping/input-mapping.service';
import { Utilities } from '../../../services/utilities';


@Component({
  selector: "admin-input-map-list",
  templateUrl: './admin-input-map-list.component.html',
  styleUrls: ['./admin-input-map-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminInputMapListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'description', 'room', 'actions'];
  dataSource: MatTableDataSource<Device>;
  sourceInput: Device;
  editingModuleName: { name: string };
  loadingIndicator: boolean;


  allDevices: Device[] = [];

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private inputsService: InputMappingService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data, filter) => this.filterData(data, filter);
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.inputsService.getInputMappings(this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.dataSource.data = results.items;
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  public editInput(input?: Device) {
    this.sourceInput = input;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.router.navigate(['/admin/deviceportmap', input.id]);
  }


  private filterData(input: Device, filter: string): boolean {
    return Utilities.searchArray(filter, false, input.name, input.description, input.room != null ? input.room.name : null);
  }
}
