import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { AppTranslationService } from '../../../services/app-translation.service';
import { SettingsService } from '../../../services/settings/settings.service';
import { Utilities } from '../../../services/utilities';
import { Settings } from '../../../models/settings/settings.model';
import { LocalStoreManager } from '../../../services/local-store-manager.service';


@Component({
  selector: 'admin-settings',
  templateUrl: './admin-settings.component.html',
  styleUrls: ['./admin-settings.component.scss'],
  animations: [fadeInOut]
})
export class AdminSettingsComponent implements OnInit, OnDestroy, AfterViewInit {
  fragmentSubscription: any;

  @ViewChild('profile', { static: true }) profilePanel: MatExpansionPanel
  @ViewChild('preferences', { static: true }) preferencesPanel: MatExpansionPanel
  loadingIndicator: boolean;
  public settings: Settings = new Settings();

  constructor(
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute,
    private translationService: AppTranslationService,
    private settingService: SettingsService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }

  private loadData() {
    var self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.settingService.getSettings()
      .subscribe(result => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        self.settings = result;
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  public save() {
    let self = this;

    this.loadingIndicator = true;
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    this.settingService.updateSettings(this.settings)
      .subscribe(result => {
        this.loadingIndicator = false;
        this.alertService.stopLoadingMessage();

          let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
          let successLabel = this.translationService.getTranslation(`shared.Success`);

        this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
      },
        error => {
          this.loadingIndicator = false;

          let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });

  }
}
