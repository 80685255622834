import { DeviceTypeValue } from "../device/device-type-value.model";
import { Device } from "../device/device.model";
import {ParameterValueSource} from "../automationRule/parameter-value-source.model";

export class RoomActionFunctionParameter {

  constructor(id: number = 0, code?: string, description?: string, ruleActionMapId?: number, value?: string, source?: ParameterValueSource, deviceId?: number, device?: Device, deviceTypeFunctionParameterId?: number) {

    this.id = id;
    this.code = code;
    this.description = description;
    this.ruleActionMapId = ruleActionMapId;
    this.value = value;
    this.deviceId = deviceId;
    this.device = device;
    this.deviceTypeFunctionParameterId = deviceTypeFunctionParameterId;
    this.source = source;
  }

  public id: number;
  public code: string;
  public description: string;
  public ruleActionMapId: number;
  public value: string;
  public source: ParameterValueSource;
  public deviceId: number;
  public device: Device;
  public deviceTypeFunctionParameterId: number;
}
