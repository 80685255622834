import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class AlarmProfileEndpoint extends EndpointFactory {

  private readonly _alarmProfileUrl: string = "/api/alarmprofile";

  get alarmprofileUrl() { return this.configurations.baseUrl + this._alarmProfileUrl; }


  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getAlarmProfileEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.alarmprofileUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAlarmProfileEndpoint(id));
      }));
  }
  
  getAlarmProfilesEndpoint<T>(alarmId?:number, page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._alarmProfileUrl}?alarmId=${alarmId}&page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAlarmProfilesEndpoint(page, pageSize));
      }));
  }

  getNewAlarmProfileEndpoint<T>(alarmProfileObject: any): Observable<T> {

    return this.http.post<T>(this.alarmprofileUrl, JSON.stringify(alarmProfileObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewAlarmProfileEndpoint(alarmProfileObject));
      }));
  }

  getUpdateAlarmProfileEndpoint<T>(alarmProfileObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.alarmprofileUrl}/${id}` : `${this.alarmprofileUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(alarmProfileObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateAlarmProfileEndpoint(alarmProfileObject, id));
      }));
  }

  getDeleteAlarmProfileEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.alarmprofileUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteAlarmProfileEndpoint(id));
      }));
  }

  changeState<T>(id: number, state: number): Observable<T> {
    let endpointUrl = `${this.alarmprofileUrl}/${id}/state/${state}`;

    return this.http.post(endpointUrl, [], this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.changeState(id, state));
      }));
  }
}
