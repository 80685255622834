import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class DeviceScheduleEndpoint extends EndpointFactory {

  private readonly _deviceScheduleUrl: string = "/api/deviceSchedule";

  get deviceScheduleUrl() { return this.configurations.baseUrl + this._deviceScheduleUrl; }


  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getDeviceScheduleEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.deviceScheduleUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeviceScheduleEndpoint(id));
      }));
  }
  
  getDeviceSchedulesEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._deviceScheduleUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeviceSchedulesEndpoint(page, pageSize));
      }));
  }

  getNewDeviceScheduleEndpoint<T>(deviceScheduleObject: any): Observable<T> {

    return this.http.post<T>(this.deviceScheduleUrl, JSON.stringify(deviceScheduleObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewDeviceScheduleEndpoint(deviceScheduleObject));
      }));
  }

  getUpdateDeviceScheduleEndpoint<T>(deviceScheduleObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.deviceScheduleUrl}/${id}` : `${this.deviceScheduleUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(deviceScheduleObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateDeviceScheduleEndpoint(deviceScheduleObject, id));
      }));
  }

  getDeleteDeviceScheduleEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.deviceScheduleUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteDeviceScheduleEndpoint(id));
      }));
  }
}
