import { HttpEventType } from '@angular/common/http';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { UploadDownloadService } from '../../upload-download.service';
import { AppTranslationService } from '../../services/app-translation.service';

@Component({
  selector: 'app-upload',
  templateUrl: 'upload.component.html'
})

export class UploadComponent {
  public progress: number;
  public message: string;
  @Output() public onUploadFinished = new EventEmitter();


  constructor(private service: UploadDownloadService, private router: Router, private alertService: AlertService, private translationService: AppTranslationService) {
      }

  public upload(files) {
    if (files.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.append('file', files[0], files[0].name);
    
    this.service.upload(formData).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress)
        this.progress = Math.round(100 * event.loaded / event.total);
      else if (event.type === HttpEventType.Response) {
        this.message = 'Upload success.';
        this.onUploadFinished.emit(event.body);
        this.router.navigateByUrl('admin/restoring');
      }
    },
      error => this.handleError(error));
  }

  private handleError(error: any) {

    this.alertService.showStickyMessage(this.translationService.getTranslation('errors.InvalidFile'), this.translationService.getTranslation('errors.RestoreDatabase'), MessageSeverity.error);
  }
}
