import { Component } from '@angular/core';
import { fadeInOut } from '../../services/animations';

@Component({
  selector: 'device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.scss'],
  animations: [fadeInOut]
})
export class DeviceComponent {
}
