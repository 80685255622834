import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AutomationRule } from 'src/app/models/automationRule/automation-rule.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AutomationRuleService } from 'src/app/services/automationRule/automation-rule.service';
import { AutomationRuleState } from '../../models/automationRule/automation-rule-state.model';

@Component({
  selector: 'automation-card',
  templateUrl: './automation-card.component.html',
  styleUrls: ['./automation-card.component.scss']
})
export class AutomationCardComponent implements OnInit {
  @Input() public automation: AutomationRule;

  public currentProfile: string;
  public defaultIcon = './assets/icons/automacao02.svg';

  constructor(private automationService: AutomationRuleService, private router: Router, private translationService: AppTranslationService) {
  }

  ngOnInit() {
    const self = this;
    
  }
  
  public translateState(state: AutomationRuleState): string {
    try {
      return this.translationService.getTranslation(`lookups.AutomationState.${AutomationRuleState[state]}`);
    } catch (e) {
      return state.toString();
    }
  }

  public getState(automation: AutomationRule): string {
    let state = '';

    if (automation.state != null) {
      try {
        state = this.translationService.getTranslation(`lookups.AutomationState.${AutomationRuleState[automation.state]}`);
      } catch (e) {
        return state;
      }
    }

    return state;
  }

  public changeState(id: number, state: number) {
    this.automationService.changeState(id, state).subscribe();
  }

  public isStateActiveOrEnabled(state: AutomationRuleState): boolean {
    switch (state) {
      case AutomationRuleState.EVALUATING:
      case AutomationRuleState.RUNNING:
        return true;
      default:
        return false;
    }
  }
}
