import { DeviceFunction } from "../device/device-function.model";
import { Device } from "../device/device.model";
import { AutomationRuleActionParameter } from "./automation-rule-action-parameter.model";
import { AutomationRuleActionType } from "./automation-rule-action-type.model";

export class AutomationRuleAction {

  constructor(id: number = 0, automationRuleId?: number, actionType?: AutomationRuleActionType, waitTime?: number, device?: Device, deviceFunction?: DeviceFunction, parameters?: AutomationRuleActionParameter[]) {

    this.id = id;
    this.automationRuleId = automationRuleId;
    this.device = device;
    this.deviceFunction = deviceFunction;
    this.waitTime = waitTime;
    this.parameters = parameters;
    this.actionType = actionType;
  }

  public id: number;
  public waitTime: number;
  public automationRuleId: number;
  public actionType: AutomationRuleActionType;
  public device: Device;
  public deviceFunction: DeviceFunction;
  public parameters: AutomationRuleActionParameter[];
}
