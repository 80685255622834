import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';



@Injectable()
export class EnergyReadingEnpoint extends EndpointFactory {

  private readonly _energyReadingUrl: string = "/api/energyreading";

  get energyReadingUrl() { return this.configurations.baseUrl + this._energyReadingUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  getEnergyReadingsEndpoint<T>(type?: number, zoneId?: number, date?: Date): Observable<T> {

    let endpointUrl = `${this._energyReadingUrl}?type=${type}&zoneId=${zoneId}&date=${date.toJSON()}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getEnergyReadingsEndpoint(type, zoneId, date));
      }));
  }

  getSum<T>(type?: number, date?: Date): Observable<T> {

    let endpointUrl = `${this._energyReadingUrl}/sum?type=${type}&date=${date.toJSON()}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSum(type, date));
      }));
  }

  getDetailed<DetailedEnergyReading>(zoneId?: number): Observable<DetailedEnergyReading> {

    let endpointUrl = `${this._energyReadingUrl}/detail?zoneId=${zoneId}`;

    return this.http.get<DetailedEnergyReading>(endpointUrl, this.getRequestHeaders()).pipe<DetailedEnergyReading>(
      catchError(error => {
        return this.handleError(error, () => this.getDetailed(zoneId));
      }));
  }
}
