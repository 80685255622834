import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { ZoneActionType } from '../../../models/alarm/zone-action-type.model';
import { ZoneAction } from '../../../models/alarm/zone-action.model';
import { DeviceCategory } from '../../../models/device/device-category.model';
import { Device } from '../../../models/device/device.model';
import { IoType } from '../../../models/io-type.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';``
import {User} from "../../../models/user.model";
import {AccessZoneAction} from "../../../models/accessZone/access-zone-action.model";
import {AccessZone} from "../../../models/accessZone/access-zone.model";
import {AccessZoneService} from "../../../services/accessZone/access-zone.service";
import {AdminAccessZoneActionEditorComponent} from "./admin-access-zone-action-editor.component";
import {Room} from "../../../models/room/room.model";
import {AccessZoneActionService} from "../../../services/accessZone/access-action.service";
import {AdminAccessZoneActionSettingsEditorComponent} from "./admin-access-zone-action-settings.component";
import {AdminUserListComponent} from "./admin-user-list.component";
import {AccessZoneUser} from "../../../models/accessZone/access-zone-user.model";



@Component({
  selector: 'admin-access-zone-settings',
  templateUrl: './admin-access-zone-settings.component.html',
  styleUrls: ['./admin-access-zone-settings.component.scss']
})
export class AdminAccessZoneSettingsComponent implements OnInit {

  public allActions: AccessZoneAction[];
  actionsDisplayedColumns = ['input', 'output', "deviceFunction", 'waitTime', 'actions'];
  actionsDataSource: MatTableDataSource<AccessZoneAction>;
  sourceAction: AccessZoneAction;

  usersDisplayedColumns = ['name', 'actions'];
  usersDataSource: MatTableDataSource<AccessZoneUser>;

  @Input() allUsers: AccessZoneUser[];

  @Input() allDevices: Device[];
  @Input() allRooms: Room[];
  @Input() inputDevices: Device[];
  @Input() outputDevices: Device[];
  @Input() accessZone: AccessZone;

  constructor(
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private zoneActionService: AccessZoneActionService,
    private accessZoneService: AccessZoneService,
    private translationService: AppTranslationService,
  ) {
    this.actionsDataSource = new MatTableDataSource();
    this.usersDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    this.alertService.stopLoadingMessage();

    let devicesData = this.route.snapshot.data['devices'];
    if (devicesData) {
      this.allDevices = devicesData.items;
    }

    this.inputDevices = this.filterSensorDevices(this.allDevices);
    this.outputDevices = this.filterOutputs(this.allDevices);

    let roomsData = this.route.snapshot.data['rooms'];
    if (roomsData) {
      this.allRooms = roomsData.items;
    }

    let accessZoneData = this.route.snapshot.data['accessZone'];
    if (accessZoneData) {
      this.accessZone = accessZoneData;
      if (this.accessZone.authorizedUsers) {
        this.usersDataSource.data = this.accessZone.authorizedUsers;
        this.allUsers = this.accessZone.authorizedUsers;
      }
    }

    this.loadActions();
  }

  public editZoneAction(action?: AccessZoneAction) {
    this.sourceAction = action;

    if (!action) {
      action = new AccessZoneAction();
    }

    let dialogRef = this.dialog.open(AdminAccessZoneActionEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { accessZone: this.accessZone,  zoneAction: action, devices: this.allDevices, rooms: this.allRooms}
      });
    dialogRef.afterClosed().subscribe(action => {
      if (action) {
        this.updateZoneActions(action);
      }
    });
  }

  public addUser() {
    let dialogRef = this.dialog.open(AdminUserListComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { accessZone: this.accessZone}
      });
    dialogRef.afterClosed().subscribe(accessZone => {

      if (accessZone) {
        this.loadData();
      }
    });
  }

  public loadData() {

    let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

    this.accessZoneService.getAccessZone(this.accessZone.id)
      .subscribe(result => {
          if (result) {
            this.accessZone = result;
            if (this.accessZone.authorizedUsers) {
              this.usersDataSource.data = this.accessZone.authorizedUsers;
              this.allUsers = this.accessZone.authorizedUsers;
            }
          }
        },
        error => {
          this.alertService.stopLoadingMessage();

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        })
  }

  public editActionSettings(zoneAction?: AccessZoneAction) {
    this.sourceAction = zoneAction;

    let dialogRef = this.dialog.open(AdminAccessZoneActionSettingsEditorComponent,
      {
        panelClass: 'mat-dialog-lg',
        data: { zoneAction: zoneAction }
      });
    dialogRef.afterClosed().subscribe(zoneAction => {
      if (zoneAction) {
        this.updateZoneActions(zoneAction);
      }
    });
  }

  private confirmZoneActionDelete(zoneAction: AccessZoneAction) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);

        this.zoneActionService.deleteAccessZoneAction(this.accessZone.id ,zoneAction.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();

              this.loadActions();
          },
            error => {
              this.alertService.stopLoadingMessage();

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }

  private confirmUserDelete(user: AccessZoneUser) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
      this.alertService.startLoadingMessage(deletingLabel);

      this.allUsers = this.allUsers.filter(x=> x.userId != user.userId);
      this.accessZone.authorizedUsers = this.allUsers;

      this.accessZoneService.updateAccessZone(this.accessZone)
        .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.allUsers = this.accessZone.authorizedUsers;
            this.usersDataSource.data = this.allUsers;
          },
          error => {
            this.alertService.stopLoadingMessage();

            this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
              MessageSeverity.error, error);
          });
    });
  }

  private filterSensorDevices(devices: Device[]): Device[] {
    return devices.filter(x => x.category === DeviceCategory.MovementSensor || x.category == DeviceCategory.SmokeSensor);
  }

  private filterOutputs(devices: Device[]): Device[] {
    return devices.filter(x => x.ioType === IoType.Output || x.ioType === IoType.Both);
  }

  private updateZoneActions(zoneAction: AccessZoneAction) {
    if (this.sourceAction) {
      Object.assign(this.sourceAction, zoneAction);
      this.sourceAction = null;
    }

    this.loadActions();
    this.refreshZoneActions();
  }


  public applyActionFilter(filterValue: string) {
    this.actionsDataSource.filter = filterValue;
  }


  private refreshZoneActions() {
    this.applyActionFilter(this.actionsDataSource.filter);
  }

  private loadActions() {

    this.zoneActionService.getAccessZoneActions(this.accessZone.id, 0, 999).subscribe(results => {
      this.allActions = results.items;
      this.actionsDataSource.data = this.allActions;
      this.accessZone.zoneActions = results.items;
    });
  }

  public cancel() {

    this.alertService.resetStickyMessage();
    this.router.navigate([`admin/accesszones` ]);
  }
}
