import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {AppTranslationService} from "../services/app-translation.service";
import {TranslateParser} from "@ngx-translate/core";

@Injectable()
export class MatPaginatorTranslation extends MatPaginatorIntl {
  private rangeLabelIntl: string;

  rangeLabel = "";

  constructor(private translationService: AppTranslationService, private translateParser: TranslateParser) {
    super();

    this.translationService.languageChanged$.subscribe((_) => {
      this.getTranslations();
    });


    this.getTranslations();
  }

  getTranslations() {
    this.itemsPerPageLabel = this.translationService.getTranslation('shared.ItemsPerPage');
    this.nextPageLabel = this.translationService.getTranslation('shared.NextPage');
    this.previousPageLabel = this.translationService.getTranslation('shared.PreviousPage');
    this.rangeLabel = this.translationService.getTranslation('shared.Range');
  }

  public getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }

    length = Math.max(length, 0);

    const startIndex: number = page * pageSize;
    const endIndex: number = startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.rangeLabel} ${length}`;
  };

}
