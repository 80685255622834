import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { ModuleTypeEndpoint } from './module-type-endpoint.service';
import { ModuleType } from '../../models/moduleType/module-type.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class ModuleTypeService {

  constructor(private router: Router, private http: HttpClient,
    private moduleTypeEndpoint: ModuleTypeEndpoint) {

  }
  
  getModuleType(id?: number) {
    if (id) {
      return this.moduleTypeEndpoint.getModuleTypeEndpoint<ModuleType>(id);
    }

    
  }

  getModuleTypes(page: number = 0, pageSize: number = 9999) {

    return this.moduleTypeEndpoint.getModuleTypesEndpoint<PagedData<ModuleType>>(page, pageSize);
  }
  
  updateModuleType(moduleType: ModuleType) {
    return this.moduleTypeEndpoint.getUpdateModuleTypeEndpoint(moduleType, moduleType.id);
  }
  
  newModuleType(moduleType: ModuleType) {
    return this.moduleTypeEndpoint.getNewModuleTypeEndpoint<ModuleType>(moduleType);
  }
  
  deleteModuleType(id): Observable<ModuleType> {

    return this.moduleTypeEndpoint.getDeleteModuleTypeEndpoint(id);
  }
}
