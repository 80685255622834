import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ValidatorService } from 'angular4-material-table';

@Injectable()
export class ModulePortMapValidatorService implements ValidatorService {
  getRowValidator(): FormGroup {
    return new FormGroup({
      'id': new FormControl(),
      'code': new FormControl(),
      'description': new FormControl(),
      'order': new FormControl(),
      'module': new FormControl(),
      'device': new FormControl(),
      'devicePort': new FormControl(),
      'wire': new FormControl(),
    });
  }
}
