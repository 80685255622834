import { Cabinet } from "../cabinet/cabinet.model";
import { ModuleType } from "../moduleType/module-type.model";

export class Module {

  constructor(id: number = 0, name?: string, description?: string, serialNumber?: string, ipAddress?: string, macAddress?: string, topic?: string, moduleType?: ModuleType, cabinet?: Cabinet, status?: string) {

    this.id = id;
    this.name = name;
    this.description = description;
    this.serialNumber = serialNumber;
    this.ipAddress = ipAddress;
    this.macAddress = macAddress;
    this.cabinet = cabinet;
    this.moduleType = moduleType;
    this.status = status == "" ? "--" : status;
  }

  public id: number;
  public name: string;
  public description: string;
  public serialNumber: string;
  public ipAddress: string;
  public macAddress: string;
  public cabinet: Cabinet;
  public moduleType: ModuleType;
  public status: string;
}
