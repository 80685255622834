import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';
import { ZoneActionType } from '../../models/alarm/zone-action-type.model';



@Injectable()
export class ZoneActionEndpoint extends EndpointFactory {

  private readonly _zoneActionMapUrl: string = "/api/zoneaction";

  get alarmzoneUrl() { return this.configurations.baseUrl + this._zoneActionMapUrl; }


  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getZoneActionEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.alarmzoneUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getZoneActionEndpoint(id));
      }));
  }
  
  getZoneActionsEndpoint<T>(alarmZoneId?: number, alarmProfileId?: number, zoneActionType?: ZoneActionType, page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._zoneActionMapUrl}?alarmZoneId=${alarmZoneId}&alarmProfileId=${alarmProfileId}&actionType=${zoneActionType}&page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getZoneActionsEndpoint(alarmZoneId, alarmProfileId, zoneActionType, page, pageSize));
      }));
  }

  getNewZoneActionEndpoint<T>(zoneActionMapObject: any): Observable<T> {

    return this.http.post<T>(this.alarmzoneUrl, JSON.stringify(zoneActionMapObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewZoneActionEndpoint(zoneActionMapObject));
      }));
  }

  getUpdateZoneActionEndpoint<T>(zoneActionMapObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.alarmzoneUrl}/${id}` : `${this.alarmzoneUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(zoneActionMapObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateZoneActionEndpoint(zoneActionMapObject, id));
      }));
  }

  getDeleteZoneActionEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.alarmzoneUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteZoneActionEndpoint(id));
      }));
  }
}
