import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { Cabinet } from '../../../models/cabinet/cabinet.model';
import { Device } from '../../../models/device/device.model';
import { Module } from '../../../models/module/module.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { AppTranslationService } from "../../../services/app-translation.service";
import { CabinetService } from '../../../services/cabinet/cabinet.service';
import { DevicePortService } from '../../../services/device/device-port.service';
import { DeviceService } from '../../../services/device/device.service';
import { ModulePortMapService } from '../../../services/module/module-port-map.service';
import { ModuleService } from '../../../services/module/module.service';
import { Utilities } from '../../../services/utilities';


@Component({
  selector: "admin-module-list",
  templateUrl: './admin-module-port-map-list.component.html',
  styleUrls: ['./admin-module-port-map-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminModulePortMapListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'description', 'moduleType', 'cabinet', 'actions'];
  dataSource: MatTableDataSource<Module>;
  sourceModule: Module;
  editingModuleName: { name: string };
  loadingIndicator: boolean;


  allDevices: Device[] = [];
  allModules: Module[] = [];
  allCabinets: Cabinet[] = [];

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private moduleService: ModuleService,
    private devicePortService: DevicePortService,
    private deviceService: DeviceService,
    private cabinetService: CabinetService,
    private modulePortMapService: ModulePortMapService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateModules(module: Module) {
    if (this.sourceModule) {
      Object.assign(this.sourceModule, module);
      this.sourceModule = null;
    }
    else {
      this.dataSource.data.unshift(module);
    }

    this.refresh();
  }

  private loadData() {
    let self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    var modulesObs = this.moduleService.getModules();
    var devicesObs = this.deviceService.getDevices();
    var cabinetsObs = this.cabinetService.getCabinets();

    forkJoin([modulesObs, devicesObs, cabinetsObs]).subscribe(results => {
      this.loadingIndicator = false;
      this.alertService.stopLoadingMessage();

      this.allModules = results[0].items;
      this.allDevices = results[1].items;
      this.allCabinets = results[2].items;

      this.dataSource.data = results[0].items;

    },
      error => {
        this.loadingIndicator = false;

        let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
        let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

        this.alertService.showStickyMessage(errorLabel, errorDetail,
          MessageSeverity.error, error);
      }
    );
  }

  public editModule(module?: Module) {
    this.sourceModule = module;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.router.navigate(['/admin/modulemap', module.id]);
  }
}
