import { AfterViewInit, Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { TranslateService } from "@ngx-translate/core";
import { AlarmAccessKey } from '../../../models/alarm/alarm-access-key.model';
import { AlarmAccessKeyService } from '../../../services/alarm/alarm-access-key.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { AppTranslationService } from "../../../services/app-translation.service";
import { Utilities } from '../../../services/utilities';
import { AdminAlarmAccessKeyEditorComponent } from "./admin-alarm-access-key-editor.component";
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from '../../../models/device/device.model';
import { AlarmProfile } from '../../../models/alarm/alarm-profile.model';
import { AccessKey } from '../../../models/accessKey/access-key.model';

@Component({
  selector: "admin-alarm-access-key-list",
  templateUrl: './admin-alarm-access-key-list.component.html',
  styleUrls: ['./admin-alarm-access-key-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminAlarmAccessKeyListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() alarmId: number;
  @Input() items: AlarmAccessKey[];

  displayedColumns = ['key', 'armedProfile', 'disarmedProfile', 'actions'];
  datasource: null;
  dataSource: MatTableDataSource<AlarmAccessKey>;
  allAlarmAccessKeys: AlarmAccessKey[];
  allAccessKeys: AccessKey[];
  allProfiles: AlarmProfile[];
  sourceAlarmAccessKey: AlarmAccessKey;
  editingAlarmAccessKeyName: { name: string };
  loadingIndicator: boolean;
  name: string;
  alarm: Device;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private alarmAccessKeyService: AlarmAccessKeyService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.alarm = this.route.snapshot.data['alarm'];
    if (this.alarm) {
      this.name = this.alarm.name;
    }

    this.allProfiles = this.route.snapshot.data['allProfiles'];
    this.allAccessKeys = this.route.snapshot.data['allAccessKeys'];


    const items = this.route.snapshot.data['alarmAccessKeys'];
    if (items) {
      this.allAlarmAccessKeys = items;
      this.dataSource.data = this.allAlarmAccessKeys;

    }

    const profileItems = this.route.snapshot.data['alarmProfiles'];
    if (profileItems) {
      this.allProfiles = profileItems.items;
    }

    const accessKeyItems = this.route.snapshot.data['allAccessKeys'];
    if (accessKeyItems) {
      this.allAccessKeys = accessKeyItems.items;
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateAlarmAccessKeys(alarmAccessKey: AlarmAccessKey) {
    if (this.sourceAlarmAccessKey) {
      Object.assign(this.sourceAlarmAccessKey, alarmAccessKey);
      this.sourceAlarmAccessKey = null;
    }
    else {
      this.dataSource.data.unshift(alarmAccessKey);
    }

    this.refresh();
  }

  public editAlarmAccessKey(alarmAccessKey?: AlarmAccessKey) {
    this.sourceAlarmAccessKey = alarmAccessKey;

    if (!alarmAccessKey) {
      alarmAccessKey = new AlarmAccessKey();
    }

    let dialogRef = this.dialog.open(AdminAlarmAccessKeyEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { alarmAccessKey: alarmAccessKey, alarm: this.alarm, allProfiles: this.allProfiles, allAccessKeys: this.allAccessKeys }
      });
    dialogRef.afterClosed().subscribe(alarmAccessKey => {
      if (alarmAccessKey) {
        this.updateAlarmAccessKeys(alarmAccessKey);
      }
    });
  }

  public alarmAccessKeyDetails(alarmAccessKey?: AlarmAccessKey) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.router.navigate([`admin/alarm/${alarmAccessKey.alarmId}/alarmaccess-key/${alarmAccessKey.id}`]);
  }

  public cancel() {

    this.alertService.resetStickyMessage();

    this.router.navigate(['/admin/alarms']);
  }

  private confirmDelete(alarmAccessKey: AlarmAccessKey) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteErrorDetail, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.alarmAccessKeyService.deleteAlarmAccessKey(this.alarm.id, alarmAccessKey.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== alarmAccessKey)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteQuestionLabel,
                MessageSeverity.error, error);
            });
      });
  }
}
