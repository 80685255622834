import { Component, Input, ViewChild, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User } from '../../../models/user.model';
import {AppTranslationService} from "../../../services/app-translation.service";
import {AccessZone} from "../../../models/accessZone/access-zone.model";
import {AccessZoneService} from "../../../services/accessZone/access-zone.service";
import {PickerIconImage} from "../../../models/picker-icon-image.model";

@Component({
  selector: 'admin-access-zone-editor',
  templateUrl: './admin-access-zone-editor.component.html',
  styleUrls: ['./admin-access-zone-editor.component.scss']
})
export class AdminAccessZoneEditorComponent {
  @ViewChild('form', { static: true })
  private form: NgForm;

  public isNewAccessZone = false;
  private onAccessZoneSaved = new Subject<AccessZone>();
  private isCodeMasked = true;

  private accessZone: AccessZone = new AccessZone();

  allUsers: User[];
  allKeyTypes: string[];

  accessZoneForm: FormGroup;
  accessZoneSaved$ = this.onAccessZoneSaved.asObservable();

  public allIconImages: PickerIconImage[] = [
    new PickerIconImage(
      "balcony",
      "./assets/icons/balcony.svg",
      ["balcony"]
    ),
    new PickerIconImage(
      "bathtub",
      "./assets/icons/bathtub.svg",
      ["bathtub"]
    ),
    new PickerIconImage(
      "bed01",
      "./assets/icons/bed01.svg",
      ["bed"]
    ),
    new PickerIconImage(
      "bed02",
      "./assets/icons/bed02.svg",
      ["bed"]
    ),
    new PickerIconImage(
      "bedroom",
      "./assets/icons/bedroom.svg",
      ["bedroom"]
    ),
    new PickerIconImage(
      "construction",
      "./assets/icons/construction01.svg",
      ["construction"]
    ),

    new PickerIconImage(
      "couch_light",
      "./assets/icons/couch_light.svg",
      ["couch_light"]
    ),
    new PickerIconImage(
      "couch01",
      "./assets/icons/couch01.svg",
      ["couch01"]
    ),
    new PickerIconImage(
      "couch02",
      "./assets/icons/couch02.svg",
      ["couch02"]
    ),
    new PickerIconImage(
      "fork_knife",
      "./assets/icons/fork_knife.svg",
      ["fork_knife"]
    ),
    new PickerIconImage(
      "fridge",
      "./assets/icons/fridge.svg",
      ["fridge"]
    ),

    new PickerIconImage(
      "gate01",
      "./assets/icons/gate01.svg",
      ["gate01"]
    ),
    new PickerIconImage(
      "gate02",
      "./assets/icons/gate02.svg",
      ["gate02"]
    ),
    new PickerIconImage(
      "home-app",
      "./assets/icons/home-app.svg",
      ["home-app"]
    ),
    new PickerIconImage(
      "icon_01",
      "./assets/icons/icon_01.svg",
      ["icon_01"]
    ),
    new PickerIconImage(
      "kitchen",
      "./assets/icons/kitchen.svg",
      ["kitchen"]
    ),
    new PickerIconImage(
      "kitchen_hood",
      "./assets/icons/kitchen_hood.svg",
      ["kitchen_hood"]
    ),
    new PickerIconImage(
      "lamp",
      "./assets/icons/lamp.svg",
      ["lamp"]
    ), new PickerIconImage(
      "living_room",
      "./assets/icons/living_room.svg",
      ["living_room"]
    ),
    new PickerIconImage(
      "modern_house",
      "./assets/icons/modern_house.svg",
      ["modern_house"]
    ),
    new PickerIconImage(
      "office",
      "./assets/icons/office.svg",
      ["office"]
    ),
    new PickerIconImage(
      "wash_clothes",
      "./assets/icons/wash_clothes.svg",
      ["wash_clothes"]
    ),
    new PickerIconImage(
      "washing_machine",
      "./assets/icons/washing_machine.svg",
      ["washing_machine"]
    ),
  ];

  get name() {
    return this.accessZoneForm.get('name');
  }

  get description() {
    return this.accessZoneForm.get('description');
  }

  get iconPath() {
    return this.accessZoneForm.get('iconPath');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminAccessZoneEditorComponent>,
    private alertService: AlertService,
    private accessZoneService: AccessZoneService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { accessZone: AccessZone, allUsers: User[], allKeyTypes: string[] },
  )
  {
    this.accessZone = data.accessZone;
    this.allUsers = data.allUsers;
    this.allKeyTypes = data.allKeyTypes;
    this.buildForm();
    this.resetForm();


    if (this.accessZone.id === 0) {
      this.isNewAccessZone = true
    }
  }

  ngOnChanges() {
    this.resetForm();
  }

  public save() {
     
    if (!this.accessZoneForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.SavingChanges`));

    const editedAccessZone = this.getEditedAccessZone();

    if (this.accessZone.id == 0) {
      this.isNewAccessZone = true;
      this.accessZoneService.newAccessZone(editedAccessZone).subscribe(
        accessZone => this.saveSuccessHelper(editedAccessZone),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewAccessZone = false;
      this.accessZoneService.updateAccessZone(editedAccessZone).subscribe(
        response => this.saveSuccessHelper(editedAccessZone),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedAccessZone(): AccessZone {
    const formModel = this.accessZoneForm.value;

    return {
      id: this.accessZone.id,
      name: formModel.name,
      description: formModel.description,
      iconPath: formModel.iconPath,
      zoneActions: this.accessZone.zoneActions,
      authorizedUsers: this.accessZone.authorizedUsers
    };
  }

  private saveSuccessHelper(accessZone?: AccessZone) {
    this.alertService.stopLoadingMessage();

    let created = this.translationService.getTranslation(`shared.DataCreated`);
    let updated = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewAccessZone) {
      this.alertService.showMessage(successLabel, created, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updated, MessageSeverity.success);
    }

    this.onAccessZoneSaved.next(accessZone);

    this.dialogRef.close(accessZone);
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  public selectIconImage(icon: PickerIconImage) {
    this.accessZone.iconPath= icon.path;
    this.accessZoneForm.controls['iconPath'].setValue(icon.path);
  }

  private buildForm() {
    this.accessZoneForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
      iconPath: '',
    });
  }

  private resetForm(replace = false) {
    this.accessZoneForm.reset({
      name: this.accessZone.name || '',
      description: this.accessZone.description || '',
      iconPath: this.accessZone.iconPath || ''
    });
  }
}
