import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { FirmwareVersionEndpoint } from './firmwareVersion-endpoint.service';
import { FirmwareVersion } from '../../models/firmwareVersion/firmware-version.model';

@Injectable()
export class FirmwareVersionService {

  constructor(private router: Router, private http: HttpClient,
    private firmwareVersionEndpoint: FirmwareVersionEndpoint) {

  }
  
  getFirmwareVersions() {
    return this.firmwareVersionEndpoint.getFirmwareVersionsEndpoint<FirmwareVersion[]>();
  }

  updateFirmware(ids: number[]) {
    return this.firmwareVersionEndpoint.getUpdateFirmwareVersionsEndpoint<FirmwareVersion[]>(ids);
  } 
}
