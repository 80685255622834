import { AfterViewInit, Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { TranslateService } from "@ngx-translate/core";
import { AlarmZone } from '../../../models/alarm/alarm-zone.model';
import { AlarmZoneService } from '../../../services/alarm/alarm-zone.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { AppTranslationService } from "../../../services/app-translation.service";
import { Utilities } from '../../../services/utilities';
import { AdminAlarmZoneEditorComponent } from "./admin-alarm-zone-editor.component";
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from '../../../models/device/device.model';

@Component({
  selector: "admin-alarm-zones-list",
  templateUrl: './admin-alarm-zone-list.component.html',
  styleUrls: ['./admin-alarm-zone-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminAlarmZoneListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input() alarmId: number;
  @Input() items: AlarmZone[];

  displayedColumns = ['name', 'description', 'actions'];
  datasource: null;
  dataSource: MatTableDataSource<AlarmZone>;
  allAlarmZones: AlarmZone[];
  sourceAlarmZone: AlarmZone;
  editingAlarmZoneName: { name: string };
  loadingIndicator: boolean;
  name: string;
  alarm: Device;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private alarmZoneService: AlarmZoneService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.alarm = this.route.snapshot.data['alarm'];
    this.name = this.alarm.name;

    let pagedData = this.route.snapshot.data['alarmZones'];
    this.allAlarmZones = pagedData.items;
    this.dataSource.data = this.allAlarmZones;

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateAlarmZones(alarmZone: AlarmZone) {
    if (this.sourceAlarmZone) {
      Object.assign(this.sourceAlarmZone, alarmZone);
      this.sourceAlarmZone = null;
    }
    else {
      this.dataSource.data.unshift(alarmZone);
    }

    this.refresh();
  }

  public editAlarmZone(alarmZone?: AlarmZone) {
    this.sourceAlarmZone = alarmZone;

    if (!alarmZone) {
      alarmZone = new AlarmZone();
    }

    let dialogRef = this.dialog.open(AdminAlarmZoneEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { alarmZone: alarmZone, alarm: this.alarm }
      });
    dialogRef.afterClosed().subscribe(alarmZone => {
      if (alarmZone) {
        this.updateAlarmZones(alarmZone);
      }
    });
  }

  public alarmZoneDetails(alarmZone?: AlarmZone) {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.router.navigate(['/admin/alarmZone', alarmZone.id]);
  }

  public cancel() {

    this.alertService.resetStickyMessage();

    this.router.navigate(['/admin/alarms']);
  }

  private confirmDelete(alarmZone: AlarmZone) {

    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.alarmZoneService.deleteAlarmZone(alarmZone.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== alarmZone)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
              let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }
}
