import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { DevicePortEndpoint } from './device-port-endpoint.service';
import { DevicePort } from '../../models/device/device-port.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class DevicePortService {

  constructor(private router: Router, private http: HttpClient,
    private devicePortEndpoint: DevicePortEndpoint) {

  }
  
  getDevicePort(deviceId?: number, id?: number) {
    if (id) {
      return this.devicePortEndpoint.getDevicePortEndpoint<DevicePort>(deviceId, id);
    }
  }

  getDevicePorts(deviceId?: number, page: number = 0, pageSize: number = 100) {

    return this.devicePortEndpoint.getDevicePortsEndpoint<PagedData<DevicePort>>(deviceId, page, pageSize);
  }
}
