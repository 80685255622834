import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Subject} from 'rxjs';
import {DeviceCategory} from '../../../models/device/device-category.model';
import {DeviceTypeState} from '../../../models/device/device-type-state.model';
import {DeviceType} from '../../../models/device/device-type.model';
import {Device} from '../../../models/device/device.model';
import {Room} from '../../../models/room/room.model';
import {Module} from '../../../models/module/module.model';
import {AlertService, MessageSeverity} from '../../../services/alert.service';
import {DeviceService} from "../../../services/device/device.service";
import {FunctionValidatorService} from '../../../services/device/function-validator.service';
import {ParameterValidatorService} from '../../../services/device/parameter-validator.service';
import {DeviceConnectionType} from 'src/app/models/device/device-connection-type.model';
import {DeviceFunction} from '../../../models/device/device-function.model';
import {AppTranslationService} from "../../../services/app-translation.service";
import {Announcement} from "../../../models/announcement/announcement.model";
import {HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel} from "@microsoft/signalr";
import {AuthService} from "../../../services/auth.service";
import {AnnouncementService} from "../../../services/announcement/announcement.service";
import {AnnouncementType} from "../../../models/announcement/announcement-type.model";
import { IoType } from '../../../models/io-type.model';
import { KeyValue } from '@angular/common';
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'admin-device-editor',
  templateUrl: './admin-device-editor.component.html',
  styleUrls: ['./admin-device-editor.component.scss']
})
export class AdminDeviceEditorComponent implements OnInit {
  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewDevice = false;
  private isSaving = false;
  private onDeviceSaved = new Subject<Device>();

  public device: Device = new Device();
  public allDeviceTypes: DeviceType[];
  public filteredDeviceTypes: DeviceType[];
  public filteredFunctions: DeviceFunction[];
  public allRooms: Room[];
  public allModules: Module[];
  public allCategories: string[];
  public translatedCategories: KeyValue<string, string>[] = [];
  public allConnectionTypes: string[];

  public allAnnouncements: Announcement[] = [];
  public searchingAnnouncements: boolean;
  public showNoSearchResults: boolean = false;
  public isDefaultFunctionVisible = true;

  iconKeypad = require("../../../../assets/icons/remote.svg");

  private announcementsHubConnection: HubConnection;

  deviceForm: FormGroup;
  deviceSaved$ = this.onDeviceSaved.asObservable();

  get name() {
    return this.deviceForm.get('name');
  }

  get description() {
    return this.deviceForm.get('description');
  }

  get ref() {
    return this.deviceForm.get('ref');
  }

  get address() {
    return this.deviceForm.get('address');
  }

  get room() {
    return this.deviceForm.get('room');
  }

  get type() {
    return this.deviceForm.get('type');
  }

  get defaultFunction() {
    return this.deviceForm.get('defaultFunction');
  }

  get category() {
    return this.deviceForm.get('category');
  }

  get module() {
    return this.deviceForm.get('module');
  }

  get hide() {
    return this.deviceForm.get('hide');
  }

  constructor(
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<AdminDeviceEditorComponent>,
    private alertService: AlertService,
    private deviceService: DeviceService,
    private formBuilder: FormBuilder,
    private functionsValidator: FunctionValidatorService,
    private parameterValidator: ParameterValidatorService,
    private translationService: AppTranslationService,
    private authService: AuthService,
    private announcementService: AnnouncementService,
    @Inject(MAT_DIALOG_DATA) public data: { device: Device, deviceTypes: DeviceType[], rooms: Room[], modules: Module[], categories: string[], connectionTypes: string[] },
  ) {
    this.device = data.device;
    this.allDeviceTypes = data.deviceTypes;
    this.allCategories = data.categories;
    this.allConnectionTypes = data.connectionTypes;
    this.allRooms = data.rooms;
    this.allModules = data.modules;

    if (this.device.id == 0) {
      this.filteredDeviceTypes = [];
    }
    else
    {
      this.filteredDeviceTypes = data.deviceTypes;
    }


    this.translateCategories();

    if (this.device.type) {
      this.filterDeviceFuntions(this.device.type.id);
    }


    this.buildForm();
    this.resetForm();

    let builder = new HubConnectionBuilder();

    this.announcementsHubConnection = builder
      .withUrl(location.origin + '/hubs/announcement',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .withAutomaticReconnect()
      .configureLogging(LogLevel.None)

      .build();

    this.announcementsHubConnection.on("announcement/device", (message) => {
      this.updateAnnouncements(message);
    });

    try {
      this.announcementsHubConnection.start().catch(function (err) {
      });
    } catch (ex) {
      console.log("Erro");
    }


  }

  ngOnInit(): void {

    this.enableSaveButton();

    this.authService.accessToken;

    this.connect();
  }

  private connect() {
    let self = this;

    if (this.announcementsHubConnection.state === HubConnectionState.Disconnected) {
      try {
        this.announcementsHubConnection.start().catch(function (err) {
          console.log("Inner Error")
        });
      } catch (err) {
        console.log("Erro2");
      }


    }
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  ngOnChanges() {
    this.resetForm();
  }

  public save() {
     
    if (!this.deviceForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    if (this.isSaving) {
      return;
    }

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    const editedDevice = this.getEditedDevice();

    if (this.device.id == 0) {
      this.isNewDevice = true;
      this.deviceService.newDevice(editedDevice).subscribe(
        device => this.saveSuccessHelper(device),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewDevice = false;

      editedDevice.category = this.device.category;
      editedDevice.connectionType = this.device.connectionType;

      this.deviceService.updateDevice(editedDevice).subscribe(
        device => this.saveSuccessHelper(device),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedDevice(): Device {
    const formModel = this.deviceForm.value;

    var category: string = formModel.category;
    var connectionType: string = formModel.connectionType;

    return {
      id: this.device.id,
      name: formModel.name,
      description: formModel.description,
      ref: formModel.ref,
      address: formModel.address,
      category: DeviceCategory[category],
      connectionType: DeviceConnectionType[connectionType],
      state: DeviceTypeState.NONE,
      type: this.allDeviceTypes.find(x => x.id == formModel.type),
      room: this.allRooms.find(x => x.id == formModel.room),
      roomId: formModel.room,
      moduleId: formModel.moduleId,
      ioType: this.device.ioType,
      devicePorts: this.device.devicePorts,
      functions: [],
      parameters: [],
      possibleStates: [],
      values: [],
      stateValue: '',
      showOnDash: formModel.showOnDash,
      hide: formModel.hide,
      defaultFunctionTypeId: formModel.defaultFunction ? formModel.defaultFunction : 0
    };
  }

  private saveSuccessHelper(device?: Device) {

    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewDevice) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onDeviceSaved.next(device);

    this.dialogRef.close(device);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {
    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    this.enableSaveButton();
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.deviceForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
      ref: '',
      address: '',
      type: [{ value: '', disabled: this.device.id != 0 }, Validators.required],
      category: [{ value: '', disabled : this.device.id != 0}, Validators.required],
      room: ['', Validators.required],
      moduleId: 0,
      showOnDash: false,
      hide: false,
      defaultFunction: 0,
    });
  }

  private resetForm(replace = false) {
    this.enableSaveButton();

    this.deviceForm.reset({
      name: this.device.name || '',
      description: this.device.description || '',
      ref: this.device.ref || '',
      address: this.device.address || '',
      type: this.device.type && this.device.type.id ? this.device.type.id : '',
      category: DeviceCategory[this.device.category] || '',
      room: this.device.room && this.device.room.id ? this.device.room.id : '',
      moduleId: this.device.moduleId ? this.device.moduleId : 0,
      showOnDash: this.device.showOnDash || false,
      hide: this.device.hide || false,
      defaultFunction: this.device.defaultFunctionTypeId
    });
  }

  public filterDeviceTypesByCategory(event) {
    var eventVal: string = event.value;
    var category: DeviceCategory = (<any>DeviceCategory)[eventVal];
    this.filteredDeviceTypes = this.allDeviceTypes.filter(x => x.category == category);

    if (this.filteredDeviceTypes.length == 1) {

      this.type.setValue(this.filteredDeviceTypes[0].id);
      this.hide.setValue(this.filteredDeviceTypes[0].ioType != IoType.Input);
      this.filterDeviceFuntions(this.filteredDeviceTypes[0].id);
      this.cdr.detectChanges();
    }
  }

  public filterDeviceFuntions(deviceTypeId) {

    this.filteredFunctions = [];

    if (deviceTypeId && deviceTypeId !== 0) {
      const deviceType = this.allDeviceTypes.find(x => x.id === deviceTypeId);
      if (deviceType) {
        this.filteredFunctions = deviceType.functions;
      }

      if (deviceType.ioType != IoType.Input) {
        this.isDefaultFunctionVisible = true;
      } else {
        this.isDefaultFunctionVisible = false;
      }
    }
  }
  private updateAnnouncements(message: any) {
    if (message && this.searchingAnnouncements) {
      this.allAnnouncements = [];

      for (const entry of message) {
        const device = this.allDeviceTypes.find(x => x.code === entry.type);
        if (device) {
          entry.name = device.name;
          this.allAnnouncements.push(entry);
        }
      }

      if (this.allAnnouncements.length == 0) {
        this.showNoSearchResults = true;
      }
    }
  }
  public getAnnouncements() {
    let self = this;

    this.searchingAnnouncements = true;
    this.showNoSearchResults = false;

    setTimeout(() => {
      self.searchingAnnouncements = false;
    }, 10000);

    this.announcementService.getAnnouncements(AnnouncementType.Device)
      .subscribe(results => {

          if (results) {
            this.allAnnouncements = [];

            for (const entry of results) {
              const devType = this.allDeviceTypes.find(x => x.code === entry.type);
              if (devType) {
                entry.name = this.translationService.getTranslation('lookups.DeviceTypes.' + devType.name);
                this.allAnnouncements.push(entry);
              }
            }
          }

          if (this.allAnnouncements.length == 0) {
            this.showNoSearchResults = true;
          }
        },
        error => {
        });
  }

  selectAnnouncement(mac: string, type: string) {
    const devType = this.allDeviceTypes.find(x => x.code === type);
    if (devType) {
      this.type.setValue(devType.id);
      this.filterDeviceFuntions(devType.id);

      this.name.setValue(devType.name + "-" + mac);
      this.description.setValue(devType.name + "-" + mac);
      this.category.setValue(DeviceCategory[devType.category]);
      this.address.setValue(mac);

      this.filteredDeviceTypes = this.allDeviceTypes.filter(x => x.category == devType.category);
    }

    this.address.setValue(mac);
  }

  public getIcon(): string {
    //TODO: By type
    return this.iconKeypad;
  }

  public onDeviceTypeChange(event) {
    const type = this.allDeviceTypes.find(x => x.id == event.value);

    if (type != undefined) {
      this.filterDeviceFuntions(type.id);

      if (type.ioType != IoType.Input) {
        this.isDefaultFunctionVisible = true;
        this.hide.setValue(false);
        this.cdr.detectChanges();
      } else {
        this.isDefaultFunctionVisible = false;
        this.hide.setValue(true);
        this.cdr.detectChanges();
      }
    }
  }

  private translateCategories() {

    let self = this;

    if (this.allCategories) {

      this.allCategories.forEach(function (item) {

        if ((<any>DeviceCategory)[item] != DeviceCategory.None) {
          var translation = self.translationService.getTranslation('lookups.deviceCategory.' + item);
          self.translatedCategories.push({ key: item, value: translation });
        }
      });

      var sorted = self.translatedCategories.sort((a, b) => {

        var isAsc = true;

        return (a.value < b.value ? -1 : 1) * (isAsc ? 1 : -1);

      });

      self.translatedCategories = sorted;

    }

  }
}
