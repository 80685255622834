import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlarmZone } from '../../models/alarm/alarm-zone.model';
import { PagedData } from '../../models/PagedData';
import { AlarmZoneService } from '../alarm/alarm-zone.service';

@Injectable()
export class AlarmZoneResolve implements Resolve<Observable<AlarmZone>> {
  constructor(private alarmZoneService: AlarmZoneService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<AlarmZone> | Promise<AlarmZone> | any {
    let id = +route.params['id'];
    return this.alarmZoneService.getAlarmZone(id).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
