import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TableDataSource } from 'angular4-material-table';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FunctionValidatorService } from '../../../services/device/function-validator.service';
import { ParameterValidatorService } from '../../../services/device/parameter-validator.service';
import {AppTranslationService} from "../../../services/app-translation.service";
import {AccessZoneAction} from "../../../models/accessZone/access-zone-action.model";
import {AccessZoneActionParameter} from "../../../models/accessZone/access-zone-action-parameter.model";
import {AccessZoneActionService} from "../../../services/accessZone/access-action.service";



@Component({
  selector: 'admin-access-zone-action-settings',
  templateUrl: './admin-access-zone-action-settings.component.html',
  styleUrls: ['./admin-access-zone-action-settings.component.scss']
})
export class   AdminAccessZoneActionSettingsEditorComponent implements OnInit {

  private isSaving = false;

  public accessZoneAction: AccessZoneAction = new AccessZoneAction();

  public allParameters: AccessZoneActionParameter[];
  public parametersListChange = new EventEmitter<AccessZoneActionParameter[]>();
  parametersDisplayedColumns = ['code', 'description', 'value', 'actionsColumn'];
  parametersDataSource: TableDataSource<AccessZoneActionParameter>;

  constructor(
    public dialogRef: MatDialogRef<AdminAccessZoneActionSettingsEditorComponent>,
    private alertService: AlertService,
    private accessZoneActionService: AccessZoneActionService,
    private functionsValidator: FunctionValidatorService,
    private parameterValidator: ParameterValidatorService,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { zoneAction: AccessZoneAction},
  ) {
    this.accessZoneAction = data.zoneAction;

    this.allParameters = this.accessZoneAction.parameters;
    this.parametersDataSource = new TableDataSource<AccessZoneActionParameter>(this.allParameters, AccessZoneActionParameter, this.parameterValidator);
    this.parametersDataSource.datasourceSubject.subscribe(parameters => {

      this.allParameters = parameters;
      this.parametersListChange.emit(parameters)
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    this.accessZoneAction.parameters = this.allParameters;

    this.accessZoneActionService.updateAccessZoneAction(this.accessZoneAction.accessZoneId, this.accessZoneAction).subscribe(
      response => this.saveSuccessHelper(this.accessZoneAction),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(accessZoneAction?: AccessZoneAction) {
    this.alertService.stopLoadingMessage();

    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);

    this.dialogRef.close(accessZoneAction);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }
}
