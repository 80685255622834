import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {DeviceGroup} from "../../models/deviceGroup/device-group.model";
import {DeviceGroupService} from "./device-group.service";

@Injectable()
export class DeviceGroupResolve implements Resolve<Observable<DeviceGroup>> {
  constructor(private deviceService: DeviceGroupService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<DeviceGroup> | Promise<DeviceGroup> | any  {
    let id = +route.params['id'];
    return this.deviceService.getDeviceGroup(id).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
