import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Device } from '../../models/device/device.model';
import { AlarmService } from './alarm.service';
import { Alarm } from '../../models/alarm/alarm.model';

@Injectable()
export class AlarmResolve implements Resolve<Observable<Alarm>> {
  constructor(private alarmService: AlarmService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Alarm> | Promise<Alarm> | any  {
    let id = +route.params['id'];
    return this.alarmService.getAlarm(id).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
