export class PickerIconImage {

  constructor(name?: string, path?: string, keywords?: string[] ) {

    this.name = name;
    this.path = path;
    this.keywords = keywords;
  }


  public name: string;
  public path: string;
  public keywords: string[];
}
