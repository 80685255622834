import { Component, Input, ViewChild, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { RoomService } from "../../../services/room/room.service";
import { Room } from '../../../models/room/room.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Floor } from '../../../models/floor/floor.model';
import { PickerIcon } from 'src/app/models/picker-icon.model';
import { PickerIconImage } from '../../../models/picker-icon-image.model';
import {AppTranslationService} from "../../../services/app-translation.service";

@Component({
  selector: 'admin-room-editor',
  templateUrl: './admin-room-editor.component.html',
  styleUrls: ['./admin-room-editor.component.scss']
})
export class AdminRoomEditorComponent {
  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewRoom = false;
  private isSaving: boolean;
  private onRoomSaved = new Subject<Room>();

  private room: Room = new Room();
  public allFloors: Floor[];

  public allIconImages: PickerIconImage[] = [
    new PickerIconImage(
      "balcony",
      "./assets/icons/balcony.svg",
      ["balcony"]
    ),
    new PickerIconImage(
      "bathtub",
      "./assets/icons/bathtub.svg",
      ["bathtub"]
    ),
    new PickerIconImage(
      "bed01",
      "./assets/icons/bed01.svg",
      ["bed"]
    ),
    new PickerIconImage(
      "bed02",
      "./assets/icons/bed02.svg",
      ["bed"]
    ),
    new PickerIconImage(
      "bedroom",
      "./assets/icons/bedroom.svg",
      ["bedroom"]
    ),
    new PickerIconImage(
      "construction",
      "./assets/icons/construction01.svg",
      ["construction"]
    ),

    new PickerIconImage(
      "couch_light",
      "./assets/icons/couch_light.svg",
      ["couch_light"]
    ),
    new PickerIconImage(
      "couch01",
      "./assets/icons/couch01.svg",
      ["couch01"]
    ),
    new PickerIconImage(
      "couch02",
      "./assets/icons/couch02.svg",
      ["couch02"]
    ),
    new PickerIconImage(
      "fork_knife",
      "./assets/icons/fork_knife.svg",
      ["fork_knife"]
    ),
    new PickerIconImage(
      "fridge",
      "./assets/icons/fridge.svg",
      ["fridge"]
    ),

    new PickerIconImage(
      "gate01",
      "./assets/icons/gate01.svg",
      ["gate01"]
    ),
    new PickerIconImage(
      "gate02",
      "./assets/icons/gate02.svg",
      ["gate02"]
    ),
    new PickerIconImage(
      "home-app",
      "./assets/icons/home-app.svg",
      ["home-app"]
    ),
    new PickerIconImage(
      "icon_01",
      "./assets/icons/icon_01.svg",
      ["icon_01"]
    ),
    new PickerIconImage(
      "kitchen",
      "./assets/icons/kitchen.svg",
      ["kitchen"]
    ),
    new PickerIconImage(
      "kitchen_hood",
      "./assets/icons/kitchen_hood.svg",
      ["kitchen_hood"]
    ),
    new PickerIconImage(
      "lamp",
      "./assets/icons/lamp.svg",
      ["lamp"]
    ), new PickerIconImage(
      "living_room",
      "./assets/icons/living_room.svg",
      ["living_room"]
    ),
    new PickerIconImage(
      "modern_house",
      "./assets/icons/modern_house.svg",
      ["modern_house"]
    ),
    new PickerIconImage(
      "office",
      "./assets/icons/office.svg",
      ["office"]
    ),
    new PickerIconImage(
      "wash_clothes",
      "./assets/icons/wash_clothes.svg",
      ["wash_clothes"]
    ),
    new PickerIconImage(
      "washing_machine",
      "./assets/icons/washing_machine.svg",
      ["washing_machine"]
    ),
  ];



  roomForm: FormGroup;
  roomSaved$ = this.onRoomSaved.asObservable();

  get name() {
    return this.roomForm.get('name');
  }

  get description() {
    return this.roomForm.get('description');
  }

  get iconImage() {
    return this.roomForm.get('iconImage');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminRoomEditorComponent>,
    private alertService: AlertService,
    private roomService: RoomService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { room: Room, floors: Floor[] },
  ) {
    this.room = data.room;
    this.allFloors = data.floors;
    this.buildForm();
    this.resetForm();
  }

  ngOnChanges() {
    this.resetForm();
  }

private disableSaveButton() {
    this.isSaving = true;screenLeft
}

private enableSaveButton() {
    this.isSaving = false;
}

  public save() {
    if (this.isSaving) {
      return;
    }

    this.disableSaveButton();

    if (!this.roomForm.valid) {
      this.alertService.showValidationError();
      this.enableSaveButton();
      return;
    }

    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    const editedRoom = this.getEditedRoom();

    if (this.room.id == 0) {
      this.isNewRoom = true;
      this.roomService.newRoom(editedRoom).subscribe(
        room => this.saveSuccessHelper(editedRoom),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewRoom = false;
      this.roomService.updateRoom(editedRoom).subscribe(
        response => this.saveSuccessHelper(editedRoom),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedRoom(): Room {
    const formModel = this.roomForm.value;

    return {
      id: this.room.id,
      name: formModel.name,
      description: formModel.description,
      tabOrder: formModel.tabOrder,
      showOnDash: formModel.showOnDash,
      iconImage: formModel.iconImage != './assets/icons/Asset05.svg' ? formModel.iconImage : '' ,
      floor: this.allFloors.find(x => x.id == formModel.floor),
      actions: []
    };
  }

  private saveSuccessHelper(room?: Room) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewRoom) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onRoomSaved.next(room);

    this.dialogRef.close(room);

    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {
    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    this.enableSaveButton();
  }

  public cancel() {
    this.resetForm();

    this.enableSaveButton();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  public selectIconImage(icon: PickerIconImage) {
    this.room.iconImage = icon.path;
    this.roomForm.controls['iconImage'].setValue(icon.path);
  }

  private buildForm() {
    this.roomForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
      description: ['', Validators.maxLength(60)],
      tabOrder: [0, Validators.required],
      icon: '',
      iconImage: './assets/icons/Asset05.svg',
      showOnDash: false,
      floor: ['', Validators.required],
    });
  }

  private resetForm(replace = false) {
    this.enableSaveButton();

    this.roomForm.reset({
      name: this.room.name || '',
      description: this.room.description || '',
      tabOrder: this.room.tabOrder || 0,
      iconImage: this.room.iconImage || './assets/icons/Asset05.svg',
      showOnDash: this.room.showOnDash || false,
      floor: this.room.floor && this.room.floor.id ? this.room.floor.id : '',
    });
  }
}
