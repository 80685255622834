export enum DeviceTypeState
{
  NONE = 0,
  OFF = 1,
  ON = 2,
  CLOSED = 3,
  OPENED = 4,
  CLOSING = 5,
  OPENING = 6,
  CONNECTED = 7,
  DISCONNECTED = 8,
  READING = 9,
  SYNCING = 10,
  ARMED = 11,
  DISARMED = 12,
  STOPPED = 13
}
