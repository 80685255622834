import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Field, FieldMap, Rule } from "angular2-query-builder";
import { fadeInOut } from "../../../../services/animations";
import { AppTranslationService } from "../../../../services/app-translation.service";

@Component({
    selector: "condition-zone-input",
    templateUrl: './condition-zone-input.component.html',
    styleUrls: ['./condition-zone-input.component.scss'],
  animations: [fadeInOut],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConditionZoneInputComponent),
      multi: true
    }
  ]
})
export class ConditionZoneInputComponent implements OnInit, ControlValueAccessor, OnChanges  {

  @Input() model: any;
  @Input() field: any;
  @Input() fields: any;
  @Input() allZones: any;

  public zone: any;
  public secondZone: any;
  public options: any[] = [];

  public dayLabel: string;
  public hourLabel: string;


    constructor(
        private translationService: AppTranslationService,
    ) {
      this.dayLabel = this.translationService.getTranslation('shared.Day');
      this.hourLabel = this.translationService.getTranslation('shared.Hour');
  }

  ngOnInit(): void {
    if (this.model !== undefined) {
      this.zoneOption = this.model.zoneOption;
      this.secondZoneId = this.model.secondZoneId;
      this.secondZoneOption = this.model.secondZoneOption;
      this.options = this.field.options;
      if (this.model.secondZoneId !== undefined) {
        let id = 'energyzone_' + this.model.secondZoneId;
        this.secondZone = this.fields[id];
        if (this.secondZone != undefined) {
          this.options = this.secondZone.options;
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  onChange: any = () => {
  }
  onTouch: any = () => { }

  get secondZoneOption(): any {
    if (this.model !== undefined) {
      return this.model.secondZoneOption;
    }

    return '';
  }

  set secondZoneOption(val: any) {
    if (val !== undefined && this.model !== undefined && this.model.secondZoneOptionId !== val) {
      this.model.secondZoneOption = val;
      this.onChange(this.model);
      this.onTouch(this.model);
    }
  }

  // get secondZoneTime(): any {
  //   if (this.model !== undefined) {
  //     return this.model.secondZoneTime;
  //   }
  //
  //   return '';
  // }
  //
  // set secondZoneTime(val: any) {
  //   if (val !== undefined && this.model !== undefined && this.model.secondZoneTime !== val) {
  //     this.model.secondZoneTime = val;
  //     this.onChange(this.model);
  //     this.onTouch(this.model);
  //   }
  // }
  //
  // get zoneTime(): any {
  //   if (this.model !== undefined) {
  //     return this.model.zoneTime;
  //   }
  //
  //   return '';
  // }
  //
  // set zoneTime(val: any) {
  //   if (val !== undefined && this.model !== undefined && this.model.zoneTime !== val) {
  //     this.model.zoneTime = val;
  //     this.onChange(this.model);
  //     this.onTouch(this.model);
  //   }
  // }

  get zoneOption(): any {
    if (this.model !== undefined) {
      return this.model.zoneOption;
    }

    return '';
  }

  set zoneOption(val: any) {
    if (val !== undefined && this.model !== undefined && this.model.zoneOptionId !== val) {
      this.model.zoneOption = val;
      this.onChange(this.model);
      this.onTouch(this.model);
    }
  }

  get secondZoneId(): any {
    if (this.model !== undefined) {
      return this.model.secondZoneId;
    }

    return '';
  }

  set secondZoneId(val: any) {
    if (val !== undefined && this.model !== undefined && this.model.secondZoneId !== val) {
      this.model.secondZoneId = val;

      this.secondZone = this.fields['energyzone_' + this.model.secondZoneId];
      this.options = this.secondZone.options;

      this.onChange(this.model);
      this.onTouch(this.model);
    }
  }

  writeValue(value: any) {
   // this.value = value
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn
  }
}
