import { ParameterValueType } from "./parameter-value-type.model";

export class DeviceParameter {
  
  constructor(id: number = 0, code?: string, description?: string, value?: string, valueType?: ParameterValueType) {

    this.id = id;
    this.name = name;
    this.description = description;
    this.value = value;
    this.valueType = valueType;
  }
  
  public id: number;
  public name: string;
  public description: string;
  public value: string;
  public valueType: ParameterValueType;
}
