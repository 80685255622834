import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AnnouncementEndpoint } from './announcement-endpoint.service';
import { Announcement } from '../../models/announcement/announcement.model';
import {AnnouncementType} from "../../models/announcement/announcement-type.model";


@Injectable()
export class AnnouncementService {

  constructor(private router: Router, private http: HttpClient,
    private announcementEndpoint: AnnouncementEndpoint) {

  }

  getAnnouncements(type: AnnouncementType) {

    return this.announcementEndpoint.getAnnouncementsEndpoint<Announcement[]>(type);
  }

}
