import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';
import {SystemConfig} from "../../models/system-config.model";


@Injectable()
export class MasterDeviceEndpoint extends EndpointFactory {

  private readonly _masterDeviceUrl: string = "/api/mastersettings";

  get masterDeviceUrl() { return this.configurations.baseUrl + this._masterDeviceUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  getMasterDeviceEndpoint<T>(): Observable<T> {
    let endpointUrl = this.masterDeviceUrl;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMasterDeviceEndpoint());
      }));
  }

  getSystemConfigEndpoint<SystemConfig>(): Observable<SystemConfig> {
    let endpointUrl = `${this.masterDeviceUrl}/systemconfig`;

    return this.http.get<SystemConfig>(endpointUrl, this.getRequestHeaders()).pipe<SystemConfig>(
      catchError(error => {
        return this.handleError(error, () => this.getSystemConfigEndpoint());
      }));
  }

  getUpdateMasterDeviceEndpoint<T>(masterDeviceObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.masterDeviceUrl}/${id}` : `${this.masterDeviceUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(masterDeviceObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateMasterDeviceEndpoint(masterDeviceObject, id));
      }));
  }

  getupdateConfigurationDataEndpoint(id: number): Observable<any> {
    let endpointUrl = `${this.masterDeviceUrl}/${id}/UpdateMasterDeviceData`;

    return this.http.post(endpointUrl, null, this.getRequestHeaders()).pipe<any>(
      catchError(error => {
        return this.handleError(error, () => this.getupdateConfigurationDataEndpoint(id));
      }));
  }

  updateFullConfigurationData(id: number): Observable<any> {
    let endpointUrl = `${this.masterDeviceUrl}/${id}/UpdateMasterDeviceFullData`;

    return this.http.post(endpointUrl, null, this.getRequestHeaders()).pipe<any>(
      catchError(error => {
        return this.handleError(error, () => this.getupdateConfigurationDataEndpoint(id));
      }));
  }

}
