import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { AccessKeyEndpoint } from './access-key-endpoint.service';
import { AccessKey } from '../../models/accessKey/access-key.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class AccessKeyService {

  constructor(private router: Router, private http: HttpClient,
    private accessKeyEndpoint: AccessKeyEndpoint) {

  }
  
  getAccessKey(id?: number): Observable<AccessKey> {
    if (id) {
      return this.accessKeyEndpoint.getAccessKeyEndpoint<AccessKey>(id);
    }

    
  }

  getAccessKeys(page: number = 0, pageSize: number = 9999): Observable<PagedData<AccessKey>>{

    return this.accessKeyEndpoint.getAccessKeysEndpoint<PagedData<AccessKey>>(page, pageSize);
  }
  
  updateAccessKey(accessKey: AccessKey): Observable<AccessKey> {
    return this.accessKeyEndpoint.getUpdateAccessKeyEndpoint(accessKey, accessKey.id);
  }
  
  newAccessKey(accessKey: AccessKey): Observable<AccessKey>{
    return this.accessKeyEndpoint.getNewAccessKeyEndpoint<AccessKey>(accessKey);
  }
  
  deleteAccessKey(id): Observable<AccessKey> {

    return this.accessKeyEndpoint.getDeleteAccessKeyEndpoint(id);
  }
}
