import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { AlarmZone } from '../../../models/alarm/alarm-zone.model';
import { ZoneDevice } from '../../../models/alarm/zone-device.model';
import { DeviceFunction } from '../../../models/device/device-function.model';
import { Device } from '../../../models/device/device.model';
import { Room } from '../../../models/room/room.model';
import { ZoneDeviceService } from '../../../services/alarm/zone-device.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { AlarmProfile } from 'src/app/models/alarm/alarm-profile.model';
import { DevicePort } from '../../../models/device/device-port.model';
import {AppTranslationService} from "../../../services/app-translation.service";


@Component({
    selector: "admin-alarm-zone-sensor-editor",
    templateUrl: './admin-alarm-zone-sensor-editor.component.html',
    styleUrls: ['./admin-alarm-zone-sensor-editor.component.scss'],
    animations: [fadeInOut]
})
export class AdminAlarmZoneSensorEditorComponent implements OnInit {

    @ViewChild('form', { static: true })
    private form: NgForm;

    private isNewAction = false;
    private isSaving: boolean;
    private onZoneDeviceSaved = new Subject<ZoneDevice>();

    private currentZoneDevice: ZoneDevice = new ZoneDevice();
    private alarmZone: AlarmZone = new AlarmZone();
    private alarmProfile: AlarmProfile = new AlarmProfile();
    public allRooms: Room[];
    public allInputs: Device[];
    public filteredDevices: Device[];
    public deviceFunctions: DeviceFunction[];
    public devicePorts: DevicePort[];


    formGroup: FormGroup;
    zoneDeviceSaved$ = this.onZoneDeviceSaved.asObservable();

    get room() {
        return this.formGroup.get('room');
    }

    get device() {
        return this.formGroup.get('device');
    }

    get deviceFunction() {
        return this.formGroup.get('deviceFunction');
    }

    get devicePort() {
        return this.formGroup.get('devicePort');
    }

    constructor(
        public dialogRef: MatDialogRef<AdminAlarmZoneSensorEditorComponent>,
        private alertService: AlertService,
        private zoneDeviceService: ZoneDeviceService,
        private formBuilder: FormBuilder,
        private translationService: AppTranslationService,
        @Inject(MAT_DIALOG_DATA) public data: { alarmZone: AlarmZone, alarmProfile: AlarmProfile, zoneDevice: ZoneDevice, rooms: Room[], inputs: Device[] },
    ) {
        this.currentZoneDevice = data.zoneDevice;
        this.alarmZone = data.alarmZone;
        this.alarmProfile = data.alarmProfile;
        this.allRooms = data.rooms;
        this.allInputs = data.inputs;

        if (this.currentZoneDevice && this.currentZoneDevice.device && this.currentZoneDevice.device) {
            this.deviceFunctions = this.currentZoneDevice.device.functions;
            this.devicePorts = this.currentZoneDevice.device.devicePorts;
        }

        if (this.currentZoneDevice && this.currentZoneDevice.device && this.currentZoneDevice.device.roomId && this.currentZoneDevice.device.roomId != 0) {
            this.filteredDevices = this.allInputs.filter(x => x.room && x.room.id == this.currentZoneDevice.device.roomId);
        }
        else {
            this.filteredDevices = this.allInputs;
        }

        this.buildForm();
        this.resetForm();
    }

    ngOnInit(): void {
    }

    ngOnChanges() {
        this.resetForm();
    }

    private disableSaveButton() {
        this.isSaving = true;
    }

    private enableSaveButton() {
        this.isSaving = false;
    }
    
    public save() {
        if (!this.formGroup.valid) {
            this.alertService.showValidationError();
            return;
        }

      let savingLabel = this.translationService.getTranslation(`shared.SavingChanges`);

        this.disableSaveButton();
        this.alertService.startLoadingMessage(savingLabel);

        const editedZoneDevice = this.getEditedAction();

        if (this.currentZoneDevice.id == 0) {
            this.isNewAction = true;
            this.zoneDeviceService.newZoneDevice(editedZoneDevice).subscribe(
                device => this.saveSuccessHelper(editedZoneDevice),
                error => this.saveFailedHelper(error));

        }
        else {
            this.isNewAction = false;
            this.zoneDeviceService.updateZoneDevice(editedZoneDevice).subscribe(
                response => this.saveSuccessHelper(editedZoneDevice),
                error => this.saveFailedHelper(error));
        }
    }

    private getEditedAction(): ZoneDevice {
        const formModel = this.formGroup.value;

        return {
            id: this.currentZoneDevice.id,
            alarmZoneId: this.alarmZone.id,
            alarmProfileId: this.alarmProfile.id,
            device: this.allInputs.find(x => x.id == formModel.device),
            deviceFunction: this.deviceFunctions.find(x => x.id == formModel.deviceFunction),
            devicePort: this.devicePorts.find(x => x.id == formModel.devicePort)
        };
    }

    private saveSuccessHelper(zoneDevice?: ZoneDevice) {
        this.alertService.stopLoadingMessage();

      let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
      let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
      let successLabel = this.translationService.getTranslation(`shared.Success`);

        if (this.isNewAction) {
            this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
        }
        else {
            this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
        }

        this.onZoneDeviceSaved.next(zoneDevice);

        this.dialogRef.close(zoneDevice);
        this.enableSaveButton();
    }

    private saveFailedHelper(error: any) {
      let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
      let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

        this.enableSaveButton();
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    }

    public cancel() {
        this.resetForm();

        this.alertService.resetStickyMessage();

        this.dialogRef.close(null);
    }

    private buildForm() {
        this.formGroup = this.formBuilder.group({
            room: ['', Validators.required],
            device: ['', Validators.required],
            deviceFunction: ['', Validators.required],
            devicePort: ['', Validators.required]
        });
    }

    public onRoomChange(id: any) {

        this.device.setValue(0);
        this.deviceFunction.setValue(0);

        this.filteredDevices = this.allInputs.filter(x => x.room && x.room.id == id.value);
    }

    public onInputDeviceChange(id: any) {

        this.deviceFunction.setValue(0);

        let currentDevice = this.allInputs.find(x => x.id == id.value);
        this.deviceFunctions = currentDevice.functions;
        this.devicePorts = currentDevice.devicePorts;
    }

    private resetForm(replace = false) {
        this.formGroup.reset({
            room: this.currentZoneDevice.device && this.currentZoneDevice.device.room ? this.currentZoneDevice.device.room.id : '',
            device: this.currentZoneDevice.device ? this.currentZoneDevice.device.id : '',
            deviceFunction: this.currentZoneDevice.deviceFunction ? this.currentZoneDevice.deviceFunction.id : '',
            devicePort: this.currentZoneDevice.devicePort ? this.currentZoneDevice.devicePort.id : ''
        });
    }
}
