import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { FirmwareVersionService } from '../../services/firmwareVersion/firmwareVersion.service';
import { FirmwareVersion } from '../../models/firmwareVersion/firmware-version.model';
import { AuthService } from '../../services/auth.service';
import { FirmwareStatus } from '../../models/firmwareVersion/firmware-status.model';
import { FirmwareCardComponent } from './firmware-card.component';

@Component({
    selector: 'firmware-list',
    templateUrl: './firmware-list.component.html',
    styleUrls: ['./firmware-list.component.scss']
})
export class FirmwareListComponent implements OnInit, OnDestroy {
  
    allFirmwareVersions: FirmwareVersion[];

    @ViewChildren(FirmwareCardComponent) firmwareCards: QueryList<FirmwareCardComponent>;

    private hubConnection: HubConnection;

    constructor(private firmwareService: FirmwareVersionService, private authService: AuthService) {
        const self = this;

        let builder = new HubConnectionBuilder();
        this.hubConnection = builder
            .withUrl(location.origin + '/hubs/firmwareupdate',
                {
                    transport: HttpTransportType.WebSockets,
                    skipNegotiation: true,
                    accessTokenFactory: () => this.authService.accessToken
                })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.None)
            .build();

        this.hubConnection.on("status", (message) => {
            self.updateModuleState(message);
        });
    }

    ngOnInit() {
        const self = this;
        this.firmwareService.getFirmwareVersions()
            .subscribe(result => {
                this.allFirmwareVersions = result;
                self.connect();
            },
            err => console.log(err));
    }

    private connect() {
        if (this.hubConnection.state === HubConnectionState.Disconnected) {
            this.hubConnection.start().catch(function (err) {});
        }
    }

    updateAll() {
        this.firmwareCards.forEach(card => card.startLoading());

        this.firmwareService.updateFirmware(this.allFirmwareVersions.map(o => o.moduleId)).subscribe();
    }

    updateModuleState(message) {
        var version = this.allFirmwareVersions.find(x => x.id == message.id);
        if (version) {
            version.version = message.version;
            version.canUpdate = message.canUpdate;
            version.percent = message.percent ? message.percent + "%" : "0%";
            version.status = message.status;

            const card = this.firmwareCards.find(card => card.firmware.id == message.id);
            if (card) {
                if(message.percent == 100) {
                    card.stopLoading();
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.hubConnection.stop();
    }
}