import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlarmZone } from 'src/app/models/alarm/alarm-zone.model';
import { DeviceTypeState } from 'src/app/models/device/device-type-state.model';
import { AlarmZoneService } from 'src/app/services/alarm/alarm-zone.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AlarmZoneState } from '../../models/alarm/alarm-zone-state.model';

@Component({
  selector: 'alarm-zone-card',
  templateUrl: './alarm-zone-card.component.html',
  styleUrls: ['./alarm-zone-card.component.scss']
})
export class AlarmZoneCardComponent implements OnInit {
  @Input() alarmZone: AlarmZone;

  public currentProfile: string;
  public defaultIcon = './assets/icons/escudo.svg';

  constructor(private alarmZoneService: AlarmZoneService, private router: Router, private translationService: AppTranslationService) {
  }

  ngOnInit() {
  }
  

  public translateState(state: DeviceTypeState): string {
    try {
      return this.translationService.getTranslation(`lookups.DeviceTypeState.${DeviceTypeState[state]}`);
    } catch (e) {
      return state.toString();
    }
  }

  public getState(zone: AlarmZone): string {
    let state = '';
    try {
      state = this.translationService.getTranslation(`lookups.AlarmZoneState.${AlarmZoneState[zone.state]}`);
    } catch (e) {
      return state;
    }

    return state;
  }

  public changeState(alarmZoneid: number, state: number) {
    this.alarmZoneService.changeState(alarmZoneid, state);
  }
}
