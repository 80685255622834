import { AccessKeyType } from "./access-key-type.model";
import { User } from "../user.model";

export class AccessKey {

  constructor(id: number = 0, name?: string, description?: string, code?: string, userId?: string, keyType?: AccessKeyType, user?: User) {

    this.id = id;
    this.name = name;
    this.description = description;
    this.code = code;
    this.keyType = keyType;
    this.userId = userId;
    this.user = user;
  }

  public id: number;
  public name: string;
  public description: string;
  public code: string;
  public userId: string;
  public user: User;
  public keyType: AccessKeyType;
}
