import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';



@Injectable()
export class AccessZoneActionEndpoint extends EndpointFactory {

  private readonly _zoneActionsKeyUrl: string = "/api/accessZone/{zoneId}/accesszoneaction";

  get actionsUrl() { return this.configurations.baseUrl + this._zoneActionsKeyUrl; }


  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  getAccessZoneActionEndpoint<T>(zoneId: number, id: number): Observable<T> {

    let endpointUrl = `${this.actionsUrl}/${id}`;
    endpointUrl = endpointUrl.replace("{zoneId}", zoneId.toString());

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAccessZoneActionEndpoint(zoneId, id));
      }));
  }

  getAccessZoneActionsEndpoint<T>(zoneId?: number, page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this.actionsUrl}?page=${page}&size=${pageSize}`;
    endpointUrl = endpointUrl.replace("{zoneId}", zoneId.toString());

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAccessZoneActionsEndpoint(zoneId, page, pageSize));
      }));
  }

  getNewAccessZoneActionEndpoint<T>(zoneId: number, accessZoneActionMapObject: any): Observable<T> {

    let endpointUrl = this.actionsUrl.replace("{zoneId}", zoneId.toString());

    return this.http.post<T>(endpointUrl, JSON.stringify(accessZoneActionMapObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewAccessZoneActionEndpoint(zoneId, accessZoneActionMapObject));
      }));
  }

  getUpdateAccessZoneActionEndpoint<T>(zoneId: number, accessZoneActionMapObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.actionsUrl}/${id}` : `${this.actionsUrl}`;
    endpointUrl = endpointUrl.replace("{zoneId}", zoneId.toString());

    return this.http.put<T>(endpointUrl, JSON.stringify(accessZoneActionMapObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateAccessZoneActionEndpoint(zoneId, accessZoneActionMapObject, id));
      }));
  }

  getDeleteAccessZoneActionEndpoint<T>(zoneId: number, id: number): Observable<T> {
    let endpointUrl = `${this.actionsUrl}/${id}`;
    endpointUrl = endpointUrl.replace("{zoneId}", zoneId.toString());

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteAccessZoneActionEndpoint(zoneId, id));
      }));
  }
}
