import { AfterViewInit, Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { TranslateService } from "@ngx-translate/core";
import { Device } from '../../models/device/device.model';
import { AutomationRuleService } from '../../services/automationRule/automation-rule.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AppTranslationService } from "../../services/app-translation.service";
import { Utilities } from '../../services/utilities';
import { Router } from '@angular/router';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { CameraService } from 'src/app/services/cameras/camera.service';
import { AutomationRule } from '../../models/automationRule/automation-rule.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: "automation-list",
  templateUrl: './automation-list.component.html',
  styleUrls: ['./automation-list.component.scss'],
  animations: [fadeInOut]
})
export class AutomationListComponent implements OnInit, OnDestroy {

  allAutomations: AutomationRule[];
  sourceAutomation: AutomationRule;
  editingAutomationName: { name: string };
  loadingIndicator: boolean;
  private hubConnection: HubConnection;



  constructor(
    private alertService: AlertService,
    private automationService: AutomationRuleService,
    private authService: AuthService,
    private translationService: AppTranslationService,
  ) {
    this.buildConnection();
  }

  ngOnInit() {
    this.loadData();
  }

  buildConnection() {
    const self = this;
    let builder = new HubConnectionBuilder();

    self.hubConnection = builder.withUrl(location.origin + '/hubs/automationState',
      {
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
        accessTokenFactory: () => this.authService.accessToken
      }).build();

    this.hubConnection.on("allautomationstatus", (message) => {
      self.updateAllAutomationStatus(message);
    });

    this.hubConnection.on("automationState", (message) => {
      self.updateAutomationState(message);
    });
  }

  private updateStatus(message: any) {
    if (message && message.automationId) {
      var automationId = message.automationId;
      if (automationId) {
        var automation = this.allAutomations.find(x => x.id == automationId);
        if (automation) {
          automation.state = message.state;
        }
      }
      else {
        console.log("automation not available");
      }
    }
  }

  private loadData() {
    var self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.automationService.getAutomationRules(0, 999)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.allAutomations = results.items;

        self.connect();
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  private connect() {
    const self = this;
    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      self.hubConnection.start().then(() => {
        self.hubConnection.invoke("EmitAutomationStates").catch(function (err) {
        });
      }).catch(function (err) {
      });
    } else if (this.hubConnection.state === HubConnectionState.Connected) {
      self.hubConnection.invoke("EmitAutomationStates").catch(function (err) {
      });
    }
  }

  private updateAutomationState(message: any) {
    if (message && message.automationId) {
      if (message.automationId && this.allAutomations && this.allAutomations.length > 0) {
        const automation = this.allAutomations.find(x => x.id === message.automationId);
        if (automation) {
          automation.state = message.state;

          return;
        }
      }
      else {
        console.log("Automation not available");
      }
    }
  }

  private updateAllAutomationStatus(message: any) {
    if (message && message.automSt) {
      for (let entry of message.automSt) {
        var automationId = entry.id;
        if (automationId && this.allAutomations && this.allAutomations.length > 0) {
          var automation = this.allAutomations.find(x => x.id == automationId);
          if (automation) {
            automation.state = entry.st;
          }
        }
        else {
          console.log("Automation not available");
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.hubConnection.stop();
  }
}
