import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { ModuleEndpoint } from './module-endpoint.service';
import { Module } from '../../models/module/module.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class ModuleService {

  constructor(private router: Router, private http: HttpClient,
    private moduleEndpoint: ModuleEndpoint) {

  }
  
  getModule(id?: number) {
    if (id) {
      return this.moduleEndpoint.getModuleEndpoint<Module>(id);
    }

    
  }

  getModules(page: number = 0, pageSize: number = 9999) {

    return this.moduleEndpoint.getModulesEndpoint<PagedData<Module>>(page, pageSize);
  }
  
  updateModule(module: Module) {
    return this.moduleEndpoint.getUpdateModuleEndpoint(module, module.id);
  }
  
  newModule(module: Module) {
    return this.moduleEndpoint.getNewModuleEndpoint<Module>(module);
  }
  
  deleteModule(id): Observable<Module> {

    return this.moduleEndpoint.getDeleteModuleEndpoint(id);
  }
}
