import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { EnergyZoneDeviceEndpoint } from './energy-zone-device-endpoint.service';
import { EnergyZoneDevice } from '../../models/energy/energy-zone-device.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class EnergyZoneDeviceService {

  constructor(private router: Router, private http: HttpClient,
    private energyZoneDeviceEndpoint: EnergyZoneDeviceEndpoint) {

  }
  
  getEnergyZoneDevice(id?: number): Observable<EnergyZoneDevice> {
    if (id) {
      return this.energyZoneDeviceEndpoint.getEnergyZoneDeviceEndpoint<EnergyZoneDevice>(id);
    }

    
  }

  getEnergyZoneDevices(energyZoneId: number, page: number = 0, pageSize: number = 9999): Observable<PagedData<EnergyZoneDevice>>{

    return this.energyZoneDeviceEndpoint.getEnergyZoneDevicesEndpoint<PagedData<EnergyZoneDevice>>(energyZoneId, page, pageSize);
  }
  
  updateEnergyZoneDevice(energyZoneDevice: EnergyZoneDevice): Observable<EnergyZoneDevice> {
    return this.energyZoneDeviceEndpoint.getUpdateEnergyZoneDeviceEndpoint(energyZoneDevice, energyZoneDevice.id);
  }
  
  newEnergyZoneDevice(energyZoneDevice: EnergyZoneDevice): Observable<EnergyZoneDevice>{
    return this.energyZoneDeviceEndpoint.getNewEnergyZoneDeviceEndpoint<EnergyZoneDevice>(energyZoneDevice);
  }
  
  deleteEnergyZoneDevice(id): Observable<EnergyZoneDevice> {

    return this.energyZoneDeviceEndpoint.getDeleteEnergyZoneDeviceEndpoint(id);
  }
}
