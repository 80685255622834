import { AlarmZoneState } from "./alarm-zone-state.model";
import { Device } from "../device/device.model";
import { DeviceFunction } from "../device/device-function.model";
import { DevicePort } from "../device/device-port.model";

export class ZoneDevice {

    constructor(id: number = 0, alarmZoneId?: number, alarmProfileId?: number, device?: Device, deviceFunction?: DeviceFunction, devicePort?: DevicePort) {
        this.id = id;
        this.alarmZoneId = alarmZoneId;
        this.device = device;
        this.deviceFunction = deviceFunction;
        this.alarmProfileId = alarmProfileId;
        this.devicePort = devicePort;
    }

    public id: number;
    public alarmZoneId: number;
    public alarmProfileId: number;
    public device: Device;
    public deviceFunction: DeviceFunction;
    public devicePort: DevicePort;
}
