export class ZoneActionParameter {

  constructor(id: number = 0, code?: string, description?: string, zoneActionId?: number, value?: string) {

    this.id = id;
    this.code = code;
    this.description = description;
    this.zoneActionId = zoneActionId;
    this.value = value;
  }

  public id: number;
  public code: string;
  public description: string;
  public zoneActionId: number;
  public value: string;
}
