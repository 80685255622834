import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PagedData } from '../../models/PagedData';
import {AccessZone} from "../../models/accessZone/access-zone.model";
import {AccessZoneService} from "./access-zone.service";

@Injectable()
export class AccessZoneResolve implements Resolve<Observable<PagedData<AccessZone>>> {
  constructor(private accessZoneService: AccessZoneService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<AccessZone> | Promise<AccessZone> | any {
    let id = +route.params['id'];

    return this.accessZoneService.getAccessZone(id).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
