import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';

import { DeviceTypeFunctionEndpoint } from './device-type-function-endpoint.service';
import { DeviceTypeFunction } from '../../models/device/device-type-function.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class DeviceTypeFunctionService {

  constructor(private router: Router, private http: HttpClient,
    private deviceTypeEndpoint: DeviceTypeFunctionEndpoint) {

  }

  getDeviceTypes(page: number = 0, pageSize: number = 999) {

    return this.deviceTypeEndpoint.getDeviceTypeFunctionssEndpoint<PagedData<DeviceTypeFunction>>(page, pageSize);
  }
}
