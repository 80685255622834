import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UploadDownloadService } from '../../upload-download.service';

@Component({
  selector: 'app-download',
  templateUrl: 'download.component.html'
})

export class DownloadComponent {

  constructor(private service: UploadDownloadService) {
      }

  public download() {
    let filename: string = 'microlumin_backup.db';
    this.service.downloadFile().subscribe((result: any) => {
      let newBlob = new Blob([result], { type: "application/octet-stream" });
      let data = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = data;
      link.download = filename;
      link.click();
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    });
  }
}
