import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { AlarmZoneState } from '../../../models/alarm/alarm-zone-state.model';
import { AlarmZone } from '../../../models/alarm/alarm-zone.model';
import { AlarmZoneService } from "../../../services/alarm/alarm-zone.service";
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { Device } from '../../../models/device/device.model';
import { PickerIconImage } from '../../../models/picker-icon-image.model';
import {AppTranslationService} from "../../../services/app-translation.service";



@Component({
  selector: 'admin-alarm-zone-editor',
  templateUrl: './admin-alarm-zone-editor.component.html',
  styleUrls: ['./admin-alarm-zone-editor.component.scss']
})
export class AdminAlarmZoneEditorComponent implements OnInit {
  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewAlarmZone = false;
  private isSaving = false;
  private onAlarmZoneSaved = new Subject<AlarmZone>();

  public isModuleVisible = false;
  public alarmzone: AlarmZone = new AlarmZone();
  public alarm: Device  = new Device();

  alarmzoneForm: FormGroup;
  onAlarmZoneSaved$ = this.onAlarmZoneSaved.asObservable();

  get name() {
    return this.alarmzoneForm.get('name');
  }

  get description() {
    return this.alarmzoneForm.get('description');
  }

  get iconPath() {
    return this.alarmzoneForm.get('iconPath');
  }

  public allIconImages: PickerIconImage[] = [
    new PickerIconImage(
      "balcony",
      "./assets/icons/balcony.svg",
      ["balcony"]
    ),
    new PickerIconImage(
      "bathtub",
      "./assets/icons/bathtub.svg",
      ["bathtub"]
    ),
    new PickerIconImage(
      "bed01",
      "./assets/icons/bed01.svg",
      ["bed"]
    ),
    new PickerIconImage(
      "bed02",
      "./assets/icons/bed02.svg",
      ["bed"]
    ),
    new PickerIconImage(
      "bedroom",
      "./assets/icons/bedroom.svg",
      ["bedroom"]
    ),
    new PickerIconImage(
      "construction",
      "./assets/icons/construction01.svg",
      ["construction"]
    ),

    new PickerIconImage(
      "couch_light",
      "./assets/icons/couch_light.svg",
      ["couch_light"]
    ),
    new PickerIconImage(
      "couch01",
      "./assets/icons/couch01.svg",
      ["couch01"]
    ),
    new PickerIconImage(
      "couch02",
      "./assets/icons/couch02.svg",
      ["couch02"]
    ),
    new PickerIconImage(
      "fork_knife",
      "./assets/icons/fork_knife.svg",
      ["fork_knife"]
    ),
    new PickerIconImage(
      "fridge",
      "./assets/icons/fridge.svg",
      ["fridge"]
    ),

    new PickerIconImage(
      "gate01",
      "./assets/icons/gate01.svg",
      ["gate01"]
    ),
    new PickerIconImage(
      "gate02",
      "./assets/icons/gate02.svg",
      ["gate02"]
    ),
    new PickerIconImage(
      "home-app",
      "./assets/icons/home-app.svg",
      ["home-app"]
    ),
    new PickerIconImage(
      "icon_01",
      "./assets/icons/icon_01.svg",
      ["icon_01"]
    ),
    new PickerIconImage(
      "kitchen",
      "./assets/icons/kitchen.svg",
      ["kitchen"]
    ),
    new PickerIconImage(
      "kitchen_hood",
      "./assets/icons/kitchen_hood.svg",
      ["kitchen_hood"]
    ),
    new PickerIconImage(
      "lamp",
      "./assets/icons/lamp.svg",
      ["lamp"]
    ), new PickerIconImage(
      "living_room",
      "./assets/icons/living_room.svg",
      ["living_room"]
    ),
    new PickerIconImage(
      "modern_house",
      "./assets/icons/modern_house.svg",
      ["modern_house"]
    ),
    new PickerIconImage(
      "office",
      "./assets/icons/office.svg",
      ["office"]
    ),
    new PickerIconImage(
      "wash_clothes",
      "./assets/icons/wash_clothes.svg",
      ["wash_clothes"]
    ),
    new PickerIconImage(
      "washing_machine",
      "./assets/icons/washing_machine.svg",
      ["washing_machine"]
    ),
  ];

  constructor(
    public dialogRef: MatDialogRef<AdminAlarmZoneEditorComponent>,
    private alertService: AlertService,
    private alarmzoneService: AlarmZoneService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { alarmZone: AlarmZone, alarm: Device},
  ) {
    this.alarmzone = data.alarmZone;
    this.alarm = data.alarm;


    this.buildForm();
    this.resetForm();
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.alarmzoneForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    let savingLabel = this.translationService.getTranslation(`shared.SavingChanges`);

    this.disableSaveButton();
    this.alertService.startLoadingMessage(savingLabel);

    const editedAlarmZone = this.getEditedAlarmZone();

    if (this.alarmzone.id == 0) {
      this.isNewAlarmZone = true;
      this.alarmzoneService.newAlarmZone(editedAlarmZone).subscribe(
        alarmzone => this.saveSuccessHelper(alarmzone),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewAlarmZone = false;
      this.alarmzoneService.updateAlarmZone(editedAlarmZone).subscribe(
        response => this.saveSuccessHelper(editedAlarmZone),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedAlarmZone(): AlarmZone {
    const formModel = this.alarmzoneForm.value;

    return {
      id: this.alarmzone.id,
      alarmId: this.alarm.id,
      name: formModel.name,
      code: formModel.code,
      description: formModel.description,
      state: AlarmZoneState.Off,
      iconPath: formModel.iconPath
    };
  }

  private saveSuccessHelper(alarmzone?: AlarmZone) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewAlarmZone) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onAlarmZoneSaved.next(alarmzone);

    this.dialogRef.close(alarmzone);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.alarmzoneForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
      code: '',
      iconPath: './assets/icons/Asset05.svg'
    });
  }

  private resetForm(replace = false) {
    this.alarmzoneForm.reset({
      name: this.alarmzone.name || '',
      code: this.alarmzone.code || '',
      description: this.alarmzone.description || '',
      alarmId: this.alarm.id,
      iconPath: this.alarmzone.iconPath|| './assets/icons/Asset05.svg'
    });
  }

  public selectIconImage(icon: PickerIconImage) {
    this.alarmzone.iconPath= icon.path;
    this.alarmzoneForm.controls['iconPath'].setValue(icon.path);
  }
}
