import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog, PageEvent } from '@angular/material';

import { fadeInOut } from '../../../services/animations';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from "../../../services/app-translation.service";
import { Utilities } from '../../../services/utilities';
import { EnergyZone } from '../../../models/energy/energy-zone.model';
import { AdminEnergyZoneEditorComponent } from "./admin-energy-zone-editor.component";
import { EnergyZoneService } from '../../../services/energy/energy-zone.service';
import { Router } from '@angular/router';

@Component({
  selector: "admin-energy-zone-list",
  templateUrl: './admin-energy-zone-list.component.html',
  styleUrls: ['./admin-energy-zone-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminEnergyZoneListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'description', 'mainZone', 'actions'];
  datasource: null;
  dataSource: MatTableDataSource<EnergyZone>;
  sourceEnergyZone: EnergyZone;
  editingEnergyZoneName: { name: string };
  loadingIndicator: boolean;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private energyZoneService: EnergyZoneService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    public router: Router
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateEnergyZones(energyZone: EnergyZone) {
    if (this.sourceEnergyZone) {
      Object.assign(this.sourceEnergyZone, energyZone);
      this.sourceEnergyZone = null;
    }

    this.loadData();
    this.refresh();
  }

  private loadData() {
    var self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.energyZoneService.getEnergyZones(this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.dataSource.data = results.items;
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  public editEnergyZone(energyZone?: EnergyZone) {
    this.sourceEnergyZone = energyZone;

    if (!energyZone) {
      energyZone = new EnergyZone();
    }

    let dialogRef = this.dialog.open(AdminEnergyZoneEditorComponent,
      {
        panelClass: 'mat-dialog-sm',
        data: { energyZone: energyZone }
      });
    dialogRef.afterClosed().subscribe(energyZone => {
      if (energyZone) {
        this.updateEnergyZones(energyZone);
      }
    });
  }

  private confirmDelete(energyZone: EnergyZone) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.energyZoneService.deleteEnergyZone(energyZone.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== energyZone)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }

  public editEnergyZoneSettings(energyZone?: EnergyZone) {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.router.navigate(['/admin/energyzonedevices', energyZone.id]);
  }
}
