// =============================
// Email: info@.com
// www..com/templates
// =============================

import { NgModule } from "@angular/core";
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule } from "@ngx-translate/core";

import { microluminappProMaterialModule } from "../modules/material.module";

import { PageHeaderComponent } from './page-header.component'
import { UserEditorComponent } from '../admin/user-editor.component';
import { AppDialogComponent } from './app-dialog.component';

import { GroupByPipe } from '../pipes/group-by.pipe';
import { FlexModule } from '@angular/flex-layout/flex';
import { GridModule } from '@angular/flex-layout/grid';

@NgModule({
  imports: [
    FlexLayoutModule,
    FlexModule,
    GridModule,
    FormsModule, ReactiveFormsModule,
    BrowserModule, BrowserAnimationsModule,
    microluminappProMaterialModule,
    TranslateModule
  ],
  exports: [
    FlexLayoutModule,
    FlexModule,
    GridModule,
    FormsModule, ReactiveFormsModule,
    BrowserModule, BrowserAnimationsModule,
    microluminappProMaterialModule,
    TranslateModule,
    PageHeaderComponent,
    GroupByPipe,
    UserEditorComponent,
    AppDialogComponent
  ],
  declarations: [
    PageHeaderComponent,
    GroupByPipe,
    UserEditorComponent,
    AppDialogComponent
  ],
  entryComponents: [
    AppDialogComponent
  ]
})
export class SharedModule {

}
