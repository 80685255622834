import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog, PageEvent, Sort } from '@angular/material';
import { EventNotification } from 'src/app/models/eventNotification/event-notification.model';
import { fadeInOut } from 'src/app/services/animations';
import { AlertService, MessageSeverity } from 'src/app/services/alert.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { EventNotificationService } from 'src/app/services/eventNotification/event-notification.service';
import { Utilities } from 'src/app/services/utilities';
import { HouseEventType } from '../../models/eventNotification/house-event-type.model';
import { AutomationTriggerType } from '../../models/eventNotification/automation-trigger-type.model';
import { HouseEventsSourceType } from '../../models/eventNotification/house-event-source-type.model';

@Component({
  selector: "event-notification-list",
  templateUrl: './event-notification-list.component.html',
  styleUrls: ['./event-notification-list.component.scss'],
  animations: [fadeInOut]
})
export class EventNotificationListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['description', 'type', 'date', 'origin'];
  datasource: null;
  dataSource: MatTableDataSource<EventNotification>;
  sourceEventNotification: EventNotification;
  editingEventNotificationName: { name: string };
  loadingIndicator: boolean;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private eventNotificationService: EventNotificationService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateEventNotifications(eventNotification: EventNotification) {
    if (this.sourceEventNotification) {
      Object.assign(this.sourceEventNotification, eventNotification);
      this.sourceEventNotification = null;
    }
    else {
      this.dataSource.data.unshift(eventNotification);
    }

    this.refresh();
  }

  private loadData() {
    var self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.eventNotificationService.getEventNotifications(this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.dataSource.data = results.items;
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data;
    let sortedData = this.dataSource.data
    if (!sort.active || sort.direction === '') {
      sortedData = data;
      return;
    }

    sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.title, b.title, isAsc);
        case 'description': return this.compare(a.description, b.description, isAsc);
        case 'origin': return this.compare(a.origin, b.origin, isAsc);
        case 'type': return this.compare(a.type, b.type, isAsc);
        default: return 0;
      }
    });

    this.dataSource.data = sortedData;
  }

  public handleDescription(event: EventNotification) {

    switch (event.type) {
      case HouseEventType.AlarmTrigger:
        return this.getAlarmTriggerDescription(event);
      case HouseEventType.AlarmProfile:
        return this.getAlarmProfileDescription(event);
      case HouseEventType.AlarmKeys:
        return this.getAlarmKeysDescription(event);
      case HouseEventType.DeviceFunction:
        return this.getDeviceFunctionDescription(event);
      case HouseEventType.AutomationTrigger:
        return this.getAutomationTriggerDescription(event);
      default:
        return "Not implemented";
    }
  }

  public getAlarmTriggerDescription(event: EventNotification): string {

    const translation = this.translationService.getTranslation(`HouseEventTypeMessage.AlarmTrigger`);
    const message = translation.replace("{zone}", event.zone).replace("{sensor}", event.sensor);

    return message;
  }

  public getAlarmProfileDescription(event: EventNotification) {
    const translation = this.translationService.getTranslation(`HouseEventTypeMessage.AlarmProfile`);
    const message = translation.replace("{oldProfile}", event.oldProfile).replace("{newProfile}", event.newProfile).replace("{user}", event.user);

    return message;
  }

  public getAlarmKeysDescription(event: EventNotification) {

    const authLabel = event.authorized ? this.translationService.getTranslation(`shared.Authorized`) : this.translationService.getTranslation(`shared.NotAuthorized`) ;
    
    const translation = this.translationService.getTranslation(`HouseEventTypeMessage.AlarmKeys`);
    const message = translation.replace("{Authorized}", authLabel).replace("{acessKey}", event.key).replace("{user}", event.user);

    return message;
  }

  public getDeviceFunctionDescription(event: EventNotification) {
    const translation = this.translationService.getTranslation(`HouseEventTypeMessage.DeviceFunction`);
    const message = translation.replace("{device}", event.device).replace("{function}", event.functionName);

    return message;
  }

  public getAutomationTriggerDescription(event: EventNotification) {

    const stateTranslation = event.automationTriggerType == AutomationTriggerType.Start ? this.translationService.getTranslation(`shared.AutomationStart`) : this.translationService.getTranslation(`shared.AutomationStop`);

    const translation = this.translationService.getTranslation(`HouseEventTypeMessage.AutomationTrigger`);
    const message = translation.replace("{automation}", event.automation).replace("{triggerType}", stateTranslation);

    return message;
  }

  public translateType(type: HouseEventType): string {
    try {
      return this.translationService.getTranslation(`lookups.HouseEventType.${HouseEventType[type]}`);
    } catch (e) {
      return type.toString();
    }
  }

  public translateSource(source: HouseEventsSourceType): string {
    try {
      return this.translationService.getTranslation(`lookups.HouseEventsSourceType.${HouseEventsSourceType[source]}`);
    } catch (e) {
      return source.toString();
    }
  }

  public compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
