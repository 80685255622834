import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { AlarmProfile } from '../../models/alarm/alarm-profile.model';
import { PagedData } from '../../models/PagedData';
import { AlarmProfileEndpoint } from './alarm-profile-endpoint.service';


@Injectable()
export class AlarmProfileService {

  constructor(private router: Router, private http: HttpClient,
    private alarmProfileEndpoint: AlarmProfileEndpoint) {

  }
  
  getAlarmProfile(id?: number) {
    if (id) {
      return this.alarmProfileEndpoint.getAlarmProfileEndpoint<AlarmProfile>(id);
    }
  }
  
  getAlarmProfiles(alarmId: number, page: number = 0, pageSize: number = 9999) {

    return this.alarmProfileEndpoint.getAlarmProfilesEndpoint<PagedData<AlarmProfile>>(alarmId, page, pageSize);
  }

  updateAlarmProfile(alarmProfile: AlarmProfile) {
    return this.alarmProfileEndpoint.getUpdateAlarmProfileEndpoint(alarmProfile, alarmProfile.id);
  }

  newAlarmProfile(alarmProfile: AlarmProfile) {
    return this.alarmProfileEndpoint.getNewAlarmProfileEndpoint<AlarmProfile>(alarmProfile);
  }

  deleteAlarmProfile(id): Observable<AlarmProfile> {

    return this.alarmProfileEndpoint.getDeleteAlarmProfileEndpoint(id);
  }
}
