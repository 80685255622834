import { Component, OnInit, OnDestroy, Input, Output, ViewChild, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

import { AlertService, MessageSeverity, DialogType } from '../../services/alert.service';
import { AuthService } from "../../services/auth.service";
import { ConfigurationService } from '../../services/configuration.service';
import { Utilities } from '../../services/utilities';
import { UserLogin } from '../../models/user-login.model';
import { AppTranslationService } from "../../services/app-translation.service";

@Component({
  selector: "app-login-control",
  templateUrl: './login-control.component.html',
  styleUrls: ['./login-control.component.scss']
})
export class LoginControlComponent implements OnInit, OnDestroy {
  isLoading = false;
  formResetToggle = true;
  modalClosedCallback: () => void;
  loginStatusSubscription: any;

  loginForm: FormGroup;

  @ViewChild('form', { static: true })
  private form: NgForm;

  @Input()
  isModal = false;

  @Output()
  enterKeyPress = new EventEmitter();

  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private configurations: ConfigurationService,
    private translationService: AppTranslationService,
    private formBuilder: FormBuilder) {
    this.buildForm();
  }

  ngOnInit() {
    this.loginForm.setValue({
      userName: '',
      password: '',
      rememberMe: this.authService.rememberMe
    });

    if (this.getShouldRedirect()) {
      this.authService.redirectLoginUser();
    }
    else {
      this.loginStatusSubscription = this.authService.getLoginStatusEvent()
        .subscribe(isLoggedIn => {
          if (this.getShouldRedirect()) {
            this.authService.redirectLoginUser();
          }
        });
    }
  }

  ngOnDestroy() {
    if (this.loginStatusSubscription) {
      this.loginStatusSubscription.unsubscribe();
    }
  }

  buildForm() {
    this.loginForm = this.formBuilder.group({
      userName: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: ''
    });
  }

  get userName() { return this.loginForm.get('userName'); }

  get password() { return this.loginForm.get('password'); }

  getShouldRedirect() {
    return !this.isModal && this.authService.isLoggedIn && !this.authService.isSessionExpired;
  }

  closeModal() {
    if (this.modalClosedCallback) {
      this.modalClosedCallback();
    }
  }

  getUserLogin(): UserLogin {
    const formModel = this.loginForm.value;
    return new UserLogin(formModel.userName, formModel.password, formModel.rememberMe);
  }

  login() {
     
    if (!this.loginForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    let attemptingLogin = this.translationService.getTranslation(`shared.AttemptingLogin`);
    let loginLabel = this.translationService.getTranslation(`shared.Login`);
    let welcomeLabel = this.translationService.getTranslation(`shared.Welcome`);
    let restoredSessionLabel = this.translationService.getTranslation(`shared.RestoredSession`);
    let pleaseTryAgainLabel = this.translationService.getTranslation(`shared.TryAgain`);
    let unableToLoginLabel = this.translationService.getTranslation(`shared.UnableToLogin`);

    this.isLoading = true;
    this.alertService.startLoadingMessage("", attemptingLogin);

    this.authService.login(this.getUserLogin())
      .subscribe(
        user => {
          setTimeout(() => {
            this.alertService.stopLoadingMessage();
            this.isLoading = false;
            this.reset();

            if (!this.isModal) {
              this.alertService.showMessage(loginLabel, `${welcomeLabel} ${user.userName}!`, MessageSeverity.success);
            }
            else {
              this.alertService.showMessage(loginLabel, restoredSessionLabel, MessageSeverity.success);
              setTimeout(() => {
                this.alertService.showStickyMessage(restoredSessionLabel, pleaseTryAgainLabel, MessageSeverity.default);
              }, 500);

              this.closeModal();
            }
          }, 500);
        },
        error => {
          this.alertService.stopLoadingMessage();

          if (Utilities.checkNoNetwork(error)) {
            this.alertService.showStickyMessage(Utilities.noNetworkMessageCaption, Utilities.noNetworkMessageDetail, MessageSeverity.error, error);
          }
          else {
            this.alertService.showStickyMessage(unableToLoginLabel, pleaseTryAgainLabel, MessageSeverity.error, error);
          }
          setTimeout(() => {
            this.isLoading = false;
          }, 500);
        });
  }


  mapLoginErrorMessage(error: string) {

    if (error == 'invalid_username_or_password')
      return 'Invalid username or password';

    if (error == 'invalid_grant')
      return 'This account has been disabled';

    return error;
  }

  reset() {
    this.formResetToggle = false;

    setTimeout(() => {
      this.formResetToggle = true;
    });
  }

  enterKeyDown() {
    this.enterKeyPress.emit();
  }
}
