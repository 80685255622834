// =============================
// Email: info@.com
// www..com/templates
// =============================

import { Component, ViewChild } from "@angular/core";

import { LoginControlComponent } from './login-control.component';

@Component({
    selector: "app-login",
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent
{

  public appLogo = require("../../assets/images/logo_fig_conf.png");

  @ViewChild(LoginControlComponent, { static: true })
    loginControl: LoginControlComponent;
}
