export class Settings {

    constructor(id: number = 0, camerasModuleActive?: boolean, climatizationModuleActive?: boolean, alarmModuleActive?: boolean, accessZonesModuleActive?: boolean, powerConsumptionModuleActive?: boolean, dashboardWindowsActive?: boolean, dashboardDoorsActive?: boolean, dashboardFloodMonitoringActive?: boolean, dashboardSecurityAlarmActive?: boolean, dashboardFireAlarmActive?: boolean) {

        this.id = id;
        this.camerasModuleActive = camerasModuleActive;
        this.alarmModuleActive = camerasModuleActive;
        this.climatizationModuleActive = climatizationModuleActive;
        this.powerConsumptionModuleActive = powerConsumptionModuleActive;
        this.dashboardWindowsActive = dashboardWindowsActive;
        this.dashboardDoorsActive = dashboardDoorsActive;
        this.dashboardFloodMonitoringActive = dashboardFloodMonitoringActive;
        this.dashboardSecurityAlarmActive = dashboardSecurityAlarmActive;
        this.dashboardFireAlarmActive = dashboardFireAlarmActive;
    }

    public id: number;
    public camerasModuleActive: boolean;
    public climatizationModuleActive: boolean;
    public alarmModuleActive: boolean;
    public accessZonesModuleActive: boolean;
    public powerConsumptionModuleActive: boolean;
    public dashboardWindowsActive: boolean;
    public dashboardDoorsActive: boolean;
    public dashboardFloodMonitoringActive: boolean;
    public dashboardSecurityAlarmActive: boolean;
    public dashboardFireAlarmActive: boolean;
}
