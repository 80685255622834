import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog, PageEvent } from '@angular/material';

import { fadeInOut } from '../../../services/animations';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from "../../../services/app-translation.service";
import { Utilities } from '../../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { EnergyZoneDevice } from '../../../models/energy/energy-zone-device.model';
import { EnergyZoneDeviceService } from '../../../services/energy/energy-zone-device.service';
import { AdminEnergyZoneDeviceEditorComponent } from './admin-energy-zone-device-editor.component';
import { Device } from '../../../models/device/device.model';
import { EnergyZone } from '../../../models/energy/energy-zone.model';

@Component({
  selector: "admin-energy-zone-device-list",
  templateUrl: './admin-energy-zone-device-list.component.html',
  styleUrls: ['./admin-energy-zone-device-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminEnergyZoneDeviceListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'actions'];
  datasource: null;
  dataSource: MatTableDataSource<EnergyZoneDevice>;
  sourceEnergyZoneDevice: EnergyZoneDevice;
  editingEnergyZoneDeviceName: { name: string };
  loadingIndicator: boolean;
  allDevices: Device[];
  allZoneDevices: EnergyZoneDevice[];
  energyZone: EnergyZone;
  public zoneName: string;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private energyZoneDeviceService: EnergyZoneDeviceService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    public router: Router,
    private route: ActivatedRoute,
  ) {
    this.dataSource = new MatTableDataSource();
  }


  ngOnInit(): void {
    let self = this;

    this.alertService.stopLoadingMessage();

    let energyZoneData = this.route.snapshot.data['energyZone'];
    if (energyZoneData) {
      this.energyZone = energyZoneData;
      this.zoneName = this.energyZone.name;
    }

    let devicesData = this.route.snapshot.data['devices'];
    if (devicesData) {
      this.allDevices = devicesData.items;
    }

    let zoneDevicesData = this.route.snapshot.data['energyZoneDevices'];
    if (zoneDevicesData) {
      this.allZoneDevices = zoneDevicesData.items;
      this.dataSource.data = this.allZoneDevices;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateEnergyZoneDevices(energyZoneDevice: EnergyZoneDevice) {
    if (this.sourceEnergyZoneDevice) {
      Object.assign(this.sourceEnergyZoneDevice, energyZoneDevice);
      this.sourceEnergyZoneDevice = null;
    }
    else {
      this.dataSource.data.unshift(energyZoneDevice);
    }

    this.refresh();
  }

  public editEnergyZoneDevice(energyZoneDevice?: EnergyZoneDevice) {
    this.sourceEnergyZoneDevice = energyZoneDevice;

    if (!energyZoneDevice) {
      energyZoneDevice = new EnergyZoneDevice();
    }

    let dialogRef = this.dialog.open(AdminEnergyZoneDeviceEditorComponent,
      {
        panelClass: 'mat-dialog-sm',
        data: { energyZone: this.energyZone, energyZoneDevice: energyZoneDevice, allDevices: this.allDevices }
      });
    dialogRef.afterClosed().subscribe(energyZoneDevice => {
      if (energyZoneDevice) {
        this.updateEnergyZoneDevices(energyZoneDevice);
      }
    });
  }

  private confirmDelete(energyZoneDevice: EnergyZoneDevice) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.energyZoneDeviceService.deleteEnergyZoneDevice(energyZoneDevice.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== energyZoneDevice)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }

  public editEnergyZoneDeviceSettings(energyZoneDevice?: EnergyZoneDevice) {

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.router.navigate(['/admin/energyzonedevices', energyZoneDevice.id]);
  }
}
