import { Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { RoomAction } from 'src/app/models/room/room-action.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { RoomActionService } from 'src/app/services/room/room-action.service';
import { RoomService } from 'src/app/services/room/room.service';

@Component({
  selector: 'actions-list-card',
  templateUrl: './actions-list-card.component.html',
  styleUrls: ['./actions-list-card.component.scss']
})

export class ActionsListCardComponent {

  @Input() roomId: number;

  allActions: RoomAction[];
  actionsDataSource: MatTableDataSource<RoomAction>;
  displayedColumns = ['name', 'actions'];

  constructor(private roomService: RoomService, private roomActionService: RoomActionService, private translationService: AppTranslationService) {
    this.actionsDataSource = new MatTableDataSource();
  }
  
  ngOnInit() {
    const self = this;
    
    this.roomService.getRoomActions(this.roomId)
      .subscribe(result => {

        self.allActions = result.items;
        self.actionsDataSource.data = this.allActions;
      });
    
  }

  public executeAction(actionId: number) {
    this.roomActionService.executeAction(this.roomId, actionId).subscribe();
  }
}
