import { Component, Input, ViewChild, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSelectChange } from '@angular/material';
import { EnergyZoneDevice } from '../../../models/energy/energy-zone-device.model';
import { EnergyZoneDeviceService } from '../../../services/energy/energy-zone-device.service';
import { Device } from '../../../models/device/device.model';
import { ActivatedRouteSnapshot } from '@angular/router';
import { EnergyZone } from '../../../models/energy/energy-zone.model';
import {AppTranslationService} from "../../../services/app-translation.service";

@Component({
    selector: 'admin-energy-zone-device-editor',
    templateUrl: './admin-energy-zone-device-editor.component.html',
    styleUrls: ['./admin-energy-zone-device-editor.component.scss']
})
export class AdminEnergyZoneDeviceEditorComponent {
    @ViewChild('form', { static: true })
    private form: NgForm;

    private isNewEnergyZoneDevice = false;
    private isSaving: boolean;
    private onEnergyZoneDeviceSaved = new Subject<EnergyZoneDevice>();

    private energyZoneDevice: EnergyZoneDevice = new EnergyZoneDevice();
    private energyZone: EnergyZone;

    public allDevices: Device[];
    public energyZoneDeviceForm: FormGroup;
    energyZoneDeviceSaved$ = this.onEnergyZoneDeviceSaved.asObservable();

    constructor(
        public dialogRef: MatDialogRef<AdminEnergyZoneDeviceEditorComponent>,
        private alertService: AlertService,
        private energyZoneDeviceService: EnergyZoneDeviceService,
        private formBuilder: FormBuilder,
        private translationService: AppTranslationService,
        @Inject(MAT_DIALOG_DATA) public data: { energyZone: EnergyZone, energyZoneDevice: EnergyZoneDevice, allDevices: Device[] },
    ) {
        this.energyZoneDevice = data.energyZoneDevice;
        this.allDevices = data.allDevices;
        this.energyZone = data.energyZone;
        this.buildForm();
        this.resetForm();
    }

    ngOnChanges() {
        this.resetForm();
    }

    private disableSaveButton() {
    this.isSaving = true;screenLeft
    }

    private enableSaveButton() {
        this.isSaving = false;
    }
    
    public save() {
        if (!this.form.submitted) {
            this.form.onSubmit(null);
            return;
        }

        if (!this.energyZoneDeviceForm.valid) {
            this.alertService.showValidationError();
            return;
        }

      let savingLabel = this.translationService.getTranslation(`shared.SavingChanges`);

        this.disableSaveButton();
        this.alertService.startLoadingMessage(savingLabel);

        const editedEnergyZoneDevice = this.getEditedEnergyZoneDevice();

        if (this.energyZoneDevice.id == 0) {
            this.isNewEnergyZoneDevice = true;
            this.energyZoneDeviceService.newEnergyZoneDevice(editedEnergyZoneDevice).subscribe(
                energyZoneDevice => this.saveSuccessHelper(editedEnergyZoneDevice),
                error => this.saveFailedHelper(error));

        }
        else {
            this.isNewEnergyZoneDevice = false;
            this.energyZoneDeviceService.updateEnergyZoneDevice(editedEnergyZoneDevice).subscribe(
                response => this.saveSuccessHelper(editedEnergyZoneDevice),
                error => this.saveFailedHelper(error));
        }
    }

    private getEditedEnergyZoneDevice(): EnergyZoneDevice {
        const formModel = this.energyZoneDeviceForm.value;

        return {
            id: this.energyZoneDevice.id,
            energyZoneId: this.energyZone.id,
            deviceId: formModel.deviceId,
            device: this.allDevices.find(x => x.id == formModel.deviceId)
        };
    }

    private saveSuccessHelper(energyZoneDevice?: EnergyZoneDevice) {
        this.alertService.stopLoadingMessage();

      let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
      let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
      let successLabel = this.translationService.getTranslation(`shared.Success`);

        if (this.isNewEnergyZoneDevice) {
            this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
        }
        else {
            this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
        }

        this.onEnergyZoneDeviceSaved.next(energyZoneDevice);

        this.dialogRef.close(energyZoneDevice);
        this.enableSaveButton();
    }

    private saveFailedHelper(error: any) {

      let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
      let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

        this.enableSaveButton();
        this.alertService.stopLoadingMessage();
        this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
        this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    }

    public cancel() {
        this.resetForm();

        this.alertService.resetStickyMessage();

        this.dialogRef.close(null);
    }

    public onDeviceChange(event: MatSelectChange) {

        if (event && event.value) {
            this.energyZoneDeviceForm.controls['deviceId'].setValue(event.value);
        }
    }

    private buildForm() {
        this.energyZoneDeviceForm = this.formBuilder.group({
            id: this.energyZoneDevice.id,
            deviceId: this.energyZoneDevice.device ? this.energyZoneDevice.device.id : '',
            device: this.energyZoneDevice.device ? this.energyZoneDevice.device : '',
            energyZoneId: this.energyZone.id
        });
    }

    private resetForm(replace = false) {
        this.energyZoneDeviceForm.reset({
        });
    }
}
