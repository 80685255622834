import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class AccessKeyEndpoint extends EndpointFactory {

  private readonly _accessKeyUrl: string = "/api/accessKey";

  get accessKeyUrl() { return this.configurations.baseUrl + this._accessKeyUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getAccessKeyEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.accessKeyUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAccessKeyEndpoint(id));
      }));
  }
  
  getAccessKeysEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._accessKeyUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAccessKeysEndpoint(page, pageSize));
      }));
  }


  getNewAccessKeyEndpoint<T>(accessKeyObject: any): Observable<T> {

    return this.http.post<T>(this.accessKeyUrl, JSON.stringify(accessKeyObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewAccessKeyEndpoint(accessKeyObject));
      }));
  }

  getUpdateAccessKeyEndpoint<T>(accessKeyObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.accessKeyUrl}/${id}` : `${this.accessKeyUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(accessKeyObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateAccessKeyEndpoint(accessKeyObject, id));
      }));
  }

  getDeleteAccessKeyEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.accessKeyUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteAccessKeyEndpoint(id));
      }));
  }
  
}
