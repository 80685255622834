import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AutomationRuleAction } from '../../models/automationRule/automation-rule-action.model';
import { PagedData } from '../../models/PagedData';
import { AutomationRuleActionService } from '../automationRule/automation-rule-action.service';
import { AutomationRuleActionType } from 'src/app/models/automationRule/automation-rule-action-type.model';

@Injectable()
export class AutomationRuleStopActionsResolve implements Resolve<Observable<PagedData<AutomationRuleAction>>> {
  constructor(private automationRuleActionMapService: AutomationRuleActionService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<PagedData<AutomationRuleAction>> | Promise<PagedData<AutomationRuleAction>> | any {
    let id = +route.params['id'];
    return this.automationRuleActionMapService.getAutomationRuleActions(id, AutomationRuleActionType.STOP, 0, 999).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
