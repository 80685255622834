import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Device } from '../../models/device/device.model';
import { DeviceService } from './device.service';

@Injectable()
export class DeviceResolve implements Resolve<Observable<Device>> {
  constructor(private deviceService: DeviceService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Device> | Promise<Device> | any  {
    let id = +route.params['id'];
    return this.deviceService.getDevice(id).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
