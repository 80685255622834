import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { MasterDevice } from '../../models/master-device.model';
import { MasterDeviceEndpoint } from './master-device-endpoint.service';


@Injectable()
export class MasterDeviceService {

  constructor(private router: Router, private http: HttpClient,
    private masterDeviceEndpoint: MasterDeviceEndpoint) {

  }

  getMasterDevice() {
    return this.masterDeviceEndpoint.getMasterDeviceEndpoint<MasterDevice>();
  }

  getSystemConfig() {
    return this.masterDeviceEndpoint.getSystemConfigEndpoint();
  }

  updateMasterDevice(MasterDevice: MasterDevice) {
    return this.masterDeviceEndpoint.getUpdateMasterDeviceEndpoint(MasterDevice, MasterDevice.id);
  }

  updateConfigurationData(id: number) {
    return this.masterDeviceEndpoint.getupdateConfigurationDataEndpoint(id);
  }

  updateFullConfigurationData(id: number) {
    return this.masterDeviceEndpoint.getupdateConfigurationDataEndpoint(id);
  }
}
