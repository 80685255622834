import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { EnergyZoneEndpoint } from './energy-zone-endpoint.service';
import { EnergyZone } from '../../models/energy/energy-zone.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class EnergyZoneService {

  constructor(private router: Router, private http: HttpClient,
    private energyZoneEndpoint: EnergyZoneEndpoint) {

  }
  
  getEnergyZone(id?: number): Observable<EnergyZone> {
    return this.energyZoneEndpoint.getEnergyZoneEndpoint<EnergyZone>(id);
  }

  getEnergyZones(page: number = 0, pageSize: number = 9999): Observable<PagedData<EnergyZone>>{

    return this.energyZoneEndpoint.getEnergyZonesEndpoint<PagedData<EnergyZone>>(page, pageSize);
  }
  
  updateEnergyZone(energyZone: EnergyZone): Observable<EnergyZone> {
    return this.energyZoneEndpoint.getUpdateEnergyZoneEndpoint(energyZone, energyZone.id);
  }
  
  newEnergyZone(energyZone: EnergyZone): Observable<EnergyZone>{
    return this.energyZoneEndpoint.getNewEnergyZoneEndpoint<EnergyZone>(energyZone);
  }
  
  deleteEnergyZone(id): Observable<EnergyZone> {

    return this.energyZoneEndpoint.getDeleteEnergyZoneEndpoint(id);
  }
}
