import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TableDataSource } from 'angular4-material-table';
import { Subject } from 'rxjs';
import { ZoneActionParameter } from 'src/app/models/alarm/zone-action-parameter.model';
import { ZoneAction } from 'src/app/models/alarm/zone-action.model';
import { ZoneActionService } from 'src/app/services/alarm/zone-action.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FunctionValidatorService } from '../../../services/device/function-validator.service';
import { ParameterValidatorService } from '../../../services/device/parameter-validator.service';
import {AppTranslationService} from "../../../services/app-translation.service";



@Component({
  selector: 'admin-alarm-zone-action-settings',
  templateUrl: './admin-alarm-zone-action-settings.component.html',
  styleUrls: ['./admin-alarm-zone-action-settings.component.scss']
})
export class AdminZoneActionSettingsEditorComponent implements OnInit {

  private isSaving = false;

  public zoneAction: ZoneAction = new ZoneAction();

  public allParameters: ZoneActionParameter[];
  public parametersListChange = new EventEmitter<ZoneActionParameter[]>();
  parametersDisplayedColumns = ['code', 'description', 'value', 'actionsColumn'];
  parametersDataSource: TableDataSource<ZoneActionParameter>;

  constructor(
    public dialogRef: MatDialogRef<AdminZoneActionSettingsEditorComponent>,
    private alertService: AlertService,
    private zoneActionService: ZoneActionService,
    private functionsValidator: FunctionValidatorService,
    private parameterValidator: ParameterValidatorService,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { zoneAction: ZoneAction},
  ) {
    this.zoneAction = data.zoneAction;

    this.allParameters = this.zoneAction.parameters;
    this.parametersDataSource = new TableDataSource<ZoneActionParameter>(this.allParameters, ZoneActionParameter, this.parameterValidator);
    this.parametersDataSource.datasourceSubject.subscribe(parameters => {

      this.allParameters = parameters;
      this.parametersListChange.emit(parameters)
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    this.zoneAction.parameters = this.allParameters;

    this.zoneActionService.updateZoneAction(this.zoneAction).subscribe(
      response => this.saveSuccessHelper(this.zoneAction),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(zoneAction?: ZoneAction) {
    this.alertService.stopLoadingMessage();

    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);

    this.dialogRef.close(zoneAction);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }
}
