import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Room } from '../../models/room/room.model';
import { RoomService } from './room.service';
import { PagedData } from '../../models/PagedData';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';

@Injectable()
export class RoomsResolve implements Resolve<Observable<PagedData<Room>>> {
  constructor(private roomService: RoomService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<PagedData<Room>> | Promise<PagedData<Room>> | any {
    return this.roomService.getRooms(0, 999).catch(error => {
                console.log(`Retrieval error: ${error}`);
                return Observable.of(null);
            });
  }
}
