export class AccessZoneActionParameter {

  constructor(id: number = 0, code?: string, description?: string, accessZoneId?: number, value?: string, deviceTypeParameterId?: number) {

    this.id = id;
    this.code = code;
    this.description = description;
    this.accessZoneId = accessZoneId;
    this.value = value;
    this.deviceTypeParameterId = deviceTypeParameterId;
  }

  public id: number;
  public code: string;
  public description: string;
  public accessZoneId: number;
  public value: string;
  public deviceTypeParameterId: number;
}
