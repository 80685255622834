import { AfterViewInit, Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { TranslateService } from "@ngx-translate/core";
import { AlarmService } from '../../services/alarm/alarm.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AppTranslationService } from "../../services/app-translation.service";
import { Utilities } from '../../services/utilities';
import { Router } from '@angular/router';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { AlarmProfileService } from 'src/app/services/alarm/alarm-profile.service';
import { CameraService } from 'src/app/services/cameras/camera.service';
import { Alarm } from '../../models/alarm/alarm.model';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: "alarm-list",
  templateUrl: './alarm-list.component.html',
  styleUrls: ['./alarm-list.component.scss'],
  animations: [fadeInOut]
})
export class AlarmListComponent implements OnInit, OnDestroy {
  //@ViewChild('videoPlayer') video: any;
  //private videoPlayer: any;

  allAlarms: Alarm[];
  sourceAlarm: Alarm;
  editingAlarmName: { name: string };
  loadingIndicator: boolean;
  private hubConnection: HubConnection;



  constructor(
    private alertService: AlertService,
    private alarmService: AlarmService,
    private authService: AuthService,
    private translationService: AppTranslationService,
  ) {
    let self = this;

    let builder = new HubConnectionBuilder();

    this.hubConnection = builder
      .withUrl(location.origin + '/hubs/alarmstatus',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .withAutomaticReconnect()
      .build();
    this.hubConnection.on("alarmstatus", (message) => {
      self.updateStatus(message);
    });
  }

  ngOnInit() {

    this.loadData();


  }

  private connect() {
    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      this.hubConnection.start().then(() => {
        this.hubConnection.invoke("EmitAlarmStatus").catch(function (err) {
        });
      }).catch(function (err) {
      });
    } else if (this.hubConnection.state === HubConnectionState.Connected) {
      this.hubConnection.invoke("EmitAlarmStatus").catch(function (err) {
      });
    }
  }

  private updateStatus(message: any) {
    if (message && message.alarmId) {
      const alarmId = message.alarmId;
      if (alarmId) {
        const alarm = this.allAlarms.find(x => x.id === alarmId);
        if (alarm) {
          alarm.state = message.alarmState;
          alarm.activeProfile =  this.getActiveProfile(alarm, message.profileId);
        }
      }
      else {
        console.log("alarm not available");
      }
    }
  }

  public getActiveProfile(alarm: Alarm, profileId: number) : string {

    let activeProfile = "";

    if (alarm && alarm.profiles) {
      const profile = alarm.profiles.find(x => x.id === profileId);
      if (profile) {
        activeProfile = profile.name;
      }
    }

    return activeProfile;
  }

  private loadData() {
    var self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.alarmService.getAlarms(0, 999)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.allAlarms = results.items;

        self.connect();
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  ngOnDestroy(): void {
    this.hubConnection.stop();
  }
}
