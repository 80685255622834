import { Floor } from "../floor/floor.model";

export class Cabinet {

  constructor(id: number = 0, name?: string, description?: string, floor?: Floor) {

    this.id = id;
    this.name = name;
    this.description = description;
    this.floor = floor;
  }

  public id: number;
  public name: string;
  public description: string;
  public floor: Floor;

}
