import { AlarmProfile } from "./alarm-profile.model";
import { AccessKey } from "../accessKey/access-key.model";

export class AlarmAccessKey {

  constructor(id: number = 0, accessKeyId?: number, accessKey?: AccessKey, alarmId?: number, armedProfileId?: number, armedProfile?: AlarmProfile, disarmedProfileId?: number, disarmedProfile?: AlarmProfile) {

    this.id = id;
    this.accessKeyId = accessKeyId;
    this.accessKey = accessKey;
    this.alarmId = alarmId;
    this.armedProfileId = armedProfileId;
    this.armedProfile = armedProfile;
    this.disarmedProfileId = disarmedProfileId;
    this.disarmedProfile = disarmedProfile;
  }

  public id: number;

  public accessKeyId: number;

  public accessKey: AccessKey;

  public alarmId: number;

  public armedProfileId: number;

  public armedProfile: AlarmProfile;

  public disarmedProfileId: number;

  public disarmedProfile: AlarmProfile;
}
