import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Device } from 'src/app/models/device/device.model';
import { DeviceCategory } from 'src/app/models/device/device-category.model';
import { DeviceTypeState } from 'src/app/models/device/device-type-state.model';
import { DeviceService } from 'src/app/services/device/device.service';
import { RoomActionService } from 'src/app/services/room/room-action.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { HttpTransportType,  HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { state } from '@angular/animations';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'device-card',
  templateUrl: './device-card.component.html',
  styleUrls: ['./device-card.component.scss']
})
export class DeviceCardComponent implements OnInit, OnDestroy {
  @Input() device: Device;

  constructor(private deviceService: DeviceService, private translationService: AppTranslationService, private authService: AuthService) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }

  public executeFunction(deviceId: number, functionId: number) {
    this.deviceService.executeFunction(deviceId, functionId).subscribe();
  }

  public translateCategory(category: DeviceCategory): string {
    try {
      return this.translationService.getTranslation(`lookups.deviceCategory.${DeviceCategory[category]}`);
    } catch (e) {
      return category.toString();
    }
  }

  public translateDeviceFunctionName(functionName: string): string {
    try {
      return this.translationService.getTranslation(`lookups.DeviceFuntions.${functionName}`);
    } catch (e) {
      return functionName;
    }
  }

  public getState(device: Device): string {
    let state = '';
    try {
      state = this.translationService.getTranslation(`lookups.DeviceTypeState.${DeviceTypeState[device.state]}`);
    } catch (e) {
      return state;
    }

    if (device.category === DeviceCategory.Blinds || device.category === DeviceCategory.Blackout) {
      if (device.stateValue) {
        state += ` - ${device.stateValue}%`;
      }
    }

    return state;
  }

  public changeDeviceValue(event: any, id: number) {

    console.log(event);
    console.log(id);


  }

  public getIcon(category: DeviceCategory, stateValue: string, state: DeviceTypeState): string {
    switch (category) {
      case DeviceCategory.Light:
        {
          if (state === DeviceTypeState.ON) {
            return './assets/icons/luzon.svg';
          }

          return './assets/icons/luzoff.svg';
        }
      case DeviceCategory.RgbLight:
      {
        if (state === DeviceTypeState.ON) {
          return './assets/icons/luzon.svg';
        }

        return './assets/icons/luzoff.svg';
      }
      case DeviceCategory.AC:
        return './assets/icons/ac.svg';
      case DeviceCategory.Fan:
        return './assets/icons/ventoinha.svg';
      case DeviceCategory.Door:
        return './assets/icons/Asset07.svg';
      case DeviceCategory.Blinds:
        return this.getBlindsIcon(stateValue);
      case DeviceCategory.Blackout:
        return this.getBlackoutsIcon(stateValue);
      case DeviceCategory.PowerPlug:
        return './assets/icons/tomada.svg';
      case DeviceCategory.RainSensor:
        return './assets/icons/agua01.svg';
      case DeviceCategory.ContactSensor:
        return './assets/icons/sensor.svg';
      case DeviceCategory.Keypad:
        return './assets/icons/remote.svg';
      case DeviceCategory.EnergyMeter:
        return './assets/icons/energy.svg';
      case DeviceCategory.MovementSensor:
        return './assets/icons/proximidade.svg';
      case DeviceCategory.Latch:
        return './assets/icons/Fechadura.svg';
      case DeviceCategory.SmokeSensor:
        return './assets/icons/smoke_sensor.svg';
      case DeviceCategory.Switch:
        return './assets/icons/interruptor.svg';
      case DeviceCategory.TemperatureSensor:
        return './assets/icons/temp_sensor.svg';
      case DeviceCategory.TwilightSensor:
        return './assets/icons/light_sensor.svg';
      case DeviceCategory.Valve:
        return './assets/icons/waterpump.svg';
      case DeviceCategory.WaterPump:
        return './assets/icons/waterpump.svg';
      case DeviceCategory.Window:
        return './assets/icons/Asset07.svg';
      case DeviceCategory.WindSensor:
        return './assets/icons/vento.svg';
      case DeviceCategory.Siren:
        return './assets/icons/coluna.svg';
      case DeviceCategory.FloodSensor:
        return './assets/icons/agua02.svg';
      default:
        return './assets/icons/light_on.svg';
    }
  }

  public getBlindsIcon(stateValue: string): string {
    const value: number = +stateValue;

    if (value <= 0) {
      return './assets/icons/estore0.svg';
    }

    if (value > 0 && value <= 10) {
      return './assets/icons/estore10.svg';
    }

    if (value > 10 && value <= 20) {
      return './assets/icons/estore20.svg';
    }

    if (value > 20 && value <= 30) {
      return './assets/icons/estore30.svg';
    }

    if (value > 30 && value <= 40) {
      return './assets/icons/estore40.svg';
    }

    if (value > 40 && value <= 50) {
      return './assets/icons/estore50.svg';
    }

    if (value > 50 && value <= 60) {
      return './assets/icons/estore60.svg';
    }

    if (value > 60 && value <= 70) {
      return './assets/icons/estore70.svg';
    }

    if (value > 70 && value <= 80) {
      return './assets/icons/estore80.svg';
    }

    if (value > 80 && value <= 90) {
      return './assets/icons/estore90.svg';
    }

    if (value > 90) {
      return './assets/icons/estore100.svg';
    }

    return './assets/icons/estore0.svg';
  }

  public getBlackoutsIcon(stateValue: string): string {
    const value: number = +stateValue;

    if (value <= 0) {
      return './assets/icons/blackout05.svg';
    }

    if (value > 0 && value <= 20) {
      return './assets/icons/blackout04.svg';
    }

    if (value > 20 && value <= 40) {
      return './assets/icons/blackout03.svg';
    }

    if (value > 40 && value <= 60) {
      return './assets/icons/blackout02.svg';
    }

    if (value > 60 && value <= 80) {
      return './assets/icons/blackout01.svg';
    }

    if (value > 80) {
      return './assets/icons/blackout00.svg';
    }
  }

  public getColor(state: DeviceTypeState): string {
    switch (state) {
      case DeviceTypeState.ON:
      case DeviceTypeState.ARMED:
      case DeviceTypeState.CONNECTED:
      case DeviceTypeState.OPENED:
      case DeviceTypeState.OPENING:
      case DeviceTypeState.SYNCING:
        return 'LawnGreen';
      default:
        return 'LawnGreen';
    }
  }
}
