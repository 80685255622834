
export class DeviceFunction {
  
  constructor(id: number = 0, name?: string, description?: string, active?: boolean, code?: number) {

    this.id = id;
    this.name = name;
    this.description = description;
    this.active = active;
    this.code = code;
  }
  
  public id: number;
  public name: string;
  public description: string;
  public active: boolean;
  public code: number;
}
