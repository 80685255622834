import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { DeviceTypeEndpoint } from './device-type-endpoint.service';
import { DeviceType } from '../../models/device/device-type.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class DeviceTypeService {

  constructor(private router: Router, private http: HttpClient,
    private deviceTypeEndpoint: DeviceTypeEndpoint) {

  }
  
  getDeviceType(id?: number) {
    if (id) {
      return this.deviceTypeEndpoint.getDeviceTypeEndpoint<DeviceType>(id);
    }

    
  }

  getDeviceTypes(page: number = 0, pageSize: number = 999) {

    return this.deviceTypeEndpoint.getDeviceTypesEndpoint<PagedData<DeviceType>>(page, pageSize);
  }
  
  updateDeviceType(deviceType: DeviceType) {
    return this.deviceTypeEndpoint.getUpdateDeviceTypeEndpoint(deviceType, deviceType.id);
  }
  
  newDeviceType(deviceType: DeviceType) {
    return this.deviceTypeEndpoint.getNewDeviceTypeEndpoint<DeviceType>(deviceType);
  }
  
  deleteDeviceType(id): Observable<DeviceType> {

    return this.deviceTypeEndpoint.getDeleteDeviceTypeEndpoint(id);
  }
}
