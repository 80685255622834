import { Component, Input } from '@angular/core';
import { Utilities } from 'src/app/services/utilities';
import { RoomService } from 'src/app/services/room/room.service';
import { RoomAction } from 'src/app/models/room/room-action.model';
import { RoomActionService } from 'src/app/services/room/room-action.service';

@Component({
  selector: 'actions-card',
  templateUrl: './actions-card.component.html',
  styleUrls: ['./actions-card.component.scss']
})

export class ActionsCardComponent {

  @Input() roomId: number;

  allActions: RoomAction[];

  constructor(private roomService: RoomService, private roomActionService: RoomActionService) {
  }
  
  ngOnInit() {
    const self = this;
    this.roomService.getRoomActions(this.roomId)
      .subscribe(result => {
        self.allActions = result.items;
      });
  }

  public executeFunction(actionId: number) {
    this.roomActionService.executeAction(this.roomId, actionId).subscribe();
  }
}
