import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class DevicePortEndpoint extends EndpointFactory {

  private readonly _devicePortUrl: string = "/api/device/{deviceId}/ports";

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getDevicePortEndpoint<T>(deviceId: number, id: number): Observable<T> {
    let endpointUrl = `${this.devicePortUrlWithId(deviceId)}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDevicePortEndpoint(deviceId, id));
      }));
  }
  
  getDevicePortsEndpoint<T>(deviceId: number, page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this.devicePortUrlWithId(deviceId)}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDevicePortsEndpoint(page, pageSize));
      }));
  }
  
  private devicePortUrlWithId(deviceId: number): string
  {
    var urlWithId = this._devicePortUrl.replace("{deviceId}", deviceId.toString());

    return this.configurations.baseUrl + urlWithId;
  }
}

