import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { PagedData } from '../../models/PagedData';
import { AppNotificationEndpoint } from './app-notification-endpoint.service';
import { stagger } from '@angular/animations';
import { AppNotification } from '../../models/appNotification/app-notification.model';

@Injectable()
export class AppNotificationService {

  constructor(private router: Router, private http: HttpClient,
    private appNotificationEndpoint: AppNotificationEndpoint) {

  }
  
  getAppNotifications() {

    return this.appNotificationEndpoint.getAppNotificationsEndpoint<AppNotification[]>();
  }

  updateAppNotification(appNotification: AppNotification) {
    return this.appNotificationEndpoint.getUpdateAppNotificationEndpoint<AppNotification>(appNotification, appNotification.id);
  }

  markAsRead(id) {
    return this.appNotificationEndpoint.markAsRead(id);
  }

  markAllAsRead() {
    return this.appNotificationEndpoint.markAllAsRead();
  }

  delete(id) {

    return this.appNotificationEndpoint.delete(id);
  }

  deleteAll() {

    return this.appNotificationEndpoint.deleteAll();
  }
}
