import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Device } from '../../models/device/device.model';
import { PagedData } from '../../models/PagedData';
import { InputMappingEndpoint } from './input-mapping-endpoint.service';


@Injectable()
export class InputMappingService {

  constructor(private router: Router, private http: HttpClient,
    private inputMappingEndpoint: InputMappingEndpoint) {

  }
  
  getInputMapping(id?: number) {
    if (id) {
      return this.inputMappingEndpoint.getInputEndpoint<Device>(id);
    }

    
  }

  getInputMappings(page: number = 0, pageSize: number = 9999) {

    return this.inputMappingEndpoint.getInputsEndpoint<PagedData<Device>>(page, pageSize);
  }
}
