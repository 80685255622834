import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import {Label} from 'ng2-charts';
import {fadeInOut} from '../../services/animations';
import {ActivatedRoute} from '@angular/router';
import {EnergyZone} from '../../models/energy/energy-zone.model';
import {EnergyReadingService} from '../../services/energy/energy-reading.service';
import {EnergyReadingViewType} from '../../models/energy/energy-reading-view-type.model';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import {ProcessedEnergyReading} from "../../models/energy/processed-energy-reading.model";
import {DeviceCategory} from "../../models/device/device-category.model";
import {AppTranslationService} from "../../services/app-translation.service";
import {HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'energy-reading-bar-chart',
  templateUrl: './energy-reading-bar-card.component.html',
  styleUrls: ['./energy-reading-bar-card.component.scss'],
  animations: [fadeInOut]
})
export class EnergyReadingBarChartComponent implements OnInit, OnChanges {
  @Input() zone: EnergyZone;
  @Input() date: Date;
  @Input() viewType: EnergyReadingViewType;

  public allZones: EnergyZone[];

  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: { xAxes: [{
        gridLines: {
          display: false
        },
      }],
      yAxes: [{
      gridLines: {
        display: false
      },
        ticks: {

        },
        scaleLabel: {
          display: true,
          labelString: "kWh"
        }
      }] },
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        display: false
      }
    }
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [pluginDataLabels];

  private consLabel: string = "";
  private revLabel: string = "";

  // public chartColors: any[] = [
  //   { backgroundColor: '0EC5CB' },
  //   { backgroundColor: '#A3FCB7' }];

  public barChartData: ChartDataSets[] = [
  ];

  constructor(
    private energyReadingService: EnergyReadingService,
    private translationService: AppTranslationService,
    private route: ActivatedRoute) {
  }



  ngOnInit() {

    this.consLabel = this.translationService.getTranslation(`shared.Consumption`);
    this.revLabel = this.translationService.getTranslation(`shared.Production`);

    this.refreshData();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refreshData();
  }

  public onViewTypeChange(viewType: EnergyReadingViewType) {
    this.viewType = viewType;

    this.refreshData();

  }

  public refreshData() {

    this.energyReadingService.getEnergyReadings(this.viewType, this.zone.id, this.date)
      .subscribe(results => {

          this.barChartLabels = [];
          this.barChartData = [];

          if (results) {
            var hasReverse = this.hasReverseReadings(results);

            var consChar = { data: [], label: this.consLabel };
            var revChar = { data: [], label: this.revLabel };

            for (var s = 0; s < results.length; s++) {

              this.barChartLabels.push(this.getReadingLabel(results[s]));

              var kwh = results[s].wattHour == 0 ? 0 : results[s].wattHour / 1000;
              var revKwh = results[s].reverseWattHour == 0 ? 0 : results[s].reverseWattHour / 1000;
              consChar.data.push(kwh);
              revChar.data.push(revKwh);
            }

            this.barChartData.push(consChar);

            if (hasReverse) {
              this.barChartData.push(revChar);
            }
          }
      },
        error => {
        });
  }

  private hasReverseReadings(readings: ProcessedEnergyReading[]) : boolean{
    var reverses = readings.filter(x=>x.reverseWattHour != 0);

    if (reverses.length > 0){
      return true;
    }

    return false;
  }

  private getReadingLabel(reading: ProcessedEnergyReading) : string {
    switch (reading.readingType){
      case EnergyReadingViewType.Year:
        return reading.year.toString();
      case EnergyReadingViewType.Month:
        return reading.month.toString();
      case EnergyReadingViewType.Day:
        return reading.day.toString();
      case EnergyReadingViewType.Hour:
        return reading.hour.toString();
    }

    return "";
  }
}
