import { AutomationRuleState } from "./automation-rule-state.model";

export class AutomationRule {

  constructor(id: number = 0, name?: string, description?: string, icon?: string, startQuery?: string, stopQuery?: string, state?: AutomationRuleState) {

    this.id = id;
    this.name = name;
    this.description = description;
    this.startQuery = startQuery;
    this.stopQuery = stopQuery;
    this.state = state;
    this.icon= icon;
  }

  public id: number;
  public name: string;
  public description: string;
  public startQuery: string;
  public stopQuery: string;
  public state: AutomationRuleState;
  public icon: string;
}
