import { stat } from "fs";
import { DeviceTypeState } from "../device/device-type-state.model";
import { AlarmProfile } from "./alarm-profile.model";
import { AlarmType } from "./alarm-type.model";
import { AlarmZoneState } from "./alarm-zone-state.model";

export class Alarm {

  constructor(id: number = 0, name?: string, description?: string, alarmType?: AlarmType, profiles?: AlarmProfile[], state: AlarmZoneState = AlarmZoneState.Off, activeProfile: string = "") {

    this.profiles = profiles;
    this.id = id;
    this.name = name;
    this.description = description;
    this.alarmType = alarmType;
    this.state = state;
    this.activeProfile = activeProfile;
  }

  public profiles: AlarmProfile[];
  public id: number;
  public name: string;
  public description: string;
  public alarmType: AlarmType;
  public state: AlarmZoneState;
  public activeProfile: string;
}
