import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ValueOption } from "../../../../models/automationRule/value-option-model";
import { DeviceTypeAcceptedValues } from "../../../../models/device/device-type-accepted-values.model";
import { DeviceTypeValueType } from "../../../../models/device/device-type-value-type.model";
import { fadeInOut } from "../../../../services/animations";
import { AppTranslationService } from "../../../../services/app-translation.service";

@Component({
    selector: "device-value-input",
    templateUrl: './device-value-input.component.html',
    styleUrls: ['./device-value-input.component.scss'],
  animations: [fadeInOut],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeviceValueInputComponent),
      multi: true
    }
  ]
})
export class DeviceValueInputComponent implements OnInit, ControlValueAccessor, OnChanges  {

  @Input() valueId: number;
  @Input() valueOptions: ValueOption[];
  @Input() model: object;

  public valueType: DeviceTypeValueType;
  public enumProperties: DeviceTypeAcceptedValues[];
  public minVal: number;
  public maxVal: number;
    
    constructor(
        private translationService: AppTranslationService,
    ) {
      
  }

    ngOnInit(): void {
      this.setProperties();
      this.value = this.model;
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.valueId && changes.valueId.currentValue) {
      this.setProperties();
    }
  }

  onChange: any = () => {
  }
  onTouch: any = () => { }

  get value(): object {
    return this.model;
  }

  set value(val) {
    if (val !== undefined && this.model !== val) {
      this.model = val;
      this.onChange(val);
      this.onTouch(val);
    }

  }

  writeValue(value: any) {
    this.value = value
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn
  }


  private setProperties() {
    if (this.valueId != 0) {

      if (this.valueOptions) {
        var option = this.valueOptions.find(x => x.value === this.valueId);

        if (option) {
          this.valueType = option.valueType;

          switch (this.valueType)
          {
            case DeviceTypeValueType.Range:
            case DeviceTypeValueType.FloatRange:
              this.setRangeProperties(option);
              break;
            case DeviceTypeValueType.Enum:
              this.setEnumProperties(option);
              break;
            default:
              break;
          }

        } else {
          this.valueType = DeviceTypeValueType.Int;
        }
      }
    }
  }

  private setRangeProperties(option: ValueOption) {

    var min = option.acceptedValues.find(x => x.code == "Min");
    var max = option.acceptedValues.find(x => x.code == "Max");

    if (min) {
      this.minVal = +min.value;
    } else
    {
      this.minVal = 0;
    }

    if (max) {
      this.maxVal = +max.value;
    } else {
      this.maxVal = 0;
    }
  }

  private setEnumProperties(option: ValueOption) {
    this.enumProperties = option.acceptedValues;
  }
}
