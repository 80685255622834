import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AutomationRule } from '../../models/automationRule/automation-rule.model';
import { AutomationRuleService } from '../automationRule/automation-rule.service';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class AutomationRulesResolve implements Resolve<Observable<AutomationRule>> {
  constructor(private automationRuleService: AutomationRuleService, private router: Router) { }

    resolve(route: ActivatedRouteSnapshot): Observable<PagedData<AutomationRule>> | Promise<PagedData<AutomationRule>> | any {
    return this.automationRuleService.getAutomationRules(0, 999).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
