import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Room } from '../../models/room/room.model';
import { RoomService } from './room.service';
import { Device } from '../../models/device/device.model';
import { PagedData } from '../../models/PagedData';
import { RoomAction } from '../../models/room/room-action.model';

@Injectable()
export class RoomActionsResolve implements Resolve<Observable<PagedData<RoomAction>>> {
  constructor(private roomService: RoomService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<PagedData<RoomAction>> | Promise<PagedData<RoomAction>> | any {
    let id = +route.params['id'];
    return this.roomService.getRoomActions(id).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
