import { Component, EventEmitter, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TableDataSource } from 'angular4-material-table';
import { Subject } from 'rxjs';
import { DeviceFunction } from '../../../models/device/device-function.model';
import { DeviceParameter } from '../../../models/device/device-parameter.model';
import { Device } from '../../../models/device/device.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { DeviceService } from "../../../services/device/device.service";
import { FunctionValidatorService } from '../../../services/device/function-validator.service';
import { ParameterValidatorService } from '../../../services/device/parameter-validator.service';
import {AppTranslationService} from "../../../services/app-translation.service";



@Component({
  selector: 'admin-device-editor',
  templateUrl: './admin-device-settings-editor.component.html',
  styleUrls: ['./admin-device-settings-editor.component.scss']
})
export class AdminDeviceSettingsEditorComponent implements OnInit {

  private isSaving = false;
  private onDeviceSaved = new Subject<Device>();

  public device: Device = new Device();

  deviceSaved$ = this.onDeviceSaved.asObservable();


  public allParameters: DeviceParameter[];
  public parametersListChange = new EventEmitter<DeviceParameter[]>();
  parametersDisplayedColumns = ['code', 'description', 'value', 'valueType', 'actionsColumn'];
  parametersDataSource: TableDataSource<DeviceParameter>;

  public allFunctions: DeviceFunction[];
  public functionsListChange = new EventEmitter<DeviceFunction[]>();
  functionsDisplayedColumns = ['name', 'description', 'active', 'actionsColumn'];
  functionsDataSource: TableDataSource<DeviceFunction>;


  constructor(
    public dialogRef: MatDialogRef<AdminDeviceSettingsEditorComponent>,
    private alertService: AlertService,
    private deviceService: DeviceService,
    private functionsValidator: FunctionValidatorService,
    private parameterValidator: ParameterValidatorService,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { device: Device},
  ) {
    this.device = data.device;

    this.allParameters = this.device.parameters;
    this.parametersDataSource = new TableDataSource<DeviceParameter>(this.allParameters, DeviceParameter, this.parameterValidator);
    this.parametersDataSource.datasourceSubject.subscribe(parameters => {

      this.allParameters = parameters;
      this.parametersListChange.emit(parameters)
    });

    this.allFunctions = this.device.functions;
    this.functionsDataSource = new TableDataSource<DeviceFunction>(this.allFunctions, DeviceFunction, this.functionsValidator);
    this.functionsDataSource.datasourceSubject.subscribe(functions => {

      this.allFunctions = functions;
      this.functionsListChange.emit(functions)
    });

  }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {

    let savingLabel = this.translationService.getTranslation(`shared.SavingChanges`);
    this.disableSaveButton();
    this.alertService.startLoadingMessage(savingLabel);

    this.device.parameters = this.allParameters;
    this.device.functions = this.allFunctions;

    this.deviceService.updateDevice(this.device).subscribe(
      response => this.saveSuccessHelper(this.device),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(device?: Device) {
    this.alertService.stopLoadingMessage();

    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);

    this.onDeviceSaved.next(device);

    this.dialogRef.close(device);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }
}
