import { Component, Input, OnDestroy } from '@angular/core';
import { Utilities } from 'src/app/services/utilities';
import { RoomService } from 'src/app/services/room/room.service';
import { RoomAction } from 'src/app/models/room/room-action.model';
import { RoomActionService } from 'src/app/services/room/room-action.service';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'security-card',
  templateUrl: './security-card.component.html',
  styleUrls: ['./security-card.component.scss']
})

export class SecurityAlarmCardComponent implements OnDestroy {
  
  issuesCount: number = 0;


  private hubConnection: HubConnection;

  iconAlarm = require("../../../assets/images/icon_intrusion.svg");
  
  constructor(private authService: AuthService) {
  }
    ngOnDestroy(): void {
      this.hubConnection.stop();
    }
  
  ngOnInit() {

    this.buildHubConnection();
  }

  private buildHubConnection() {
    let self = this;

    let builder = new HubConnectionBuilder();

    this.hubConnection = builder
      .withUrl(location.origin + '/hubs/securityMonitoring',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.on("securityMonitoring", (message) => {
      this.issuesCount = message.numberOfIssues;
    });

    this.hubConnection.start().then(() => {
      this.hubConnection.invoke("securityMonitoring").catch(err => console.error(err.toString()));
    }).catch(function (err) {
    });
  }
}
