import { AfterViewInit, Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { DevicePort } from '../../../models/device/device-port.model';
import { Device } from '../../../models/device/device.model';
import { ModulePortMap } from '../../../models/module/module-port-map.model';
import { Module } from '../../../models/module/module.model';
import { PagedData } from '../../../models/PagedData';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { ModulePortMapService } from '../../../services/module/module-port-map.service';
import { TranslateService } from "@ngx-translate/core";
import { AppTranslationService } from "../../../services/app-translation.service";
import { Utilities } from '../../../services/utilities';
import { AdminModulePortMapEditorComponent } from './admin-module-port-map-editor.component';
import { Room } from '../../../models/room/room.model';
import { RoomService } from '../../../services/room/room.service';
import { DeviceService } from '../../../services/device/device.service';
import {DevicePortMapping} from "../../../models/device/device-port-mapping.model";

@Component({
  selector: "admin-module-port-map-inner-listing",
  templateUrl: './admin-module-port-map-inner-listing.component.html',
  styleUrls: ['./admin-module-port-map-inner-listing.component.scss'],
  animations: [fadeInOut]
})
export class AdminModulePortMapInnerListingComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['code', 'description', 'room', 'device', 'devicePort', 'wire', 'actions'];
  dataSource: MatTableDataSource<ModulePortMap>;
  sourcePortMap: ModulePortMap;

  name: string;
  module: Module;
  id: number;
  loadingIndicator: boolean;


  freePorts: DevicePortMapping[];
  devicePorts: DevicePort[];
  allDevices: Device[];
  devicesLoaded: boolean;
  roomsLoaded: boolean;
  allRooms: Room[];
  allModulePorts: ModulePortMap[];
  modulePortsChange = new EventEmitter<ModulePortMap[]>();
  allWireColors: string[] = [];

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private modulePortMapService: ModulePortMapService,
    private roomService: RoomService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.devicesLoaded = false;
    this.roomsLoaded = false;

    this.module = this.route.snapshot.data['module'];
    if (this.module) {
      this.name = this.module.name;
    }

    let pagedData = this.route.snapshot.data['modulePortMaps'];
    if (pagedData) {
      this.allModulePorts = pagedData.items;
      this.dataSource.data = this.allModulePorts;
      this.freePorts = pagedData.freePorts;
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data, filter) => this.filterData(data, filter);
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private loadData() {
    this.roomService.getRooms().subscribe(results => {
      this.allRooms = results.items;
        this.roomsLoaded = true;
    },
      error => {

        let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
        let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

        this.alertService.showStickyMessage(errorLabel, errorDetail,
          MessageSeverity.error, error);
      });

    this.deviceService.getDevices().subscribe(results => {
      this.allDevices = results.items;
      this.devicesLoaded = true;
    },
      error => {

        let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
        let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

        this.alertService.showStickyMessage(errorLabel, errorDetail,
          MessageSeverity.error, error);
      });

    this.updateFreePorts();
  }

  private updateFreePorts() {
    this.modulePortMapService.getFreePorts().subscribe(results => {
        this.freePorts = results;
      },
      error => {

        let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
        let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

        this.alertService.showStickyMessage(errorLabel, errorDetail,
          MessageSeverity.error, error);
      });
  }

  private filterData(portMap: ModulePortMap, filter: string): boolean {
    return Utilities.searchArray(filter, false, portMap.code, portMap.description, portMap.device != null ? portMap.device.description : null, portMap.devicePort != null ? portMap.devicePort.description : null);
  }

  public editPortMap(portMap: ModulePortMap) {
    this.sourcePortMap = portMap;

    if (!portMap) {
      portMap = new ModulePortMap();
    }

    let dialogRef = this.dialog.open(AdminModulePortMapEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { moduleId: this.module.id, modulePortMap: portMap, devices: this.allDevices, freePorts: this.freePorts, rooms: this.allRooms }
      });
    dialogRef.afterClosed().subscribe(device => {
      if (device) {
        this.updateMaps(device);
      }
    });
  }

  private updateMaps(modulePortMap: ModulePortMap) {
    if (this.sourcePortMap) {
      Object.assign(this.sourcePortMap, modulePortMap);
      this.sourcePortMap = null;
    }
    else {
      this.dataSource.data.unshift(modulePortMap);
    }

    this.updateFreePorts();

    this.refresh();
  }

  public cancel() {

    this.alertService.resetStickyMessage();

    this.router.navigate(['/admin/moduleportmap']);
  }
}
