import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { Room } from '../../../models/room/room.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { DeviceSchedule } from '../../../models/device/device-schedule.model';
import { Device } from '../../../models/device/device.model';
import { DeviceScheduleService } from '../../../services/device/device-schedule.service';
import { DeviceFunction } from '../../../models/device/device-function.model';
import {AppTranslationService} from "../../../services/app-translation.service";



@Component({
  selector: 'admin-device-schedule-editor',
  templateUrl: './admin-device-schedule-editor.component.html',
  styleUrls: ['./admin-device-schedule-editor.component.scss']
})
export class AdminDeviceScheduleEditorComponent implements OnInit {
  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewDeviceSchedule = false;
  private isSaving = false;
  private onDeviceScheduleSaved = new Subject<DeviceSchedule>();

  public isModuleVisible = false;
  public deviceSchedule: DeviceSchedule = new DeviceSchedule();
  public allRooms: Room[];
  public allDevices: Device[];
  public filteredDevices: Device[];
  public filteredFunctions: DeviceFunction[];

  deviceScheduleForm: FormGroup;
  deviceScheduleSaved$ = this.onDeviceScheduleSaved.asObservable();

  get device() {
    return this.deviceScheduleForm.get('device');
  }

  get room() {
    return this.deviceScheduleForm.get('room');
  }

  get time() {
    return this.deviceScheduleForm.get('time');
  }

  get monday() {
    return this.deviceScheduleForm.get('monday');
  }

  get tuesday() {
    return this.deviceScheduleForm.get('tuesday');
  }

  get wednesday() {
    return this.deviceScheduleForm.get('wednesday');
  }

  get thursday() {
    return this.deviceScheduleForm.get('thursday');
  }

  get friday() {
    return this.deviceScheduleForm.get('friday');
  }

  get saturday() {
    return this.deviceScheduleForm.get('saturday');
  }

  get sunday() {
    return this.deviceScheduleForm.get('sunday');
  }

  get functionId() {
    return this.deviceScheduleForm.get('functionId');
  }

  get isEnabled() {
    return this.deviceScheduleForm.get('isEnabled');
  }

  get functionValue() {
    return this.deviceScheduleForm.get('functionValue');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminDeviceScheduleEditorComponent>,
    private alertService: AlertService,
    private deviceScheduleService: DeviceScheduleService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { deviceSchedule: DeviceSchedule; rooms: Room[]; devices: Device[] },
  ) {
    this.deviceSchedule = data.deviceSchedule;
    this.allRooms = data.rooms;
    this.allDevices = data.devices;
    this.filteredFunctions = [];

    if (this.deviceSchedule.device) {
      this.filterDevices(this.deviceSchedule.device.roomId);
      this.filterFunctionsByDeviceId(this.deviceSchedule.device.id);
    }

    this.buildForm();
    this.resetForm();
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.deviceScheduleForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    const editedDeviceSchedule = this.getEditedDeviceSchedule();

    if (this.deviceSchedule.id == 0) {
      this.isNewDeviceSchedule = true;
      this.deviceScheduleService.newDeviceSchedule(editedDeviceSchedule).subscribe(
        deviceSchedule => this.saveSuccessHelper(deviceSchedule),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewDeviceSchedule = false;
      this.deviceScheduleService.updateDeviceSchedule(editedDeviceSchedule).subscribe(
        deviceSchedule => this.saveSuccessHelper(deviceSchedule),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedDeviceSchedule(): DeviceSchedule {
    const formModel = this.deviceScheduleForm.value;

    const time = formModel.time.split(':');

    return {
      id: this.deviceSchedule.id,
      deviceId: formModel.device,
      hour: time[0],
      minute: time[1],
      monday: formModel.monday,
      tuesday: formModel.tuesday,
      wednesday: formModel.wednesday,
      thursday: formModel.thursday,
      friday: formModel.friday,
      saturday: formModel.saturday,
      sunday: formModel.sunday,
      functionId: formModel.functionId,
      functionValue: formModel.functionValue,
      isEnabled: formModel.isEnabled,
      device: null,
      room: null,
      deviceFunction: null,
      functionCode: 0
    };
  }

  private saveSuccessHelper(deviceSchedule?: DeviceSchedule) {
    
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewDeviceSchedule) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onDeviceScheduleSaved.next(deviceSchedule);

    this.dialogRef.close(deviceSchedule);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.deviceScheduleForm = this.formBuilder.group({
      device: [0, Validators.required],
      time: ['', Validators.required],
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      functionId: ['', Validators.required],
      functionValue: [0, Validators.required],
      isEnabled: [true, Validators.required],
      room: [0, Validators.required],
    });
  }

  private resetForm(replace = false) {

    const hour = this.deviceSchedule.hour ? this.deviceSchedule.hour.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) : '00';
    const minute = this.deviceSchedule.minute ? this.deviceSchedule.minute.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) : '00';
    const time = `${hour}:${minute}`;

    this.deviceScheduleForm.reset({
      device: this.deviceSchedule.deviceId || 0,
      monday: this.deviceSchedule.monday || false,
      tuesday: this.deviceSchedule.tuesday || false,
      wednesday: this.deviceSchedule.wednesday || false,
      thursday: this.deviceSchedule.thursday || false,
      friday: this.deviceSchedule.friday || false,
      saturday: this.deviceSchedule.saturday || false,
      sunday: this.deviceSchedule.sunday || false,
      functionId: this.deviceSchedule.functionId || '',
      functionValue: this.deviceSchedule.functionValue || 0,
      isEnabled: this.deviceSchedule.isEnabled || false,
      room: this.deviceSchedule.device ? this.deviceSchedule.device.roomId : 0,
      time: time
    });
  }

  public filterDevicesForm(event) {
    this.filterDevices(event.value);
  }

  public filterDevices(roomId) {

    this.filteredDevices = [];

    if (roomId !== 0) {
      this.filteredDevices = this.allDevices.filter(x => x.roomId === roomId);
    }
  }

  public onDeviceChange(event) {
    this.filterFunctionsByDeviceId(event.value);
  }

  private filterFunctionsByDeviceId(deviceId: number) {
    const device = this.allDevices.find(x => x.id === deviceId);

    if (device != undefined) {
    this.filteredFunctions = [];

    this.filteredFunctions = device.functions;
  }
}
}
