import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class AccessZoneEndpoint extends EndpointFactory {

  private readonly _accessZoneUrl: string = "/api/accessZone";

  get accessZoneUrl() { return this.configurations.baseUrl + this._accessZoneUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  getAccessZoneEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.accessZoneUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAccessZoneEndpoint(id));
      }));
  }

  getAccessZonesEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._accessZoneUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAccessZonesEndpoint(page, pageSize));
      }));
  }


  getNewAccessZoneEndpoint<T>(accessZoneObject: any): Observable<T> {

    return this.http.post<T>(this.accessZoneUrl, JSON.stringify(accessZoneObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewAccessZoneEndpoint(accessZoneObject));
      }));
  }

  getUpdateAccessZoneEndpoint<T>(accessZoneObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.accessZoneUrl}/${id}` : `${this.accessZoneUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(accessZoneObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateAccessZoneEndpoint(accessZoneObject, id));
      }));
  }

  getDeleteAccessZoneEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.accessZoneUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteAccessZoneEndpoint(id));
      }));
  }

}
