import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import { TranslateService } from "@ngx-translate/core";
import { AlarmZone } from '../../models/alarm/alarm-zone.model';
import { AlarmZoneService } from '../../services/alarm/alarm-zone.service';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { AppTranslationService } from "../../services/app-translation.service";
import { Utilities } from '../../services/utilities';
import { ActivatedRoute, Router } from '@angular/router';
import { Device } from '../../models/device/device.model';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { AuthService } from '../../services/auth.service';

@Component({
  selector: "alarm",
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.scss'],
  animations: [fadeInOut]
})
export class AlarmComponent implements OnInit, OnDestroy {

  allAlarmZones: AlarmZone[];
  editingAlarmZoneName: { name: string };
  loadingIndicator: boolean;
  name: string;
  alarm: Device;
  private hubConnection: HubConnection;
  
  constructor(
    private alertService: AlertService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.buildOnChangeStateHubConnection();
  }
    
  ngOnInit() {
    let self = this;
    this.alarm = this.route.snapshot.data['alarm'];
    if (this.alarm) {
      this.name = this.alarm.name;
    }
    
    let pagedData = this.route.snapshot.data['alarmZones'];
    if (pagedData) {
      this.allAlarmZones = pagedData.items;

      this.connect();
    }
    
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }

  private buildOnChangeStateHubConnection() {
    const self = this;

    const builder = new HubConnectionBuilder();

    this.hubConnection = builder
      .withUrl(location.origin + '/hubs/alarmzonestatus',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .withAutomaticReconnect()
      .build();

    this.hubConnection.on("alarmzonestatus", (message) => {
      self.updateStatus(message);
    });
  }

  private connect() {
    const self = this;

    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      this.hubConnection.start().then(() => {
        this.hubConnection.invoke("EmitAlarmZoneStatus", self.alarm.id).catch();
      }).catch(err => console.error(err.toString()));
    } else if (this.hubConnection.state === HubConnectionState.Connected) {
      this.hubConnection.invoke("EmitAlarmZoneStatus", self.alarm.id).catch();
    }
  }

  private updateStatus(message: any) {
    if (message && message.alarmZoneId) {
      const alarmZoneId = message.alarmZoneId;
      if (alarmZoneId) {
        const zone = this.allAlarmZones.find(x => x.id === alarmZoneId);
        if (zone) {
          zone.state = message.state;
        }
      }
      else {
        console.log("alarm not available");
      }
    }
  }
  
  public cancel() {

    this.alertService.resetStickyMessage();

    this.router.navigate(['/alarms']);
  }

  ngOnDestroy(): void {
    this.hubConnection.stop();
  }
}
