import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlarmProfile } from 'src/app/models/alarm/alarm-profile.model';
import { AlarmZone } from '../../../models/alarm/alarm-zone.model';
import { AlertService } from '../../../services/alert.service';
import { Device } from 'src/app/models/device/device.model';
import { IoType } from 'src/app/models/io-type.model';
import { DeviceCategory } from 'src/app/models/device/device-category.model';
import { Room } from 'src/app/models/room/room.model';



@Component({
  selector: 'admin-alarm-profile-details',
  templateUrl: './admin-alarm-profile-settings.component.html',
  styleUrls: ['./admin-alarm-profile-settings.component.scss']
})
export class AdminAlarmProfileSettingsComponent implements OnInit {

  public allZones: AlarmZone[];
  public alarmProfile: AlarmProfile;
  public allDevices: Device[];
  public inputDevices: Device[];
  public outputDevices: Device[];
  public allRooms: Room[];

  constructor(
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.alertService.stopLoadingMessage();

    this.alarmProfile = this.route.snapshot.data['alarmProfile'];

    let zonesData = this.route.snapshot.data['alarmZones'];
    if (zonesData) {
      this.allZones = zonesData.items;
    }

    let devicesData = this.route.snapshot.data['devices'];
    if (devicesData) {
      this.allDevices = devicesData.items;
    }

    this.inputDevices = this.filterSensorDevices(this.allDevices);
    this.outputDevices = this.filterOutputs(this.allDevices);

    let roomsData = this.route.snapshot.data['rooms'];
    if (roomsData) {
      this.allRooms = roomsData.items;
    }
  }

  private filterSensorDevices(devices: Device[]): Device[] {
    return devices.filter(x => x.category == DeviceCategory.MovementSensor || x.category == DeviceCategory.SmokeSensor || x.category == DeviceCategory.FloodSensor);
  }

  private filterOutputs(devices: Device[]): Device[] {
    return devices.filter(x => x.ioType == IoType.Output || x.ioType == IoType.Both);
  }

  public cancel() {

    this.alertService.resetStickyMessage();

    this.router.navigate(['admin/alarm', this.alarmProfile.alarmId]);
  }
}
