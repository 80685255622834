import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { AlarmZone } from '../../models/alarm/alarm-zone.model';
import { PagedData } from '../../models/PagedData';
import { AlarmZoneEndpoint } from './alarm-zone-endpoint.service';


@Injectable()
export class AlarmZoneService {

  constructor(private router: Router, private http: HttpClient,
    private alarmZoneEndpoint: AlarmZoneEndpoint) {

  }
  
  getAlarmZone(id?: number) {
    if (id) {
      return this.alarmZoneEndpoint.getAlarmZoneEndpoint<AlarmZone>(id);
    }
  }
  
  getAlarmZones(alarmId: number, page: number = 0, pageSize: number = 9999) {

    return this.alarmZoneEndpoint.getAlarmZonesEndpoint<PagedData<AlarmZone>>(alarmId, page, pageSize);
  }

  updateAlarmZone(alarmZone: AlarmZone) {
    return this.alarmZoneEndpoint.getUpdateAlarmZoneEndpoint(alarmZone, alarmZone.id);
  }

  newAlarmZone(alarmZone: AlarmZone) {
    return this.alarmZoneEndpoint.getNewAlarmZoneEndpoint<AlarmZone>(alarmZone);
  }

  deleteAlarmZone(id): Observable<AlarmZone> {

    return this.alarmZoneEndpoint.getDeleteAlarmZoneEndpoint(id);
  }

  changeState(id: number, state: number) {
    return this.alarmZoneEndpoint.changeState(id, state).subscribe();
  }
}
