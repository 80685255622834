import { User } from "../user.model";
import {AccessZoneAction} from "./access-zone-action.model";

export class AccessZoneUser {

  constructor(id: number = 0, name?: string, userId?: string, accessZoneId?: number) {

    this.id = id;
    this.name = name;
    this.userId = userId;
    this.accessZoneId = accessZoneId;
  }

  public id: number;
  public userId: string;
  public accessZoneId: number;
  public name: string;
}
