import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { DevicePortMapService } from './device-port-map.service';
import { Observable } from 'rxjs';
import { DevicePortMap } from '../../models/device/device-port-map.model';
import { PagedData } from '../../models/PagedData';
import {DevicePortMapping} from "../../models/device/device-port-mapping.model";
import {ModulePortMapService} from "../module/module-port-map.service";

@Injectable()
export class DeviceFreePortsResolve implements Resolve<Observable<DevicePortMapping[]>> {
  constructor(private portService: ModulePortMapService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<DevicePortMapping[]> | Promise<DevicePortMapping[]> | any  {
    return this.portService.getFreePorts().catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
