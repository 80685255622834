import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlarmProfile } from '../../models/alarm/alarm-profile.model';
import { AlarmProfileService } from '../alarm/alarm-profile.service';

@Injectable()
export class AlarmProfileResolve implements Resolve<Observable<AlarmProfile>> {
  constructor(private alarmProfileService: AlarmProfileService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<AlarmProfile> | Promise<AlarmProfile> | any {
    let id = +route.params['profileId'];
    return this.alarmProfileService.getAlarmProfile(id).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
