import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { TranslateService } from "@ngx-translate/core";
import { DeviceSchedule } from '../../../models/device/device-schedule.model';
import { Device } from '../../../models/device/device.model';
import { Room } from '../../../models/room/room.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { AppTranslationService } from "../../../services/app-translation.service";
import { DeviceScheduleService } from '../../../services/device/device-schedule.service';
import { DeviceService } from '../../../services/device/device.service';
import { RoomService } from '../../../services/room/room.service';
import { Utilities } from '../../../services/utilities';
import { AdminDeviceScheduleEditorComponent } from './admin-device-schedule-editor.component';

@Component({
  selector: "admin-device-schedule-list",
  templateUrl: './admin-device-schedule-list.component.html',
  styleUrls: ['./admin-device-schedule-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminDeviceScheduleListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  //displayedColumns = ['device', 'days', 'time', 'function', 'isEnabled', 'actions'];
  columnDefinitions = [
    { def: 'device', showMobile: true },
    { def: 'days', showMobile: false },
    { def: 'time', showMobile: false },
    { def: 'function', showMobile: true },
    { def: 'isEnabled', showMobile: false },
    { def: 'actions', showMobile: true },
  ];

  datasource: null;
  dataSource: MatTableDataSource<DeviceSchedule>;
  sourceDeviceSchedule: DeviceSchedule;
  editingDeviceScheduleName: { name: string };
  loadingIndicator: boolean;
  isMobile: boolean = false;

  private allDevices: Device[];
  private allRooms: Room[];

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private deviceScheduleService: DeviceScheduleService,
    private deviceService: DeviceService,
    private roomService: RoomService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    translate: TranslateService
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateDeviceSchedules(deviceSchedule: DeviceSchedule) {
    this.loadData();
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.deviceService.getDevices(0, 999)
      .subscribe(devices => {
        this.alertService.stopLoadingMessage();

        this.allDevices = devices.items;

        this.roomService.getRooms(0, 999)
          .subscribe(rooms => {
            this.alertService.stopLoadingMessage();

            this.allRooms = rooms.items;

            this.deviceScheduleService.getDeviceSchedules(this.paginator.pageIndex, this.paginator.pageSize)
              .subscribe(results => {
                this.alertService.stopLoadingMessage();
                this.loadingIndicator = false;

                this.dataSource.data = results;
              },
                error => {
                  this.handleError(error);
                });
          },
            error => {
              this.handleError(error);
            });
      },
      error => {
        this.handleError(error);
      });
  }

  public editDeviceSchedule(deviceSchedule?: DeviceSchedule) {
    this.sourceDeviceSchedule = deviceSchedule;

    if (!deviceSchedule) {
      deviceSchedule = new DeviceSchedule();
    }

    let dialogRef = this.dialog.open(AdminDeviceScheduleEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { deviceSchedule: deviceSchedule, rooms: [...this.allRooms], devices: [...this.allDevices]}
      });
    dialogRef.afterClosed().subscribe(deviceSchedule => {
      if (deviceSchedule) {
        this.updateDeviceSchedules(deviceSchedule);
      }
    });
  }

  private confirmDelete(deviceSchedule: DeviceSchedule) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.deviceScheduleService.deleteDeviceSchedule(deviceSchedule.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== deviceSchedule)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }

  public getTime(deviceSchedule: DeviceSchedule) {
    const hour = deviceSchedule.hour ? deviceSchedule.hour.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) : '00';
    const minute = deviceSchedule.minute ? deviceSchedule.minute.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }) : '00';
    const time = `${hour}:${minute}`;

    return time;
  }

  sortData(sort: Sort) {
      const data = this.dataSource.data;
      let sortedData = this.dataSource.data
      if (!sort.active || sort.direction === '') {
        sortedData = data;
        return;
      }

      sortedData = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch (sort.active) {
          case 'device': return this.compare(a.device ? a.device.name : '', b.room ? b.device.name : '', isAsc);
          default: return 0;
        }
      });

      this.dataSource.data = sortedData;
    }

  public compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  onResize(event) {
    var width = event.target.innerWidth;
    if (width < 768) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  getDisplayedColumns(): string[] {
    return this.columnDefinitions
      .filter(cd => !this.isMobile || cd.showMobile)
      .map(cd => cd.def);
  }

  public handleError(error) {
    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;

    let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

    this.alertService.showStickyMessage(errorLabel, errorDetail,
      MessageSeverity.error, error);
  }

  public getFunctionTranslation(code: number) {

  }
}
