import { Component, Input, OnDestroy } from '@angular/core';
import { Utilities } from 'src/app/services/utilities';
import { RoomService } from 'src/app/services/room/room.service';
import { RoomAction } from 'src/app/models/room/room-action.model';
import { RoomActionService } from 'src/app/services/room/room-action.service';
import { Device } from 'src/app/models/device/device.model';
import { MatTableDataSource } from '@angular/material';
import { HttpTransportType,  HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { DeviceCategory } from 'src/app/models/device/device-category.model';
import { DeviceTypeState } from 'src/app/models/device/device-type-state.model';
import { DeviceService } from 'src/app/services/device/device.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'devices-card',
  templateUrl: './devices-card.component.html',
  styleUrls: ['./devices-card.component.scss']
})

export class DevicesCardComponent implements OnDestroy {

  @Input() roomId: number;

  allActions: RoomAction[];
  allRoomDevices: Device[];
  devicesDataSource: MatTableDataSource<Device>;
  displayedColumns = ['name', 'category', 'actions'];
  private allDevicesHubConnection: HubConnection;
  private onChangeStatueHubConnection: HubConnection;

  constructor(private roomService: RoomService, private deviceService: DeviceService, private roomActionService: RoomActionService, private translationService: AppTranslationService, private authService: AuthService) {
    this.devicesDataSource = new MatTableDataSource();
  }

  ngOnInit() {
    const self = this;

    this.roomService.getRoomDevices(this.roomId)
      .subscribe(result => {

        self.allRoomDevices = result.items;
        self.devicesDataSource.data = this.allRoomDevices;
      });

    this.allDeviceStatusHubConnection();
    this.buildOnChangeStateHubConnection();
  }

  public executeFunction(deviceId: number, functionId: number) {
    this.deviceService.executeFunction(deviceId, functionId).subscribe();
  }

  private allDeviceStatusHubConnection() {
    let self = this;

    let builder = new HubConnectionBuilder();

    this.allDevicesHubConnection = builder
      .withUrl(location.origin + '/hubs/alldevicestatus',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    this.allDevicesHubConnection.on("alldevicestatus", (message) => {
      self.updateAllDeviceStatus(message);
    });

    this.allDevicesHubConnection.start().then(() => {
      this.allDevicesHubConnection.invoke("emitStatus", self.roomId).catch();
    }).catch(function (err) {
    });
  }

  private updateAllDeviceStatus(message: any) {
    if (message && message.devicesSt) {
      for (let entry of message.devicesSt) {
        var deviceId = entry.id;
        if (deviceId) {
          var device = this.allRoomDevices.find(x => x.id == deviceId);
          if (device) {
            device.state = entry.st;
          }
        }
        else {
          console.log("DeviceId not available");
        }
      }
    }
  }

  private updateDeviceStatus(message: any) {
    if (message && message.deviceId) {
      var deviceId = message.deviceId;
      if (deviceId) {
        var device = this.allRoomDevices.find(x => x.id == deviceId);
        if (device) {
          device.state = message.state;
        }
      }
      else {
        console.log("DeviceId not available");
      }
    }
  }

  private buildOnChangeStateHubConnection() {
    let self = this;

    let builder = new HubConnectionBuilder();

    this.onChangeStatueHubConnection = builder
      .withUrl(location.origin + '/hubs/devicestatus',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    this.onChangeStatueHubConnection.on("status", (message) => {
      self.updateDeviceStatus(message);
    });

    this.onChangeStatueHubConnection.start().catch(function (err) {
    });
  }

  public translateCategory(category: DeviceCategory): string {
    try {
      return this.translationService.getTranslation(`lookups.deviceCategory.${DeviceCategory[category]}`);
    } catch (e) {
      return category.toString();
    }
  }

  public getState(state: DeviceTypeState): string {
    try {
      return this.translationService.getTranslation(`lookups.DeviceTypeState.${DeviceTypeState[state]}`);
    } catch (e) {
      return state.toString();
    }
  }

  public changeDeviceValue(event: any, id: number) {

    console.log(event);
    console.log(id);


  }

  public getIcon(category: DeviceCategory, state: DeviceTypeState): string {
    switch (category) {
      case DeviceCategory.Light:
        {
          if (state === DeviceTypeState.ON) {
            return 'lightbulb-on';
          }

          return 'lightbulb';
        }
      case DeviceCategory.RgbLight:
      {
        if (state === DeviceTypeState.ON) {
          return 'lightbulb-on';
        }

        return 'lightbulb';
      }
      case DeviceCategory.AC:
        return 'air-filter';
      case DeviceCategory.Fan:
        return 'fan';
      case DeviceCategory.Door:
        return 'door';
      case DeviceCategory.Blinds:
        return 'blinds';
      case DeviceCategory.PowerPlug:
        return 'power-plug';
      case DeviceCategory.Alarm:
        return 'shield-home';
      case DeviceCategory.RainSensor:
        return 'weather-rainy';
      case DeviceCategory.ContactSensor:
        return 'contactless-payment';
      case DeviceCategory.Keypad:
        return 'keyboard-outline';
      case DeviceCategory.EnergyMeter:
        return 'flash';
      case DeviceCategory.MovementSensor:
        return 'motion-sensor';
      case DeviceCategory.Latch:
        return 'shield-home';
      case DeviceCategory.SmokeSensor:
        return 'smoke-detector';
      case DeviceCategory.Switch:
        return 'light-switch';
      case DeviceCategory.TemperatureSensor:
        return 'thermometer-lines';
      case DeviceCategory.TwilightSensor:
        return 'brightness-7';
      case DeviceCategory.Valve:
        return 'pipe-disconnected';
      case DeviceCategory.WaterPump:
        return 'water-pump';
      case DeviceCategory.Window:
        return 'window-closed';
      case DeviceCategory.WindSensor:
        return 'air-purifier';
      case DeviceCategory.Siren:
        return 'bullhorn-outline';
      case DeviceCategory.FloodSensor:
        return 'pipe-leak';
      default:
        return 'devices';
    }
  }

  public getColor(state: DeviceTypeState): string {
    switch (state) {
      case DeviceTypeState.ON:
      case DeviceTypeState.ARMED:
      case DeviceTypeState.CONNECTED:
      case DeviceTypeState.OPENED:
      case DeviceTypeState.OPENING:
      case DeviceTypeState.SYNCING:
        return 'LawnGreen';
      default:
        return 'LawnGreen';
    }
  }

  public isIconActive(state: DeviceTypeState): boolean {
    switch (state) {
      case DeviceTypeState.ON:
      case DeviceTypeState.ARMED:
      case DeviceTypeState.CONNECTED:
      case DeviceTypeState.OPENED:
      case DeviceTypeState.OPENING:
      case DeviceTypeState.SYNCING:
        return true;
      default:
        return false;
    }
  }

  ngOnDestroy(): void {
    this.allDevicesHubConnection.stop();
    this.onChangeStatueHubConnection.stop();
  }
}
