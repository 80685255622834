import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ValidatorService } from 'angular4-material-table';

@Injectable()
export class FunctionValidatorService implements ValidatorService {
  getRowValidator(): FormGroup {
    return new FormGroup({
      'id': new FormControl(),
      'name': new FormControl(),
      'description': new FormControl(),
      'active': new FormControl()
    });
  }
}
