import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { DevicePortMapEndpoint } from './device-port-map-endpoint.service';
import { DevicePortMap } from '../../models/device/device-port-map.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class DevicePortMapService {

  constructor(private router: Router, private http: HttpClient,
    private devicePortMapEndpoint: DevicePortMapEndpoint) {

  }

  getDevicePortMap(id?: number) {
    if (id) {
      return this.devicePortMapEndpoint.getDevicePortMapEndpoint<DevicePortMap>(id);
    }


  }

  getDevicePortMaps(page: number = 0, pageSize: number = 9999) {

    return this.devicePortMapEndpoint.getDevicePortMapsEndpoint<PagedData<DevicePortMap>>(page, pageSize);
  }

  updateDevicePortMap(devicePortMap: DevicePortMap) {
    return this.devicePortMapEndpoint.getUpdateDevicePortMapEndpoint(devicePortMap, devicePortMap.id);
  }

  newDevicePortMap(devicePortMap: DevicePortMap) {
    return this.devicePortMapEndpoint.getNewDevicePortMapEndpoint<DevicePortMap>(devicePortMap);
  }

  deleteDevicePortMap(id): Observable<DevicePortMap> {

    return this.devicePortMapEndpoint.getDeleteDevicePortMapEndpoint(id);
  }
}
