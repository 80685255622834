import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';

import { AppTranslationService } from './app-translation.service';
import { LocalStoreManager } from './local-store-manager.service';
import { DBkeys } from './db-keys';
import { Utilities } from './utilities';
import { environment } from '../../environments/environment';

type UserConfiguration = {
  language: string,
  homeUrl: string,
  themeId: number,
  showDashboardStatistics: boolean,
  showDashboardNotifications: boolean,
  showDashboardTodo: boolean,
  showDashboardBanner: boolean
};

@Injectable()
export class ConfigurationService {
  public static readonly appVersion: string = "2.1.0";

  public baseUrl = environment.baseUrl || Utilities.baseUrl();
  public tokenUrl = environment.tokenUrl || environment.baseUrl || Utilities.baseUrl();
  public loginUrl = environment.loginUrl;
  public fallbackBaseUrl = "http://microluminapp-pro..com";

  //***Specify default configurations here***
  public static readonly defaultLanguage: string = "en";
  public static readonly defaultHomeUrl: string = "/";
  public static readonly defaultThemeId: number = 1;
  public static readonly defaultShowDashboardStatistics: boolean = true;
  public static readonly defaultShowDashboardNotifications: boolean = true;
  public static readonly defaultShowDashboardTodo: boolean = false;
  public static readonly defaultShowDashboardBanner: boolean = true;
  //***End of defaults***

  private _language: string = null;
  private _homeUrl: string = null;
  private _themeId: number = null;
  private _showDashboardStatistics: boolean = null;
  private _showDashboardNotifications: boolean = null;
  private _showDashboardTodo: boolean = null;
  private _showDashboardBanner: boolean = null;
  private onConfigurationImported: Subject<boolean> = new Subject<boolean>();

  private _mainModuleActive: boolean = null;
  private _energyModuleActive: boolean = null;
  private _alarmModuleActive: boolean = null;
  private _accessControlModuleActive: boolean = null;

  configurationImported$ = this.onConfigurationImported.asObservable();

  constructor(
    private localStorage: LocalStoreManager,
    private translationService: AppTranslationService,
  ) {
    this.loadLocalChanges();
  }

  private loadLocalChanges() {
    if (this.localStorage.exists(DBkeys.LANGUAGE)) {
      this._language = this.localStorage.getDataObject<string>(DBkeys.LANGUAGE);
      this.translationService.changeLanguage(this._language);
    }
    else {
      this.resetLanguage();
    }

    if (this.localStorage.exists(DBkeys.HOME_URL)) {
      this._homeUrl = this.localStorage.getDataObject<string>(DBkeys.HOME_URL);
    }

    if (this.localStorage.exists(DBkeys.THEME_ID)) {
      this._themeId = this.localStorage.getDataObject<number>(DBkeys.THEME_ID);
    }

    if (this.localStorage.exists(DBkeys.SHOW_DASHBOARD_STATISTICS)) {
      this._showDashboardStatistics = this.localStorage.getDataObject<boolean>(DBkeys.SHOW_DASHBOARD_STATISTICS);
    }

    if (this.localStorage.exists(DBkeys.SHOW_DASHBOARD_NOTIFICATIONS)) {
      this._showDashboardNotifications = this.localStorage.getDataObject<boolean>(DBkeys.SHOW_DASHBOARD_NOTIFICATIONS);
    }

    if (this.localStorage.exists(DBkeys.SHOW_DASHBOARD_TODO)) {
      this._showDashboardTodo = this.localStorage.getDataObject<boolean>(DBkeys.SHOW_DASHBOARD_TODO);
    }

    if (this.localStorage.exists(DBkeys.SHOW_DASHBOARD_BANNER)) {
      this._showDashboardBanner = this.localStorage.getDataObject<boolean>(DBkeys.SHOW_DASHBOARD_BANNER);
    }

    if (this.localStorage.exists(DBkeys.MAIN_MODULE_ACTIVE)) {
      this._mainModuleActive = this.localStorage.getDataObject<boolean>(DBkeys.MAIN_MODULE_ACTIVE);
    }

    if (this.localStorage.exists(DBkeys.ENERGY_MODULE_ACTIVE)) {
      this._energyModuleActive = this.localStorage.getDataObject<boolean>(DBkeys.ENERGY_MODULE_ACTIVE);
    }

    if (this.localStorage.exists(DBkeys.ALARM_MODULE_ACTIVE)) {
      this._alarmModuleActive = this.localStorage.getDataObject<boolean>(DBkeys.ALARM_MODULE_ACTIVE);
    }

    if (this.localStorage.exists(DBkeys.ACCESS_CONTROL_MODULE_ACTIVE)) {
      this._accessControlModuleActive = this.localStorage.getDataObject<boolean>(DBkeys.ACCESS_CONTROL_MODULE_ACTIVE);
    }
  }

  private saveToLocalStore(data: any, key: string) {
    setTimeout(() => this.localStorage.savePermanentData(data, key));
  }

  public import(jsonValue: string) {
    this.clearLocalChanges();

    if (jsonValue) {


      let importValue: UserConfiguration = Utilities.JsonTryParse(jsonValue);

      if (importValue.language != null) {
        this.language = importValue.language;
      }

      if (importValue.homeUrl != null) {
        this.homeUrl = importValue.homeUrl;
      }

      if (importValue.themeId != null) {
        this.themeId = importValue.themeId;
      }

      if (importValue.showDashboardStatistics != null) {
        this.showDashboardStatistics = importValue.showDashboardStatistics;
      }

      if (importValue.showDashboardNotifications != null) {
        this.showDashboardNotifications = importValue.showDashboardNotifications;
      }

      if (importValue.showDashboardTodo != null) {
        this.showDashboardTodo = importValue.showDashboardTodo;
      }

      if (importValue.showDashboardBanner != null) {
        this.showDashboardBanner = importValue.showDashboardBanner;
      }
    }

    this.onConfigurationImported.next();
  }

  public export(changesOnly = true): string {
    let exportValue: UserConfiguration =
      {
        language: changesOnly ? this._language : this.language,
        homeUrl: changesOnly ? this._homeUrl : this.homeUrl,
        themeId: changesOnly ? this._themeId : this.themeId,
        showDashboardStatistics: changesOnly ? this._showDashboardStatistics : this.showDashboardStatistics,
        showDashboardNotifications: changesOnly ? this._showDashboardNotifications : this.showDashboardNotifications,
        showDashboardTodo: changesOnly ? this._showDashboardTodo : this.showDashboardTodo,
        showDashboardBanner: changesOnly ? this._showDashboardBanner : this.showDashboardBanner
      };

    return JSON.stringify(exportValue);
  }

  public clearLocalChanges() {
    this._language = null;
    this._homeUrl = null;
    this._themeId = null;
    this._showDashboardStatistics = null;
    this._showDashboardNotifications = null;
    this._showDashboardTodo = null;
    this._showDashboardBanner = null;

    this.localStorage.deleteData(DBkeys.LANGUAGE);
    this.localStorage.deleteData(DBkeys.HOME_URL);
    this.localStorage.deleteData(DBkeys.THEME_ID);
    this.localStorage.deleteData(DBkeys.SHOW_DASHBOARD_STATISTICS);
    this.localStorage.deleteData(DBkeys.SHOW_DASHBOARD_NOTIFICATIONS);
    this.localStorage.deleteData(DBkeys.SHOW_DASHBOARD_TODO);
    this.localStorage.deleteData(DBkeys.SHOW_DASHBOARD_BANNER);

    this.localStorage.deleteData(DBkeys.MAIN_MODULE_ACTIVE);
    this.localStorage.deleteData(DBkeys.ENERGY_MODULE_ACTIVE);
    this.localStorage.deleteData(DBkeys.ACCESS_CONTROL_MODULE_ACTIVE);
    this.localStorage.deleteData(DBkeys.ALARM_MODULE_ACTIVE);

    this.resetLanguage();
  }

  private resetLanguage() {
    let language = this.translationService.useBrowserLanguage();

    if (language) {
      this._language = language;
    }
    else {
      this._language = this.translationService.changeLanguage()
    }
  }

  set language(value: string) {
    this._language = value;
    this.saveToLocalStore(value, DBkeys.LANGUAGE);
    this.translationService.changeLanguage(value);
  }
  get language() {
    return this._language || ConfigurationService.defaultLanguage;
  }

  set homeUrl(value: string) {
    this._homeUrl = value;
    this.saveToLocalStore(value, DBkeys.HOME_URL);
  }
  get homeUrl() {
    return this._homeUrl || ConfigurationService.defaultHomeUrl;
  }

  set themeId(value: number) {
    this._themeId = value;
    this.saveToLocalStore(value, DBkeys.THEME_ID);
  }
  get themeId() {
    return this._themeId || ConfigurationService.defaultThemeId;
  }

  set mainModuleActive(value: boolean) {
    this._mainModuleActive = value;
    this.saveToLocalStore(value, DBkeys.MAIN_MODULE_ACTIVE);
  }

  get mainModuleActive() {
    return this._mainModuleActive;
  }

  set energyModuleActive(value: boolean) {
    this._energyModuleActive = value;
    this.saveToLocalStore(value, DBkeys.ENERGY_MODULE_ACTIVE);
  }
  get energyModuleActive() {
    return this._energyModuleActive;
  }

  set alarmModuleActive(value: boolean) {
    this._alarmModuleActive = value;
    this.saveToLocalStore(value, DBkeys.ALARM_MODULE_ACTIVE);
  }
  get alarmModuleActive() {
    return this._alarmModuleActive;
  }

  set accessControlModuleActive(value: boolean) {
    this._accessControlModuleActive = value;
    this.saveToLocalStore(value, DBkeys.ACCESS_CONTROL_MODULE_ACTIVE);
  }
  get accessControlModuleActive() {
    return this._accessControlModuleActive;
  }

  set showDashboardStatistics(value: boolean) {
    this._showDashboardStatistics = value;
    this.saveToLocalStore(value, DBkeys.SHOW_DASHBOARD_STATISTICS);
  }
  get showDashboardStatistics() {
    return this._showDashboardStatistics != null ? this._showDashboardStatistics : ConfigurationService.defaultShowDashboardStatistics;
  }

  set showDashboardNotifications(value: boolean) {
    this._showDashboardNotifications = value;
    this.saveToLocalStore(value, DBkeys.SHOW_DASHBOARD_NOTIFICATIONS);
  }
  get showDashboardNotifications() {
    return this._showDashboardNotifications != null ? this._showDashboardNotifications : ConfigurationService.defaultShowDashboardNotifications;
  }

  set showDashboardTodo(value: boolean) {
    this._showDashboardTodo = value;
    this.saveToLocalStore(value, DBkeys.SHOW_DASHBOARD_TODO);
  }
  get showDashboardTodo() {
    return this._showDashboardTodo != null ? this._showDashboardTodo : ConfigurationService.defaultShowDashboardTodo;
  }

  set showDashboardBanner(value: boolean) {
    this._showDashboardBanner = value;
    this.saveToLocalStore(value, DBkeys.SHOW_DASHBOARD_BANNER);
  }
  get showDashboardBanner() {
    return this._showDashboardBanner != null ? this._showDashboardBanner : ConfigurationService.defaultShowDashboardBanner;
  }
}
