import {EnergyReadingViewType} from "./energy-reading-view-type.model";

export class DetailedEnergyReading {

  constructor(
    zoneId?: number,
    dayPhase1Wh?: number,
    dayPhase2Wh?: number,
    dayPhase3Wh?: number,
    dayPhase1ReverseWh?: number,
    dayPhase2ReverseWh?: number,
    dayPhase3ReverseWh?: number,
    monthPhase1Wh?: number,
    monthPhase2Wh?: number,
    monthPhase3Wh?: number,
    monthPhase1ReverseWh?: number,
    monthPhase2ReverseWh?: number,
    monthPhase3ReverseWh?: number) {

    this.zoneId = zoneId;
    this.dayPhase1Wh = dayPhase1Wh;
    this.dayPhase2Wh = dayPhase2Wh;
    this.dayPhase3Wh = dayPhase3Wh;
    this.dayPhase1ReverseWh = dayPhase1ReverseWh;
    this.dayPhase2ReverseWh = dayPhase2ReverseWh;
    this.dayPhase3ReverseWh = dayPhase3ReverseWh;
    this.monthPhase1Wh = monthPhase1Wh;
    this.monthPhase2Wh = monthPhase2Wh;
    this.monthPhase3Wh = monthPhase3Wh;
    this.monthPhase1ReverseWh = monthPhase1ReverseWh;
    this.monthPhase2ReverseWh = monthPhase2ReverseWh;
    this.monthPhase3ReverseWh = monthPhase3ReverseWh;
  }

  public zoneId: number;
  public dayPhase1Wh: number;
  public dayPhase2Wh: number;
  public dayPhase3Wh: number;
  public dayPhase1ReverseWh: number;
  public dayPhase2ReverseWh: number;
  public dayPhase3ReverseWh: number;
  public monthPhase1Wh: number;
  public monthPhase2Wh: number;
  public monthPhase3Wh: number;
  public monthPhase1ReverseWh: number;
  public monthPhase2ReverseWh: number;
  public monthPhase3ReverseWh: number;
}
