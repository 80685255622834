import { Component, OnDestroy } from '@angular/core';
import { HttpTransportType,  HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'doors-card',
  templateUrl: './doors-card.component.html',
  styleUrls: ['./doors-card.component.scss']
})

export class DoorsCardComponent implements OnDestroy  {
    
  opened: number = 0;
  total: number = 0;


  private hubConnection: HubConnection;

  iconDoor = require("../../../assets/images/OpenDoor.svg");

  constructor(private authService: AuthService) {
  }
  
  ngOnInit() {

    this.buildHubConnection();
  }

  ngOnDestroy(): void {
    this.hubConnection.stop();
  }

  private buildHubConnection() {
    let self = this;

    let builder = new HubConnectionBuilder();

    this.hubConnection = builder
      .withUrl(location.origin + '/hubs/doorsMonitoring',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.on("doorsMonitoring", (message) => {
      this.opened = message.numberOfIssues;
      this.total = message.numberOfDevices;
    });

    this.hubConnection.start().then(() => {
      this.hubConnection.invoke("doorsMonitoring").catch(err => console.error(err.toString()));
    }).catch(function (err) {
    });
  }
}
