import {DeviceCategory} from "../device/device-category.model";
import {Room} from "../room/room.model";
import {Device} from "../device/device.model";

export class DeviceGroup {

  constructor(id: number = 0, name?: string, category?: DeviceCategory, roomId?: number, room?: Room, devices?: Array<Device>) {

    this.id = id;
    this.name = name;
    this.category = category;
    this.room = room;
    this.roomId = roomId;
    this.devices = devices;
  }

  public id: number;
  public name: string;
  public category: DeviceCategory;
  public roomId: number;
  public room: Room;
  public devices: Array<Device>;
}
