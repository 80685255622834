import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MasterDevice } from '../../models/master-device.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { MasterDeviceService } from "../../services/masterDevice/master-device.service";
import { fadeInOut } from '../../services/animations';
import { Utilities } from '../../services/utilities';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadDownloadService } from '../../upload-download.service';
import {AppTranslationService} from "../../services/app-translation.service";

@Component({
  selector: 'master-device-editor',
  templateUrl: './master-device-editor.component.html',
  styleUrls: ['./master-device-editor.component.scss'],
  animations: [fadeInOut]
})
export class MasterDeviceEditorComponent implements OnInit {
  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewMaster = false;
  private isSaving: boolean;

  private onMasterSaved = new Subject<MasterDevice>();


  private master: MasterDevice = new MasterDevice();
  public isUpdating: boolean;
  masterForm: FormGroup;
  masterSaved$ = this.onMasterSaved.asObservable();

  ngOnInit(): void {
    let self = this;
  }



  get description() {
    return this.masterForm.get('description');
  }

  constructor(
    private alertService: AlertService,
    private masterService: MasterDeviceService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private service: UploadDownloadService,
  private translationService: AppTranslationService
  ) {


    this.master = this.route.snapshot.data['masterDevice'];
    this.buildForm();
    this.resetForm();
  }

  ngOnChanges() {
    this.resetForm();
  }

  private loadData() {
    var self = this;
    this.alertService.startLoadingMessage();

    this.masterService.getMasterDevice()
      .subscribe(result => {
        this.alertService.stopLoadingMessage();
        this.master = result;
        this.resetForm();
      },
      error => {
        this.alertService.stopLoadingMessage();

        let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
        let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  public updateFormState(message: any) {
    if (message.success) {
      let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
      let successLabel = this.translationService.getTranslation(`shared.Success`);

      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }
    else {
      let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
      let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

      this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, message.message);
    }

    this.isUpdating = false;
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.masterForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    const editedMaster = this.getEditedMaster();

    this.isNewMaster = false;
    this.masterService.updateMasterDevice(editedMaster).subscribe(
      response => this.saveSuccessHelper(editedMaster),
      error => this.saveFailedHelper(error));
  }

  private getEditedMaster(): MasterDevice {
    const formModel = this.masterForm.value;

    return {
      id: this.master.id,
      description: formModel.description,
    };
  }

  private saveSuccessHelper(master?: MasterDevice) {
    this.alertService.stopLoadingMessage();

    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);

    this.onMasterSaved.next(master);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();
  }

  public updateConfigurationData() {

    let updatingLabel = this.translationService.getTranslation(`shared.Updating`);

    this.isUpdating = true;
    this.disableSaveButton();
    this.alertService.startLoadingMessage(updatingLabel);

    this.masterService.updateConfigurationData(this.master.id).subscribe(
      response => {
        this.disableSaveButton();
        this.alertService.stopLoadingMessage();
      },
      error => this.saveFailedHelper(error));
  }

  public updateFullConfigurationData() {

    let updatingLabel = this.translationService.getTranslation(`shared.Updating`);

    this.isUpdating = true;
    this.disableSaveButton();
    this.alertService.startLoadingMessage(updatingLabel);

    this.masterService.updateFullConfigurationData(this.master.id).subscribe(
      response => {
        this.disableSaveButton();
      },
      error => this.saveFailedHelper(error));
  }

  private buildForm() {
    this.masterForm = this.formBuilder.group({
      description: ''
    });
  }

  private resetForm(replace = false) {
    this.masterForm.reset({
      description: this.master.description || ''
    });
  }
}
