import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class CameraEndpoint extends EndpointFactory {

  private readonly _cameraUrl: string = "/api/camera";

  get cameraUrl() { return this.configurations.baseUrl + this._cameraUrl; }

  private readonly _cameraStreamUrl: string = "/api/camerastream";

  get cameraStreamUrl() { return this.configurations.baseUrl + this._cameraStreamUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  getCameraEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.cameraStreamUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCameraEndpoint(id));
      }));
  }

  getCamerasEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this.cameraUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCamerasEndpoint(page, pageSize));
      }));
  }

  getCameraStreamEndpoint(id: number): Observable<Object> {
    let endpointUrl = `${this.cameraStreamUrl}/${id}`;

    this.http.get(endpointUrl, this.getRequestHeaders()).subscribe(response => {
      console.log(response);
      return;
    });

    return this.http.get(endpointUrl, this.getRequestHeaders())
      .pipe(
      catchError(error => {
        return this.handleError(error, () => this.getCameraStreamEndpoint(id));
      }));
  }
}
