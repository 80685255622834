import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { DeviceCategory } from 'src/app/models/device/device-category.model';
import { DeviceTypeState } from 'src/app/models/device/device-type-state.model';
import { Device } from 'src/app/models/device/device.model';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { DeviceService } from 'src/app/services/device/device.service';
import { RoomService } from 'src/app/services/room/room.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.scss']
})

export class DeviceListComponent implements OnInit, OnDestroy {

  @Input() roomId: number;

  allDevices: Device[];

  displayedColumns = ['name', 'category', 'actions'];
  private allDevicesHubConnection: HubConnection;

  constructor(private roomService: RoomService, private deviceService: DeviceService, private translationService: AppTranslationService, private authService: AuthService) {

    this.allDeviceStatusHubConnection();
  }

  ngOnInit() {
    const self = this;

    this.roomService.getRoomDevices(this.roomId)
      .subscribe(result => {

        self.allDevices = result.items.sort(function (a, b) {
          if (a.category > b.category) {
            return 1;
          }
          if (a.category < b.category) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        this.connect();
      },
        err => console.log(err));
  }

  public executeFunction(deviceId: number, functionId: number) {
    this.deviceService.executeFunction(deviceId, functionId).subscribe();
  }

  private allDeviceStatusHubConnection() {
    let self = this;

    let builder = new HubConnectionBuilder();

    this.allDevicesHubConnection = builder
      .withUrl(location.origin + '/hubs/devicestatus',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken,
        })
      .withAutomaticReconnect([0, 2000, 10000, 30000, 60000, 90000])
      .configureLogging(LogLevel.None)
      .build();

    this.allDevicesHubConnection.on("alldevicestatus", (message) => {

      console.log(message);

      self.updateAllDeviceStatus(message);
    });

    this.allDevicesHubConnection.on("status", (message) => {
      self.updateDeviceStatus(message);
    });

    this.allDevicesHubConnection.onreconnected((connectionId?: string) => {
      let message = `allDevicesHubConnection->onreconnected->${connectionId}`;
      console.log(message);
    });

    this.allDevicesHubConnection.onreconnecting((error?: Error) => {
      let message = `allDevicesHubConnection->onreconnecting->${error}`;
      console.log(message);
    });

    this.allDevicesHubConnection.onclose((error?: Error) => {
      let message = `allDevicesHubConnection->onclose->${error}`;
      console.log(message);
    });

    this.allDevicesHubConnection.off("alldevicestatus", (message) => {
      console.log(`allDevicesHubConnection->off-> alldevicestatus->${message}`);
    });

    this.allDevicesHubConnection.off("status", (message) => {
      console.log(`allDevicesHubConnection->off-> alldevicestatus->${message}`);
    });
  }

  private connect() {
    let self = this;

    if (this.allDevicesHubConnection.state === HubConnectionState.Disconnected) {

      this.allDevicesHubConnection.start().then(() => {

        this.allDevicesHubConnection.invoke("emitAllStatus", self.roomId).catch();
      }).catch();

    } else if (this.allDevicesHubConnection.state === HubConnectionState.Connected) {
      this.allDevicesHubConnection.invoke("EmitAlarmStatus").catch();
    }
  }


  private updateAllDeviceStatus(message: any) {
    if (message && message.devicesSt) {
      for (let entry of message.devicesSt) {
        const deviceId = entry.id;
        if (deviceId && this.allDevices && this.allDevices.length > 0) {
          const device = this.allDevices.find(x => x.id == deviceId);
          if (device) {
            device.state = entry.st;
            device.stateValue = entry.vl;
          }
        }
      }
    }
  }

  private updateDeviceStatus(message: any) {
    if (message && message.deviceId) {
      const deviceId = message.deviceId;
      if (deviceId && this.allDevices && this.allDevices.length > 0) {
        const device = this.allDevices.find(x => x.id === deviceId);
        if (device) {
          device.state = message.state;
          device.stateValue = message.value;
        }
      }
    }
  }

  public translateCategory(category: DeviceCategory): string {
    try {
      return this.translationService.getTranslation(`lookups.deviceCategory.${DeviceCategory[category]}`);
    } catch (e) {
      return category.toString();
    }
  }

  public translateState(state: DeviceTypeState): string {
    try {
      return this.translationService.getTranslation(`lookups.DeviceTypeState.${DeviceTypeState[state]}`);
    } catch (e) {
      return state.toString();
    }
  }

  public changeDeviceValue(event: any, id: number) {

    console.log(event);
    console.log(id);


  }

  public getIcon(category: DeviceCategory, state: DeviceTypeState): string {
    switch (category) {
      case DeviceCategory.Light:
        {
          if (state === DeviceTypeState.ON) {
            return 'lightbulb-on';
          }

          return 'lightbulb';
        }
      case DeviceCategory.RgbLight:
      {
        if (state === DeviceTypeState.ON) {
          return 'lightbulb-on';
        }

        return 'lightbulb';
      }
      case DeviceCategory.AC:
        return 'air-filter';
      case DeviceCategory.Fan:
        return 'fan';
      case DeviceCategory.Door:
        return 'door';
      case DeviceCategory.Blinds:
        return 'blinds';
      case DeviceCategory.PowerPlug:
        return 'power-plug';
      case DeviceCategory.Alarm:
        return 'shield-home';
      case DeviceCategory.RainSensor:
        return 'weather-rainy';
      case DeviceCategory.ContactSensor:
        return 'contactless-payment';
      case DeviceCategory.Keypad:
        return 'keyboard-outline';
      case DeviceCategory.EnergyMeter:
        return 'flash';
      case DeviceCategory.MovementSensor:
        return 'motion-sensor';
      case DeviceCategory.Latch:
        return 'shield-home';
      case DeviceCategory.SmokeSensor:
        return 'smoke-detector';
      case DeviceCategory.Switch:
        return 'light-switch';
      case DeviceCategory.TemperatureSensor:
        return 'thermometer-lines';
      case DeviceCategory.TwilightSensor:
        return 'brightness-7';
      case DeviceCategory.Valve:
        return 'pipe-disconnected';
      case DeviceCategory.WaterPump:
        return 'water-pump';
      case DeviceCategory.Window:
        return 'window-closed';
      case DeviceCategory.WindSensor:
        return 'air-purifier';
      case DeviceCategory.Siren:
        return 'bullhorn-outline';
      case DeviceCategory.FloodSensor:
        return 'pipe-leak';
      default:
        return 'devices';
    }
  }

  public getColor(state: DeviceTypeState): string {
    switch (state) {
      case DeviceTypeState.ON:
      case DeviceTypeState.ARMED:
      case DeviceTypeState.CONNECTED:
      case DeviceTypeState.OPENED:
      case DeviceTypeState.OPENING:
      case DeviceTypeState.SYNCING:
        return 'LawnGreen';
      default:
        return 'LawnGreen';
    }
  }

  public isIconActive(state: DeviceTypeState): boolean {
    switch (state) {
      case DeviceTypeState.ON:
      case DeviceTypeState.ARMED:
      case DeviceTypeState.CONNECTED:
      case DeviceTypeState.OPENED:
      case DeviceTypeState.OPENING:
      case DeviceTypeState.SYNCING:
        return true;
      default:
        return false;
    }
  }

  ngOnDestroy(): void {
    this.allDevicesHubConnection.stop();
  }
}
