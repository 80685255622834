import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ValidatorService } from 'angular4-material-table';

@Injectable()
export class RoomActionMapValidatorService implements ValidatorService {
  getRowValidator(): FormGroup {
    return new FormGroup({
      'roomId': new FormControl(),
      'outputDeviceId': new FormControl('', Validators.required),
      'outputFunctionId': new FormControl('', Validators.required)
    });
  }
}
