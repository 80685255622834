import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { AlarmProfile } from 'src/app/models/alarm/alarm-profile.model';
import { AlarmZone } from '../../../models/alarm/alarm-zone.model';
import { Device } from '../../../models/device/device.model';
import { AlarmZoneService } from '../../../services/alarm/alarm-zone.service';
import { AlertService } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { AppTranslationService } from "../../../services/app-translation.service";
import { AlarmAccessKey } from '../../../models/alarm/alarm-access-key.model';
import { AccessKey } from '../../../models/accessKey/access-key.model';

@Component({
  selector: "admin-alarm-settings",
  templateUrl: './admin-alarm-settings.component.html',
  styleUrls: ['./admin-alarm-settings.component.scss'],
  animations: [fadeInOut]
})
export class AdminAlarmSettingsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'description', 'actions'];
  datasource: null;
  allAlarmZones: AlarmZone[];
  allAlarmProfiles: AlarmProfile[];
  allAlarmAccessKeys: AlarmAccessKey[];
  AllAccessKeys: AccessKey[];
  editingAlarmZoneName: { name: string };
  loadingIndicator: boolean;
  name: string;
  alarm: Device;

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private alarmZoneService: AlarmZoneService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.alarm = this.route.snapshot.data['alarm'];
    if (this.alarm) {
      this.name = this.alarm.name;
    }

    let zonesData = this.route.snapshot.data['alarmZones'];
    if (zonesData) {
      this.allAlarmZones = zonesData.items;
    }

    let profilesData = this.route.snapshot.data['alarmProfiles'];
    if (profilesData) {
      this.allAlarmProfiles = profilesData.items;
    }

    let accessKeysData = this.route.snapshot.data['accessKeys'];
    if (accessKeysData ) {
      this.AllAccessKeys = accessKeysData.items;
    }

    let alarmAccessKeysData = this.route.snapshot.data['alarmAccessKeys'];
    if (alarmAccessKeysData) {
      this.allAlarmAccessKeys = alarmAccessKeysData.items;
    }

    this.alertService.stopLoadingMessage();
    this.loadingIndicator = false;
  }
}
