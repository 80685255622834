import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class DevicePortMapEndpoint extends EndpointFactory {

  private readonly _devicePortMapUrl: string = "/api/deviceportmaps";

  get devicePortMapUrl() { return this.configurations.baseUrl + this._devicePortMapUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getDevicePortMapEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this._devicePortMapUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDevicePortMapEndpoint( id));
      }));
  }
  
  getDevicePortMapsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._devicePortMapUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDevicePortMapsEndpoint(page, pageSize));
      }));
  }


  getNewDevicePortMapEndpoint<T>(devicePortMapObject: any): Observable<T> {

    return this.http.post<T>(this._devicePortMapUrl, JSON.stringify(devicePortMapObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewDevicePortMapEndpoint(devicePortMapObject));
      }));
  }

  getUpdateDevicePortMapEndpoint<T>(devicePortMapObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this._devicePortMapUrl}/${id}` : `${this.devicePortMapUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(devicePortMapObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateDevicePortMapEndpoint(devicePortMapObject, id));
      }));
  }

  getDeleteDevicePortMapEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this._devicePortMapUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteDevicePortMapEndpoint(id));
      }));
  }  
}
