import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { RoomService } from 'src/app/services/room/room.service';
import { Utilities } from 'src/app/services/utilities';
import { Room } from '../../models/room/room.model';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { DeviceService } from '../../services/device/device.service';
import { RoomActionService } from '../../services/room/room-action.service';
import {AppTranslationService} from "../../services/app-translation.service";


@Component({
  selector: 'room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit, AfterViewInit {


  room: Room;
  name: string;

  constructor(
    private alertService: AlertService,
    private roomActionService: RoomActionService,
    private deviceService: DeviceService,
    private roomService: RoomService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private translationService: AppTranslationService,
  ) {
  }

  ngOnInit() {

    let self = this;
    this.alertService.stopLoadingMessage();

    this.room = this.route.snapshot.data['room'];
    if (this.room) {
      this.name = self.room.name;
    }
    else {
      this.room = new Room();
    }
  }

  ngAfterViewInit() {
  }



  public executeFunction(deviceId:number, functionId: number) {

    let executeSucessLabel = this.translationService.getTranslation(`shared.ExecuteSuccess`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    this.deviceService.executeFunction(deviceId, functionId).subscribe(
      response => this.alertService.showMessage(successLabel, executeSucessLabel, MessageSeverity.success),
      error => this.saveFailedHelper(error));
  }

  public runAction(actionId: number) {

    let executeSucessLabel = this.translationService.getTranslation(`shared.ExecuteSuccess`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    this.roomActionService.executeAction(1, actionId).subscribe(
      response => this.alertService.showMessage(successLabel, executeSucessLabel, MessageSeverity.success),
      error => this.saveFailedHelper(error));
  }


  private saveFailedHelper(error: any) {
    let executeErrorLabel = this.translationService.getTranslation(`shared.ExecuteErrorLabel`);
    let executeErrorDetail = this.translationService.getTranslation(`shared.ExecuteErrorDetail`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(executeErrorLabel, executeErrorDetail, MessageSeverity.error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

}
