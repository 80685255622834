import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { AlarmZone } from '../../../models/alarm/alarm-zone.model';
import { ZoneAction } from '../../../models/alarm/zone-action.model';
import { DeviceFunction } from '../../../models/device/device-function.model';
import { Device } from '../../../models/device/device.model';
import { Room } from '../../../models/room/room.model';
import { ZoneActionService } from '../../../services/alarm/zone-action.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { ZoneActionType } from '../../../models/alarm/zone-action-type.model';
import { AlarmProfile } from 'src/app/models/alarm/alarm-profile.model';
import {AppTranslationService} from "../../../services/app-translation.service";


@Component({
  selector: "admin-alarm-zone-action-editor",
  templateUrl: './admin-alarm-zone-action-editor.component.html',
  styleUrls: ['./admin-alarm-zone-action-editor.component.scss'],
  animations: [fadeInOut]
})
export class AdminAlarmZoneActionEditorComponent implements OnInit {

  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewAction = false;
  private isSaving: boolean;
  private onZoneActionSaved = new Subject<ZoneAction>();

  public currentZoneAction: ZoneAction = new ZoneAction();
  public alarmZone: AlarmZone = new AlarmZone();
  public alarmProfile: AlarmProfile = new AlarmProfile();
  public allRooms: Room[];
  public allDevices: Device[];
  public filteredDevices: Device[];
  public deviceFunctions: DeviceFunction[];
  public allTypes: string[];
  public actionType: ZoneActionType;

  formGroup: FormGroup;
  zoneActionSaved$ = this.onZoneActionSaved.asObservable();

  get room() {
    return this.formGroup.get('room');
  }

  get device() {
    return this.formGroup.get('device');
  }

  get waitTime() {
    return this.formGroup.get('waitTime');
  }

  get deviceFunction() {
    return this.formGroup.get('deviceFunction');
  }

  get type() {
    return this.formGroup.get('type');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminAlarmZoneActionEditorComponent>,
    private alertService: AlertService,
    private zoneActionService: ZoneActionService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { alarmZone: AlarmZone, alarmProfile: AlarmProfile, zoneAction: ZoneAction, rooms: Room[], devices: Device[], actionType: ZoneActionType },
  ) {
    this.currentZoneAction = data.zoneAction;
    this.alarmZone = data.alarmZone;
    this.alarmProfile = data.alarmProfile;
    this.allRooms = data.rooms;
    this.allDevices = data.devices;
    this.actionType = data.actionType;

    this.allTypes= Object.keys(ZoneActionType).filter((item) => {
      return isNaN(Number(item));
    });

    if (this.currentZoneAction && this.currentZoneAction.device && this.currentZoneAction.device) {
      this.deviceFunctions = this.currentZoneAction.device.functions;
    }

    if (this.currentZoneAction && this.currentZoneAction.device && this.currentZoneAction.device.roomId && this.currentZoneAction.device.roomId != 0) {
      this.filteredDevices = this.allDevices.filter(x => x.room && x.room.id == this.currentZoneAction.device.roomId);
    }
    else {
      this.filteredDevices = this.allDevices;
    }

    this.buildForm();
    this.resetForm();
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.formGroup.valid) {
      this.alertService.showValidationError();
      return;
    }

    let savingLabel = this.translationService.getTranslation(`shared.SavingChanges`);

    this.disableSaveButton();
    this.alertService.startLoadingMessage(savingLabel);

    const editedZoneAction = this.getEditedAction();

    if (this.currentZoneAction.id == 0) {
      this.isNewAction = true;
      this.zoneActionService.newZoneAction(editedZoneAction).subscribe(
        device => this.saveSuccessHelper(device),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewAction = false;
      this.zoneActionService.updateZoneAction(editedZoneAction).subscribe(
        response => this.saveSuccessHelper(editedZoneAction),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedAction(): ZoneAction {
    const formModel = this.formGroup.value;

    return {
      id: this.currentZoneAction.id,
      waitTime: formModel.waitTime,
      alarmZoneId: this.alarmZone.id,
      alarmProfileId: this.alarmProfile.id,
      device: this.allDevices.find(x => x.id == formModel.device),
      deviceFunction: this.deviceFunctions.find(x => x.id == formModel.deviceFunction),
      type: this.actionType,
      parameters: this.currentZoneAction.parameters
    };
  }

  private saveSuccessHelper(zoneAction?: ZoneAction) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewAction) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onZoneActionSaved.next(zoneAction);

    this.dialogRef.close(zoneAction);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      waitTime: [0, Validators.required],
      room: ['', Validators.required],
      type: [''],
      device: ['', Validators.required],
      deviceFunction: ['', Validators.required]
    });
  }

  public onRoomChange(id: any) {

    this.device.setValue(0);
    this.deviceFunction.setValue(0);

    this.filteredDevices = this.allDevices.filter(x => x.room && x.room.id == id.value);
  }

  public onInputDeviceChange(id: any) {

    this.deviceFunction.setValue(0);

    let currentDevice = this.allDevices.find(x => x.id == id.value);
    this.deviceFunctions = currentDevice.functions;
  }

  private resetForm(replace = false) {
    this.formGroup.reset({
      waitTime: this.currentZoneAction.waitTime,
      room: this.currentZoneAction.device && this.currentZoneAction.device.room ? this.currentZoneAction.device.room.id : '',
      device: this.currentZoneAction.device ? this.currentZoneAction.device.id : '',
      deviceFunction: this.currentZoneAction.deviceFunction ? this.currentZoneAction.deviceFunction.id : ''
    });
  }
}

