import { Floor } from "../floor/floor.model";
import { RoomAction } from "./room-action.model";

export class Room {
  
  constructor(id: number = 0, name?: string, description?: string, floor?: Floor,iconImage?: string, showOnDash?: boolean, tabOrder?: number, actions?: RoomAction[]) {

    this.id = id;
    this.name = name;
    this.description = description;
    this.tabOrder = tabOrder;
    this.showOnDash = showOnDash;
    this.iconImage = iconImage ? iconImage : './assets/icons/Asset07.svg';
    this.floor = floor;
    this.actions = actions;
  }
  
  public id: number;
  public name: string;
  public description: string;
  public tabOrder: number;
  public floor: Floor;
  public iconImage: string;
  public showOnDash: boolean;
  public actions: RoomAction[];  
}
