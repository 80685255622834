import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { AutomationRuleAction } from '../../models/automationRule/automation-rule-action.model';
import { PagedData } from '../../models/PagedData';
import { AutomationRuleActionEndpoint } from './automation-rule-action-endpoint.service';
import { AutomationRuleActionType } from 'src/app/models/automationRule/automation-rule-action-type.model';


@Injectable()
export class AutomationRuleActionService {

  constructor(private router: Router, private http: HttpClient,
    private automationRuleActionMapEndpoint: AutomationRuleActionEndpoint) {

  }
  
  getAutomationRuleAction(id?: number) {
    if (id) {
      return this.automationRuleActionMapEndpoint.getAutomationRuleActionEndpoint<AutomationRuleAction>(id);
    }
  }
  
  getAutomationRuleActions(ruleId: number, actionType: AutomationRuleActionType, page: number = 0, pageSize: number = 9999) {

    return this.automationRuleActionMapEndpoint.getAutomationRuleActionsEndpoint<PagedData<AutomationRuleAction>>(ruleId, actionType, page, pageSize);
  }

  updateAutomationRuleAction(automationRuleActionMap: AutomationRuleAction) {
    return this.automationRuleActionMapEndpoint.getUpdateAutomationRuleActionEndpoint(automationRuleActionMap, automationRuleActionMap.id);
  }

  newAutomationRuleAction(automationRuleActionMap: AutomationRuleAction) {
    return this.automationRuleActionMapEndpoint.getNewAutomationRuleActionEndpoint<AutomationRuleAction>(automationRuleActionMap);
  }

  deleteAutomationRuleAction(id): Observable<AutomationRuleAction> {

    return this.automationRuleActionMapEndpoint.getDeleteAutomationRuleActionEndpoint(id);
  }
}
