export class AlarmProfile {
  
  constructor(id: number = 0, name?: string, code?: string, description?: string, iconPath?: string, alarmId?: number, waitTime?: number) {

    this.id = id;
    this.name = name;
    this.code = code;
    this.description = description;
    this.alarmId = alarmId;
    this.iconPath = iconPath;
    this.waitTime = waitTime;
  }
  
  public id: number;
  public name: string;
  public code: string;
  public description: string;
  public alarmId: number;
  public iconPath: string;
  public waitTime: number;
}
