import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { DeviceEndpoint } from './device-endpoint.service';
import { Device } from '../../models/device/device.model';
import { PagedData } from '../../models/PagedData';
import { DeviceCategory } from '../../models/device/device-category.model';

@Injectable()
export class DeviceService {

  constructor(private router: Router, private http: HttpClient,
    private deviceEndpoint: DeviceEndpoint) {

  }
  
  getDevice(id?: number) {
    if (id) {
      return this.deviceEndpoint.getDeviceEndpoint<Device>(id);
    }
  }

  getDevicesByCategory(category:number, page: number = 0, pageSize: number = 9999) {

    return this.deviceEndpoint.getDevicesByCategoryEndpoint<PagedData<Device>>(category, page, pageSize);
  }

  getDevices(page: number = 0, pageSize: number = 9999) {

    return this.deviceEndpoint.getDevicesEndpoint<PagedData<Device>>(page, pageSize);
  }
  
  updateDevice(device: Device) {
      return this.deviceEndpoint.getUpdateDeviceEndpoint<Device>(device, device.id);
  }
  
  newDevice(device: Device) {
    return this.deviceEndpoint.getNewDeviceEndpoint<Device>(device);
  }
  
  deleteDevice(id): Observable<Device> {

    return this.deviceEndpoint.getDeleteDeviceEndpoint(id);
  }


  executeFunction(id: number, functionId: number): Observable<any> {
    return this.deviceEndpoint.getExecuteFunctionEndpoint(id, functionId);
  }
}
