import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog, PageEvent } from '@angular/material';

import { fadeInOut } from '../../../services/animations';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from "../../../services/app-translation.service";
import { User } from '../../../models/user.model';
import { AccountService } from '../../../services/account.service';
import {AccessZone} from "../../../models/accessZone/access-zone.model";
import {AccessZoneService} from "../../../services/accessZone/access-zone.service";
import {AdminAccessZoneEditorComponent} from "./admin-access-zone-editor.component";
import {Alarm} from "../../../models/alarm/alarm.model";
import {Router} from "@angular/router";

@Component({
  selector: "admin-access-zone-list",
  templateUrl: './admin-access-zone-list.component.html',
  styleUrls: ['./admin-access-zone-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminAccessZoneListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'description', 'actions'];
  datasource: null;
  dataSource: MatTableDataSource<AccessZone>;
  sourceAccessZone: AccessZone;
  editingAccessZoneName: { name: string };
  loadingIndicator: boolean;

  allUsers: User[] =[];

  constructor(
    private alertService: AlertService,
    private accountService: AccountService,
    private translationService: AppTranslationService,
    private accessZoneService: AccessZoneService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
    this.loadUsers();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateAccessZones(accessZone: AccessZone) {
    if (this.sourceAccessZone) {
      Object.assign(this.sourceAccessZone, accessZone);
      this.sourceAccessZone = null;
    }

    this.loadData();
    this.refresh();
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

    this.accessZoneService.getAccessZones(this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(results => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.dataSource.data = results.items;

        },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  private loadUsers() {
    let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

    this.accountService.getUsers()
      .subscribe(results => {

          this.allUsers = results;
        },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  public editAccessZone(accessZone?: AccessZone) {
    this.sourceAccessZone = accessZone;

    if (!accessZone) {
      accessZone = new AccessZone();
    }

    let dialogRef = this.dialog.open(AdminAccessZoneEditorComponent,
      {
        panelClass: 'mat-dialog-sm',
        data: { accessZone: accessZone, allUsers: this.allUsers}
      });
    dialogRef.afterClosed().subscribe(accessZone => {
      if (accessZone) {
        this.updateAccessZones(accessZone);
      }
    });
  }

  public editSettings(accessZone?: AccessZone) {
    this.sourceAccessZone = accessZone;

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.router.navigate([`/admin/accesszones/${accessZone.id}/settings`]);
  }

  private confirmDelete(accessZone: AccessZone) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.accessZoneService.deleteAccessZone(accessZone.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== accessZone)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }
}
