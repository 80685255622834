import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { AlarmAccessKeyEndpoint } from './alarm-access-key-endpoint.service';
import { AlarmAccessKey } from '../../models/alarm/alarm-access-key.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class AlarmAccessKeyService {

  constructor(private router: Router, private http: HttpClient,
    private alarmAccessKeyEndpoint: AlarmAccessKeyEndpoint) {

  }
  
  getAlarmAccessKey(alarmId: number, id?: number): Observable<AlarmAccessKey> {
    if (id) {
      return this.alarmAccessKeyEndpoint.getAlarmAccessKeyEndpoint<AlarmAccessKey>(alarmId, id);
    }

    
  }

  getAlarmAccessKeys(alarmId: number): Observable<AlarmAccessKey[]>{

    return this.alarmAccessKeyEndpoint.getAlarmAccessKeysEndpoint<AlarmAccessKey[]>(alarmId);
  }
  
  updateAlarmAccessKey(alarmId: number, alarmAccessKey: AlarmAccessKey): Observable<AlarmAccessKey> {
    return this.alarmAccessKeyEndpoint.getUpdateAlarmAccessKeyEndpoint(alarmId, alarmAccessKey, alarmAccessKey.id);
  }
  
  newAlarmAccessKey(alarmId: number, alarmAccessKey: AlarmAccessKey): Observable<AlarmAccessKey>{
    return this.alarmAccessKeyEndpoint.getNewAlarmAccessKeyEndpoint<AlarmAccessKey>(alarmId, alarmAccessKey);
  }
  
  deleteAlarmAccessKey(alarmId: number, id: number): Observable<AlarmAccessKey> {

    return this.alarmAccessKeyEndpoint.getDeleteAlarmAccessKeyEndpoint(alarmId, id);
  }
}
