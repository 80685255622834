import { Device } from "../device/device.model";
import { DeviceFunction } from "../device/device-function.model";
import {AccessZoneActionParameter} from "./access-zone-action-parameter.model";

export class AccessZoneAction {

  constructor(id: number = 0, waitTime?: number, accessZoneId?: number, inputDevice?: Device, outputDevice?: Device, deviceFunction?: DeviceFunction, parameters?: AccessZoneActionParameter[]) {

    this.id = id;
    this.accessZoneId = accessZoneId;
    this.inputDevice = inputDevice;
    this.outputDevice = outputDevice;
    this.deviceFunction = deviceFunction;
    this.waitTime = waitTime;
    this.parameters = parameters;
  }

  public id: number;
  public waitTime: number;
  public accessZoneId: number;
  public inputDevice: Device;
  public outputDevice: Device;
  public deviceFunction: DeviceFunction;
  public parameters: AccessZoneActionParameter[];
}
