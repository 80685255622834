import { Component, ChangeDetectorRef, ViewChild, ViewEncapsulation, OnInit, OnDestroy, ElementRef, HostListener } from "@angular/core";
import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationStart } from '@angular/router';
import { MatExpansionPanel, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSidenav } from '@angular/material';
import { ToastaService, ToastaConfig, ToastOptions, ToastData } from 'ngx-toasta';

import { AlertService, AlertDialog, DialogType, AlertMessage, MessageSeverity } from './services/alert.service';
import { NotificationService } from "./services/notification.service";
import { AppTranslationService } from "./services/app-translation.service";
import { AccountService } from './services/account.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { AppTitleService } from './services/app-title.service';
import { AuthService } from './services/auth.service';
import { ConfigurationService } from './services/configuration.service';
import { Permission } from './models/permission.model';
import { LoginDialogComponent } from "./components/login/login-dialog.component";
import { AppDialogComponent } from './shared/app-dialog.component';
import { Location } from '@angular/common';
import { SettingsService } from "./services/settings/settings.service";
import { Settings } from "./models/settings/settings.model";
import { Message } from "@angular/compiler/src/i18n/i18n_ast";
import { AppNotificationService } from "./services/appNotification/app-notification.service";
import { AppNotification } from "./models/appNotification/app-notification.model";
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

declare var Hls: any;

@Component({
  selector: "app-root",
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('admin', { static: true }) adminExpander: MatExpansionPanel;

  private _mobileQueryListener: () => void;
  isAppLoaded: boolean;
  isUserLoggedIn: boolean;
  isMainModuleActive: boolean;
  isEnergyModuleActive: boolean;
  isAlarmModuleActive: boolean;
  isAccessControlActive: boolean;
  isAdminExpanded: boolean = false;
  removePrebootScreen: boolean;
  newNotificationCount = 0;
  appTitle = `uLumin - Configuração do Sistema`;
  appLogo = require("./assets/images/topo_appserver.png");
  iconHome = require("./assets/images/casa.svg");
  iconDiv = require("./assets/images/divisoes.svg");
  iconAuto = require("./assets/images/automacao02.svg");
  iconAlarm = require("./assets/images/escudo.svg");
  iconCam = require("./assets/images/camara.svg");
  iconEread = require("./assets/images/energy.svg");
  iconID = require("./assets/images/users_1.svg");
  iconFunc = require("./assets/images/id.svg");
  iconZones = require("./assets/images/zones.svg");
  iconDivision = require("./assets/images/divisoes.svg");
  iconSboard = require("./assets/images/puzzle.svg");
  iconMod = require("./assets/images/ioc.svg");
  iconDevice = require("./assets/images/sensor.svg");
  iconIQ = require("./assets/images/master01.svg");
  iconMap = require("./assets/images/iocmap.svg");
  iconMapio = require("./assets/images/maps.svg");
  iconAction = require("./assets/images/action.svg");
  iconScenes = require("./assets/images/servidores.svg");
  iconEnergy = require("./assets/images/energy.svg");
  iconAkeys = require("./assets/images/password.svg");
  iconRule = require("./assets/images/id.svg");
  iconFirm = require("./assets/images/Asset 119.svg");
  iconConf = require("./assets/images/engrenagem.svg");
  iconLog = require("./assets/images/log.svg");
  iconUlumin = require("./assets/images/ulumin.svg");
  iconClock = require("./assets/images/relogio.svg");

  settings: Settings = new Settings();

  appNotifications: AppNotification[] = [];
  public notificationsCounter: 0;

  private notificationsHubConnection: HubConnection;

  mobileQuery: MediaQueryList;
  stickyToasties: number[] = [];

  dataLoadingConsecutiveFailures = 0;
  notificationsLoadingSubscription: any;

  public mobile = false;

  public notificationCounter = 0;

  get notificationsTitle() {

    let gT = (key: string) => this.translationService.getTranslation(key);

    if (this.newNotificationCount) {
      return `${gT("app.Notifications")} (${this.newNotificationCount} ${gT("app.New")})`;
    }
    else {
      return gT("app.Notifications");
    }
  }

  constructor(storageManager: LocalStoreManager,
    private toastaService: ToastaService,
    private toastaConfig: ToastaConfig,
    private accountService: AccountService,
    private alertService: AlertService,
    private appTitleService: AppTitleService,
    private authService: AuthService,
    private translationService: AppTranslationService,
    public configurations: ConfigurationService,
    private settingService: SettingsService,
    private appNotificationService: AppNotificationService,
    public router: Router,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private location: Location) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    var self = this;

    storageManager.initialiseStorageSyncListener();

    translationService.addLanguages(["en", "pt"]);
    translationService.setDefaultLanguage('en');

    this.toastaConfig.theme = 'material';
    this.toastaConfig.position = 'top-right';
    this.toastaConfig.limit = 100;
    this.toastaConfig.showClose = true;

    this.appTitleService.appName = this.appTitle;
  }

  async ngOnInit() {
    let self = this;

    this.isUserLoggedIn = this.authService.isLoggedIn;

    if (window.innerWidth <= 600) { // 768px portrait
      this.mobile = true;
    }

    // 1 sec to ensure all the effort to get the css animation working is appreciated :|, Preboot screen is removed .5 sec later
    setTimeout(() => this.isAppLoaded = true, 500);
    setTimeout(() => this.removePrebootScreen = true, 1000);

    setTimeout(() => {
      if (this.isUserLoggedIn) {
        this.alertService.resetStickyMessage();
        var loginTranslate = this.translationService.getTranslation("app.Login");
        var welcomeTranslate = this.translationService.getTranslation("app.Welcomeback");
        this.alertService.showMessage(loginTranslate, `${welcomeTranslate} ${this.userName}!`, MessageSeverity.default);
      }
    }, 2000);

    this.alertService.getDialogEvent().subscribe(alert => this.dialog.open(AppDialogComponent,
      {
        data: alert,
        panelClass: 'mat-dialog-sm'
      }));
    this.alertService.getMessageEvent().subscribe(message => this.showToast(message, false));
    this.alertService.getStickyMessageEvent().subscribe(message => this.showToast(message, true));

    this.authService.reLoginDelegate = () => this.redirectToLogin();

    this.authService.getLoginStatusEvent().subscribe(isLoggedIn => {
      this.isUserLoggedIn = isLoggedIn;

      if (this.isUserLoggedIn) {

        this.getNotifications();

        this.settingService.getSettings().subscribe(result => {
          this.settings = result;
          this.isMainModuleActive = this.configurations.mainModuleActive;
          this.isEnergyModuleActive = this.configurations.energyModuleActive;
          this.isAlarmModuleActive = this.configurations.alarmModuleActive;
          this.isAccessControlActive = this.configurations.accessControlModuleActive;

          self.changeDetectorRef.detectChanges();
        },
          err => this.settings = new Settings());

        this.initNotificationsLoading();
      }
      else {
        this.unsubscribeNotifications();
      }

      setTimeout(() => {
        if (!this.isUserLoggedIn) {
          this.alertService.showMessage("Session Ended!", "", MessageSeverity.default);
        }
      }, 500);
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        let url = (<NavigationStart>event).url;

        if (url !== url.toLowerCase()) {
          this.router.navigateByUrl((<NavigationStart>event).url.toLowerCase());
        }

        if (this.adminExpander && url.indexOf('admin') > 0) {
          this.adminExpander.open();
        }
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 600) { // 768px portrait
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }


  ngOnDestroy() {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    this.unsubscribeNotifications();
    this.notificationsHubConnection.stop();
  }

  private unsubscribeNotifications() {
    if (this.notificationsHubConnection) {
      this.notificationsHubConnection.stop();
    }
  }

  public markAllAsRead() {
    var self = this;

    this.appNotificationService.markAllAsRead().subscribe(result => {

      self.getNotifications();
    },
      err => console.log(err));
  }

  private initNotificationsLoading() {

    let builder = new HubConnectionBuilder();

    this.notificationsHubConnection = builder
      .withUrl(location.origin + '/hubs/appnotifications',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .configureLogging(LogLevel.None)
      .build();

    this.notificationsHubConnection.on("appNotification", (message) => {
      this.showToast(new AlertMessage(MessageSeverity.info, message.subject, message.message), false);

      this.getNotifications();
    });

    try {
      this.notificationsHubConnection.start().catch(function (err) {
      });
    } catch (ex) { }
  }

  getNotifications() {
    let self = this;

    this.appNotificationService.getAppNotifications()
      .subscribe(result => {

        self.appNotifications = result;

        if (result) {
          this.notificationCounter = result.filter(x => x.unread).length;
        }
      },
        err => console.log(err));
  }

  markAsRead(notification: AppNotification) {
    var self = this;

    if (notification.unread) {
      this.appNotificationService.markAsRead(notification.id).subscribe(result => {

        self.getNotifications();
      },
        err => console.log(err));
    }
  }

  deleteNotification(notification: AppNotification) {
    var self = this;

    this.appNotificationService.delete(notification.id).subscribe(result => {

      self.getNotifications();
    },
      err => console.log(err));
  }

  deleteAllNotification() {
    var self = this;

    this.appNotificationService.deleteAll().subscribe(result => {

      self.getNotifications();
    },
      err => console.log(err));
  }

  showLoginDialog(): void {

    let dialogRef = this.dialog.open(LoginDialogComponent, { minWidth: 600 });

    dialogRef.afterClosed().subscribe(result => {
      this.alertService.resetStickyMessage();

      if (!result || this.authService.isSessionExpired) {
        this.authService.logout();
        this.router.navigateByUrl('/login');
        this.alertService.showStickyMessage("Session Expired", "Your Session has expired. Please log in again to renew your session", MessageSeverity.warn);
      }
      else {
        location.reload();
      }
    });
  }

  redirectToLogin(): void {
    this.router.navigateByUrl('/login');
  }

  public handleMenuItemClick(sideNav: MatSidenav) {
    if (this.mobileQuery.matches) {
      sideNav.close();
    }
  }

  showToast(message: AlertMessage, isSticky: boolean) {
    if (message == null) {
      for (let id of this.stickyToasties.slice(0)) {
        this.toastaService.clear(id);
      }

      return;
    }

    let toastOptions: ToastOptions = {
      title: message.summary,
      msg: message.detail,
      timeout: isSticky ? 0 : 4000
    };

    if (isSticky) {
      toastOptions.onAdd = (toast: ToastData) => this.stickyToasties.push(toast.id);

      toastOptions.onRemove = (toast: ToastData) => {
        let index = this.stickyToasties.indexOf(toast.id, 0);

        if (index > -1) {
          this.stickyToasties.splice(index, 1);
        }

        toast.onAdd = null;
        toast.onRemove = null;
      };
    }

    switch (message.severity) {
      case MessageSeverity.default: this.toastaService.default(toastOptions); break
      case MessageSeverity.info: this.toastaService.info(toastOptions); break;
      case MessageSeverity.success: this.toastaService.success(toastOptions); break;
      case MessageSeverity.error: this.toastaService.error(toastOptions); break
      case MessageSeverity.warn: this.toastaService.warning(toastOptions); break;
      case MessageSeverity.wait: this.toastaService.wait(toastOptions); break;
    }
  }

  logout() {
    this.authService.logout();
    this.authService.redirectLogoutUser();
  }

  get userName(): string {
    return this.authService.currentUser ? this.authService.currentUser.userName : "";
  }

  get fullName(): string {
    return this.authService.currentUser ? this.authService.currentUser.fullName : "";
  }

  get canViewCustomers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewProducts() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewOrders() {
    return true;
  }

  get canViewUsers() {
    return this.accountService.userHasPermission(Permission.viewUsersPermission);
  }

  get canViewRoles() {
    return this.accountService.userHasPermission(Permission.viewRolesPermission);
  }

  get isAdmin() {
    return this.accountService.userHasPermission(Permission.adminPermission);
  }

  get canEditScenarios() {
    return this.accountService.userHasPermission(Permission.manageScenarionsPermission);
  }
}
