import { NgModule, ErrorHandler } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from '@angular/common/http';
import { MatFormFieldModule, MatInputModule, MatSlideToggleModule } from '@angular/material';


import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { ToastaModule } from 'ngx-toasta';
import {ChartsModule, ThemeService} from 'ng2-charts';
import { MatIconRegistry, MatIconModule } from '@angular/material';

import { AppRoutingModule } from './app-routing.module';
import { AppErrorHandler } from './app-error.handler';

import { SharedModule } from './shared/shared.module'
import { AdminModule } from './admin/admin.module';
import { SettingsModule } from './settings/settings.module';
import { FooterModule } from './shared/footer.component';
import { ThemePickerModule } from './shared/theme-picker.component';

import { AppTitleService } from './services/app-title.service';
import { AppTranslationService, TranslateLanguageLoader } from './services/app-translation.service';
import { ConfigurationService } from './services/configuration.service';
import { AlertService } from './services/alert.service';
import { LocalStoreManager } from './services/local-store-manager.service';
import { EndpointFactory } from './services/endpoint-factory.service';
import { NotificationService } from './services/notification.service';
import { NotificationEndpoint } from './services/notification-endpoint.service';
import { AccountService } from './services/account.service';
import { AccountEndpoint } from './services/account-endpoint.service';
import { FloorService } from './services/floor/floor.service';
import { FloorEndpoint } from './services/floor/floor-endpoint.service';
import { RoomService } from './services/room/room.service';
import { RoomEndpoint } from './services/room/room-endpoint.service';
import { RoomResolve } from './services/room/room-resolve';
import { RoomDevicesResolve } from './services/room/room-devices-resolve';
import { RoomActionsResolve } from './services/room/room-actions-resolve';
import { CabinetService } from './services/cabinet/cabinet.service';
import { CabinetEndpoint } from './services/cabinet/cabinet-endpoint.service';
import { ModuleService } from './services/module/module.service';
import { ModuleEndpoint } from './services/module/module-endpoint.service';
import { ModulePortMapService } from './services/module/module-port-map.service';
import { ModulePortMapEndpoint } from './services/module/module-port-map-endpoint.service';
import { ModuleResolve } from './services/module/module-resolve';
import { ModulePortMapResolve } from './services/module/module-port-map-resolve';
import { ModulePortMapValidatorService } from './services/module/module-port-map-validator.service';
import { DeviceService } from './services/device/device.service';
import { DeviceEndpoint } from './services/device/device-endpoint.service';
import { DeviceScheduleService } from './services/device/device-schedule.service';
import { DeviceScheduleEndpoint } from './services/device/device-schedule-endpoint.service';
import { DevicePortService } from './services/device/device-port.service';
import { FunctionValidatorService } from './services/device/function-validator.service';
import { ParameterValidatorService } from './services/device/parameter-validator.service';
import { DeviceResolve } from './services/device/device-resolve';
import { MasterDeviceResolve } from './services/masterDevice/master-device-resolve';
import { DevicePortEndpoint } from './services/device/device-port-endpoint.service';
import { DevicePortMapService } from './services/devicePortMap/device-port-map.service';
import { DevicePortMapEndpoint } from './services/devicePortMap/device-port-map-endpoint.service';
import { DeviceTypeService } from './services/deviceType/device-type.service';
import { DeviceTypeEndpoint } from './services/deviceType/device-type-endpoint.service';
import { ModuleTypeService } from './services/moduleType/module-type.service';
import { ModuleTypeEndpoint } from './services/moduleType/module-type-endpoint.service';
import { InputMappingService } from './services/inputMapping/input-mapping.service';
import { InputMappingEndpoint } from './services/inputMapping/input-mapping-endpoint.service';
import { MasterDeviceService } from './services/masterDevice/master-device.service';
import { MasterDeviceEndpoint } from './services/masterDevice/master-device-endpoint.service';


import { AppComponent } from "./app.component";
import { LoginComponent } from "./components/login/login.component";
import { LoginControlComponent } from "./components/login/login-control.component";
import { LoginDialogComponent } from "./components/login/login-dialog.component";
import { CustomersComponent } from "./components/customers/customers.component";
import { DeviceComponent } from "./components/devices/device.component";
import { DeviceListComponent } from "./components/devices/device-list.component";
import { RoomListComponent } from "./components/rooms/room-list.component";
import { AdminAutomationRuleListComponent } from "./components/automationRules/admin/admin-automation-rule-list.component";
import { AdminAutomationRuleEditorComponent } from "./components/automationRules/admin/admin-automation-rule-editor.component";
import { AboutComponent } from "./components/about/about.component";
import { NotFoundComponent } from "./components/not-found/not-found.component";
import { AdminFloorEditorComponent } from "./components/floors/admin/admin-floor-editor.component";
import { AdminFloorListComponent } from "./components/floors/admin/admin-floor-list.component";
import { AdminRoomEditorComponent } from "./components/rooms/admin/admin-room-editor.component";
import { AdminRoomListComponent } from "./components/rooms/admin/admin-room-list.component";
import { AdminCabinetEditorComponent } from "./components/cabinets/admin/admin-cabinet-editor.component";
import { AdminCabinetListComponent } from "./components/cabinets/admin/admin-cabinet-list.component";
import { AdminModuleEditorComponent } from "./components/modules/admin/admin-module-editor.component";
import { AdminModuleListComponent } from "./components/modules/admin/admin-module-list.component";
import { AdminModulePortMapEditorComponent } from "./components/modules/admin/admin-module-port-map-editor.component";
import { AdminModulePortMapListComponent } from "./components/modules/admin/admin-module-port-map-list.component";
import { AdminDeviceEditorComponent } from "./components/devices/admin/admin-device-editor.component";
import { AdminDeviceListComponent } from "./components/devices/admin/admin-device-list.component";
import { AdminDeviceScheduleListComponent } from "./components/devices/admin/admin-device-schedule-list.component";
import { AdminDeviceSettingsEditorComponent } from "./components/devices/admin/admin-device-settings-editor.component";
import { AdminInputMapListComponent } from "./components/inputMapping/admin/admin-input-map-list.component";
import { AdminDevicePortMapEditorComponent } from "./components/devices/admin/admin-device-port-map-editor.component";
import { AdminDevicePortMapListComponent } from "./components/devices/admin/admin-device-port-map-listing.component";
import { MasterDeviceEditorComponent } from "./components/masterdevice/master-device-editor.component";
import { TodoDemoComponent } from "./components/controls/todo-demo.component";
import { StatisticsDemoComponent } from "./components/controls/statistics-demo.component";
import { NotificationsViewerComponent } from "./components/controls/notifications-viewer.component";
import { AddTaskDialogComponent } from './components/controls/add-task-dialog.component';
import { AdminModulePortMapInnerListingComponent } from "./components/modules/admin/admin-module-port-map-inner-listing.component";
import { AdminRoomActionListComponent } from "./components/rooms/admin/admin-room-action-list.component";
import { AdminRoomActionEditorComponent } from "./components/rooms/admin/admin-room-action-editor.component";
import { RoomActionEndpoint } from "./services/room/room-action-endpoint.service";
import { RoomActionService } from "./services/room/room-action.service";
import { RoomActionMapValidatorService } from "./services/room/room-action-map-validator.service";

import { NgxGaugeModule } from 'ngx-gauge';

//Alarms
import { AdminAlarmSettingsComponent } from "./components/alarm/admin/admin-alarm-settings.component";
import { AdminAlarmListComponent } from "./components/alarm/admin/admin-alarm-list.component";
import { AlarmComponent } from "./components/alarm/alarm.component";
import { AlarmListComponent } from "./components/alarm/alarm-list.component";
import { AlarmService } from './services/alarm/alarm.service';
import { AlarmEndpoint } from './services/alarm/alarm-endpoint.service';
import { AlarmZoneService } from './services/alarm/alarm-zone.service';
import { AlarmZoneEndpoint } from './services/alarm/alarm-zone-endpoint.service';


import 'hammerjs';
import { AlarmZonesResolve } from "./services/alarm/alarm-zones-resolve";
import { AdminAlarmZoneEditorComponent } from "./components/alarm/admin/admin-alarm-zone-editor.component";
import { ZoneActionEndpoint } from "./services/alarm/zone-action-endpoint.service";
import { ZoneActionService } from "./services/alarm/zone-action.service";
import { ZoneDeviceEndpoint } from "./services/alarm/zone-device-endpoint.service";
import { ZoneDeviceService } from "./services/alarm/zone-device.service";
import { AdminAlarmZoneSettingsComponent } from "./components/alarm/admin/admin-alarm-zone-settings.component";
import { AlarmZoneResolve } from "./services/alarm/alarm-zone-resolve";
import { RoomsResolve } from "./services/room/rooms-resolve";
import { DevicesResolve } from "./services/device/devices-resolve";
import { AdminAlarmZoneActionEditorComponent } from "./components/alarm/admin/admin-alarm-zone-action-editor.component";
import { AdminAlarmZoneSensorEditorComponent } from "./components/alarm/admin/admin-alarm-zone-sensor-editor.component";
import { AdminZoneActionSettingsEditorComponent } from "./components/alarm/admin/admin-alarm-zone-action-settings.component";
import { AutomationRuleService } from "./services/automationRule/automation-rule.service";
import { AutomationRuleEndpoint } from "./services/automationRule/automation-rule-endpoint.service";
import { AdminAutomationRuleActionSettingsEditorComponent } from "./components/automationRules/admin/admin-automation-rule-action-settings.component";
import { AdminAutomationRuleSettingsComponent } from "./components/automationRules/admin/admin-automation-rule-settings.component";
import { AutomationRuleActionService } from "./services/automationRule/automation-rule-action.service";
import { AutomationRuleActionEndpoint } from "./services/automationRule/automation-rule-action-endpoint.service";
import { AutomationRuleResolve } from "./services/automationRule/automation-rule-resolve";
import { AutomationRuleStartActionsResolve } from "./services/automationRule/automation-rule-start-action-resolve";
import { AutomationRuleStopActionsResolve } from "./services/automationRule/automation-rule-stop-action-resolve";
import { AdminAutomationRuleActionEditorComponent } from "./components/automationRules/admin/admin-automation-rule-action-editor.component";
import { QueryBuilderModule } from "angular2-query-builder";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { RoomComponent } from "./components/rooms/room.component";
import { ActionsCardComponent } from "./components/dashboard/cards/actions-card.component";
import { IconPickerModule } from 'ngx-icon-picker';
import { DevicesCardComponent } from "./components/dashboard/cards/devices-card.component";
import { ActionsListCardComponent } from "./components/dashboard/cards/actions-list-card.component";
import { PressureCardComponent } from "./components/dashboard/cards/pressure-card.component";
import { TemperatureCardComponent } from "./components/dashboard/cards/temperature-card.component";
import { humidityCardComponent } from "./components/dashboard/cards/humidity-card.component";
import { EventNotificationListComponent } from "./components/eventNotifications/event-notification-list.component";
import { EventNotificationService } from "./services/eventNotification/event-notification.service";
import { EventNotificationEndpoint } from "./services/eventNotification/event-notification-endpoint.service";
import { DomSanitizer } from "@angular/platform-browser";
import { AlertsCardComponent } from "./components/dashboard/cards/alerts-card.component";
import { WindowsCardComponent } from "./components/dashboard/cards/windows-card.component";
import { MdePopoverModule } from '@material-extended/mde';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SecurityAlarmCardComponent } from "./components/dashboard/cards/security-card.component";
import { FloodAlarmCardComponent } from "./components/dashboard/cards/flood-alarm-card.component";
import { FireAlarmCardComponent } from "./components/dashboard/cards/fire-alarm-card.component";
import { DoorsCardComponent } from "./components/dashboard/cards/doors-card.component";

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { ClimatizationCardComponent } from "./components/dashboard/cards/climatization-card.component";
import { ActionsComponent } from "./components/rooms/actions.component";
import { DeviceCardComponent } from "./components/devices/device-card.component";
import { AlarmProfileService } from "./services/alarm/alarm-profile.service";
import { AlarmProfileEndpoint } from "./services/alarm/alarm-profile-endpoint.service";
import { AlarmProfilesResolve } from "./services/alarm/alarm-profiles-resolve";
import { AdminAlarmProfileListComponent } from "./components/alarm/admin/admin-alarm-profile-list.component";
import { AdminAlarmZoneListComponent } from "./components/alarm/admin/admin-alarm-zone-list.component";
import { AdminAlarmProfileEditorComponent } from "./components/alarm/admin/admin-alarm-profile-editor.component";
import { AdminAlarmProfileSettingsComponent } from "./components/alarm/admin/admin-alarm-profile-settings.component";
import { AlarmProfileResolve } from "./services/alarm/alarm-profile-resolve";
import { AlarmCardComponent } from "./components/alarm/alarm-card.component";
import { AlarmZoneCardComponent } from "./components/alarm/alarm-zone-card.component";
import { CameraService } from "./services/cameras/camera.service";
import { CameraEndpoint } from "./services/cameras/camera-endpoint.service";
import { AutomationListComponent } from "./components/automationRules/automation-list.component";
import { AutomationCardComponent } from "./components/automationRules/automation-card.component";
import { AdminSettingsComponent } from "./components/settings/admin/admin-settings.component";
import { SettingsEndpoint } from "./services/settings/settings-endpoint.service";
import { SettingsService } from "./services/settings/settings.service";
import { CookieService } from 'ngx-cookie-service';
import { ClimatizationService } from "./services/climatization/climatization.service";
import { ClimatizationEndpoint } from "./services/climatization/climatization-endpoint.service";
import { EnergyZoneService } from "./services/energy/energy-zone.service";
import { EnergyZoneEndpoint } from "./services/energy/energy-zone-endpoint.service";
import { AdminEnergyZoneListComponent } from "./components/energy/admin/admin-energy-zone-list.component";
import { AdminEnergyZoneEditorComponent } from "./components/energy/admin/admin-energy-zone-editor.component";
import { AdminEnergyZoneDeviceListComponent } from "./components/energy/admin/admin-energy-zone-device-list.component";
import { AdminEnergyZoneDeviceEditorComponent } from "./components/energy/admin/admin-energy-zone-device-editor.component";
import { EnergyZoneDeviceService } from "./services/energy/energy-zone-device.service";
import { EnergyZoneDeviceEndpoint } from "./services/energy/energy-zone-device-endpoint.service";
import { EnergyZoneDevicesResolve } from "./services/energy/energy-zone-devices.resolve";
import { EnergyZoneResolve } from "./services/energy/energy-zone.resolve";
import { EnergyReadingDevicesResolve } from "./services/device/energy-reading-devices-resolve";
import { EnergyReadingComponent } from "./components/energy/energy-reading.component";
import { EnergyZonesResolve } from "./services/energy/energy-zones.resolve";
import { EnergyReadingService } from "./services/energy/energy-reading.service";
import { EnergyReadingEnpoint } from "./services/energy/energy-reading-endpoint.service";
import { AutomationRulesResolve } from "./services/automationRule/automation-rules-resolve";
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FirmwareListComponent } from "./components/firmwareVersion/firmware-list.component";
import { FirmwareVersionService } from "./services/firmwareVersion/firmwareVersion.service";
import { FirmwareVersionEndpoint } from "./services/firmwareVersion/firmwareVersion-endpoint.service";
import { FirmwareCardComponent } from "./components/firmwareVersion/firmware-card.component";
import { AnnouncementEndpoint } from "./services/announcement/announcement-endpoint.service";
import { AnnouncementService } from "./services/announcement/announcement.service";
import { AdminAlarmEditorComponent } from "./components/alarm/admin/admin-alarm-editor.component";
import { AdminAccessKeyEditorComponent } from "./components/accessKeys/admin/admin-access-key-editor.component";
import { AdminAccessKeyListComponent } from "./components/accessKeys/admin/admin-access-key-list.component";
import { AccessKeyEndpoint } from "./services/accessKey/access-key-endpoint.service";
import { AccessKeyService } from "./services/accessKey/access-key.service";
import { AccessKeysResolve } from "./services/accessKey/access-keys-resolve";
import { AlarmAccessKeyService } from "./services/alarm/alarm-access-key.service";
import { AlarmAccessKeyEndpoint } from "./services/alarm/alarm-access-key-endpoint.service";
import { AlarmAccessKeysResolve } from "./services/alarm/alarm-access-key-resolve";
import { AdminAlarmAccessKeyListComponent } from "./components/alarm/admin/admin-alarm-access-key-list.component";
import { AdminAlarmAccessKeyEditorComponent } from "./components/alarm/admin/admin-alarm-access-key-editor.component";
import { AlarmResolve } from "./services/alarm/alarm-resolve";
import { AdminDeviceScheduleEditorComponent } from "./components/devices/admin/admin-device-schedule-editor.component";
import { MatBadgeModule } from '@angular/material/badge';
import { AppNotificationService } from "./services/appNotification/app-notification.service";
import { AppNotificationEndpoint } from "./services/appNotification/app-notification-endpoint.service";
import { DownloadComponent } from "./shared/file/download.component";
import { UploadDownloadService } from "./upload-download.service";
import { UploadComponent } from "./shared/file/upload.component";
import { RestoringComponent } from "./components/restoring/restoring.component";
import {AdminAccessZoneListComponent} from "./components/accessZones/admin/admin-access-zone-list.component";
import {AdminAccessZoneEditorComponent} from "./components/accessZones/admin/admin-access-zone-editor.component";
import {AccessZonesResolve} from "./services/accessZone/access-zones-resolve";
import {AccessZoneService} from "./services/accessZone/access-zone.service";
import {AccessZoneEndpoint} from "./services/accessZone/access-zone-endpoint.service";
import {AdminAccessZoneSettingsComponent} from "./components/accessZones/admin/admin-access-zone-settings.component";
import {AccessZoneResolve} from "./services/accessZone/access-zone-resolve";
import {AccessZoneActionService} from "./services/accessZone/access-action.service";
import {AccessZoneActionEndpoint} from "./services/accessZone/access-action-endpoint.service";
import {AdminAccessZoneActionEditorComponent} from "./components/accessZones/admin/admin-access-zone-action-editor.component";
import {AdminAccessZoneActionSettingsEditorComponent} from "./components/accessZones/admin/admin-access-zone-action-settings.component";
import {AdminUserListComponent} from "./components/accessZones/admin/admin-user-list.component";
import {EnergyReadingBarChartComponent} from "./components/energy/energy-reading-bar-card.component";
import {EnergyReadingDetailCardComponent} from "./components/energy/energy-reading-detail-card.component";
import {EnergyReadingPieChartComponent} from "./components/energy/energy-reading-pi-chart.component";
import {MatPaginatorTranslation} from "./shared/mat-paginator-translation";
import {MatPaginatorIntl} from "@angular/material/paginator";
import { DeviceValueInputComponent } from "./components/automationRules/admin/components/device-value-input.component";
import { ConditionDeviceInputComponent } from "./components/automationRules/admin/components/condition-device-input.component";
import {
  ConditionZoneInputComponent
} from "./components/automationRules/admin/components/condition-zone-input.component";
import {ZoneInputComponent} from "./components/automationRules/admin/components/zone-input.component";
import {
  AdminRoomActionSettingsComponent
} from "./components/rooms/admin/admin-room-action-settings.component";
import {DeviceTypeFunctionService} from "./services/deviceTypeFunction/device-type-function.service";
import {DeviceTypeFunctionEndpoint} from "./services/deviceTypeFunction/device-type-function-endpoint.service";
import {AdminRoomActionMapEditorComponent} from "./components/rooms/admin/admin-action-map-editor.component";
import {RoomActionMapsResolve} from "./services/room/room-action-maps-resolve";
import {RoomActionResolve} from "./services/room/room-action-resolve";
import {AdminActionMapSettingsEditorComponent} from "./components/rooms/admin/admin-action-map-settings.component";
import {AdminDeviceGroupListComponent} from "./components/deviceGroups/admin/admin-device-group-list.component";
import {AdminDeviceGroupEditorComponent} from "./components/deviceGroups/admin/admin-device-group-editor.component";
import {DeviceGroupService} from "./services/deviceGroup/device-group.service";
import {DeviceGroupEndpoint} from "./services/deviceGroup/device-group-endpoint.service";
import {AdminDeviceGroupSettingsComponent} from "./components/deviceGroups/admin/admin-device-group-settings.component";
import {
  AdminDeviceGroupDeviceEditorComponent
} from "./components/deviceGroups/admin/admin-group-device-editor.component";
import {DeviceGroupResolve} from "./services/deviceGroup/device-group-resolve";
import {DeviceFreePortsResolve} from "./services/devicePortMap/device-free-ports-resolve";

library.add(fas, far, fab);

@NgModule({
  imports: [
    MatBadgeModule,
    SharedModule,
    FooterModule,
    ThemePickerModule,
    HttpClientModule,
    AngularSvgIconModule,
    AdminModule,
    SettingsModule,
    AppRoutingModule,
    IconPickerModule,
    MdePopoverModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLanguageLoader
      }
    }),
    ToastaModule.forRoot(),
    ChartsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSlideToggleModule,
    QueryBuilderModule,
    MatIconModule,
    FontAwesomeModule
  ],
  declarations: [
    AppComponent,
    LoginComponent, LoginControlComponent, LoginDialogComponent,
    DashboardComponent,
    CustomersComponent,
    RoomListComponent,
    AdminAutomationRuleListComponent,
    DeviceListComponent,
    AboutComponent,
    NotFoundComponent,
    NotificationsViewerComponent,
    AddTaskDialogComponent,
    StatisticsDemoComponent, TodoDemoComponent,
    AdminFloorListComponent,
    AdminAccessKeyEditorComponent,
    AdminAccessKeyListComponent,
    AdminFloorEditorComponent,
    AdminRoomListComponent,
    AdminRoomEditorComponent,
    AdminRoomActionListComponent,
    AdminRoomActionEditorComponent,
    AdminCabinetListComponent,
    AdminCabinetEditorComponent,
    AdminModuleEditorComponent,
    AdminModuleListComponent,
    AdminModulePortMapEditorComponent,
    AdminModulePortMapListComponent,
    AdminAutomationRuleEditorComponent,
    RoomComponent,
    DeviceComponent,
    AdminDeviceEditorComponent,
    AdminDeviceListComponent,
    AdminModulePortMapInnerListingComponent,
    AdminInputMapListComponent,
    AdminDevicePortMapEditorComponent,
    AdminDevicePortMapListComponent,
    AdminDeviceSettingsEditorComponent,
    MasterDeviceEditorComponent,
    AdminAlarmEditorComponent,
    AdminAlarmSettingsComponent,
    AdminAlarmListComponent,
    AlarmComponent,
    AlarmListComponent,
    AdminAlarmZoneEditorComponent,
    AdminAlarmZoneSettingsComponent,
    AdminAlarmZoneSensorEditorComponent,
    AdminAlarmZoneActionEditorComponent,
    AdminAlarmZoneListComponent,
    AdminAlarmProfileListComponent,
    AdminZoneActionSettingsEditorComponent,
    AdminAutomationRuleActionSettingsEditorComponent,
    AdminAutomationRuleSettingsComponent,
    AdminAutomationRuleActionEditorComponent,
    ActionsCardComponent,
    DevicesCardComponent,
    AlarmCardComponent,
    ActionsListCardComponent,
    PressureCardComponent,
    AlarmZoneCardComponent,
    TemperatureCardComponent,
    humidityCardComponent,
    EventNotificationListComponent,
    AlertsCardComponent,
    WindowsCardComponent,
    SecurityAlarmCardComponent,
    FloodAlarmCardComponent,
    FireAlarmCardComponent,
    DoorsCardComponent,
    ClimatizationCardComponent,
    ActionsComponent,
    DeviceCardComponent,
    AdminAlarmProfileEditorComponent,
    AdminAlarmProfileSettingsComponent,
    AutomationListComponent,
    AutomationCardComponent,
    AdminSettingsComponent,
    AdminEnergyZoneListComponent,
    AdminEnergyZoneEditorComponent,
    AdminEnergyZoneDeviceListComponent,
    AdminEnergyZoneDeviceEditorComponent,
    EnergyReadingComponent,
    FirmwareListComponent,
    FirmwareCardComponent,
    AdminAlarmAccessKeyListComponent,
    AdminAlarmAccessKeyEditorComponent,
    AdminDeviceScheduleListComponent,
    AdminDeviceScheduleEditorComponent,
    DownloadComponent,
    UploadComponent,
    RestoringComponent,
    AdminAccessZoneListComponent,
    AdminAccessZoneEditorComponent,
    AdminAccessZoneSettingsComponent,
    AdminAccessZoneActionEditorComponent,
    AdminAccessZoneActionSettingsEditorComponent,
    AdminUserListComponent,
    EnergyReadingPieChartComponent,
    EnergyReadingBarChartComponent,
    EnergyReadingDetailCardComponent,
    DeviceValueInputComponent,
    ConditionDeviceInputComponent,
    ConditionZoneInputComponent,
    ZoneInputComponent,
    AdminRoomActionSettingsComponent,
    AdminRoomActionMapEditorComponent,
    AdminActionMapSettingsEditorComponent,
    AdminDeviceGroupListComponent,
    AdminDeviceGroupEditorComponent,
    AdminDeviceGroupSettingsComponent,
    AdminDeviceGroupDeviceEditorComponent
  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    AlertService,
    ConfigurationService,
    AppTitleService,
    AppTranslationService,
    NotificationService,
    NotificationEndpoint,
    AccountService,
    AccountEndpoint,
    LocalStoreManager,
    EndpointFactory,
    FloorService,
    FloorEndpoint,
    RoomService,
    RoomEndpoint,
    RoomActionService,
    RoomActionEndpoint,
    RoomResolve,
    RoomsResolve,
    RoomDevicesResolve,
    RoomActionsResolve,
    RoomActionResolve,
    RoomActionMapsResolve,
    CabinetService,
    CabinetEndpoint,
    ModuleService,
    ModuleEndpoint,
    DeviceService,
    DeviceEndpoint,
    DeviceScheduleService,
    DeviceScheduleEndpoint,
    DevicePortService,
    DevicePortEndpoint,
    DeviceTypeService,
    DeviceTypeEndpoint,
    ModuleTypeService,
    ModuleTypeEndpoint,
    ModulePortMapService,
    ModulePortMapEndpoint,
    ModulePortMapValidatorService,
    ModulePortMapResolve,
    ModuleResolve,
    InputMappingService,
    InputMappingEndpoint,
    DevicePortMapService,
    DevicePortMapEndpoint,
    DeviceResolve,
    DevicesResolve,
    ParameterValidatorService,
    FunctionValidatorService,
    RoomActionMapValidatorService,
    MasterDeviceService,
    MasterDeviceEndpoint,
    MasterDeviceResolve,
    AlarmService,
    AlarmEndpoint,
    AlarmZoneService,
    AlarmZoneEndpoint,
    AlarmZonesResolve,
    AlarmProfileService,
    AlarmProfileEndpoint,
    AlarmProfilesResolve,
    ZoneActionEndpoint,
    ZoneActionService,
    ZoneDeviceEndpoint,
    ZoneDeviceService,
    AlarmZoneResolve,
    AutomationRuleService,
    AutomationRuleEndpoint,
    AutomationRuleActionService,
    AutomationRuleActionEndpoint,
    AutomationRuleResolve,
    AutomationRuleStartActionsResolve,
    AutomationRuleStopActionsResolve,
    EventNotificationService,
    EventNotificationEndpoint,
    AlarmProfileResolve,
    CameraService,
    CameraEndpoint,
    SettingsService,
    SettingsEndpoint,
    CookieService,
    ClimatizationService,
    ClimatizationEndpoint,
    EnergyZoneService,
    EnergyZoneEndpoint,
    EnergyZoneDeviceService,
    EnergyZoneDeviceEndpoint,
    EnergyZoneDevicesResolve,
    EnergyZoneResolve,
    EnergyReadingDevicesResolve,
    EnergyZonesResolve,
    EnergyReadingService,
    EnergyReadingEnpoint,
    AutomationRulesResolve,
    FirmwareVersionService,
    FirmwareVersionEndpoint,
    AnnouncementService,
    AnnouncementEndpoint,
    AccessKeyService,
    AccessKeyEndpoint,
    AccessKeysResolve,
    AlarmAccessKeyService,
    AlarmAccessKeyEndpoint,
    AlarmAccessKeysResolve,
    AlarmResolve,
    AppNotificationService,
    AppNotificationEndpoint,
    UploadDownloadService,
    AccessZoneEndpoint,
    AccessZoneService,
    AccessZonesResolve,
    AccessZoneResolve,
    AccessZoneActionService,
    AccessZoneActionEndpoint,
    ThemeService,
    MatPaginatorTranslation,
    DeviceTypeFunctionService,
    DeviceTypeFunctionEndpoint,
    DeviceGroupService,
    DeviceGroupEndpoint,
    DeviceGroupResolve,
    DeviceFreePortsResolve,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorTranslation,
    },
  ],
  entryComponents: [
    LoginDialogComponent,
    AddTaskDialogComponent,
    AdminFloorEditorComponent,
    AdminRoomEditorComponent,
    AdminCabinetEditorComponent,
    AdminModuleListComponent,
    AdminModuleEditorComponent,
    DeviceListComponent,
    AdminAutomationRuleListComponent,
    AdminAutomationRuleEditorComponent,
    RoomListComponent,
    AdminDeviceListComponent,
    AdminDeviceEditorComponent,
    AdminModulePortMapListComponent,
    AdminModulePortMapEditorComponent,
    AdminModulePortMapInnerListingComponent,
    AdminInputMapListComponent,
    AdminDevicePortMapListComponent,
    AdminDevicePortMapEditorComponent,
    AdminDeviceSettingsEditorComponent,
    AdminRoomActionListComponent,
    AdminRoomActionEditorComponent,
    MasterDeviceEditorComponent,
    AdminAlarmListComponent,
    AdminAlarmEditorComponent,
    AdminAlarmSettingsComponent,
    AdminAlarmZoneEditorComponent,
    AdminAlarmProfileEditorComponent,
    AlarmListComponent,
    AdminAlarmZoneSettingsComponent,
    AdminAlarmZoneSensorEditorComponent,
    AdminAlarmZoneActionEditorComponent,
    AdminZoneActionSettingsEditorComponent,
    AdminAutomationRuleActionSettingsEditorComponent,
    AdminAutomationRuleSettingsComponent,
    AdminAutomationRuleActionEditorComponent,
    EventNotificationListComponent,
    AdminAlarmProfileSettingsComponent,
    AutomationListComponent,
    AdminSettingsComponent,
    AdminEnergyZoneListComponent,
    AdminEnergyZoneEditorComponent,
    AdminEnergyZoneDeviceListComponent,
    AdminEnergyZoneDeviceEditorComponent,
    EnergyReadingComponent,
    AdminAccessKeyListComponent,
    AdminAccessKeyEditorComponent,
    AdminAlarmAccessKeyListComponent,
    AdminAlarmAccessKeyEditorComponent,
    AdminDeviceScheduleListComponent,
    AdminDeviceScheduleEditorComponent,
    AdminAccessZoneListComponent,
    AdminAccessZoneEditorComponent,
    AdminAccessZoneSettingsComponent,
    AdminAccessZoneActionEditorComponent,
    AdminAccessZoneActionSettingsEditorComponent,
    AdminUserListComponent,
    AdminRoomActionSettingsComponent,
    AdminRoomActionMapEditorComponent,
    AdminActionMapSettingsEditorComponent,
    AdminDeviceGroupListComponent,
    AdminDeviceGroupEditorComponent,
    AdminDeviceGroupSettingsComponent,
    AdminDeviceGroupDeviceEditorComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/mdi.svg'));

    //matIconRegistry.addSvgIcon("luminTeste", domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/agua01.svg"));


    matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    matIconRegistry.registerFontClassAlias('fontawesome', 'fas');
  }
}

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;

}
