export class MasterDevice {

  constructor(id: number = 0, description?: string) {

    this.id = id;
    this.description = description;
  }

  public id: number;
  public description: string;
}
