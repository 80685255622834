import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog, PageEvent } from '@angular/material';

import { fadeInOut } from '../../../services/animations';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from "../../../services/app-translation.service";
import { AccessKeyService } from '../../../services/accessKey/access-key.service';
import { Utilities } from '../../../services/utilities';
import { AccessKey } from '../../../models/accessKey/access-key.model';
import { AdminAccessKeyEditorComponent } from "./admin-access-key-editor.component";
import { User } from '../../../models/user.model';
import { AccountService } from '../../../services/account.service';
import { AccessKeyType } from '../../../models/accessKey/access-key-type.model';

@Component({
  selector: "admin-access-key-list",
  templateUrl: './admin-access-key-list.component.html',
  styleUrls: ['./admin-access-key-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminAccessKeyListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'description', 'keyType', 'user', 'actions'];
  datasource: null;
  dataSource: MatTableDataSource<AccessKey>;
  sourceAccessKey: AccessKey;
  editingAccessKeyName: { name: string };
  loadingIndicator: boolean;

  allUsers: User[] = [];
  allKeyTypes: string[] =[];

  constructor(
    private alertService: AlertService,
    private accountService: AccountService,
    private translationService: AppTranslationService,
    private accessKeyService: AccessKeyService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateAccessKeys(accessKey: AccessKey) {
    this.loadData();
  }

  private loadData() {
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

    this.allKeyTypes = Object.keys(AccessKeyType).filter((item) => {
      return isNaN(Number(item));
    });

    this.accountService.getUsers()
      .subscribe(results => {

        this.allUsers = results;

        this.accessKeyService.getAccessKeys(this.paginator.pageIndex, this.paginator.pageSize)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;

            for (let i = 0; i < results.items.length; i++) {
              let item = results.items[i];

              const user = this.allUsers.find(x => x.id === item.userId);
              if (user) {
                item.user = user;
                results.items[i] = item;
              }
            }

            this.dataSource.data = results.items;

          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(errorLabel, errorDetail,
                MessageSeverity.error, error);
            });
      },
        error => {
          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  public editAccessKey(accessKey?: AccessKey) {
    this.sourceAccessKey = accessKey;

    if (!accessKey) {
      accessKey = new AccessKey();
    }

    let dialogRef = this.dialog.open(AdminAccessKeyEditorComponent,
      {
        panelClass: 'mat-dialog-sm',
        data: { accessKey: accessKey, allUsers: this.allUsers, allKeyTypes: this.allKeyTypes }
      });
    dialogRef.afterClosed().subscribe(accessKey => {
      if (accessKey) {
        this.updateAccessKeys(accessKey);
      }
    });
  }

  private confirmDelete(accessKey: AccessKey) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.accessKeyService.deleteAccessKey(accessKey.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== accessKey)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }

  public translateKeyType(keyType: AccessKeyType): string {
    try {
      return this.translationService.getTranslation(`lookups.AccessKeyTypes.${AccessKeyType[keyType]}`);
    } catch (e) {
      return keyType.toString();
    }
  }
}
