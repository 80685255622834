import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AutomationRuleActionService } from 'src/app/services/automationRule/automation-rule-action.service';
import { AutomationRuleAction } from '../../../models/automationRule/automation-rule-action.model';
import { AutomationRule } from '../../../models/automationRule/automation-rule.model';
import { Device } from '../../../models/device/device.model';
import { Room } from '../../../models/room/room.model';
import { IoType } from '../../../models/io-type.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { Utilities } from '../../../services/utilities';
import { AdminAutomationRuleActionEditorComponent } from './admin-automation-rule-action-editor.component';
import { AdminAutomationRuleActionSettingsEditorComponent } from './admin-automation-rule-action-settings.component';
import { FormControl, FormBuilder } from '@angular/forms';
import { FieldMap, QueryBuilderConfig } from 'angular2-query-builder';
import { AutomationRuleService } from 'src/app/services/automationRule/automation-rule.service';
import * as _ from "lodash";
import { AutomationRuleActionType } from 'src/app/models/automationRule/automation-rule-action-type.model';
import { DeviceTypeState } from 'src/app/models/device/device-type-state.model';
import {EnergyZone} from "../../../models/energy/energy-zone.model";

@Component({
  selector: 'admin-automation-rule-settings',
  templateUrl: './admin-automation-rule-settings.component.html',
  styleUrls: ['./admin-automation-rule-settings.component.scss']
})
export class AdminAutomationRuleSettingsComponent implements OnInit {

  public allDevices: Device[];
  public inputDevices: Device[];
  public outputDevices: Device[];
  public allZones: EnergyZone[];

  public allRooms: Room[];
  sourceAutomationRuleAction: AutomationRuleAction;

  public allAutomationRuleStartActions: AutomationRuleAction[];
  public allAutomationRuleStopActions: AutomationRuleAction[];
  automationRuleActionDisplayedColumns = ['waitTime', 'device', "deviceFunction", 'actions'];
  automationRuleStartActionDataSource: MatTableDataSource<AutomationRuleAction>;
  automationRuleStopActionDataSource: MatTableDataSource<AutomationRuleAction>;

  public automationRule: AutomationRule;
  public allDeviceConditionType = [];
  public allZoneConditionType = [];


  public startQueryCtrl: FormControl;
  public stopQueryCtrl: FormControl;

  public startQuery = {
    condition: 'and',
    rules: []
  };

  public stopQuery = {
    condition: 'and',
    rules: []
  };

  public config: QueryBuilderConfig;

  public allFieldMap: FieldMap;


  private timeOnlyOnceLabel: string;
  private timeRepeatDailyLabel: string;
  private timeWeekendLabel: string;
  private timeWeekDaysLabel: string;
  private dateOnlyOnceLabel: string;
  private repeatEveryWeekLabel: string;
  private repeatEveryMonthLabel: string;
  private repeatEveryYearLabel: string;
  private sunnyLabel: string;
  private lightRainLabel: string;
  private heavyRainLabel: string;
  private fogLabel: string;
  private snowLabel: string;
  private frostLabel: string;
  private cloudyLabel: string;
  private thunderstormLabel: string;
  private minTempLabel: string;
  private maxTempLabel: string;
  private windTypeLabel: string;
  private precipitationProbLabel: string;
  private timeLabel: string;
  private dateLabel: string;
  private weatherLabel: string;
  private devicesLabel: string;
  private energyLabel: string;

  private functionLabel: string;
  private stateLabel: string;
  private zoneLabel: string;
  private valueLabel: string;
  private deviceLabel: string;

  private dailyConsumptionLabel: string;
  private dailyProductionLabel: string;
  private realtimeConsumptionLabel: string;
  private realtimeProductionLabel: string;

  private monthlyConsumptionLabel: string;
  private monthlyProductionLabel: string;
  private dailySurplusLabel: string;
  private monthlySurplusLabel: string;
  private realtimeSurplusLabel: string;

  // private dailyAverageConsumptionLabel: string;
  // private dailyAverageProductionLabel: string;
  // private averageConsumptionLabel: string;
  // private averageProductionLabel: string;

  public currentConfig: QueryBuilderConfig;
  public allowRuleset: boolean = true;
  public allowCollapse: boolean;
  public deviceGroups: any;


  constructor(
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private automationRuleActionService: AutomationRuleActionService,
    private translationService: AppTranslationService,
    private formBuilder: FormBuilder,
    private automationRuleService: AutomationRuleService
  ) {
    this.automationRuleStartActionDataSource = new MatTableDataSource();
    this.automationRuleStopActionDataSource = new MatTableDataSource();
    this.startQueryCtrl = this.formBuilder.control(this.startQuery);
    this.stopQueryCtrl = this.formBuilder.control(this.stopQuery);
    this.currentConfig = this.config;
    this.setLabels();
  }

  ngOnInit(): void {
    let self = this;

    this.alertService.stopLoadingMessage();

    this.automationRule = this.route.snapshot.data['automationRule'];

    if (this.automationRule.startQuery) {
      this.startQuery = JSON.parse(this.automationRule.startQuery);
    }

    if (this.automationRule.stopQuery) {
      this.stopQuery = JSON.parse(this.automationRule.stopQuery);
    }

    let devicesData = this.route.snapshot.data['devices'];
    if (devicesData) {
      this.allDevices = devicesData.items;
      this.inputDevices = this.filterInputs(this.allDevices);
      this.outputDevices = this.filterOutputs(this.allDevices);

    }

    let zonesData = this.route.snapshot.data['zones'];
    if(zonesData) {
      this.allZones = zonesData.items;
    }

    let roomsData = this.route.snapshot.data['rooms'];
    if (roomsData) {
      this.allRooms = roomsData.items;
    }

    let startActionsPagedData = this.route.snapshot.data['automationRuleStartActions'];
    if (startActionsPagedData) {
      this.allAutomationRuleStartActions = startActionsPagedData.items;
      this.automationRuleStartActionDataSource.data = this.allAutomationRuleStartActions;
    }

    let stopActionsPagedData = this.route.snapshot.data['automationRuleStopActions'];
    if (stopActionsPagedData) {
      this.allAutomationRuleStopActions = stopActionsPagedData.items;
      this.automationRuleStopActionDataSource.data = this.allAutomationRuleStopActions;
    }

    let entities = {
      time: { name: this.timeLabel },
      date: { name: this.dateLabel },
      weather: { name: this.weatherLabel },
      devices: { name: this.devicesLabel },
      energy: { name: this.energyLabel }
    };

    this.allDeviceConditionType = [{ name: this.functionLabel, value: 'function' },
      { name: this.stateLabel, value: 'state' },
      { name: this.valueLabel, value: 'value' },
      { name: this.deviceLabel, value: 'device' }];

    this.allZoneConditionType = [
      { name: this.zoneLabel, value: 'zone' },
      { name: this.valueLabel, value: 'value' }];

    this.config = {
      fields: {
        timeOnce: { name: this.timeOnlyOnceLabel, type: 'timeOnce', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'time' },
        timeDaily: { name: this.timeRepeatDailyLabel, type: 'timeRepeatDaily', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'time' },
        timeWeekend: { name: this.timeWeekendLabel, type: 'timeWeekend', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'time' },
        timeWeekDay: { name: this.timeWeekDaysLabel, type: 'timeWeekDays', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'time' },
        dateOnce: { name: this.dateOnlyOnceLabel, type: 'dateOnce', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'date' },
        dateRepeatWeek: { name: this.repeatEveryWeekLabel, type: 'dateEveryWeek', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'date' },
        dateRepeatMonth: { name: this.repeatEveryMonthLabel, type: 'dateEveryMonth', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'date' },
        dateRepeatYear: { name: this.repeatEveryYearLabel, type: 'dateEveryYear', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'date' },
        weatherSunny: { name: this.sunnyLabel, type: 'weatherSunny', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'weather' },
        weatherLightRain: { name: this.lightRainLabel, type: 'weatherLightRain', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'weather' },
        weatherHeavyRain: { name: this.heavyRainLabel, type: 'weatherHeavyRain', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'weather' },
        weatherFog: { name: this.fogLabel, type: 'weatherFog', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'weather' },
        weatherSnow: { name: this.snowLabel, type: 'weatherSnow', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'weather' },
        weatherFrost: { name: this.frostLabel, type: 'weatherFrost', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'weather' },
        weatherCloudy: { name: this.cloudyLabel, type: 'weatherCloudy', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'weather' },
        weatherThunderStorm: { name: this.thunderstormLabel, type: 'weatherThunderStorm', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'weather' },
        weatherMinTemperature: { name: this.minTempLabel, type: 'weatherMinTemperature', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'weather' },
        weatherMaxTemperature: { name: this.maxTempLabel, type: 'weatherMaxTemperature', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'weather' },
        weatherPrecipitationProb: { name: this.precipitationProbLabel, type: 'weatherPrecipitationProb', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'weather' },
        weatherWindType: { name: this.windTypeLabel, type: 'weatherWindType', operators: ['<', '=', '!=', '<=', '=>', '>'], entity: 'weather' },
      }
    };

    for (let device of this.allDevices) {
      let options = [];
      let ports = [];
      let stateOptions = [];
      let valueOptions = [];

      device.functions.forEach(function (deviceFunction) {
        options.push({ name: self.translateDeviceFunctionName(deviceFunction.name), value: deviceFunction.id });
      });

      device.possibleStates.forEach(function (deviceState) {
        stateOptions.push({ name: deviceState.code, value: deviceState.id });
      });

      device.values.forEach(function (deviceValue) {
        valueOptions.push({
          name: deviceValue.code, value: deviceValue.id, valueType: deviceValue.valueType, acceptedValues: deviceValue.acceptedValues
        });
      });

      device.devicePorts.forEach(function (devicePort) {
        ports.push({ name: devicePort.description, value: devicePort.id });
      });

      let field = {
        name: device.name,
        operators: ['<', '=', '!=', '<=', '=>', '>'],
        type: 'device',
        entity: 'devices',
        options: options,
        devicePorts: ports,
        stateOptions: stateOptions,
        valueOptions: valueOptions
      };

      this.config.fields[device.id] = field;
    }

    for (let zone of this.allZones) {
      let options = [];

      options.push({ name: this.realtimeConsumptionLabel, value: 'realtimeConsumption' });
      options.push({ name: this.dailyConsumptionLabel, value: 'dailyConsumption' });
      options.push({ name: this.monthlyConsumptionLabel, value: 'monthlyConsumption' });
      //options.push({ name: this.dailyAverageConsumptionLabel, value: 'dailyAverageConsumption' });
      //options.push({ name: this.averageConsumptionLabel, value: 'averageConsumption' });
      options.push({ name: this.realtimeProductionLabel, value: 'realtimeProduction' });
      options.push({ name: this.dailyProductionLabel, value: 'dailyProduction' });
      options.push({ name: this.monthlyProductionLabel, value: 'monthlyProduction' });
      options.push({ name: this.realtimeSurplusLabel, value: 'realtimeSurplus' });
      options.push({ name: this.dailySurplusLabel, value: 'dailySurplus' });
      options.push({ name: this.monthlySurplusLabel, value: 'monthlySurplus' });
      //options.push({ name: this.dailyAverageProductionLabel, value: 'dailyAverageProduction' });
      //options.push({ name: this.averageProductionLabel, value: 'averageProduction' });

      let field = {
        name: zone.name,
        operators: ['<', '=', '!=', '<=', '=>', '>'],
        type: 'energy',
        entity: 'energy',
        options: options,
      };

      let fieldLabel = 'energyzone_' + zone.id;
      this.config.fields[fieldLabel] = field;
    }

    this.config.entities = entities;
    this.currentConfig = this.config;

    this.deviceGroups = this.groupBy(this.allDevices, device => device.room.name);
    this.allFieldMap = this.config.fields;
  }

  private groupBy(list, keyGetter) {
    const self = this;
    const map = new Map();
    list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });

    var sortedMap = Array.from(map.keys())
      .sort(function (a, b) { return a > b ? 1 : -1 })
      .map(function (k) {

        let items = map.get(k)
          .sort(function (deviceA, deviceB) { return deviceA.name > deviceB.name ? 1 : -1 })
          .map(function (device) {
            return self.config.fields[device.id];
          })

        return { key: k, value: items };
      });

    return sortedMap;
  }

  public getFieldsOfGroup(entity: any) {
    return this.deviceGroups;
  }

  private saveFailedHelper(error: any) {
    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public editAutomationRuleAction(automationRuleAction: AutomationRuleAction) {
    this.sourceAutomationRuleAction = automationRuleAction;

    if (!automationRuleAction) {
      automationRuleAction = new AutomationRuleAction();
    }

    let dialogRef = this.dialog.open(AdminAutomationRuleActionEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { automationRule: this.automationRule, automationRuleAction: automationRuleAction, devices: this.allDevices, rooms: this.allRooms, actionType: automationRuleAction.actionType }
      });
    dialogRef.afterClosed().subscribe(action => {
      if (action) {
        this.updateAutomationRuleActions(action);
      }
    });
  }

  public createAutomationRuleAction(actionType: AutomationRuleActionType) {

    let automationRuleAction = new AutomationRuleAction();
    this.sourceAutomationRuleAction = automationRuleAction;

    let dialogRef = this.dialog.open(AdminAutomationRuleActionEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { automationRule: this.automationRule, automationRuleAction: automationRuleAction, devices: this.allDevices, rooms: this.allRooms, actionType: actionType }
      });
    dialogRef.afterClosed().subscribe(action => {
      if (action) {
        this.updateAutomationRuleActions(action);
      }
    });
  }

  public editAutomationRuleActionSettings(automationRuleAction?: AutomationRuleAction) {
    this.sourceAutomationRuleAction = automationRuleAction;

    let dialogRef = this.dialog.open(AdminAutomationRuleActionSettingsEditorComponent,
      {
        panelClass: 'mat-dialog-lg',
        data: { automationRuleAction: automationRuleAction, allDevices: this.allDevices }
      });
    dialogRef.afterClosed().subscribe(automationRuleAction => {
      if (automationRuleAction) {
        this.updateAutomationRuleActions(automationRuleAction);
      }
    });
  }

  private confirmAutomationRuleActionDelete(automationRuleAction: AutomationRuleAction) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);

        this.automationRuleActionService.deleteAutomationRuleAction(automationRuleAction.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();

            if (automationRuleAction.actionType == AutomationRuleActionType.START) {
              this.automationRuleStartActionDataSource.data = this.automationRuleStartActionDataSource.data.filter(item => item !== automationRuleAction)
            }
            else {
              this.automationRuleStopActionDataSource.data = this.automationRuleStopActionDataSource.data.filter(item => item !== automationRuleAction)
            }
          },
            error => {
              this.alertService.stopLoadingMessage();

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }

  private filterInputs(devices: Device[]): Device[] {
    return devices.filter(x => x.ioType == IoType.Input || x.ioType == IoType.Both);
  }

  private filterOutputs(devices: Device[]): Device[] {
    return devices.filter(x => x.ioType == IoType.Output || x.ioType == IoType.Both);
  }

  private updateAutomationRuleActions(automationRuleAction: AutomationRuleAction) {
    if (this.sourceAutomationRuleAction) {
      Object.assign(this.sourceAutomationRuleAction, automationRuleAction);
      this.sourceAutomationRuleAction = null;

      if (automationRuleAction.actionType == AutomationRuleActionType.START) {
        this.loadStartAutomationRuleActions();
      }
      else {
        this.loadStopAutomationRuleActions();
      }
    }

    this.refreshAutomationRuleActions();
  }

  public applyAutomationRuleStartActionFilter(filterValue: string) {
    this.automationRuleStartActionDataSource.filter = filterValue;
  }

  public applyAutomationRuleStopActionFilter(filterValue: string) {
    this.automationRuleStopActionDataSource.filter = filterValue;
  }

  private refreshAutomationRuleActions() {
    this.applyAutomationRuleStartActionFilter(this.automationRuleStartActionDataSource.filter);
    this.applyAutomationRuleStopActionFilter(this.automationRuleStopActionDataSource.filter);
  }

  public cancel() {

    this.alertService.resetStickyMessage();

    this.router.navigate(['admin/automationrules']);
  }

  private saveSuccessHelper(automationRule?: AutomationRule) {
    this.alertService.stopLoadingMessage();

    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);

  }

  public save() {

    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    this.automationRule.startQuery = JSON.stringify(this.startQuery);
    this.automationRule.stopQuery = JSON.stringify(this.stopQuery);

    this.automationRuleService.updateAutomationRule(this.automationRule).subscribe(
      response => this.saveSuccessHelper(this.automationRule),
      error => this.saveFailedHelper(error));
  }

  private loadStartAutomationRuleActions() {
    this.automationRuleActionService.getAutomationRuleActions(this.automationRule.id, AutomationRuleActionType.START, 0, 999).subscribe(
      response => {
        this.allAutomationRuleStartActions = response.items;
        this.automationRuleStartActionDataSource.data = this.allAutomationRuleStartActions;

      }
    );
  }

  private loadStopAutomationRuleActions() {
    this.automationRuleActionService.getAutomationRuleActions(this.automationRule.id, AutomationRuleActionType.STOP, 0, 999).subscribe(
      response => {
        this.allAutomationRuleStopActions = response.items;
        this.automationRuleStopActionDataSource.data = this.allAutomationRuleStopActions;
      }
    );
  }

  public translateDeviceFunctionName(functionName: string): string {
    try {
      return this.translationService.getTranslation(`lookups.DeviceFuntions.${functionName}`);
    } catch (e) {
      return functionName;
    }
  }

  public onEntityChange(event: any, rule: any) {
    console.log(rule);
  }

  public setLabels() {
    this.timeOnlyOnceLabel = this.translationService.getTranslation('shared.TimeOnlyOnce');
    this.timeRepeatDailyLabel = this.translationService.getTranslation('shared.TimeRepeatDaily');
    this.timeWeekendLabel = this.translationService.getTranslation('shared.TimeWeekend');
    this.timeWeekDaysLabel = this.translationService.getTranslation('shared.TimeWeekDay');
    this.dateOnlyOnceLabel = this.translationService.getTranslation('shared.DateOnlyOnce');
    this.repeatEveryWeekLabel = this.translationService.getTranslation('shared.RepeatEveryWeek');
    this.repeatEveryMonthLabel = this.translationService.getTranslation('shared.RepeatEveryMonth');
    this.repeatEveryYearLabel = this.translationService.getTranslation('shared.RepeatEveryYear');
    this.sunnyLabel = this.translationService.getTranslation('shared.Sunny');
    this.lightRainLabel = this.translationService.getTranslation('shared.LightRain');
    this.heavyRainLabel = this.translationService.getTranslation('shared.HeavyRain');
    this.fogLabel = this.translationService.getTranslation('shared.Fog');
    this.snowLabel = this.translationService.getTranslation('shared.Snow');
    this.frostLabel = this.translationService.getTranslation('shared.Frost');
    this.cloudyLabel = this.translationService.getTranslation('shared.Cloudy');
    this.thunderstormLabel = this.translationService.getTranslation('shared.ThunderStorm');
    this.minTempLabel = this.translationService.getTranslation('shared.MinTemp');
    this.maxTempLabel = this.translationService.getTranslation('shared.MaxTemp');
    this.windTypeLabel = this.translationService.getTranslation('shared.WindType');
    this.precipitationProbLabel = this.translationService.getTranslation('shared.PrecipitationProb');
    this.timeLabel = this.translationService.getTranslation('shared.Time');
    this.dateLabel = this.translationService.getTranslation('shared.Date');
    this.weatherLabel = this.translationService.getTranslation('shared.Weather');
    this.devicesLabel = this.translationService.getTranslation('shared.Devices');
    this.energyLabel = this.translationService.getTranslation('shared.Energy');

    this.functionLabel = this.translationService.getTranslation('shared.Function');
    this.stateLabel = this.translationService.getTranslation('shared.State');
    this.zoneLabel = this.translationService.getTranslation('shared.Zone');
    this.valueLabel = this.translationService.getTranslation('shared.Value');
    this.deviceLabel = this.translationService.getTranslation('shared.Device');

    this.dailyConsumptionLabel = this.translationService.getTranslation('shared.DailyConsumption');
    this.dailyProductionLabel = this.translationService.getTranslation('shared.DailyProduction');
    this.monthlyConsumptionLabel = this.translationService.getTranslation('shared.MonthlyConsumption');
    this.monthlyProductionLabel = this.translationService.getTranslation('shared.MonthlyProduction');

    this.realtimeConsumptionLabel = this.translationService.getTranslation('shared.RealtimeConsumption');
    this.realtimeProductionLabel = this.translationService.getTranslation('shared.RealtimeProduction');
    // this.dailyAverageConsumptionLabel = this.translationService.getTranslation('shared.DailyAverageConsumption');
    // this.dailyAverageProductionLabel = this.translationService.getTranslation('shared.DailyAverageProduction');
    // this.averageConsumptionLabel = this.translationService.getTranslation('shared.AverageConsumption');
    // this.averageProductionLabel = this.translationService.getTranslation('shared.AverageProduction');

    this.dailySurplusLabel = this.translationService.getTranslation('shared.DailySurplus');
    this.monthlySurplusLabel = this.translationService.getTranslation('shared.MonthlySurplus');
    this.realtimeSurplusLabel = this.translationService.getTranslation('shared.RealtimeSurplus');
  }
}
