import { Component, OnInit, AfterViewInit } from '@angular/core';
import { fadeInOut } from '../../services/animations';
import { ConfigurationService } from '../../services/configuration.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { RoomService } from '../../services/room/room.service';
import { Room } from '../../models/room/room.model';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";

@Component({
  selector: 'room-list',
  templateUrl: './room-list.component.html',
  styleUrls: ['./room-list.component.scss'],
  animations: [fadeInOut]
})

export class RoomListComponent implements OnInit {

  loadingIndicator: boolean;
  allRooms: Room[] = [];

  private allRoomsHubConnection: HubConnection;

  constructor(
    public configurations: ConfigurationService,
    public roomService: RoomService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {

    this.initHubConnection();
  }

  ngOnInit(): void {

    let roomsData = this.route.snapshot.data['rooms'];
    if (roomsData) {
      this.allRooms = roomsData.items.sort(function (a, b) {
        if (a.tabOrder > b.tabOrder) {
          return 1;
        }
        if (a.tabOrder < b.tabOrder) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      this.connectToHub();
    }
  }

  private connectToHub() {
    let self = this;

    if (this.allRoomsHubConnection.state === HubConnectionState.Disconnected) {

      this.allRoomsHubConnection.start().then(() => {
        this.allRoomsHubConnection.invoke("emitStatus").catch();
      }).catch(function (err) {
      });

    } else if (this.allRoomsHubConnection.state === HubConnectionState.Connected) {
      this.allRoomsHubConnection.invoke("emitStatus").catch(function (err) {
      });
    }
  }

  private initHubConnection() {
    let self = this;

    let builder = new HubConnectionBuilder();

    this.allRoomsHubConnection = builder
      .withUrl(location.origin + '/hubs/allroomstatus',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken,
        })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    this.allRoomsHubConnection.on("allroomstatus", (message) => {

      console.log(message);

      self.updateAllDeviceStatus(message);
    });
  }

  private updateAllDeviceStatus(message: any) {
    console.log(message);

  }

  public roomDetails(room?: Room) {

    this.router.navigate(['/room', room.id]);
  }
}
