import { Component, Input, OnDestroy } from '@angular/core';
import { Utilities } from 'src/app/services/utilities';
import { RoomService } from 'src/app/services/room/room.service';
import { RoomAction } from 'src/app/models/room/room-action.model';
import { RoomActionService } from 'src/app/services/room/room-action.service';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'windows-card',
  templateUrl: './windows-card.component.html',
  styleUrls: ['./windows-card.component.scss']
})

export class WindowsCardComponent implements OnDestroy {
  
  windowOpened: number = 0;
  totalWindows: number = 0;


  private hubConnection: HubConnection;

  iconWindow= require("../../../assets/images/OpenWindow.svg");

  constructor(private authService: AuthService) {
  }
    
  ngOnInit() {

    this.buildHubConnection();
  }

  private buildHubConnection() {
    let self = this;

    let builder = new HubConnectionBuilder();

    this.hubConnection = builder
      .withUrl(location.origin + '/hubs/windowsMonitoring',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.on("windowsMonitoring", (message) => {
      this.windowOpened = message.numberOfIssues;
      this.totalWindows = message.numberOfDevices;
    });

    this.hubConnection.start().then(() => {
      this.hubConnection.invoke("windowsMonitoring").catch(err => console.error(err.toString()));
    }).catch(function (err) {
    });
  }

  ngOnDestroy(): void {
    this.hubConnection.stop();
  }
}
