import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Field, FieldMap, Rule } from "angular2-query-builder";
import { fadeInOut } from "../../../../services/animations";
import { AppTranslationService } from "../../../../services/app-translation.service";

@Component({
    selector: "condition-device-input",
    templateUrl: './condition-device-input.component.html',
    styleUrls: ['./condition-device-input.component.scss'],
  animations: [fadeInOut],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConditionDeviceInputComponent),
      multi: true
    }
  ]
})
export class ConditionDeviceInputComponent implements OnInit, ControlValueAccessor, OnChanges  {
 
  @Input() model: any;
  @Input() field: any;
  @Input() fields: any;
  @Input() deviceGroups: any;

  public secondDevice: any;
  public devicePorts: any[] = [];
  public valueOptions: any[] = [];


    constructor(
        private translationService: AppTranslationService,
    ) {
      
  }

  ngOnInit(): void {
    if (this.model !== undefined) {
      this.valueId = this.model.valueId;
      this.secondValueId = this.model.secondValueId;
      this.secondDeviceId = this.model.secondDeviceId;
      this.secondDevicePortId = this.secondDevicePortId;
      if (this.model.secondDeviceId !== undefined) {
        this.secondDevice = this.fields[this.model.secondDeviceId];
        if (this.secondDevice != undefined) {
          this.valueOptions = this.secondDevice.valueOptions;
          this.devicePorts = this.secondDevice.devicePorts;
        }
      }
    }
      
  }

  ngOnChanges(changes: SimpleChanges) {

    
  }

  onChange: any = () => {
  }
  onTouch: any = () => { }

  get valueId(): any {
    if (this.model !== undefined) {
      return this.model.valueId;
    }

    return '';
  }

  set valueId(val: any) {
    if (val !== undefined && this.model !== undefined && this.model.valueId !== val) {
      this.model.valueId = val;
      this.onChange(this.model);
      this.onTouch(this.model);
    }
  }

  get secondValueId(): any {
    if (this.model !== undefined) {
      return this.model.secondValueId;
    }

    return '';
  }

  set secondValueId(val: any) {
    if (val !== undefined && this.model !== undefined && this.model.secondValueId !== val) {
      this.model.secondValueId = val;
      this.onChange(this.model);
      this.onTouch(this.model);
    }
  }

  get secondDeviceId(): any {
    
    if (this.model !== undefined) {
      return this.model.secondDeviceId;
    }

    return '';
  }

  set secondDeviceId(val: any) {
    if (val !== undefined && this.model !== undefined && this.model.secondDeviceId !== val) {
      this.model.secondDeviceId = val;

      this.secondDevice = this.fields[this.model.secondDeviceId];
      this.valueOptions = this.secondDevice.valueOptions;
      this.devicePorts = this.secondDevice.devicePorts;

      this.onChange(this.model);
      this.onTouch(this.model);
    }
  }

  get secondDevicePortId(): any {
    if (this.model !== undefined) {
      return this.model.secondDevicePortId;
    }

    return '';
  }

  set secondDevicePortId(val: any) {
    if (val !== undefined && this.model !== undefined && this.model.secondDevicePortId !== val) {
      this.model.secondDevicePortId = val;
      this.onChange(this.model);
      this.onTouch(this.model);
    }
  }

  writeValue(value: any) {
   // this.value = value
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn
  }
}
