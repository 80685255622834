import { Component, Input, OnDestroy } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { ClimatizationService } from '../../../services/climatization/climatization.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { AuthService } from '../../../services/auth.service';

@Component({
  selector: 'humidity-card',
  templateUrl: './humidity-card.component.html',
  styleUrls: ['./humidity-card.component.scss']
})

export class humidityCardComponent implements OnDestroy {

  //gaugeThick = "10";
  gaugeValue = 0;
  gaugeLabel = "Humidade";
  gaugeAppendText = "%";

  @Input() roomId: number;

  private hubConnection: HubConnection;

  iconHumidity = require("../../../assets/images/agua01.svg");
  
  constructor(private authService: AuthService) {
    }
    ngOnDestroy(): void {
      this.hubConnection.stop();
    }
  
  ngOnInit() {

    this.buildHubConnection();
  }

  private buildHubConnection() {
    let self = this;

    let builder = new HubConnectionBuilder();

    this.hubConnection = builder
      .withUrl(location.origin + '/hubs/currentHumidity',
        {
          transport: HttpTransportType.WebSockets,
          skipNegotiation: true,
          accessTokenFactory: () => this.authService.accessToken
        })
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    this.hubConnection.on("currentHumidity", (message) => {
      if (message && message.humidity) {
        self.gaugeValue = message.humidity;
      }
    });

    this.hubConnection.start().then(() => {
      this.hubConnection.invoke("emitHumidity", self.roomId).catch(err => console.error(err.toString()));
    }).catch(function (err) {
    });
  }
}
