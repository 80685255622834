import { DeviceFunction } from "../device/device-function.model";
import { DevicePort } from "../device/device-port.model";
import { Device } from "../device/device.model";
import { RoomAction } from "./room-action.model";
import {RoomActionFunctionParameter} from "./room-action-function-parameter.model";

export class RoomActionMap {

  constructor(id: number = 0, waitTime: number, roomAction?: RoomAction, roomActionId?: number, outputDeviceId?: number, outputDevice?: Device, outputFunction?: DeviceFunction, outputFunctionId?: number, parameters?: RoomActionFunctionParameter[] ) {

    this.id = id;
    this.waitTime = waitTime;
    this.roomAction = roomAction;
    this.outputDevice = outputDevice;
    this.outputDeviceId = outputDeviceId;
    this.outputFunction = outputFunction;
    this.outputFunctionId = outputFunctionId;
    this.parameters = parameters;
    this.roomActionId = roomActionId;
  }

  public id: number;
  public waitTime: number;
  public roomActionId: number;
  public roomAction: RoomAction;
  public outputDeviceId: number;
  public outputDevice: Device;
  public outputFunction: DeviceFunction;
  public outputFunctionId: number;
  public parameters: RoomActionFunctionParameter[];
}
