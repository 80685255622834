import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Alarm } from 'src/app/models/alarm/alarm.model';
import { AlarmService } from 'src/app/services/alarm/alarm.service';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { AlarmType } from '../../models/alarm/alarm-type.model';
import { AlarmZoneState } from '../../models/alarm/alarm-zone-state.model';

@Component({
  selector: 'alarm-card',
  templateUrl: './alarm-card.component.html',
  styleUrls: ['./alarm-card.component.scss']
})
export class AlarmCardComponent implements OnInit {
  @Input() alarm: Alarm;

  public currentProfile: string;

  constructor(private alarmService: AlarmService, private router: Router, private translationService: AppTranslationService) {
  }

  ngOnInit() {
  }
  
  public getIcon(alarmType: AlarmType): string {
    switch (alarmType) {

      case AlarmType.Security:
        {
          if (this.alarm.state === AlarmZoneState.Off) {
            return './assets/icons/icon_intrusion.svg';
          } else {
            return './assets/icons/icon_intrusion_02.svg';
          }
        }
      case AlarmType.Fire:
        {
          if (this.alarm.state === AlarmZoneState.Off) {
            return './assets/icons/icon_fire.svg';
          } else {
            return './assets/icons/icon_fire_02.svg';
          }
        }
      case AlarmType.Flood:
        {
          if (this.alarm.state === AlarmZoneState.Off) {
            return './assets/icons/icon_flood.svg';
          } else {
            return './assets/icons/icon_flood_02.svg';
          }
        }
      default:
        return './assets/icons/icon_security.svg';
    }
  }

  public getState(alarm: Alarm): string {
    let state = '';
    try {
      state = this.translationService.getTranslation(`lookups.AlarmZoneState.${AlarmZoneState[alarm.state]}`);
    } catch (e) {
      return state;
    }

    return state;
  }

  public changeProfile(alarmId: number, profileId: number) {
    this.alarmService.changeProfile(alarmId, profileId);
  }

  public changeState(alarmId: number, state: number) {
    this.alarmService.changeState(alarmId, state);
  }

  public editAlarm(alarm?: Alarm) {
    this.router.navigate(['alarm', alarm.id]);
  }
}
