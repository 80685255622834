export enum HouseEventsSourceType {
  None = 0,
  Alarm = 1,
  Uapp = 2,
  Device = 3,
  IowHub = 4,
  Umaster = 5,
  Automation = 6,
  Schedule = 7,
  AccessControl = 8,
  Ioc = 9
}
