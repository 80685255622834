import { Component, Input, ViewChild, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FloorService } from "../../../services/floor/floor.service";
import { Floor } from '../../../models/floor/floor.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {AppTranslationService} from "../../../services/app-translation.service";

@Component({
  selector: 'admin-floor-editor',
  templateUrl: './admin-floor-editor.component.html',
  styleUrls: ['./admin-floor-editor.component.scss']
})
export class AdminFloorEditorComponent {
  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewFloor = false;
  private isSaving: boolean;
  private onFloorSaved = new Subject<Floor>();

  private floor: Floor = new Floor();

  floorForm: FormGroup;
  floorSaved$ = this.onFloorSaved.asObservable();

  get name() {
    return this.floorForm.get('name');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminFloorEditorComponent>,
    private alertService: AlertService,
    private floorService: FloorService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { floor: Floor },
  )
  {
    this.floor = data.floor;
    this.buildForm();
    this.resetForm();
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.floorForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    if (this.isSaving) {
      return;
    }

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    const editedFloor = this.getEditedFloor();

    if (this.floor.id == 0) {
      this.isNewFloor = true;
      this.floorService.newFloor(editedFloor).subscribe(
        floor => this.saveSuccessHelper(editedFloor),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewFloor = false;
      this.floorService.updateFloor(editedFloor).subscribe(
        response => this.saveSuccessHelper(editedFloor),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedFloor(): Floor {
    const formModel = this.floorForm.value;

    return {
      id: this.floor.id,
      name: formModel.name,
      description: formModel.description
    };
  }

  private saveSuccessHelper(floor?: Floor) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewFloor) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onFloorSaved.next(floor);

    this.dialogRef.close(floor);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);

    this.enableSaveButton();
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.enableSaveButton();

    this.floorForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.required, Validators.maxLength(30)])],
      description: ['', Validators.maxLength(60)]
    });
  }

  private resetForm(replace = false) {
    this.floorForm.reset({
      name: this.floor.name || '',
      description: this.floor.description || ''
    });
  }
}
