export enum HouseEventType {
  None = 0,
  AlarmTrigger = 1,
  AlarmAction = 2,
  AlarmProfile = 3,
  AlarmKeys = 4,
  DeviceFunction = 5,
  AutomationTrigger = 6,
  AutomationAction = 7
}
