import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';
import { endianness } from 'os';


@Injectable()
export class AlarmEndpoint extends EndpointFactory {

  private readonly _alarmUrl: string = "/api/alarm";

  get alarmUrl() { return this.configurations.baseUrl + this._alarmUrl; }


  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getAlarmEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.alarmUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAlarmEndpoint(id));
      }));
  }

  changeProfile<T>(id: number, profileId: number): Observable<T> {
    let endpointUrl = `${this.alarmUrl}/${id}/profile/${profileId}`;

    return this.http.post(endpointUrl, [], this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.changeProfile(id, profileId));
      }));
  }

  changeState<T>(id: number, state: number): Observable<T> {
    let endpointUrl = `${this.alarmUrl}/${id}/state/${state}`;

    return this.http.post(endpointUrl, [], this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.changeState(id, state));
      }));
  }
  
  getAlarmsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._alarmUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAlarmsEndpoint(page, pageSize));
      }));
  }

  getNewAlarmEndpoint<T>(alarmObject: any): Observable<T> {

    return this.http.post<T>(this.alarmUrl, JSON.stringify(alarmObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewAlarmEndpoint(alarmObject));
      }));
  }

  getUpdateAlarmEndpoint<T>(alarmObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.alarmUrl}/${id}` : `${this.alarmUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(alarmObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateAlarmEndpoint(alarmObject, id));
      }));
  }

  getDeleteAlarmEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.alarmUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteAlarmEndpoint(id));
      }));
  }
}
