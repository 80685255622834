import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/catch';
import { EndpointFactory } from './services/endpoint-factory.service';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './services/configuration.service';

@Injectable()
export class UploadDownloadService extends EndpointFactory {
  private baseApiUrl: string;
  private apiDownloadUrl: string;
  private apiUploadUrl: string;
  private apiFileUrl: string;

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);

    this.baseApiUrl = configurations.baseUrl;
    this.apiDownloadUrl = this.baseApiUrl + '/api/MasterSettings/BackupDatabase';
    this.apiUploadUrl = this.baseApiUrl + '/api/MasterSettings/RestoreDatabase';
  }

  public downloadFile() {
    return this.http.get(this.apiDownloadUrl,
      { headers: this.getBlobRequestHeaders().headers, responseType: 'blob' });
  }

  public upload(data: FormData) {
    return this.http.post(this.apiUploadUrl,
      data,
      { headers: this.getBlobRequestHeaders().headers, responseType: 'blob', reportProgress: true, observe: 'events' });
  }
}
