import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { EventNotificationEndpoint } from './event-notification-endpoint.service';
import { EventNotification } from '../../models/eventNotification/event-notification.model';
import { PagedData } from '../../models/PagedData';
import { Device } from '../../models/device/device.model';

@Injectable()
export class EventNotificationService {

  constructor(private router: Router, private http: HttpClient,
    private eventNotificationEndpoint: EventNotificationEndpoint) {

  }
  
  getEventNotification(id?: number): Observable<EventNotification> {
    if (id) {
      return this.eventNotificationEndpoint.getEventNotificationEndpoint<EventNotification>(id);
    }

    
  }

  getEventNotifications(page: number = 0, pageSize: number = 9999): Observable<PagedData<EventNotification>> {

    return this.eventNotificationEndpoint.getEventNotificationsEndpoint<PagedData<EventNotification>>(page, pageSize);
  }

  getAlarms(page: number = 0, pageSize: number = 9999): Observable<PagedData<EventNotification>> {

    return this.eventNotificationEndpoint.getAlarmsEndpoint<PagedData<EventNotification>>(page, pageSize);
  }
}
