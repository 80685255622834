import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { AutomationRuleEndpoint } from './automation-rule-endpoint.service';
import { AutomationRule } from '../../models/automationRule/automation-rule.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class AutomationRuleService {

  constructor(private router: Router, private http: HttpClient,
    private automationRuleEndpoint: AutomationRuleEndpoint) {

  }
  
  getAutomationRule(id?: number): Observable<AutomationRule> {
    if (id) {
      return this.automationRuleEndpoint.getAutomationRuleEndpoint<AutomationRule>(id);
    }

    
  }

  getAutomationRules(page: number = 0, pageSize: number = 9999): Observable<PagedData<AutomationRule>>{

    return this.automationRuleEndpoint.getAutomationRulesEndpoint<PagedData<AutomationRule>>(page, pageSize);
  }
  
  updateAutomationRule(automationRule: AutomationRule): Observable<AutomationRule> {
    return this.automationRuleEndpoint.getUpdateAutomationRuleEndpoint(automationRule, automationRule.id);
  }
  
  newAutomationRule(automationRule: AutomationRule): Observable<AutomationRule>{
    return this.automationRuleEndpoint.getNewAutomationRuleEndpoint<AutomationRule>(automationRule);
  }
  
  deleteAutomationRule(id): Observable<AutomationRule> {

    return this.automationRuleEndpoint.getDeleteAutomationRuleEndpoint(id);
  }

  changeState(id: number, state: number): Observable<any> {
    return this.automationRuleEndpoint.changeStateEndpoint(id, state);
  }
}
