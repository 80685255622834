import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable} from 'rxjs';
import { PagedData } from '../../models/PagedData';
import {AccessZoneEndpoint} from "./access-zone-endpoint.service";
import {AccessZone} from "../../models/accessZone/access-zone.model";

@Injectable()
export class AccessZoneService {

  constructor(private router: Router, private http: HttpClient,
    private accessZoneEndpoint: AccessZoneEndpoint) {

  }

  getAccessZone(id?: number): Observable<AccessZone> {
    if (id) {
      return this.accessZoneEndpoint.getAccessZoneEndpoint<AccessZone>(id);
    }
  }

  getAccessZones(page: number = 0, pageSize: number = 9999): Observable<PagedData<AccessZone>>{

    return this.accessZoneEndpoint.getAccessZonesEndpoint<PagedData<AccessZone>>(page, pageSize);
  }

  updateAccessZone(accessZone: AccessZone): Observable<AccessZone> {
    return this.accessZoneEndpoint.getUpdateAccessZoneEndpoint(accessZone, accessZone.id);
  }

  newAccessZone(accessZone: AccessZone): Observable<AccessZone>{
    return this.accessZoneEndpoint.getNewAccessZoneEndpoint<AccessZone>(accessZone);
  }

  deleteAccessZone(id): Observable<AccessZone> {

    return this.accessZoneEndpoint.getDeleteAccessZoneEndpoint(id);
  }
}
