import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Camera } from '../../models/device/camera.model';
import { PagedData } from '../../models/PagedData';
import { CameraEndpoint } from './camera-endpoint.service';


@Injectable()
export class CameraService {

  constructor(private router: Router, private http: HttpClient,
    private cameraEndpoint: CameraEndpoint) {

  }
  
  getStream(id?: number) {
    if (id) {
      return this.cameraEndpoint.getCameraStreamEndpoint(id);
    }
  }

  getCameras(page: number = 0, pageSize: number = 9999) {

    return this.cameraEndpoint.getCamerasEndpoint<PagedData<Camera>>(page, pageSize);
  }
}
