import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource, Sort } from '@angular/material';
import { Room } from '../../../models/room/room.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { AppTranslationService } from "../../../services/app-translation.service";
import { RoomService } from '../../../services/room/room.service';
import { Utilities } from '../../../services/utilities';
import { Device } from '../../../models/device/device.model';
import { DeviceService } from '../../../services/device/device.service';
import {Router} from "@angular/router";
import {DeviceGroup} from "../../../models/deviceGroup/device-group.model";
import {DeviceGroupService} from "../../../services/deviceGroup/device-group.service";
import {AdminDeviceGroupEditorComponent} from "./admin-device-group-editor.component";
import {DeviceCategory} from "../../../models/device/device-category.model";


@Component({
  selector: "admin-device-group-list.component",
  templateUrl: './admin-device-group-list.component.html',
  styleUrls: ['./admin-device-group-list.component.scss'],
  animations: [fadeInOut]
})
export class AdminDeviceGroupListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns = ['name', 'category', 'room', 'actions'];
  dataSource: MatTableDataSource<DeviceGroup>;
  sourceDeviceGroup: DeviceGroup;
  loadingIndicator: boolean;

  allRooms: Room[] = [];
  allDevices: Device[] = [];

  constructor(
    private alertService: AlertService,
    private translationService: AppTranslationService,
    private deviceGroupService: DeviceGroupService,
    private devicesService: DeviceService,
    private roomService: RoomService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    public router: Router,
  ) {
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data, filter) => this.filterData(data, filter);
  }

  public applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue;
  }

  private refresh() {
    // Causes the filter to refresh there by updating with recently added data.
    this.applyFilter(this.dataSource.filter);
  }

  private updateDeviceGroups(deviceGroup: DeviceGroup) {
    if (this.sourceDeviceGroup) {
      Object.assign(this.sourceDeviceGroup, deviceGroup);
      this.sourceDeviceGroup = null;
    }
    else {
      this.dataSource.data.unshift(deviceGroup);
    }

    this.loadData();
    this.refresh();
  }

  private loadData() {
    var self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.roomService.getRooms()
      .subscribe(results => {
        this.allRooms = results.items;
      },
        error => {
          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
      });

    this.devicesService.getDevices()
      .subscribe(results => {
        this.allDevices = results.items;
      },
        error => {
          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });

    this.deviceGroupService.getDeviceGroups(this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(results => {
        this.alertService.stopLoadingMessage();
        this.loadingIndicator = false;

        this.dataSource.data = results.items;
      },
        error => {
          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          this.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          this.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }

  public editDeviceGroup(deviceGroup?: DeviceGroup) {
    this.sourceDeviceGroup = deviceGroup;

    if (!deviceGroup) {
      deviceGroup = new DeviceGroup();
    }

    let dialogRef = this.dialog.open(AdminDeviceGroupEditorComponent,
      {
        panelClass: 'mat-dialog-sm',
        data: { deviceGroup: deviceGroup, rooms: [...this.allRooms], devices: [...this.allDevices]}
      });
    dialogRef.afterClosed().subscribe(deviceGroup => {
      if (deviceGroup) {
        this.updateDeviceGroups(deviceGroup);
      }
    });
  }

  public translateCategory(category: DeviceCategory): string {
    try {
      return this.translationService.getTranslation(`lookups.deviceCategory.${DeviceCategory[category]}`);
    } catch (e) {
      return category.toString();
    }
  }

  private confirmDelete(deviceGroup: DeviceGroup) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
        this.alertService.startLoadingMessage(deletingLabel);
        this.loadingIndicator = true;

        this.deviceGroupService.deleteDeviceGroup(deviceGroup.id)
          .subscribe(results => {
            this.alertService.stopLoadingMessage();
            this.loadingIndicator = false;
            this.dataSource.data = this.dataSource.data.filter(item => item !== deviceGroup)
          },
            error => {
              this.alertService.stopLoadingMessage();
              this.loadingIndicator = false;

              this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
                MessageSeverity.error, error);
            });
      });
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data;
    let sortedData = this.dataSource.data
    if (!sort.active || sort.direction === '') {
      sortedData = data;
      return;
    }

    sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return this.compare(a.name, b.name, isAsc);
        case 'room': return this.compare(a.room ? a.room.name : '', b.room ? b.room.name : '', isAsc);
        default: return 0;
      }
    });

    this.dataSource.data = sortedData;
  }

  public editDeviceGroupSettings(deviceGroup?: DeviceGroup) {
    this.sourceDeviceGroup = deviceGroup;

    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.router.navigate([`admin/devicegroups/${deviceGroup.id}/settings`]);
  }

  public compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  private filterData(input: DeviceGroup, filter: string): boolean {
    return Utilities.searchArray(filter, false,
      input.room != null ? input.room.name : null);
  }
}
