import { Component, OnInit, AfterViewInit, TemplateRef, ViewChild, Input } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource, MatSnackBar, MatDialog, PageEvent } from '@angular/material';

import { fadeInOut } from '../../../services/animations';
import { AlertService, DialogType, MessageSeverity } from '../../../services/alert.service';
import { AppTranslationService } from "../../../services/app-translation.service";
import { AutomationRuleService } from '../../../services/automationRule/automation-rule.service';
import { Utilities } from '../../../services/utilities';
import { AutomationRule } from '../../../models/automationRule/automation-rule.model';
import { AdminAutomationRuleEditorComponent } from "./admin-automation-rule-editor.component";
import { AdminAutomationRuleSettingsComponent } from './admin-automation-rule-settings.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
    selector: "admin-automation-rule-list",
    templateUrl: './admin-automation-rule-list.component.html',
    styleUrls: ['./admin-automation-rule-list.component.scss'],
    animations: [fadeInOut]
})
export class AdminAutomationRuleListComponent implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    allAutomations: AutomationRule[];
    displayedColumns = ['name', 'description', 'actions'];
    dataSource: MatTableDataSource<AutomationRule>;
    sourceAutomationRule: AutomationRule;
    editingAutomationRuleName: { name: string };
    loadingIndicator: boolean;

    constructor(
        private alertService: AlertService,
        private translationService: AppTranslationService,
        private automationRuleService: AutomationRuleService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        public router: Router,
        private route: ActivatedRoute,
    ) {
        this.dataSource = new MatTableDataSource();
    }

    ngOnInit() {
        let automations = this.route.snapshot.data['automations'];
        if (automations) {
            this.allAutomations = automations.items;
            this.dataSource.data = this.allAutomations;
        }
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.data = this.allAutomations;
        this.refresh();
    }

    public applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue;
    }

    private refresh() {
        // Causes the filter to refresh there by updating with recently added data.
        this.applyFilter(this.dataSource.filter);
    }

    private updateAutomationRules(automationRule: AutomationRule) {
        this.loadData(true);
    }

    private loadData(orderById: boolean) {
        var self = this;
        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;

        this.automationRuleService.getAutomationRules(0, 999)
            .subscribe(results => {
                self.alertService.stopLoadingMessage();
                self.loadingIndicator = false;

                self.allAutomations = results.items;
                self.dataSource.data = results.items;

                if (orderById == true) {
                    self.sort.active = 'id';
                    self.sort.direction = 'desc';
                    self.sort.sortChange.emit();
                    self.sort._stateChanges.next();
                }

            },
                error => {
                    self.alertService.stopLoadingMessage();
                    self.loadingIndicator = false;

                  let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
                  let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

                    self.alertService.showStickyMessage(errorLabel, errorDetail,
                        MessageSeverity.error, error);
                });
    }

    public editAutomationRule(automationRule?: AutomationRule) {
        this.sourceAutomationRule = automationRule;

        if (!automationRule) {
            automationRule = new AutomationRule();
        }

        let dialogRef = this.dialog.open(AdminAutomationRuleEditorComponent,
            {
                panelClass: 'mat-dialog-sm',
                data: { automationRule: automationRule }
            });
        dialogRef.afterClosed().subscribe(automationRule => {
            if (automationRule) {
                this.updateAutomationRules(automationRule);
            }
        });
    }

    private confirmDelete(automationRule: AutomationRule) {

      let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
      let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
      let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
      let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
      let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

        this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
            .onAction().subscribe(() => {
                this.alertService.startLoadingMessage(deletingLabel);
                this.loadingIndicator = true;

                this.automationRuleService.deleteAutomationRule(automationRule.id)
                    .subscribe(results => {
                        this.alertService.stopLoadingMessage();
                        this.loadingIndicator = false;
                        this.dataSource.data = this.dataSource.data.filter(item => item !== automationRule)
                    },
                        error => {
                            this.alertService.stopLoadingMessage();
                            this.loadingIndicator = false;

                            this.alertService.showStickyMessage(deleteErrorDetail, deleteErrorLabel,
                                MessageSeverity.error, error);
                        });
            });
    }

    public editAutomationRuleSettings(automationRule?: AutomationRule) {
        this.sourceAutomationRule = automationRule;

        this.alertService.startLoadingMessage();
        this.loadingIndicator = true;

        this.router.navigate(['/admin/automationrulesettings', automationRule.id]);
    }
}
