import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, forkJoin } from 'rxjs';
import {catchError, mergeMap, tap} from 'rxjs/operators';

import { RoomActionEndpoint } from './room-action-endpoint.service';
import { RoomAction } from '../../models/room/room-action.model';
import { PagedData } from '../../models/PagedData';
import {RoomActionMap} from "../../models/room/room-action-map.model";
import {RoomActionFunctionParameter} from "../../models/room/room-action-function-parameter.model";

@Injectable()
export class RoomActionService {

  constructor(private router: Router, private http: HttpClient,
    private roomActionEndpoint: RoomActionEndpoint) {

  }

  getRoomAction(id?: number) {
    if (id) {
      return this.roomActionEndpoint.getRoomActionEndpoint<RoomAction>(id);
    }
  }

  getRoomActions(page: number = 0, pageSize: number = 9999) {

    return this.roomActionEndpoint.getRoomActionsEndpoint<PagedData<RoomAction>>(page, pageSize);
  }

  updateRoomAction(roomAction: RoomAction) {
    return this.roomActionEndpoint.getUpdateRoomActionEndpoint(roomAction, roomAction.id);
  }

  newRoomAction(roomAction: RoomAction) {
    return this.roomActionEndpoint.getNewRoomActionEndpoint<RoomAction>(roomAction);
  }

  deleteRoomAction(id): Observable<RoomAction> {

    return this.roomActionEndpoint.getDeleteRoomActionEndpoint(id);
  }

  executeAction(id: number, actionId: number): Observable<any>{
    return this.roomActionEndpoint.getExecuteActionEndpoint(id, actionId);
  }

  getRoomActionMaps(roomActionId:number, page: number = 0, pageSize: number = 9999) {
    return this.roomActionEndpoint.getRoomActionMaps<PagedData<RoomActionMap>>(roomActionId, page, pageSize);
  }

  getRoomActionMap(roomActionId:number, mapId:number) {
    return this.roomActionEndpoint.getRoomActionMap(roomActionId, mapId);
  }

  createRoomActionMap(roomActionId:number, map:RoomActionMap) {
    return this.roomActionEndpoint.createRoomActionMap(roomActionId, map);
  }

  updateRoomActionMap(roomActionId:number, map:RoomActionMap) {
    return this.roomActionEndpoint.updateRoomActionMap(roomActionId, map.id, map);
  }

  deleteRoomActionMap(roomActionId:number, mapId:number) {
    return this.roomActionEndpoint.deleteRoomActionMap(roomActionId, mapId);
  }

  getRoomActionMapParameters(roomActionId:number, mapId:number, page: number = 0, pageSize: number = 9999) {
    return this.roomActionEndpoint.getRoomActionMapParameters<PagedData<RoomActionFunctionParameter>>(roomActionId, mapId, page, pageSize);
  }

  updateRoomActionMapParameter(roomActionId:number, mapId:number, parameterId:number, map:RoomActionFunctionParameter) {
    return this.roomActionEndpoint.updateRoomActionMapParameter(roomActionId, mapId, parameterId, map);
  }
}
