import { Room } from "../room/room.model";
import { IoType } from "../io-type.model";
import { DeviceCategory } from "./device-category.model";
import { DeviceFunction } from "./device-function.model";
import { DeviceParameter } from "./device-parameter.model";
import { DevicePort } from "./device-port.model";
import { DeviceTypeState } from "./device-type-state.model";
import { DeviceType } from "./device-type.model";
import { DeviceConnectionType } from "./device-connection-type.model";
import { DeviceAvailableState } from "./device-available-state.model";
import { DeviceTypeValue } from "./device-type-value.model";

export class Device {

  constructor(id: number = 0, name?: string, description?: string, ref?: string, address?: string, category?: DeviceCategory, state?: DeviceTypeState, type?: DeviceType, ioType?: IoType, connectionType?: DeviceConnectionType, room?: Room, roomId?: number, devicePorts?: DevicePort[], functions?: DeviceFunction[], parameters?: DeviceParameter[], possibleStates?: DeviceAvailableState[], values?: DeviceTypeValue[], moduleId?: number, showOnDash?: boolean, hide?: boolean, defaultFunctionTypeId?: number) {

    this.id = id;
    this.name = name;
    this.description = description;
    this.ref = ref;
    this.address = address;
    this.category = category;
    this.state = state ? state : DeviceTypeState.NONE;
    this.type = type;
    this.room = room;
    this.devicePorts = devicePorts;
    this.functions = functions;
    this.roomId = roomId;
    this.parameters = parameters;
    this.moduleId = moduleId;
    this.ioType = ioType;
    this.connectionType = connectionType;
    this.possibleStates = possibleStates;
    this.values = values;
    this.showOnDash = showOnDash;
    this.hide = hide;
    this.defaultFunctionTypeId = defaultFunctionTypeId;
  }

  public id: number;
  public name: string;
  public ref: string;
  public description: string;
  public address: string;
  public category: DeviceCategory;
  public state: DeviceTypeState;
  public type: DeviceType;
  public ioType: IoType;
  public connectionType: DeviceConnectionType;
  public room: Room;
  public roomId: number;
  public moduleId: number;
  public devicePorts: DevicePort[];
  public functions: DeviceFunction[];
  public parameters: DeviceParameter[];
  public possibleStates: DeviceAvailableState[];
  public values: DeviceTypeValue[];
  public stateValue: string;
  public showOnDash: boolean;
  public hide: boolean;
  public defaultFunctionTypeId: number;
}
