import { Component, Input, ViewChild, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AutomationRuleService } from "../../../services/automationRule/automation-rule.service";
import { AutomationRule } from '../../../models/automationRule/automation-rule.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AutomationRuleActionType } from 'src/app/models/automationRule/automation-rule-action-type.model';
import { PickerIconImage } from '../../../models/picker-icon-image.model';
import {AppTranslationService} from "../../../services/app-translation.service";

@Component({
  selector: 'admin-automation-rule-editor',
  templateUrl: './admin-automation-rule-editor.component.html',
  styleUrls: ['./admin-automation-rule-editor.component.scss']
})
export class AdminAutomationRuleEditorComponent {
  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewAutomationRule = false;
  private isSaving: boolean;
  private onAutomationRuleSaved = new Subject<AutomationRule>();

  private automationRule: AutomationRule = new AutomationRule();

  automationRuleForm: FormGroup;
  automationRuleSaved$ = this.onAutomationRuleSaved.asObservable();

  get name() {
    return this.automationRuleForm.get('name');
  }

  get icon() {
    return this.automationRuleForm.get('icon');
  }

  public allIconImages: PickerIconImage[] = [
    new PickerIconImage(
      "Lamp",
      "./assets/icons/Asset01.svg",
      ["lamp, light"]
    ),
    new PickerIconImage(
      "Sofa",
      "./assets/icons/Asset02.svg",
      ["sofa", "livingroom"]
    ),
    new PickerIconImage(
      "Door",
      "./assets/icons/Asset03.svg",
      ["door"]
    ),
    new PickerIconImage(
      "Rooms",
      "./assets/icons/Asset07.svg",
      ["rooms"]
    ),
    new PickerIconImage(
      "Day",
      "./assets/icons/Asset09.svg",
      ["day"]
    ),
    new PickerIconImage(
      "Night",
      "./assets/icons/Asset10.svg",
      ["night"]
    ),
    new PickerIconImage(
      "Play",
      "./assets/icons/Asset11.svg",
      ["play"]
    ),
    new PickerIconImage(
      "Blind",
      "./assets/icons/Asset27.svg",
      ["blind"]
    ),
    new PickerIconImage(
      "LighOff",
      "./assets/icons/Asset12.svg",
      ["light"]
    ),
    new PickerIconImage(
      "LighOn",
      "./assets/icons/Asset13.svg",
      ["light"]
    ),

    new PickerIconImage(
      "agua01",
      "./assets/icons/agua01.svg",
      ["agua01"]
    ),
    new PickerIconImage(
      "agua02",
      "./assets/icons/agua02.svg",
      ["agua02"]
    ),
    new PickerIconImage(
      "apoio",
      "./assets/icons/apoio.svg",
      ["apoio"]
    ),
    new PickerIconImage(
      "aspersores",
      "./assets/icons/aspersores.svg",
      ["aspersores"]
    ),
    new PickerIconImage(
      "calendario",
      "./assets/icons/calendario.svg",
      ["calendario"]
    ),
    new PickerIconImage(
      "camara",
      "./assets/icons/camara.svg",
      ["camara"]
    ), new PickerIconImage(
      "coluna",
      "./assets/icons/coluna.svg",
      ["coluna"]
    ), new PickerIconImage(
      "cozinha",
      "./assets/icons/cozinha.svg",
      ["cozinha"]
    ),
    new PickerIconImage(
      "crono",
      "./assets/icons/crono.svg",
      ["crono"]
    ),
    new PickerIconImage(
      "divisoes",
      "./assets/icons/divisoes.svg",
      ["divisoes"]
    ), new PickerIconImage(
      "engrenagem",
      "./assets/icons/engrenagem.svg",
      ["engrenagem"]
    ), new PickerIconImage(
      "escudo",
      "./assets/icons/escudo.svg",
      ["escudo"]
    ), new PickerIconImage(
      "estore",
      "./assets/icons/estore.svg",
      ["estore"]
    ),
    new PickerIconImage(
      "frigo",
      "./assets/icons/frigo.svg",
      ["frigo"]
    ),
    new PickerIconImage(
      "gas",
      "./assets/icons/gas.svg",
      ["gas"]
    ),
    new PickerIconImage(
      "house",
      "./assets/icons/house.svg",
      ["house"]
    ),
    new PickerIconImage(
      "id",
      "./assets/icons/id.svg",
      ["id"]
    ),
    new PickerIconImage(
      "lampada",
      "./assets/icons/lampada.svg",
      ["lampada"]
    ),
    new PickerIconImage(
      "LighOff",
      "./assets/icons/luzoff.svg",
      ["light"]
    ),
    new PickerIconImage(
      "LighOn",
      "./assets/icons/luzon.svg",
      ["light"]
    ),
    new PickerIconImage(
      "micro",
      "./assets/icons/micro.svg",
      ["micro"]
    ),
    new PickerIconImage(
      "nuvem",
      "./assets/icons/nuvem.svg",
      ["nuvem"]
    ),

    new PickerIconImage(
      "processador",
      "./assets/icons/processador.svg",
      ["processador"]
    ),
    new PickerIconImage(
      "proximidade",
      "./assets/icons/proximidade.svg",
      ["proximidade"]
    ),
    new PickerIconImage(
      "puzzle",
      "./assets/icons/puzzle.svg",
      ["puzzle"]
    ),
    new PickerIconImage(
      "relogio",
      "./assets/icons/relogio.svg",
      ["relogio"]
    ),
    new PickerIconImage(
      "remote",
      "./assets/icons/remote.svg",
      ["remote"]
    ),
    new PickerIconImage(
      "sinal",
      "./assets/icons/sinal.svg",
      ["sinal"]
    ),
    new PickerIconImage(
      "LighOn",
      "./assets/icons/tomada.svg",
      ["light"]
    ),
    new PickerIconImage(
      "vento",
      "./assets/icons/vento.svg",
      ["vento"]
    ),
    new PickerIconImage(
      "ventoinha",
      "./assets/icons/ventoinha.svg",
      ["ventoinha"]
    ),
    new PickerIconImage(
      "wifi",
      "./assets/icons/wifi.svg",
      ["wifi"]
    ),

  ];

  constructor(
    public dialogRef: MatDialogRef<AdminAutomationRuleEditorComponent>,
    private alertService: AlertService,
    private automationRuleService: AutomationRuleService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { automationRule: AutomationRule },
  ) {
    this.automationRule = data.automationRule;
    this.buildForm();
    this.resetForm();
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.automationRuleForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.disableSaveButton();
    let savingLabel = this.translationService.getTranslation(`shared.SavingChanges`);
    this.alertService.startLoadingMessage(savingLabel);

    const editedAutomationRule = this.getEditedAutomationRule();

    if (this.automationRule.id == 0) {
      this.isNewAutomationRule = true;
      this.automationRuleService.newAutomationRule(editedAutomationRule).subscribe(
        automationRule => this.saveSuccessHelper(automationRule),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewAutomationRule = false;
      this.automationRuleService.updateAutomationRule(editedAutomationRule).subscribe(
        response => this.saveSuccessHelper(response),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedAutomationRule(): AutomationRule {
    const formModel = this.automationRuleForm.value;

    return {
      id: this.automationRule.id,
      name: formModel.name,
      description: formModel.description,
      startQuery: this.automationRule.startQuery,
      stopQuery: this.automationRule.stopQuery,
      state: 1,
      icon: formModel.icon != './assets/icons/Asset05.svg' ? formModel.icon : '',
    };
  }

  private saveSuccessHelper(automationRule?: AutomationRule) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewAutomationRule) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onAutomationRuleSaved.next(automationRule);

    this.dialogRef.close(automationRule);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.automationRuleForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
      icon: ''
    });
  }

  private resetForm(replace = false) {
    this.automationRuleForm.reset({
      name: this.automationRule.name || '',
      description: this.automationRule.description || '',
      icon: this.automationRule.icon || './assets/icons/Asset05.svg'
    });
  }

  public selectIcon(icon: PickerIconImage) {
    this.automationRule.icon = icon.path;
    this.automationRuleForm.controls['icon'].setValue(icon.path);
  }
}
