import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { Device } from '../../../models/device/device.model';
import { Room } from '../../../models/room/room.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import {AppTranslationService} from "../../../services/app-translation.service";
import {DeviceGroupService} from "../../../services/deviceGroup/device-group.service";
import {DeviceGroup} from "../../../models/deviceGroup/device-group.model";


@Component({
  selector: "admin-group-device-editor.component",
  templateUrl: './admin-group-device-editor.component.html',
  styleUrls: ['./admin-group-device-editor.component.scss'],
  animations: [fadeInOut]
})
export class AdminDeviceGroupDeviceEditorComponent implements OnInit {

  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewAction = false;
  private isSaving: boolean;
  private onDeviceGroupDeviceSaved = new Subject<DeviceGroup>();

  public currentDeviceGroup: DeviceGroup;
  public allRooms: Room[];
  public allDevices: Device[];
  public filteredDevices: Device[];

  formGroup: FormGroup;
  DeviceGroupDeviceSaved$ = this.onDeviceGroupDeviceSaved.asObservable();

  get room() {
    return this.formGroup.get('room');
  }

  get device() {
    return this.formGroup.get('device');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminDeviceGroupDeviceEditorComponent>,
    private alertService: AlertService,
    private deviceGroupService: DeviceGroupService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { deviceGroup: DeviceGroup, rooms: Room[], devices: Device[] },
  ) {
      this.currentDeviceGroup = data.deviceGroup;
      this.allRooms = data.rooms;
      this.allDevices = data.devices;

    this.filteredDevices = this.allDevices.filter(x => x.room && x.room.id == this.currentDeviceGroup.roomId && x.category == this.currentDeviceGroup.category);

    this.buildForm();
    this.resetForm();
  }

  ngOnInit(): void {
    this.enableSaveButton();
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.formGroup.valid) {
      this.alertService.showValidationError();
      return;
    }

    if (this.isSaving) {
      return;
    }

    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    let editedDevice = this.getEditedDevice();


    this.isNewAction = false;
    this.deviceGroupService.addDevice(this.currentDeviceGroup.id, editedDevice.id).subscribe(
      response => this.saveSuccessHelper(response),
      error => this.saveFailedHelper(error));
  }

  private getEditedDevice(): Device {
    const formModel = this.formGroup.value;

    return this.allDevices.find(x => x.id == formModel.device);
  }

  private saveSuccessHelper(deviceGroup: DeviceGroup) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewAction) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onDeviceGroupDeviceSaved.next();

    this.dialogRef.close(true);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    this.enableSaveButton();
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
    this.enableSaveButton();
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      room: ['', Validators.required],
      device: ['', Validators.required],
    });
  }

  public onRoomChange(id: any) {

    this.device.setValue('');

    this.filteredDevices = this.allDevices.filter(x => x.room && x.room.id == id.value && x.category == this.currentDeviceGroup.category);
  }

  private resetForm(replace = false) {
    this.formGroup.reset({
      room: '',
      device: '',
    });
  }
}

