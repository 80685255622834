import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';
import {AnnouncementType} from "../../models/announcement/announcement-type.model";


@Injectable()
export class AnnouncementEndpoint extends EndpointFactory {

  private readonly _announcementUrl: string = "/api/announcement";

  get announcementUrl() { return this.configurations.baseUrl + this._announcementUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  getAnnouncementsEndpoint<T>(type: AnnouncementType): Observable<T> {

    let endpointUrl = `${this._announcementUrl}?type=${type}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAnnouncementsEndpoint(type));
      }));
  }

}
