import { Cabinet } from "../cabinet/cabinet.model";
import { ModuleType } from "../moduleType/module-type.model";
import { DevicePort } from "../device/device-port.model";
import { Device } from "../device/device.model";
import { Module } from "./module.model";
import { Room } from "../room/room.model";
import { IoType } from "../io-type.model";

export class ModulePortMap {

  constructor(id: number = 0, code?: string, description?: string, order?: number, type?: IoType, module?: Module, device?: Device, devicePort?: DevicePort, room?: Room, wire?: string) {

    this.id = id;
    this.description = description;
    this.code = code;
    this.order = order;
    this.device = device;
    this.module = module;
    this.devicePort = devicePort;
    this.wire = wire;
    this.room = room;
    this.type = type;
  }

  public id: number;
  public code: string;
  public description: string;
  public order: number;
  public type: IoType;
  public module: Module;
  public device: Device;
  public devicePort: DevicePort;
  public room: Room;
  public wire: string;
}
