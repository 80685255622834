import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';


@Injectable()
export class AutomationRuleEndpoint extends EndpointFactory {

  private readonly _automationRuleUrl: string = "/api/automationRule";

  get automationRuleUrl() { return this.configurations.baseUrl + this._automationRuleUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getAutomationRuleEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.automationRuleUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAutomationRuleEndpoint(id));
      }));
  }
  
  getAutomationRulesEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._automationRuleUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAutomationRulesEndpoint(page, pageSize));
      }));
  }


  getNewAutomationRuleEndpoint<T>(automationRuleObject: any): Observable<T> {

    return this.http.post<T>(this.automationRuleUrl, JSON.stringify(automationRuleObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewAutomationRuleEndpoint(automationRuleObject));
      }));
  }

  getUpdateAutomationRuleEndpoint<T>(automationRuleObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.automationRuleUrl}/${id}` : `${this.automationRuleUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(automationRuleObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateAutomationRuleEndpoint(automationRuleObject, id));
      }));
  }

  getDeleteAutomationRuleEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.automationRuleUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteAutomationRuleEndpoint(id));
      }));
  }

  changeStateEndpoint<T>(id: number, state: number): Observable<T> {

    let endpointUrl = `${this.automationRuleUrl}/${id}/ChangeState/${state}`;

    return this.http.post<T>(endpointUrl, JSON.stringify({ "id": id, "state": state }), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.changeStateEndpoint(id, state));
      }));
  }
}
