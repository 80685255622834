export enum DeviceCategory {
  None = 0,
  Light = 1,
  Window = 2,
  Blinds = 3,
  Alarm = 4,
  Door = 5,
  Switch = 6,
  AC = 7,
  Fan = 8,
  TemperatureSensor = 9,
  LightSensor = 10,
  PowerPlug = 11,
  Valve = 12,
  Latch = 13,
  Siren = 14,
  WaterPump = 15,
  MovementSensor = 16,
  ContactSensor = 17,
  SmokeSensor = 18,
  TwilightSensor = 19,
  RainSensor = 20,
  FloodSensor = 21,
  WindSensor = 22,
  EnergyMeter = 23,
  Keypad = 24,
  Blackout = 25,
  Camera = 26,
  NotificationsGateway = 27,
  System = 28,
  RgbLight
}
