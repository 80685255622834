import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TableDataSource } from 'angular4-material-table';
import { Subject, concat } from 'rxjs';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FunctionValidatorService } from '../../../services/device/function-validator.service';
import { ParameterValidatorService } from '../../../services/device/parameter-validator.service';
import { AutomationRuleAction } from 'src/app/models/automationRule/automation-rule-action.model';
import {AppTranslationService} from "../../../services/app-translation.service";
import { Device } from '../../../models/device/device.model';
import {RoomActionFunctionParameter} from "../../../models/room/room-action-function-parameter.model";
import {RoomActionMap} from "../../../models/room/room-action-map.model";
import {RoomActionService} from "../../../services/room/room-action.service";



@Component({
  selector: 'admin-action-map-settings.component',
  templateUrl: './admin-action-map-settings.component.html',
  styleUrls: ['./admin-action-map-settings.component.scss']
})
export class AdminActionMapSettingsEditorComponent implements OnInit {

  private isSaving = false;

  public roomActionMap: RoomActionMap;

  public allParameters: RoomActionFunctionParameter[];
  public allDevices: Device[];
  public parametersListChange = new EventEmitter<RoomActionFunctionParameter[]>();
  parametersDisplayedColumns = ['code', 'description', 'source', 'deviceId', 'value', 'actionsColumn'];
  parametersDataSource: TableDataSource<RoomActionFunctionParameter>;

  constructor(
    public dialogRef: MatDialogRef<AdminActionMapSettingsEditorComponent>,
    private alertService: AlertService,
    private roomActionService: RoomActionService,
    private functionsValidator: FunctionValidatorService,
    private parameterValidator: ParameterValidatorService,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { roomActionMap: RoomActionMap, allDevices: Device[]},
  ) {
    const self = this;

    this.roomActionMap = data.roomActionMap;
    this.allParameters = this.roomActionMap.parameters;
    this.allDevices = data.allDevices;
    this.parametersDataSource = new TableDataSource<RoomActionFunctionParameter>(this.allParameters, RoomActionFunctionParameter, this.parameterValidator);
    this.parametersDataSource.datasourceSubject.subscribe(parameters => {

      self.allParameters = parameters;
      this.parametersListChange.emit(parameters)

    });
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {

    this.disableSaveButton();
    let savingLabel = this.translationService.getTranslation(`shared.SavingChanges`);
    this.alertService.startLoadingMessage(savingLabel);

    this.roomActionMap.parameters = this.allParameters;

    this.roomActionService.updateRoomActionMap(this.roomActionMap.roomActionId, this.roomActionMap).subscribe(
      response => this.saveSuccessHelper(this.roomActionMap),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(roomActionMap?: RoomActionMap) {
    this.alertService.stopLoadingMessage();

    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);

    this.dialogRef.close(roomActionMap);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public updateRowDevice(row: any, deviceId: number) {

    var device = this.allDevices.find(x => x.id == deviceId);

    row.currentData.device = device;

  }


  public getDeviceValues(deviceId: any) {

    console.log("getDeviceValues");


    var device = this.allDevices.find(x => x.id == deviceId);
    if (device) {
      return device.values;
    }

    return [];
  }

  public cancel() {

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }
}
