import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { ClimatizationEndpoint } from './climatization-endpoint.service';


@Injectable()
export class ClimatizationService {

  constructor(private router: Router, private http: HttpClient,
    private climatizationEndpoint: ClimatizationEndpoint) {

  }
  
  changeTargetTemperatureEndpoint(roomId: number, targetTemperature: number): Observable<any> {
    return this.climatizationEndpoint.changeTargetTemperatureEndpoint(roomId, targetTemperature);
  }

  changeModeEndpoint(roomId: number, mode: number): Observable<any> {
    return this.climatizationEndpoint.changeModeEndpoint(roomId, mode);
  }
}
