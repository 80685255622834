import { Component, OnInit } from '@angular/core';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import {AppTranslationService} from "../../../services/app-translation.service";
import { Device } from '../../../models/device/device.model';
import {RoomActionMap} from "../../../models/room/room-action-map.model";
import {RoomAction} from "../../../models/room/room-action.model";
import {Room} from "../../../models/room/room.model";
import {MatDialog} from "@angular/material/dialog";
import {AdminRoomActionMapEditorComponent} from "./admin-action-map-editor.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {RoomActionService} from "../../../services/room/room-action.service";
import {MatTableDataSource} from "@angular/material/table";
import {ActivatedRoute} from "@angular/router";
import {fadeInOut} from "../../../services/animations";
import {AdminActionMapSettingsEditorComponent} from "./admin-action-map-settings.component";



@Component({
  selector: 'admin-room-action-settings',
  templateUrl: './admin-room-action-settings.component.html',
  styleUrls: ['./admin-room-action-settings.component.scss'],
  animations: [fadeInOut]
})
export class AdminRoomActionSettingsComponent implements OnInit {

  public roomAction: RoomAction;
  private sourceRoomActionMap: RoomActionMap;
  private allDevices: Device[];
  private allRooms: Room[];
  public allRoomActionMaps: RoomActionMap[];
  roomActionMapDisplayedColumns = ['waitTime', 'device', "deviceFunction", 'actions'];
  roomActionMapsDataSource: MatTableDataSource<RoomActionMap>;
  loadingIndicator: boolean;

  constructor(
    private alertService: AlertService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private roomActionService: RoomActionService,
    private translationService: AppTranslationService,
    private route: ActivatedRoute,
  ) {
    this.roomAction = this.route.snapshot.data['roomAction'];
    this.allDevices = this.route.snapshot.data['devices'].items;
    this.allRooms = this.route.snapshot.data['rooms'].items;
    this.roomActionMapsDataSource = new MatTableDataSource<RoomActionMap>();
    this.refreshData();
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }
  public editRoomActionMap(roomActionMap: RoomActionMap) {
    this.sourceRoomActionMap = roomActionMap;

    let dialogRef = this.dialog.open(AdminRoomActionMapEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { roomAction: this.roomAction, roomActionMap: roomActionMap, devices: this.allDevices, rooms: this.allRooms }
      });
    dialogRef.afterClosed().subscribe(action => {
      if (action) {
        this.refreshData();
      }
    });
  }
  public createRoomActionMap() {

    let actionMap = new RoomActionMap(0, 0);
    this.sourceRoomActionMap = actionMap;

    let dialogRef = this.dialog.open(AdminRoomActionMapEditorComponent,
      {
        panelClass: 'mat-dialog-md',
        data: { roomAction: this.roomAction, roomActionMap: actionMap, devices: this.allDevices, rooms: this.allRooms }
      });
    dialogRef.afterClosed().subscribe(action => {
      if (action) {
        this.refreshData();
      }
    });
  }

  public editParameters(roomActionMap?: RoomActionMap) {
    this.sourceRoomActionMap = roomActionMap;

    let dialogRef = this.dialog.open(AdminActionMapSettingsEditorComponent,
      {
        panelClass: 'mat-dialog-lg',
        data: { roomActionMap: roomActionMap, allDevices: this.allDevices }
      });
    dialogRef.afterClosed().subscribe(result => {
      this.refreshData();
    });
  }

  private confirmAutomationRuleActionDelete(roomActionMap: RoomActionMap) {

    let deleteErrorDetail = this.translationService.getTranslation(`shared.DeleteErrorDetail`);
    let deleteErrorLabel = this.translationService.getTranslation(`shared.DeleteError`);
    let deleteQuestionLabel = this.translationService.getTranslation(`shared.DeleteQuestion`);
    let deleteLabel = this.translationService.getTranslation(`shared.DeleteCaps`);
    let deletingLabel = this.translationService.getTranslation(`shared.Deleting`);

    this.snackBar.open(deleteQuestionLabel, deleteLabel, { duration: 5000 })
      .onAction().subscribe(() => {
      this.alertService.startLoadingMessage(deletingLabel);

      this.roomActionService.deleteRoomActionMap(this.roomAction.id, roomActionMap.id)
        .subscribe(results => {
            this.alertService.stopLoadingMessage();

            this.refreshData();
          },
          error => {
            this.alertService.stopLoadingMessage();

            this.alertService.showStickyMessage(deleteErrorLabel, deleteErrorDetail,
              MessageSeverity.error, error);
          });
    });
  }

  public areSettingsEnabled(roomActionMap: RoomActionMap) {
    if (roomActionMap.parameters != undefined && roomActionMap.parameters.length > 0) {
      return true;
    }
    return false;
  }

  public translateDeviceFunctionName(functionName: string): string {
    try {
      return this.translationService.getTranslation(`lookups.DeviceFuntions.${functionName}`);
    } catch (e) {
      return functionName;
    }
  }

  public applyFilter(filterValue: string) {
    this.roomActionMapsDataSource.filter = filterValue;
  }

  private refreshData() {
    var self = this;
    this.alertService.startLoadingMessage();
    this.loadingIndicator = true;

    this.roomActionService.getRoomActionMaps(this.roomAction.id, 0, 999)
      .subscribe(results => {
          self.alertService.stopLoadingMessage();
          this.loadingIndicator = false;

          self.allRoomActionMaps = results.items;
          self.roomActionMapsDataSource.data = results.items;
        },
        error => {
          self.alertService.stopLoadingMessage();

          let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
          let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

          self.alertService.showStickyMessage(errorLabel, errorDetail,
            MessageSeverity.error, error);
        });
  }
}
