import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AlarmAccessKey } from '../../models/alarm/alarm-access-key.model';
import { PagedData } from '../../models/PagedData';
import { AlarmAccessKeyService } from '../alarm/alarm-access-key.service';

@Injectable()
export class AlarmAccessKeysResolve implements Resolve<Observable<PagedData<AlarmAccessKey>>> {
  constructor(private alarmAccessKeyService: AlarmAccessKeyService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<AlarmAccessKey> | Promise<AlarmAccessKey> | any {
    let id = +route.params['id'];
    return this.alarmAccessKeyService.getAlarmAccessKeys(id).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
