import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { RoomAction } from 'src/app/models/room/room-action.model';
import { RoomService } from 'src/app/services/room/room.service';
import { Utilities } from 'src/app/services/utilities';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { fadeInOut } from '../../services/animations';
import { RoomActionService } from '../../services/room/room-action.service';
import { Observable } from 'rxjs';
import {AppTranslationService} from "../../services/app-translation.service";


@Component({
  selector: 'actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss'],
  animations: [fadeInOut]
})
export class ActionsComponent implements OnInit, AfterViewInit {


  allActions: RoomAction[];

  name: string;
  @Input() roomId: number;

  constructor(
    private alertService: AlertService,
    private roomActionService: RoomActionService,
    private roomService: RoomService,
    private translationService: AppTranslationService,
  ) {
  }

  ngOnInit() {

    let self = this;
    this.alertService.stopLoadingMessage();

    if (this.roomId != 0) {
      this.roomService.getRoomActions(this.roomId)
        .subscribe(result => {
          this.alertService.stopLoadingMessage();

          self.allActions = result.items;

        },
          error => {
            this.alertService.stopLoadingMessage();

            let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
            let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

            this.alertService.showStickyMessage(errorLabel, errorDetail,
              MessageSeverity.error, error);
          });
    }
  }

  ngAfterViewInit() {
  }

  public runAction(actionId: number) {

    let executeSucessLabel = this.translationService.getTranslation(`shared.ExecuteSuccess`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    this.roomActionService.executeAction(1, actionId).subscribe(
      response => this.alertService.showMessage(successLabel, executeSucessLabel, MessageSeverity.success),
      error => this.saveFailedHelper(error));
  }


  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

}
