import { RoomActionMap } from "./room-action-map.model";
import { Room } from "./room.model";

export class RoomAction {

  constructor(id: number = 0, name?: string, description?: string, room?: Room, showOnDash?: boolean, iconImage?: string) {

    this.id = id;
    this.name = name;
    this.description = description;
    this.room = room;
    this.showOnDash = showOnDash;
    this.iconImage = iconImage;
  }

  public id: number;
  public name: string;
  public description: string;
  public showOnDash: boolean;
  public room: Room;
  public iconImage: string;
}
