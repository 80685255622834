import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { DeviceFunction } from '../../../models/device/device-function.model';
import { DevicePortMap } from '../../../models/device/device-port-map.model';
import { DevicePort } from '../../../models/device/device-port.model';
import { Device } from '../../../models/device/device.model';
import { Room } from '../../../models/room/room.model';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { DevicePortMapService } from '../../../services/devicePortMap/device-port-map.service';
import {AppTranslationService} from "../../../services/app-translation.service";
import { IoType } from '../../../models/io-type.model';
import {ModulePortMap} from "../../../models/module/module-port-map.model";



@Component({
  selector: "admin-device-port-map-editor",
  templateUrl: './admin-device-port-map-editor.component.html',
  styleUrls: ['./admin-device-port-map-editor.component.scss'],
  animations: [fadeInOut]
})
export class AdminDevicePortMapEditorComponent implements OnInit {

  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewMap = false;
  private isSaving: boolean;
  private onPortMapSaved = new Subject<DevicePortMap>();

  private currentPortMap: DevicePortMap = new DevicePortMap();
  public allRooms: Room[];
  public allDevices: Device[];
  public allInputs: Device[];
  public inputFilteredDevices: Device[];
  public outputFilteredDevices: Device[];
  public inputFunctions: DeviceFunction[];
  public outputFunctions: DeviceFunction[];
  public inputPorts: DevicePort[];
  public outputPorts: DevicePort[];
  public modulePortMaps: ModulePortMap[];

  mappingForm: FormGroup;
  portMapSaved$ = this.onPortMapSaved.asObservable();

  get inputRoom() {
    return this.mappingForm.get('inputRoom');
  }

  get inputDevice() {
    return this.mappingForm.get('inputDevice');
  }

  get inputFunction() {
    return this.mappingForm.get('inputFunction');
  }

  get outputRoom() {
    return this.mappingForm.get('outputRoom');
  }

  get outputDevice() {
    return this.mappingForm.get('outputDevice');
  }

  get forceRemoteExecution() {
    return this.mappingForm.get('forceRemoteExecution');
  }

  get outputFunction() {
    return this.mappingForm.get('outputFunction');
  }

  get outputPort() {
    return this.mappingForm.get('outputPort');
  }

  get inputPort() {
    return this.mappingForm.get('inputPort');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminDevicePortMapEditorComponent>,
    private alertService: AlertService,
    private devicePortMapService: DevicePortMapService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { devicePortMap: DevicePortMap, devices: Device[], modulePortMaps: ModulePortMap[], rooms: Room[], inputs: Device[] },
  ) {
    this.currentPortMap = data.devicePortMap;
    this.allDevices = data.devices;
    this.allRooms = data.rooms;
    this.allInputs = data.inputs;
    this.modulePortMaps = data.modulePortMaps;

    if (this.currentPortMap.inputDevice && this.currentPortMap.inputDevice.roomId && this.currentPortMap.inputDevice.roomId != 0) {
      this.inputFilteredDevices = this.allInputs.filter(x => x.room && x.room.id == this.currentPortMap.inputDevice.roomId);
    }
    else {
      this.inputFilteredDevices = this.allInputs;
    }

    if (this.currentPortMap.outputDevice && this.currentPortMap.outputDevice.roomId && this.currentPortMap.outputDevice.roomId != 0) {
      this.outputFilteredDevices = this.allDevices.filter(x => x.room && x.room.id == this.currentPortMap.outputDevice.roomId);
    }
    else {
      this.outputFilteredDevices = this.allDevices;
    }

    this.loadPorts();

    this.buildForm();
    this.resetForm();
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnChanges() {
    this.resetForm();
  }

  private loadData() {
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
    if (this.isSaving) {
      return;
    }

    this.disableSaveButton();

    if (!this.mappingForm.valid) {
      this.alertService.showValidationError();
      this.enableSaveButton();
      return;
    }

    let savingLabel = this.translationService.getTranslation(`shared.SavingChanges`);

    this.alertService.startLoadingMessage(savingLabel);

    const editedDevice = this.getEditeMap();

    if (this.currentPortMap.id == 0) {
      this.isNewMap = true;
      this.devicePortMapService.newDevicePortMap(editedDevice).subscribe(
        device => this.saveSuccessHelper(editedDevice),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewMap = false;
      this.devicePortMapService.updateDevicePortMap(editedDevice).subscribe(
        response => this.saveSuccessHelper(editedDevice),
        error => this.saveFailedHelper(error));
    }
  }

  private loadPorts() {
    if (this.currentPortMap.inputDevice && this.currentPortMap.inputDevice.devicePorts) {
      let inputDevice = this.allDevices.filter(x => x.id == this.currentPortMap.inputDevice.id)[0];
      if (inputDevice) {
        this.inputPorts = inputDevice.devicePorts.filter(port => port.type == IoType.Input || port.type == IoType.Both);
        this.inputFunctions = inputDevice.functions;
      }
    }

    if (this.currentPortMap.outputDevice && this.currentPortMap.inputDevice.devicePorts) {
      let outputDevice = this.allDevices.filter(x => x.id == this.currentPortMap.outputDevice.id)[0];
      if (outputDevice) {
        this.outputPorts = outputDevice.devicePorts.filter(port => port.type == IoType.Output || port.type == IoType.Both);
        this.outputFunctions = outputDevice.functions;
      }
    }
  }

  private getEditeMap(): DevicePortMap {
    const formModel = this.mappingForm.value;

    return {
      id: this.currentPortMap.id,
      inputDevice: this.allDevices.find(x => x.id == formModel.inputDevice),
      inputFunction: this.inputFunctions.find(x => x.id == formModel.inputFunction),
      inputPort: this.inputPorts.find(x => x.id == formModel.inputPort),
      outputDevice: this.allDevices.find(x => x.id == formModel.outputDevice),
      outputFunction: this.outputFunctions.find(x => x.id == formModel.outputFunction),
      outputPort: this.outputPorts.find(x => x.id == formModel.outputPort),
      forceRemoteExecution: formModel.forceRemoteExecution,
      localExecution: this.currentPortMap.localExecution,
    };
  }

  private saveSuccessHelper(portMap?: DevicePortMap) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewMap) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onPortMapSaved.next(portMap);

    this.dialogRef.close(portMap);

    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {
    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.mappingForm = this.formBuilder.group({
      inputRoom: ['', Validators.required],
      inputDevice: ['', Validators.required],
      inputFunction: ['', Validators.required],
      inputPort: ['', Validators.required],
      outputFunction: ['', Validators.required],
      outputDevice: ['', Validators.required],
      outputRoom: ['', Validators.required],
      outputPort: ['', Validators.required],
      forceRemoteExecution: false
    });
  }

  public onInputRoomChange(id: any) {

    this.inputDevice.setValue(0);
    this.inputFunction.setValue(0);
    this.inputPort.setValue(0);

    this.inputFilteredDevices = this.allInputs.filter(x => x.room && x.room.id == id.value && x.ioType != IoType.Output);
  }


  public onOutputRoomChange(id: any) {

    this.outputDevice.setValue(0);
    this.outputFunction.setValue(0);
    this.outputPort.setValue(0);

    this.outputFilteredDevices = this.allDevices.filter(x => x.room && x.room.id == id.value && x.ioType != IoType.Input);
  }

  public onInputDeviceChange(id: any) {

    this.inputFunction.setValue(0);
    this.inputPort.setValue(0);

    let currentDevice = this.allDevices.find(x => x.id == id.value);
    this.inputFunctions = currentDevice.functions;

    this.inputPorts = currentDevice
      .devicePorts
      .filter(port => port.type == IoType.Input || port.type == IoType.Both);
  }

  public onOutputDeviceChange(id: any) {

    this.outputFunction.setValue(0);
    this.outputPort.setValue(0);

    let currentDevice = this.allDevices.find(x => x.id == id.value);
    this.outputFunctions = currentDevice.functions;

    this.outputPorts = currentDevice
      .devicePorts
      .filter(port => port.type == IoType.Output || port.type == IoType.Both);
  }

  private resetForm(replace = false) {
    this.enableSaveButton();

    this.mappingForm.reset({
      inputRoom: this.currentPortMap.inputDevice && this.currentPortMap.inputDevice.room ? this.currentPortMap.inputDevice.room.id : '',
      inputDevice: this.currentPortMap.inputDevice ? this.currentPortMap.inputDevice.id : '',
      inputFunction: this.currentPortMap.inputFunction ? this.currentPortMap.inputFunction.id : '',
      inputPort: this.currentPortMap.inputPort ? this.currentPortMap.inputPort.id : '',
      outputDevice: this.currentPortMap.outputDevice ? this.currentPortMap.outputDevice.id : '',
      outputFunction: this.currentPortMap.outputFunction ? this.currentPortMap.outputFunction.id : '',
      outputRoom: this.currentPortMap.outputDevice && this.currentPortMap.outputDevice.room ? this.currentPortMap.outputDevice.room.id : '',
      outputPort: this.currentPortMap.outputPort ? this.currentPortMap.outputPort.id : '',
      forceRemoteExecution: this.currentPortMap.forceRemoteExecution || false
    });
  }
}
