import { Component, Input, OnDestroy } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { ClimatizationService } from '../../../services/climatization/climatization.service';
import { AppTranslationService } from '../../../services/app-translation.service';
import { SettingsService } from '../../../services/settings/settings.service';
import { Settings } from '../../../models/settings/settings.model';
import { AuthService } from '../../../services/auth.service';

@Component({
    selector: 'temperature-card',
    templateUrl: './temperature-card.component.html',
    styleUrls: ['./temperature-card.component.scss']
})

export class TemperatureCardComponent implements OnDestroy {

    gaugeValue = "0";
    gaugeLabel = "Temperatura";
    
   

    public settings: Settings = new Settings();
    @Input() roomId: number;

  private temperatureHubConnection: HubConnection;

  iconTemp = require("../../../assets/images/temp_sensor.svg");


  constructor(private authService: AuthService) {
  }


    ngOnDestroy(): void {
      this.temperatureHubConnection.stop();
    }

    ngOnInit() {

        this.buildTemperatureHubConnection();
    }

    private buildTemperatureHubConnection() {
        let self = this;

        let builder = new HubConnectionBuilder();

      this.temperatureHubConnection = builder
        .withUrl(location.origin + '/hubs/currentTemperature',
          {
            transport: HttpTransportType.WebSockets,
            skipNegotiation: true,
            accessTokenFactory: () => this.authService.accessToken
          })
        .configureLogging(LogLevel.None)
        .withAutomaticReconnect()
        .build();

        this.temperatureHubConnection.on("currentTemperature", (message) => {
            if (message && message.temperature) {
                if (message.roomId == this.roomId) {
                  self.gaugeValue = message.temperature;
                }
            }
        });

        this.temperatureHubConnection.start().then(() => {
          this.temperatureHubConnection.invoke("emitTemperature", self.roomId).catch(err => console.error(err.toString()));
        }).catch(function (err) {
        });
    }
}
