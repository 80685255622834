import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { AlarmZone } from '../../../models/alarm/alarm-zone.model';
import { AutomationRuleAction } from '../../../models/automationRule/automation-rule-action.model';
import { DeviceFunction } from '../../../models/device/device-function.model';
import { Device } from '../../../models/device/device.model';
import { Room } from '../../../models/room/room.model';
import { AutomationRuleActionService } from '../../../services/automationRule/automation-rule-action.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { fadeInOut } from '../../../services/animations';
import { AutomationRule } from 'src/app/models/automationRule/automation-rule.model';
import { AutomationRuleActionType } from 'src/app/models/automationRule/automation-rule-action-type.model';
import {AppTranslationService} from "../../../services/app-translation.service";


@Component({
  selector: "admin-automation-rule-action-editor",
  templateUrl: './admin-automation-rule-action-editor.component.html',
  styleUrls: ['./admin-automation-rule-action-editor.component.scss'],
  animations: [fadeInOut]
})
export class AdminAutomationRuleActionEditorComponent implements OnInit {

  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewAction = false;
  private isSaving: boolean;
  private onAutomationRuleActionSaved = new Subject<AutomationRuleAction>();

  public currentAutomationRuleAction: AutomationRuleAction = new AutomationRuleAction();
  public automationRule: AutomationRule = new AutomationRule();
  public allRooms: Room[];
  public allDevices: Device[];
  public filteredDevices: Device[];
  public deviceFunctions: DeviceFunction[];
  public allTypes: string[];
  public actionType: AutomationRuleActionType;

  formGroup: FormGroup;
  automationRuleActionSaved$ = this.onAutomationRuleActionSaved.asObservable();

  get room() {
    return this.formGroup.get('room');
  }

  get device() {
    return this.formGroup.get('device');
  }

  get waitTime() {
    return this.formGroup.get('waitTime');
  }

  get deviceFunction() {
    return this.formGroup.get('deviceFunction');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminAutomationRuleActionEditorComponent>,
    private alertService: AlertService,
    private automationRuleActionService: AutomationRuleActionService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { automationRule: AutomationRule, automationRuleAction: AutomationRuleAction, rooms: Room[], devices: Device[], actionType: AutomationRuleActionType },
  ) {
      this.currentAutomationRuleAction = data.automationRuleAction;
      this.automationRule = data.automationRule;
      this.allRooms = data.rooms;
      this.allDevices = data.devices;
      this.actionType = data.actionType;

      if (this.currentAutomationRuleAction && this.currentAutomationRuleAction.device && this.currentAutomationRuleAction.device) {
        this.deviceFunctions = this.currentAutomationRuleAction.device.functions;
      }

      if (this.currentAutomationRuleAction && this.currentAutomationRuleAction.device && this.currentAutomationRuleAction.device.roomId && this.currentAutomationRuleAction.device.roomId != 0) {
        this.filteredDevices = this.allDevices.filter(x => x.room && x.room.id == this.currentAutomationRuleAction.device.roomId);
      }

    this.buildForm();
    this.resetForm();
  }

  ngOnInit(): void {
    this.enableSaveButton();
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.formGroup.valid) {
      this.alertService.showValidationError();
      return;
    }

    if (this.isSaving) {
      return;
    }

    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.Saving`));

    const editedAutomationRuleAction = this.getEditedAction();

    if (this.currentAutomationRuleAction.id == 0) {
      this.isNewAction = true;
      this.automationRuleActionService.newAutomationRuleAction(editedAutomationRuleAction).subscribe(
        device => this.saveSuccessHelper(device),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewAction = false;
      this.automationRuleActionService.updateAutomationRuleAction(editedAutomationRuleAction).subscribe(
        response => this.saveSuccessHelper(editedAutomationRuleAction),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedAction(): AutomationRuleAction {
    const formModel = this.formGroup.value;

    return {
      id: this.currentAutomationRuleAction.id,
      waitTime: formModel.waitTime,
      automationRuleId: this.automationRule.id,
      actionType: this.actionType,
      device: this.allDevices.find(x => x.id == formModel.device),
      deviceFunction: this.deviceFunctions.find(x => x.id == formModel.deviceFunction),
      parameters: this.currentAutomationRuleAction.parameters
    };
  }

  private saveSuccessHelper(automationRuleAction?: AutomationRuleAction) {

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewAction) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onAutomationRuleActionSaved.next(automationRuleAction);

    this.dialogRef.close(automationRuleAction);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
    this.enableSaveButton();
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
    this.enableSaveButton();
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      waitTime: [0, Validators.required],
      room: ['', Validators.required],
      device: ['', Validators.required],
      deviceFunction: ['', Validators.required]
    });
  }

  public onRoomChange(id: any) {

    this.device.setValue('');
    this.deviceFunction.setValue('');

    this.filteredDevices = this.allDevices.filter(x => x.room && x.room.id == id.value);
  }

  public onInputDeviceChange(id: any) {

    this.deviceFunction.setValue('');

    if (this.allDevices && this.allDevices.length > 0) {
      let currentDevice = this.allDevices.find(x => x.id == id.value);
      this.deviceFunctions = currentDevice.functions;
    }
  }

  private resetForm(replace = false) {
    this.formGroup.reset({
      waitTime: this.currentAutomationRuleAction.waitTime  || 0,
      room: this.currentAutomationRuleAction.device && this.currentAutomationRuleAction.device.room ? this.currentAutomationRuleAction.device.room.id : '',
      device: this.currentAutomationRuleAction.device ? this.currentAutomationRuleAction.device.id : '',
      deviceFunction: this.currentAutomationRuleAction.deviceFunction ? this.currentAutomationRuleAction.deviceFunction.id : ''
    });
  }
}

