import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AutomationRule } from '../../models/automationRule/automation-rule.model';
import { AutomationRuleService } from '../automationRule/automation-rule.service';

@Injectable()
export class AutomationRuleResolve implements Resolve<Observable<AutomationRule>> {
  constructor(private automationRuleService: AutomationRuleService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<AutomationRule> | Promise<AutomationRule> | any {
    let id = +route.params['id'];
    return this.automationRuleService.getAutomationRule(id).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
