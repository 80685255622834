import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Subject} from 'rxjs';
import {DeviceFunction} from '../../../models/device/device-function.model';
import {Device} from '../../../models/device/device.model';
import {Room} from '../../../models/room/room.model';
import {AlertService, MessageSeverity} from '../../../services/alert.service';
import {fadeInOut} from '../../../services/animations';
import {ZoneActionType} from '../../../models/alarm/zone-action-type.model';
import {AlarmProfile} from 'src/app/models/alarm/alarm-profile.model';
import {AppTranslationService} from "../../../services/app-translation.service";
import {AccessZoneAction} from "../../../models/accessZone/access-zone-action.model";
import {AccessZoneActionService} from "../../../services/accessZone/access-action.service";
import {AccessZone} from "../../../models/accessZone/access-zone.model";
import {DeviceCategory} from "../../../models/device/device-category.model";


@Component({
  selector: "admin-access-zone-action-editor",
  templateUrl: './admin-access-zone-action-editor.component.html',
  styleUrls: ['./admin-access-zone-action-editor.component.scss'],
  animations: [fadeInOut]
})
export class AdminAccessZoneActionEditorComponent implements OnInit {

  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewAction = false;
  private isSaving: boolean;
  private onZoneActionSaved = new Subject<AccessZoneAction>();

  public currentZoneAction: AccessZoneAction = new AccessZoneAction();
  public accessZone: AccessZone = new AccessZone();
  public allRooms: Room[];
  public allDevices: Device[];
  public outputFilteredDevices: Device[];
  public inputFilteredDevices: Device[];
  public deviceFunctions: DeviceFunction[];

  formGroup: FormGroup;
  zoneActionSaved$ = this.onZoneActionSaved.asObservable();

  get inputRoom() {
    return this.formGroup.get('inputRoom');
  }

  get inputDevice() {
    return this.formGroup.get('inputDevice');
  }

  get outputRoom() {
    return this.formGroup.get('outputRoom');
  }

  get outputDevice() {
    return this.formGroup.get('outputDevice');
  }

  get waitTime() {
    return this.formGroup.get('waitTime');
  }

  get deviceFunction() {
    return this.formGroup.get('deviceFunction');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminAccessZoneActionEditorComponent>,
    private alertService: AlertService,
    private zoneActionService: AccessZoneActionService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { accessZone: AccessZone, alarmProfile: AlarmProfile, zoneAction: AccessZoneAction, rooms: Room[], devices: Device[], actionType: ZoneActionType },
  ) {
    this.currentZoneAction = data.zoneAction;
    this.accessZone = data.accessZone;
    this.allRooms = data.rooms;
    this.allDevices = data.devices;

    if (this.currentZoneAction && this.currentZoneAction.outputDevice && this.currentZoneAction.outputDevice) {
      this.deviceFunctions = this.currentZoneAction.outputDevice.functions;
    }

    if (this.currentZoneAction && this.currentZoneAction.outputDevice && this.currentZoneAction.outputDevice.roomId) {
      this.outputFilteredDevices = this.allDevices.filter(x => x.room && x.room.id == this.currentZoneAction.outputDevice.roomId);
    }
    else {
      this.outputFilteredDevices = this.allDevices;
    }

    if (this.currentZoneAction && this.currentZoneAction.inputDevice && this.currentZoneAction.inputDevice.roomId) {
      this.inputFilteredDevices = this.allDevices.filter(x => x.room && x.room.id == this.currentZoneAction.inputDevice.roomId);
    }
    else {
      this.inputFilteredDevices = this.allDevices;
    }

    this.buildForm();
    this.resetForm();
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
    if (this.isSaving) {
      return;
    }
     
    if (!this.formGroup.valid) {
      this.alertService.showValidationError();
      return;
    }

    let savingLabel = this.translationService.getTranslation(`shared.SavingChanges`);

    this.disableSaveButton();
    this.alertService.startLoadingMessage(savingLabel);

    const editedZoneAction = this.getEditedAction();

    if (this.currentZoneAction.id == 0) {
      this.isNewAction = true;
      this.zoneActionService.newAccessZoneAction(this.accessZone.id, editedZoneAction).subscribe(
        action => this.saveSuccessHelper(action),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewAction = false;
      this.zoneActionService.updateAccessZoneAction(this.accessZone.id, editedZoneAction).subscribe(
        action => this.saveSuccessHelper(editedZoneAction),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedAction(): AccessZoneAction {
    const formModel = this.formGroup.value;

    return {
      id: this.currentZoneAction.id,
      waitTime: formModel.waitTime,
      accessZoneId: this.accessZone.id,
      inputDevice: this.allDevices.find(x => x.id == formModel.inputDevice),
      outputDevice: this.allDevices.find(x => x.id == formModel.outputDevice),
      deviceFunction: this.deviceFunctions.find(x => x.id == formModel.deviceFunction),
      parameters: []
    };
  }

  private saveSuccessHelper(zoneAction?: AccessZoneAction) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewAction) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onZoneActionSaved.next(zoneAction);

    this.dialogRef.close(zoneAction);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.enableSaveButton();
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.formGroup = this.formBuilder.group({
      waitTime: [0, Validators.required],
      inputRoom: ['', Validators.required],
      inputDevice: ['', Validators.required],
      outputRoom: ['', Validators.required],
      outputDevice: ['', Validators.required],
      deviceFunction: ['', Validators.required]
    });
  }

  public onInputRoomChange(id: any) {

    this.inputDevice.setValue(0);

    this.inputFilteredDevices = this.allDevices.filter(x => x.room && x.room.id == id.value && x.category == DeviceCategory.Keypad);
  }

  public onOutputRoomChange(id: any) {

    this.outputDevice.setValue(0);
    this.deviceFunction.setValue(0);

    this.outputFilteredDevices = this.allDevices.filter(x => x.room && x.room.id == id.value);
  }

  public onOutputDeviceChange(id: any) {

    this.deviceFunction.setValue(0);

    let currentDevice = this.allDevices.find(x => x.id == id.value);
    this.deviceFunctions = currentDevice.functions;
  }

  private resetForm(replace = false) {
    this.formGroup.reset({
      waitTime: this.currentZoneAction.waitTime ?  this.currentZoneAction.waitTime: 0,
      inputRoom: this.currentZoneAction.inputDevice && this.currentZoneAction.inputDevice.room ? this.currentZoneAction.inputDevice.room.id : '',
      inputDevice: this.currentZoneAction.inputDevice ? this.currentZoneAction.inputDevice.id : '',
      outputRoom: this.currentZoneAction.outputDevice && this.currentZoneAction.outputDevice.room ? this.currentZoneAction.outputDevice.room.id : '',
      outputDevice: this.currentZoneAction.outputDevice ? this.currentZoneAction.outputDevice.id : '',
      deviceFunction: this.currentZoneAction.deviceFunction ? this.currentZoneAction.deviceFunction.id : ''
    });
  }
}

