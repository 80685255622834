import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Module } from '../../models/module/module.model';
import { ModuleService } from './module.service';

@Injectable()
export class ModuleResolve implements Resolve<Observable<Module>> {
  constructor(private moduleService: ModuleService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Module> | Promise<Module> | any  {
    let id = +route.params['id'];
    return this.moduleService.getModule(id).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
