import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { Observable } from 'rxjs';
import { ZoneDevice } from '../../models/alarm/zone-device.model';
import { PagedData } from '../../models/PagedData';
import { ZoneDeviceEndpoint } from './zone-device-endpoint.service';


@Injectable()
export class ZoneDeviceService {

  constructor(private router: Router, private http: HttpClient,
    private zoneDeviceEndpoint: ZoneDeviceEndpoint) {

  }
  
  getZoneDevice(id?: number) {
    if (id) {
      return this.zoneDeviceEndpoint.getZoneDeviceEndpoint<ZoneDevice>(id);
    }
  }
  
  getZoneDevices(alarmZoneId: number, alarmProfileId: number, page: number = 0, pageSize: number = 9999) {

    return this.zoneDeviceEndpoint.getZoneDevicesEndpoint<PagedData<ZoneDevice>>(alarmZoneId, alarmProfileId, page, pageSize);
  }

  updateZoneDevice(zoneDevice: ZoneDevice) {
    return this.zoneDeviceEndpoint.getUpdateZoneDeviceEndpoint(zoneDevice, zoneDevice.id);
  }

  newZoneDevice(zoneDevice: ZoneDevice) {
    return this.zoneDeviceEndpoint.getNewZoneDeviceEndpoint<ZoneDevice>(zoneDevice);
  }

  deleteZoneDevice(id): Observable<ZoneDevice> {

    return this.zoneDeviceEndpoint.getDeleteZoneDeviceEndpoint(id);
  }
}
