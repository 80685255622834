import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedData } from '../../models/PagedData';
import {DeviceGroup} from "../../models/deviceGroup/device-group.model";
import {DeviceGroupEndpoint} from "./device-group-endpoint.service";

@Injectable()
export class DeviceGroupService {

  constructor(private router: Router, private http: HttpClient,
    private deviceGroupEndpoint: DeviceGroupEndpoint) {

  }

  getDeviceGroup(id?: number): Observable<DeviceGroup> {
    if (id) {
      return this.deviceGroupEndpoint.getDeviceGroupEndpoint<DeviceGroup>(id);
    }


  }

  getDeviceGroups(page: number = 0, pageSize: number = 9999): Observable<PagedData<DeviceGroup>>{

    return this.deviceGroupEndpoint.getDeviceGroupsEndpoint<PagedData<DeviceGroup>>(page, pageSize);
  }

  updateDeviceGroup(deviceGroup: DeviceGroup): Observable<DeviceGroup> {
    return this.deviceGroupEndpoint.getUpdateDeviceGroupEndpoint(deviceGroup, deviceGroup.id);
  }

  newDeviceGroup(deviceGroup: DeviceGroup): Observable<DeviceGroup>{
    return this.deviceGroupEndpoint.getNewDeviceGroupEndpoint<DeviceGroup>(deviceGroup);
  }

  deleteDeviceGroup(id): Observable<DeviceGroup> {

    return this.deviceGroupEndpoint.getDeleteDeviceGroupEndpoint(id);
  }

  addDevice(id: number, deviceId: number): Observable<DeviceGroup>{
    return this.deviceGroupEndpoint.getAddDeviceEndpoint(id, deviceId);
  }

  deleteDevice(id: number, deviceId: number): Observable<DeviceGroup> {

    return this.deviceGroupEndpoint.getDeleteDeviceEndpoint(id, deviceId);
  }
}
