import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';
import {RoomActionMap} from "../../models/room/room-action-map.model";
import {Room} from "../../models/room/room.model";
import {RoomActionFunctionParameter} from "../../models/room/room-action-function-parameter.model";


@Injectable()
export class RoomActionEndpoint extends EndpointFactory {

  private readonly _roomActionUrl: string = "/api/roomAction";
  private readonly _masterDeviceUrl: string = "/api/masterSettings";

  get roomActionUrl() { return this.configurations.baseUrl + this._roomActionUrl; }
  get masterDeviceUrl() { return this.configurations.baseUrl + this._masterDeviceUrl; }

  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }

  getRoomActionEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.roomActionUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRoomActionEndpoint(id));
      }));
  }

  getRoomActionsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._roomActionUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRoomActionsEndpoint(page, pageSize));
      }));
  }


  getNewRoomActionEndpoint<T>(roomActionObject: any): Observable<T> {

    return this.http.post<T>(this.roomActionUrl, JSON.stringify(roomActionObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewRoomActionEndpoint(roomActionObject));
      }));
  }

  getUpdateRoomActionEndpoint<T>(roomActionObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.roomActionUrl}/${id}` : `${this.roomActionUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(roomActionObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateRoomActionEndpoint(roomActionObject, id));
      }));
  }

  getDeleteRoomActionEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.roomActionUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteRoomActionEndpoint(id));
      }));
  }

  getExecuteActionEndpoint<T>(id:number, actionId:number): Observable<T> {

    let endpointUrl = `${this.masterDeviceUrl}/${id}/ExecuteAction/${actionId}`;

    return this.http.post<T>(endpointUrl, JSON.stringify({ "id" : id, "actionId": actionId }), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getExecuteActionEndpoint(id, actionId));
      }));
  }

  getRoomActionMaps<T>(roomActionId:number, page?: number, pageSize?: number): Observable<T> {

    let endpointUrl = `${this.roomActionUrl}/${roomActionId}/maps?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRoomActionMaps(roomActionId, page, pageSize));
      }));
  }

  getRoomActionMap<RoomActionMap>(roomActionId:number, mapId:number): Observable<RoomActionMap> {

    let endpointUrl = `${this.roomActionUrl}/${roomActionId}/maps/${mapId}`;

    return this.http.get<RoomActionMap>(endpointUrl, this.getRequestHeaders()).pipe<RoomActionMap>(
      catchError(error => {
        return this.handleError(error, () => this.getRoomActionMap(roomActionId, mapId));
      }));
  }

  createRoomActionMap<RoomActionMap>(roomActionId:number, map:RoomActionMap): Observable<RoomActionMap> {

    let endpointUrl = `${this.roomActionUrl}/${roomActionId}/maps/`;

    return this.http.post<RoomActionMap>(endpointUrl, JSON.stringify(map), this.getRequestHeaders()).pipe<RoomActionMap>(
      catchError(error => {
        return this.handleError(error, () => this.createRoomActionMap(roomActionId, map));
      }));
  }

  updateRoomActionMap<RoomActionMap>(roomActionId:number, mapId:number, map:RoomActionMap): Observable<RoomActionMap> {

    let endpointUrl = `${this.roomActionUrl}/${roomActionId}/maps/${mapId}`;

    return this.http.put<RoomActionMap>(endpointUrl, JSON.stringify(map), this.getRequestHeaders()).pipe<RoomActionMap>(
      catchError(error => {
        return this.handleError(error, () => this.createRoomActionMap(roomActionId, map));
      }));
  }

  deleteRoomActionMap<T>(roomActionId:number, mapId:number): Observable<T> {

    let endpointUrl = `${this.roomActionUrl}/${roomActionId}/maps/${mapId}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteRoomActionMap(roomActionId, mapId));
      }));
  }

  getRoomActionMapParameters<T>(roomActionId:number, mapId:number, page?: number, pageSize?: number): Observable<T> {

    let endpointUrl = `${this.roomActionUrl}/${roomActionId}/maps/${mapId}/parameters`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getRoomActionMapParameters(roomActionId, mapId, page, pageSize));
      }));
  }

  updateRoomActionMapParameter<T>(roomActionId:number, mapId:number, parameterId:number, parameter:RoomActionFunctionParameter): Observable<T> {

    let endpointUrl = `${this.roomActionUrl}/${roomActionId}/maps/${mapId}/parameters/${parameterId}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(parameter), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateRoomActionMapParameter(roomActionId, mapId, parameterId, parameter));
      }));
  }
}
