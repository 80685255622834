import { Component, Input, ViewChild, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';

import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { CabinetService } from "../../../services/cabinet/cabinet.service";
import { Cabinet } from '../../../models/cabinet/cabinet.model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Floor } from '../../../models/floor/floor.model';
import {AppTranslationService} from "../../../services/app-translation.service";

@Component({
  selector: 'admin-cabinet-editor',
  templateUrl: './admin-cabinet-editor.component.html',
  styleUrls: ['./admin-cabinet-editor.component.scss']
})
export class AdminCabinetEditorComponent {
  @ViewChild('form', { static: true })
  private form: NgForm;

  private isNewCabinet = false;
  private isSaving: boolean;
  private onCabinetSaved = new Subject<Cabinet>();
  public allFloors: Floor[];

  cabinet: Cabinet = new Cabinet();

  cabinetForm: FormGroup;
  cabinetSaved$ = this.onCabinetSaved.asObservable();

  get name() {
    return this.cabinetForm.get('name');
  }

  get description() {
    return this.cabinetForm.get('description');
  }

  get floor() {
    return this.cabinetForm.get('floor');
  }

  constructor(
    public dialogRef: MatDialogRef<AdminCabinetEditorComponent>,
    private alertService: AlertService,
    private cabinetService: CabinetService,
    private formBuilder: FormBuilder,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { cabinet: Cabinet, floors: Floor[] },
  ) {
    this.cabinet = data.cabinet;
    this.allFloors = data.floors;
    this.buildForm();
    this.resetForm();
  }

  ngOnChanges() {
    this.resetForm();
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {
     
    if (!this.cabinetForm.valid) {
      this.alertService.showValidationError();
      return;
    }

    this.disableSaveButton();
    this.alertService.startLoadingMessage(this.translationService.getTranslation(`shared.SavingChanges`));

    const editedCabinet = this.getEditedCabinet();

    if (this.cabinet.id == 0) {
      this.isNewCabinet = true;
      this.cabinetService.newCabinet(editedCabinet).subscribe(
        cabinet => this.saveSuccessHelper(editedCabinet),
        error => this.saveFailedHelper(error));

    }
    else {
      this.isNewCabinet = false;
      this.cabinetService.updateCabinet(editedCabinet).subscribe(
        response => this.saveSuccessHelper(editedCabinet),
        error => this.saveFailedHelper(error));
    }
  }

  private getEditedCabinet(): Cabinet {
    const formModel = this.cabinetForm.value;

    return {
      id: this.cabinet.id,
      name: formModel.name,
      description: formModel.description,
      floor: this.allFloors.find(x => x.id == formModel.floor)
    };
  }

  private saveSuccessHelper(cabinet?: Cabinet) {
    this.alertService.stopLoadingMessage();

    let createSucessLabel = this.translationService.getTranslation(`shared.DataCreated`);
    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    if (this.isNewCabinet) {
      this.alertService.showMessage(successLabel, createSucessLabel, MessageSeverity.success);
    }
    else {
      this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);
    }

    this.onCabinetSaved.next(cabinet);
    this.cabinet = cabinet;

    this.dialogRef.close(cabinet);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public cancel() {
    this.resetForm();

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }

  private buildForm() {
    this.cabinetForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: '',
      floor: ['', Validators.required],
    });
  }

  private resetForm(replace = false) {
    this.cabinetForm.reset({
      name: this.cabinet.name || '',
      description: this.cabinet.description || '',
      floor: this.cabinet.floor && this.cabinet.floor.id ? this.cabinet.floor.id : ''
    });
  }
}
