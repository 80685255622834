import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ModulePortMapService } from './module-port-map.service';
import { Observable } from 'rxjs';
import { ModulePortMap } from '../../models/module/module-port-map.model';
import { PagedData } from '../../models/PagedData';

@Injectable()
export class ModulePortMapResolve implements Resolve<Observable<PagedData<ModulePortMap>>> {
  constructor(private modulePortMapService: ModulePortMapService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<PagedData<ModulePortMap>> | Promise<PagedData<ModulePortMap>> | any  {
    let id = +route.params['id'];
    return this.modulePortMapService.getModulePortMaps(id, 0, 999).catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
