import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ConfigurationService } from '../configuration.service';
import { EndpointFactory } from '../endpoint-factory.service';
import { AlarmProfile } from 'src/app/models/alarm/alarm-profile.model';



@Injectable()
export class ZoneDeviceEndpoint extends EndpointFactory {

  private readonly _zoneDeviceUrl: string = "/api/zonedevice";

  get alarmzoneUrl() { return this.configurations.baseUrl + this._zoneDeviceUrl; }


  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getZoneDeviceEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.alarmzoneUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getZoneDeviceEndpoint(id));
      }));
  }
  
  getZoneDevicesEndpoint<T>(alarmZoneId?: number, alarmProfileId?: number, page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._zoneDeviceUrl}?alarmZoneId=${alarmZoneId}&alarmProfileId=${alarmProfileId}&page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getZoneDevicesEndpoint(alarmZoneId, alarmProfileId, page, pageSize));
      }));
  }

  getNewZoneDeviceEndpoint<T>(zoneDeviceObject: any): Observable<T> {

    return this.http.post<T>(this.alarmzoneUrl, JSON.stringify(zoneDeviceObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewZoneDeviceEndpoint(zoneDeviceObject));
      }));
  }

  getUpdateZoneDeviceEndpoint<T>(zoneDeviceObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.alarmzoneUrl}/${id}` : `${this.alarmzoneUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(zoneDeviceObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateZoneDeviceEndpoint(zoneDeviceObject, id));
      }));
  }

  getDeleteZoneDeviceEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.alarmzoneUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteZoneDeviceEndpoint(id));
      }));
  }
}
