import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { TableDataSource } from 'angular4-material-table';
import { Subject, concat } from 'rxjs';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { FunctionValidatorService } from '../../../services/device/function-validator.service';
import { ParameterValidatorService } from '../../../services/device/parameter-validator.service';
import { AutomationRuleAction } from 'src/app/models/automationRule/automation-rule-action.model';
import { AutomationRuleActionParameter } from 'src/app/models/automationRule/automation-rule-action-parameter.model';
import { AutomationRuleActionService } from 'src/app/services/automationRule/automation-rule-action.service';
import {AppTranslationService} from "../../../services/app-translation.service";
import { Device } from '../../../models/device/device.model';



@Component({
  selector: 'admin-automation-rule-action-settings',
  templateUrl: './admin-automation-rule-action-settings.component.html',
  styleUrls: ['./admin-automation-rule-action-settings.component.scss']
})
export class AdminAutomationRuleActionSettingsEditorComponent implements OnInit {

  private isSaving = false;

  public automationRuleAction: AutomationRuleAction = new AutomationRuleAction();

  public allParameters: AutomationRuleActionParameter[];
  public allDevices: Device[];
  public parametersListChange = new EventEmitter<AutomationRuleActionParameter[]>();
  parametersDisplayedColumns = ['code', 'description', 'source', 'deviceId', 'value', 'actionsColumn'];
  parametersDataSource: TableDataSource<AutomationRuleActionParameter>;

  constructor(
    public dialogRef: MatDialogRef<AdminAutomationRuleActionSettingsEditorComponent>,
    private alertService: AlertService,
    private automationRuleActionService: AutomationRuleActionService,
    private functionsValidator: FunctionValidatorService,
    private parameterValidator: ParameterValidatorService,
    private translationService: AppTranslationService,
    @Inject(MAT_DIALOG_DATA) public data: { automationRuleAction: AutomationRuleAction, allDevices: Device[]},
  ) {
    const self = this;

    this.automationRuleAction = data.automationRuleAction;
    this.allParameters = this.automationRuleAction.parameters;
    this.allDevices = data.allDevices;
    this.parametersDataSource = new TableDataSource<AutomationRuleActionParameter>(this.allParameters, AutomationRuleActionParameter, this.parameterValidator);
    this.parametersDataSource.datasourceSubject.subscribe(parameters => {

      self.allParameters = parameters;
      this.parametersListChange.emit(parameters)

    });
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

  private disableSaveButton() {
    this.isSaving = true;screenLeft
  }

  private enableSaveButton() {
      this.isSaving = false;
  }

  public save() {

    this.disableSaveButton();
    let savingLabel = this.translationService.getTranslation(`shared.SavingChanges`);
    this.alertService.startLoadingMessage(savingLabel);

    this.automationRuleAction.parameters = this.allParameters;

    this.automationRuleActionService.updateAutomationRuleAction(this.automationRuleAction).subscribe(
      response => this.saveSuccessHelper(this.automationRuleAction),
      error => this.saveFailedHelper(error));
  }

  private saveSuccessHelper(automationRuleAction?: AutomationRuleAction) {
    this.alertService.stopLoadingMessage();

    let updateSucessLabel = this.translationService.getTranslation(`shared.DataUpdated`);
    let successLabel = this.translationService.getTranslation(`shared.Success`);

    this.alertService.showMessage(successLabel, updateSucessLabel, MessageSeverity.success);

    this.dialogRef.close(automationRuleAction);
    this.enableSaveButton();
  }

  private saveFailedHelper(error: any) {

    let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
    let errorLabel = this.translationService.getTranslation(`shared.SaveError`);

    this.enableSaveButton();
    this.alertService.stopLoadingMessage();
    this.alertService.showStickyMessage(errorLabel, errorDetail, MessageSeverity.error, error);
    this.alertService.showStickyMessage(error, null, MessageSeverity.error);
  }

  public updateRowDevice(row: any, deviceId: number) {

    var device = this.allDevices.find(x => x.id == deviceId);

    row.currentData.device = device;
    
  }


  public getDeviceValues(deviceId: any) {

    console.log("getDeviceValues");


    var device = this.allDevices.find(x => x.id == deviceId);
    if (device) {
      return device.values;
    }

    return [];
  }

  public cancel() {

    this.alertService.resetStickyMessage();

    this.dialogRef.close(null);
  }
}
