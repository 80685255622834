import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Room } from '../../models/room/room.model';
import { RoomService } from './room.service';

@Injectable()
export class RoomResolve implements Resolve<Observable<Room>> {
  constructor(private roomService: RoomService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Room> | Promise<Room> | any {
    let id = +route.params['id'];
    return this.roomService.getRoom(id).catch (error => {
      return Observable.of(null);
    });
  }
}
