import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Device } from '../../models/device/device.model';
import { MasterDevice } from '../../models/master-device.model';
import { MasterDeviceService } from './master-device.service';

@Injectable()
export class MasterDeviceResolve implements Resolve<Observable<MasterDevice>> {
  constructor(private deviceService: MasterDeviceService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<MasterDevice> | Promise<MasterDevice> | any  {
    return this.deviceService.getMasterDevice().catch(error => {
      console.log(`Retrieval error: ${error}`);
      return Observable.of(null);
    });
  }
}
