import { Component, Input, OnInit } from '@angular/core';
import { AppTranslationService } from 'src/app/services/app-translation.service';
import { FirmwareVersion } from '../../models/firmwareVersion/firmware-version.model';
import { FirmwareType } from '../../models/firmwareVersion/firmware-type.model';
import { FirmwareVersionService } from '../../services/firmwareVersion/firmwareVersion.service';
import { FirmwareStatus } from '../../models/firmwareVersion/firmware-status.model';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'firmware-card',
    templateUrl: './firmware-card.component.html',
    styleUrls: ['./firmware-card.component.scss']
})
export class FirmwareCardComponent implements OnInit {
    @Input() firmware: FirmwareVersion;
    public isLoading: boolean = false;
    public isUpdated: boolean = false;
    public receivedUpdateMessage: boolean = false;

    iconMaster = require("../../assets/images/master01.svg");
    iconModule = require("../../assets/images/ioc.svg");
    iconAlarm = require("../../assets/images/escudo.svg");
    icon = require("../../assets/images/ulumin.svg");
    public canUpdate: boolean = true;

    constructor(private firmwareService: FirmwareVersionService, private translationService: AppTranslationService, private router: Router, private authService: AuthService) {}

    ngOnInit() {}

    updateFirmware() {
        this.startLoading();
        this.firmwareService.updateFirmware([this.firmware.moduleId]).subscribe();
    }

    public navigateToDetails() {
        if (this.firmware.firmwareType == FirmwareType.Master) {
            this.router.navigate(['/admin/masterdevice']);
        }
    }

    public translateStatus(status: FirmwareStatus): string {
        try {
            return this.translationService.getTranslation(`lookups.FirmwareStatus.${FirmwareStatus[status]}`);
        } catch (e) {
            return status.toString();
        }
    }

    public getIcon(): string {
        switch (this.firmware.firmwareType) {
            case FirmwareType.Module: {
                return this.iconModule;
            }
            case FirmwareType.Master: {
                return this.iconMaster;
            }
            case FirmwareType.Alarm: {
                return this.iconAlarm;
            }
            default:
                return this.icon;
        }
    }

    public startLoading() {
        this.isLoading = true;
        this.isUpdated = false;
        this.canUpdate = false;
        
        setTimeout(()=>{
            this.cancelLoading();
        }, 30000);
    }

    private cancelLoading() {
        this.isLoading = false;
        this.isUpdated = false;
        this.canUpdate = true;
    }

    public stopLoading() {
        this.isLoading = false;
        this.isUpdated = true;
        this.canUpdate = true;
    }
}
