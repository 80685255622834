import { Component, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { MasterDevice } from '../../models/master-device.model';
import { fadeInOut } from '../../services/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { UploadDownloadService } from '../../upload-download.service';


@Component({
  selector: 'restoring',
  templateUrl: './restoring.component.html',
  styleUrls: ['./restoring.component.scss'],
  animations: [fadeInOut]
})
export class RestoringComponent implements OnInit {
 
  public isUpdating: boolean;
  public percentage: number = 0;
  timerId;

  ngOnInit(): void {
    this.percentage = 0;
    this.timerId = setInterval(() => { this.updatePercentage() }, 500);
  }
  
  constructor(
    private router: Router,
  ) {

  }

  updatePercentage() {

    this.percentage = this.percentage + 1;

    if (this.percentage>=100) {
      this.router.navigateByUrl('/');

      this.stopInterval();
    }

  }

  stopInterval() {
    clearInterval(this.timerId);
  }
}
