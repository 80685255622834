import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material';

import { AlertService, MessageSeverity } from '../services/alert.service';
import { ConfigurationService } from '../services/configuration.service';
import { AppTranslationService } from "../services/app-translation.service";
import { AccountService } from '../services/account.service';
import { Utilities } from '../services/utilities';

export interface PageInfo
{
    title: string;
    icon: string;
    path: string;
    isDefault: boolean;
}

export interface LanguagePreference
{
    name: string;
    locale: string;
    isDefault: boolean;
}

@Component({
    selector: 'user-preferences',
    templateUrl: './user-preferences.component.html',
    styleUrls: ['./user-preferences.component.scss']
})
export class UserPreferencesComponent
{
    languages: LanguagePreference[] = [
        { name: 'English',    locale: 'en', isDefault: false },
        { name: 'Portuguese', locale: 'pt', isDefault: true },
    ]

    homePages: PageInfo[] = [
        { title: 'Dashboard', icon: 'dashboard', path: '/',         isDefault: false },
      { title: 'Divsions', icon: 'picture_in_picture',      path: '/rooms',    isDefault: true }
    ]

    constructor(
        private alertService: AlertService,
        private translationService: AppTranslationService,
        private accountService: AccountService,
        private snackBar: MatSnackBar,
        public configurations: ConfigurationService
    ) { }

    get currentHomePage(): PageInfo
    {
        return this.homePages.find(x => x.path == this.configurations.homeUrl)  || this.homePages[0];
    }

    reload()
    {

      let defaultsLoadedLabel = this.translationService.getTranslation(`shared.DefaultsLoadedDetail`);
      let reloadLabel = this.translationService.getTranslation(`shared.ReloadLabel`);
      let reloadQuestionLabel = this.translationService.getTranslation(`shared.ReloadQuestion`);
      let errorDetail = this.translationService.getTranslation(`shared.LoadErrorDetail`);
      let errorLabel = this.translationService.getTranslation(`shared.LoadError`);

      this.snackBar.open(reloadQuestionLabel, reloadLabel, { duration: 5000 })
            .onAction().subscribe(() =>
            {
                this.alertService.startLoadingMessage();

                this.accountService.getUserPreferences()
                    .subscribe(results =>
                    {
                        this.alertService.stopLoadingMessage();

                        this.configurations.import(results);

                      this.alertService.showMessage(defaultsLoadedLabel, "", MessageSeverity.info);

                    },
                    error =>
                    {
                        this.alertService.stopLoadingMessage();
                      this.alertService.showStickyMessage(errorLabel, errorDetail,
                            MessageSeverity.error, error);
                    });
            });
    }

    save()
    {

      let saveLabel = this.translationService.getTranslation(`shared.SaveLabel`);
      let saveQuestion = this.translationService.getTranslation(`shared.SaveQuestion`);
      let errorDetail = this.translationService.getTranslation(`shared.SaveErrorDetail`);
      let errorLabel = this.translationService.getTranslation(`shared.SaveError`);
      let successLabel = this.translationService.getTranslation(`shared.Success`);

      this.snackBar.open(saveQuestion, saveLabel, { duration: 5000 })
            .onAction().subscribe(() =>
            {
                this.accountService.updateUserPreferences(this.configurations.export())
                    .subscribe(response =>
                    {
                        this.alertService.stopLoadingMessage();
                      this.alertService.showMessage(successLabel, successLabel, MessageSeverity.success)

                    },
                    error =>
                    {
                        this.alertService.stopLoadingMessage();
                      this.alertService.showStickyMessage(errorLabel, errorDetail,
                            MessageSeverity.error, error);
                    });
            });
    }

    reset()
    {
      let resetLabel = this.translationService.getTranslation(`shared.ResetLabel`);
      let resetQuestion = this.translationService.getTranslation(`shared.ResetQuestion`);
      let defaultsDetail = this.translationService.getTranslation(`shared.DefaultsLoadedDetail`);
      let newDefaultsLabel = this.translationService.getTranslation(`shared.NewDefaultsLabel`);

      this.snackBar.open(resetQuestion, resetLabel, { duration: 5000 })
            .onAction().subscribe(() =>
            {
                this.configurations.import(null);
              this.alertService.showMessage(newDefaultsLabel, defaultsDetail, MessageSeverity.success);
            });
    }
}
