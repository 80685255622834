import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EndpointFactory } from '../endpoint-factory.service';
import { ConfigurationService } from '../configuration.service';
import { endianness } from 'os';


@Injectable()
export class AppNotificationEndpoint extends EndpointFactory {

  private readonly _appNotificationUrl: string = "/api/appNotification";

  get appNotificationUrl() { return this.configurations.baseUrl + this._appNotificationUrl; }


  constructor(http: HttpClient, configurations: ConfigurationService, injector: Injector) {

    super(http, configurations, injector);
  }
  
  getAppNotificationEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.appNotificationUrl}/${id}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAppNotificationEndpoint(id));
      }));
  }

  getAppNotificationsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {

    if (!page) {
      page = 0;
    }

    if (!pageSize || pageSize == 0) {
      pageSize = 1;
    }

    let endpointUrl = `${this._appNotificationUrl}?page=${page}&size=${pageSize}`;

    return this.http.get<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAppNotificationsEndpoint(page, pageSize));
      }));
  }

  getUpdateAppNotificationEndpoint<T>(appNotificationObject: any, id: number): Observable<T> {
    let endpointUrl = id ? `${this.appNotificationUrl}/${id}` : `${this.appNotificationUrl}`;

    return this.http.put<T>(endpointUrl, JSON.stringify(appNotificationObject), this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateAppNotificationEndpoint(appNotificationObject, id));
      }));
  }

  markAllAsRead<T>(): Observable<T> {
    let endpointUrl = `${this.appNotificationUrl}/markallread`;

    return this.http.put<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.markAllAsRead());
      }));
  }

  markAsRead<T>(id: number): Observable<T> {
    let endpointUrl = `${this.appNotificationUrl}/${id}/markread`;

    return this.http.put<T>(endpointUrl, null, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.markAsRead(id));
      }));
  }

  delete<T>(id: number): Observable<T> {
    let endpointUrl = `${this.appNotificationUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.delete(id));
      }));
  }

  deleteAll<T>(): Observable<T> {
    let endpointUrl = `${this.appNotificationUrl}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.deleteAll());
      }));
  }

  getDeleteAppNotificationEndpoint<T>(id: number): Observable<T> {
    let endpointUrl = `${this.appNotificationUrl}/${id}`;

    return this.http.delete<T>(endpointUrl, this.getRequestHeaders()).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getDeleteAppNotificationEndpoint(id));
      }));
  }
}
